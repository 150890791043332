
define("json!benchmark/../../data/cities.json", function(){ return [
  {
    "country": "United States",
    "cities": [
      {
        "city": "New York",
        "zip": "10001",
        "country": "USA",
        "lon": "-73.996328",
        "cz": "4A",
        "state_id": "NY",
        "file": "0-14734",
        "lat": "40.750422"
      },
      {
        "city": "Los Angeles",
        "zip": "90001",
        "country": "USA",
        "lon": "-118.248405",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23174",
        "lat": "33.973951"
      },
      {
        "city": "Chicago",
        "zip": "60601",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Houston",
        "zip": "77001",
        "country": "USA",
        "lon": "-95.309789",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.813142"
      },
      {
        "city": "Philadelphia",
        "zip": "19019",
        "country": "USA",
        "lon": "-75.11787",
        "cz": "4A",
        "state_id": "PA",
        "file": "0-13739",
        "lat": "40.001811"
      },
      {
        "city": "Phoenix",
        "zip": "85001",
        "country": "USA",
        "lon": "-112.351835",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.703967"
      },
      {
        "city": "San Antonio",
        "zip": "78201",
        "country": "USA",
        "lon": "-98.537495",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12921",
        "lat": "29.399933"
      },
      {
        "city": "San Diego",
        "zip": "92101",
        "country": "USA",
        "lon": "-117.174366",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.7211"
      },
      {
        "city": "Dallas",
        "zip": "75201",
        "country": "USA",
        "lon": "-96.790329",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.781179"
      },
      {
        "city": "San Jose",
        "zip": "95101",
        "country": "USA",
        "lon": "-121.705327",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.189396"
      },
      {
        "city": "Austin",
        "zip": "73301",
        "country": "USA",
        "lon": "-97.771258",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-13958",
        "lat": "30.326374"
      },
      {
        "city": "Indianapolis",
        "zip": "46201",
        "country": "USA",
        "lon": "-86.13216",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "39.775092"
      },
      {
        "city": "Jacksonville",
        "zip": "32099",
        "country": "USA",
        "lon": "-81.768622",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-13889",
        "lat": "30.337538"
      },
      {
        "city": "San Francisco",
        "zip": "94101",
        "country": "USA",
        "lon": "-122.727802",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.784827"
      },
      {
        "city": "Columbus",
        "zip": "43085",
        "country": "USA",
        "lon": "-82.897222",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14821",
        "lat": "40.040113"
      },
      {
        "city": "Charlotte",
        "zip": "28201",
        "country": "USA",
        "lon": "-80.804151",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13881",
        "lat": "35.26002"
      },
      {
        "city": "Fort Worth",
        "zip": "76101",
        "country": "USA",
        "lon": "-97.291484",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.771419"
      },
      {
        "city": "Detroit",
        "zip": "48201",
        "country": "USA",
        "lon": "-83.150823",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.239933"
      },
      {
        "city": "El Paso",
        "zip": "79901",
        "country": "USA",
        "lon": "-106.484592",
        "cz": "3B",
        "state_id": "TX",
        "file": "0-23044",
        "lat": "31.763608"
      },
      {
        "city": "Memphis",
        "zip": "37501",
        "country": "USA",
        "lon": "-89.990415",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13893",
        "lat": "35.169255"
      },
      {
        "city": "Seattle",
        "zip": "98101",
        "country": "USA",
        "lon": "-121.803388",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.432251"
      },
      {
        "city": "Denver",
        "zip": "80201",
        "country": "USA",
        "lon": "-104.856808",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "39.726303"
      },
      {
        "city": "Boston",
        "zip": "02101",
        "country": "USA",
        "lon": "-71.026964",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.370567"
      },
      {
        "city": "Baltimore",
        "zip": "21201",
        "country": "USA",
        "lon": "-76.623489",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93721",
        "lat": "39.296536"
      },
      {
        "city": "Oklahoma City",
        "zip": "73101",
        "country": "USA",
        "lon": "-97.562817",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13967",
        "lat": "35.491608"
      },
      {
        "city": "Portland",
        "zip": "97201",
        "country": "USA",
        "lon": "-122.693157",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24229",
        "lat": "45.498931"
      },
      {
        "city": "Las Vegas",
        "zip": "89101",
        "country": "USA",
        "lon": "-115.10647",
        "cz": "5B",
        "state_id": "NV",
        "file": "0-23169",
        "lat": "36.17372"
      },
      {
        "city": "Milwaukee",
        "zip": "53201",
        "country": "USA",
        "lon": "-87.958409",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "43.011264"
      },
      {
        "city": "Albuquerque",
        "zip": "87101",
        "country": "USA",
        "lon": "-106.644831",
        "cz": "4B",
        "state_id": "NM",
        "file": "0-23050",
        "lat": "35.199592"
      },
      {
        "city": "Tucson",
        "zip": "85701",
        "country": "USA",
        "lon": "-110.970869",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23160",
        "lat": "32.217975"
      },
      {
        "city": "Fresno",
        "zip": "93650",
        "country": "USA",
        "lon": "-119.801015",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "36.8411"
      },
      {
        "city": "Sacramento",
        "zip": "94203",
        "country": "USA",
        "lon": "-121.555406",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.380456"
      },
      {
        "city": "Long Beach",
        "zip": "90801",
        "country": "USA",
        "lon": "-118.200957",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.804309"
      },
      {
        "city": "Kansas City",
        "zip": "64101",
        "country": "USA",
        "lon": "-94.598583",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-3947",
        "lat": "39.102404"
      },
      {
        "city": "Mesa",
        "zip": "85201",
        "country": "USA",
        "lon": "-111.856967",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.440695"
      },
      {
        "city": "Virginia Beach",
        "zip": "23450",
        "country": "USA",
        "lon": "-76.12036",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "36.844004"
      },
      {
        "city": "Atlanta",
        "zip": "30301",
        "country": "USA",
        "lon": "-84.47405",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-13874",
        "lat": "33.844371"
      },
      {
        "city": "Colorado Springs",
        "zip": "80901",
        "country": "USA",
        "lon": "-104.857828",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-93037",
        "lat": "38.861469"
      },
      {
        "city": "Omaha",
        "zip": "68101",
        "country": "USA",
        "lon": "-96.171104",
        "cz": "5A",
        "state_id": "NE",
        "file": "0-94918",
        "lat": "41.291736"
      },
      {
        "city": "Raleigh",
        "zip": "27601",
        "country": "USA",
        "lon": "-78.604175",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.773755"
      },
      {
        "city": "Miami",
        "zip": "33101",
        "country": "USA",
        "lon": "-80.19782",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "25.779076"
      },
      {
        "city": "Oakland",
        "zip": "94601",
        "country": "USA",
        "lon": "-122.223779",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.786027"
      },
      {
        "city": "Minneapolis",
        "zip": "55401",
        "country": "USA",
        "lon": "-93.273024",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.979265"
      },
      {
        "city": "Tulsa",
        "zip": "74101",
        "country": "USA",
        "lon": "-95.868667",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13968",
        "lat": "36.039147"
      },
      {
        "city": "Cleveland",
        "zip": "44101",
        "country": "USA",
        "lon": "-81.599648",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.523401"
      },
      {
        "city": "Wichita",
        "zip": "67201",
        "country": "USA",
        "lon": "-97.258997",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-3928",
        "lat": "37.651974"
      },
      {
        "city": "Arlington",
        "zip": "76001",
        "country": "USA",
        "lon": "-97.291484",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.771419"
      },
      {
        "city": "New Orleans",
        "zip": "70112",
        "country": "USA",
        "lon": "-90.077",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-12916",
        "lat": "29.958304"
      },
      {
        "city": "Bakersfield",
        "zip": "93301",
        "country": "USA",
        "lon": "-119.007662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23155",
        "lat": "35.483501"
      },
      {
        "city": "Tampa",
        "zip": "33601",
        "country": "USA",
        "lon": "-82.582035",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.996097"
      },
      {
        "city": "Honolulu",
        "zip": "96801",
        "country": "USA",
        "lon": "-168.021815",
        "cz": "1A",
        "state_id": "HI",
        "file": "0-22536",
        "lat": "24.859832"
      },
      {
        "city": "Aurora",
        "zip": "80010",
        "country": "USA",
        "lon": "-104.84748",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "39.715287"
      },
      {
        "city": "Anaheim",
        "zip": "92801",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Santa Ana",
        "zip": "92701",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Riverside",
        "zip": "92501",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Corpus Christi",
        "zip": "78401",
        "country": "USA",
        "lon": "-97.648158",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12924",
        "lat": "27.759399"
      },
      {
        "city": "Pittsburgh",
        "zip": "15201",
        "country": "USA",
        "lon": "-80.024817",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-94823",
        "lat": "40.434436"
      },
      {
        "city": "Anchorage",
        "zip": "99501",
        "country": "USA",
        "lon": "-149.486981",
        "cz": "7",
        "state_id": "AK",
        "file": "0-26451",
        "lat": "61.287624"
      },
      {
        "city": "Stockton",
        "zip": "95201",
        "country": "USA",
        "lon": "-121.298794",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.672196"
      },
      {
        "city": "Cincinnati",
        "zip": "45201",
        "country": "USA",
        "lon": "-84.53822",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-93814",
        "lat": "39.166759"
      },
      {
        "city": "Toledo",
        "zip": "43601",
        "country": "USA",
        "lon": "-83.569359",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-94830",
        "lat": "41.720684"
      },
      {
        "city": "Greensboro",
        "zip": "27401",
        "country": "USA",
        "lon": "-79.831692",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13723",
        "lat": "36.055285"
      },
      {
        "city": "Newark",
        "zip": "07101",
        "country": "USA",
        "lon": "-74.22509",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.736101"
      },
      {
        "city": "Plano",
        "zip": "75023",
        "country": "USA",
        "lon": "-96.659574",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.104885"
      },
      {
        "city": "Henderson",
        "zip": "89009",
        "country": "USA",
        "lon": "-114.972061",
        "cz": "5B",
        "state_id": "NV",
        "file": "0-23169",
        "lat": "35.927901"
      },
      {
        "city": "Lincoln",
        "zip": "68501",
        "country": "USA",
        "lon": "-96.823133",
        "cz": "5A",
        "state_id": "NE",
        "file": "0-94918",
        "lat": "40.865142"
      },
      {
        "city": "Buffalo",
        "zip": "14201",
        "country": "USA",
        "lon": "-78.832706",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14733",
        "lat": "42.929303"
      },
      {
        "city": "Jersey City",
        "zip": "07097",
        "country": "USA",
        "lon": "-74.075485",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.73276"
      },
      {
        "city": "Chula Vista",
        "zip": "91909",
        "country": "USA",
        "lon": "-116.846046",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.016928"
      },
      {
        "city": "Fort Wayne",
        "zip": "46801",
        "country": "USA",
        "lon": "-85.070713",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-14827",
        "lat": "41.093763"
      },
      {
        "city": "Orlando",
        "zip": "32801",
        "country": "USA",
        "lon": "-81.373291",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.545179"
      },
      {
        "city": "Chandler",
        "zip": "85224",
        "country": "USA",
        "lon": "-111.85062",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.298461"
      },
      {
        "city": "Laredo",
        "zip": "78040",
        "country": "USA",
        "lon": "-99.494078",
        "cz": "2B",
        "state_id": "TX",
        "file": "0-12924",
        "lat": "27.515879"
      },
      {
        "city": "Norfolk",
        "zip": "23501",
        "country": "USA",
        "lon": "-76.208521",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "36.895911"
      },
      {
        "city": "Durham",
        "zip": "27701",
        "country": "USA",
        "lon": "-78.875467",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "36.004307"
      },
      {
        "city": "Madison",
        "zip": "53701",
        "country": "USA",
        "lon": "-89.423861",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14837",
        "lat": "43.06956"
      },
      {
        "city": "Lubbock",
        "zip": "79401",
        "country": "USA",
        "lon": "-101.842056",
        "cz": "3B",
        "state_id": "TX",
        "file": "0-23042",
        "lat": "33.607701"
      },
      {
        "city": "Irvine",
        "zip": "92602",
        "country": "USA",
        "lon": "-117.72222",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.718018"
      },
      {
        "city": "Glendale",
        "zip": "85301",
        "country": "USA",
        "lon": "-112.18717",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.276539"
      },
      {
        "city": "Garland",
        "zip": "75040",
        "country": "USA",
        "lon": "-96.615821",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.931523"
      },
      {
        "city": "Hialeah",
        "zip": "33002",
        "country": "USA",
        "lon": "-80.458168",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "25.558428"
      },
      {
        "city": "Reno",
        "zip": "89501",
        "country": "USA",
        "lon": "-119.704614",
        "cz": "5B",
        "state_id": "NV",
        "file": "0-23185",
        "lat": "39.65558"
      },
      {
        "city": "Chesapeake",
        "zip": "23320",
        "country": "USA",
        "lon": "-76.218759",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "36.749991"
      },
      {
        "city": "Gilbert",
        "zip": "85233",
        "country": "USA",
        "lon": "-111.815281",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.335401"
      },
      {
        "city": "Baton Rouge",
        "zip": "70801",
        "country": "USA",
        "lon": "-91.185607",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-13970",
        "lat": "30.44924"
      },
      {
        "city": "Irving",
        "zip": "75014",
        "country": "USA",
        "lon": "-96.777626",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.767268"
      },
      {
        "city": "Scottsdale",
        "zip": "85250",
        "country": "USA",
        "lon": "-111.874248",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.524143"
      },
      {
        "city": "North Las Vegas",
        "zip": "89030",
        "country": "USA",
        "lon": "-115.15431",
        "cz": "5B",
        "state_id": "NV",
        "file": "0-23169",
        "lat": "36.225271"
      },
      {
        "city": "Fremont",
        "zip": "94536",
        "country": "USA",
        "lon": "-121.982721",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.565285"
      },
      {
        "city": "Richmond",
        "zip": "23218",
        "country": "USA",
        "lon": "-77.493157",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13740",
        "lat": "37.524246"
      },
      {
        "city": "San Bernardino",
        "zip": "92401",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Birmingham",
        "zip": "35201",
        "country": "USA",
        "lon": "-86.801904",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-13876",
        "lat": "33.456412"
      },
      {
        "city": "Spokane",
        "zip": "99201",
        "country": "USA",
        "lon": "-117.435997",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24157",
        "lat": "47.66264"
      },
      {
        "city": "Rochester",
        "zip": "14601",
        "country": "USA",
        "lon": "-77.684264",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14768",
        "lat": "43.286024"
      },
      {
        "city": "Des Moines",
        "zip": "50301",
        "country": "USA",
        "lon": "-93.572173",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14933",
        "lat": "41.672687"
      },
      {
        "city": "Modesto",
        "zip": "95350",
        "country": "USA",
        "lon": "-121.016796",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.669463"
      },
      {
        "city": "Fayetteville",
        "zip": "28301",
        "country": "USA",
        "lon": "-78.781027",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.053416"
      },
      {
        "city": "Tacoma",
        "zip": "98401",
        "country": "USA",
        "lon": "-122.444335",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.253671"
      },
      {
        "city": "Oxnard",
        "zip": "93030",
        "country": "USA",
        "lon": "-119.17749",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23174",
        "lat": "34.224892"
      },
      {
        "city": "Fontana",
        "zip": "92334",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Columbus",
        "zip": "31901",
        "country": "USA",
        "lon": "-84.97823",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-93842",
        "lat": "32.472864"
      },
      {
        "city": "Montgomery",
        "zip": "36101",
        "country": "USA",
        "lon": "-86.257817",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-13895",
        "lat": "32.356988"
      },
      {
        "city": "Moreno Valley",
        "zip": "92551",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Shreveport",
        "zip": "71101",
        "country": "USA",
        "lon": "-93.750228",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-13957",
        "lat": "32.498202"
      },
      {
        "city": "Aurora",
        "zip": "60504",
        "country": "USA",
        "lon": "-88.136616",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.768399"
      },
      {
        "city": "Yonkers",
        "zip": "10701",
        "country": "USA",
        "lon": "-73.866926",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "40.946107"
      },
      {
        "city": "Akron",
        "zip": "44301",
        "country": "USA",
        "lon": "-81.507831",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14895",
        "lat": "41.012239"
      },
      {
        "city": "Huntington Beach",
        "zip": "92605",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Little Rock",
        "zip": "72201",
        "country": "USA",
        "lon": "-92.284832",
        "cz": "3A",
        "state_id": "AR",
        "file": "0-13963",
        "lat": "34.755998"
      },
      {
        "city": "Amarillo",
        "zip": "79101",
        "country": "USA",
        "lon": "-101.795512",
        "cz": "4B",
        "state_id": "TX",
        "file": "0-23047",
        "lat": "35.205452"
      },
      {
        "city": "Glendale",
        "zip": "91201",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Mobile",
        "zip": "36601",
        "country": "USA",
        "lon": "-88.103184",
        "cz": "2A",
        "state_id": "AL",
        "file": "0-13894",
        "lat": "30.701142"
      },
      {
        "city": "Grand Rapids",
        "zip": "49501",
        "country": "USA",
        "lon": "-85.629101",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94860",
        "lat": "42.984226"
      },
      {
        "city": "Salt Lake City",
        "zip": "84101",
        "country": "USA",
        "lon": "-111.900719",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.756095"
      },
      {
        "city": "Tallahassee",
        "zip": "32301",
        "country": "USA",
        "lon": "-84.203379",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-93805",
        "lat": "30.418514"
      },
      {
        "city": "Huntsville",
        "zip": "35801",
        "country": "USA",
        "lon": "-86.556439",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-3856",
        "lat": "34.718428"
      },
      {
        "city": "Grand Prairie",
        "zip": "75050",
        "country": "USA",
        "lon": "-96.777626",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.767268"
      },
      {
        "city": "Knoxville",
        "zip": "37901",
        "country": "USA",
        "lon": "-83.884804",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13891",
        "lat": "36.032334"
      },
      {
        "city": "Worcester",
        "zip": "01601",
        "country": "USA",
        "lon": "-71.879415",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-94746",
        "lat": "42.265275"
      },
      {
        "city": "Newport News",
        "zip": "23601",
        "country": "USA",
        "lon": "-76.463471",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "37.058296"
      },
      {
        "city": "Brownsville",
        "zip": "78520",
        "country": "USA",
        "lon": "-97.514466",
        "cz": "1A",
        "state_id": "TX",
        "file": "0-12919",
        "lat": "26.052155"
      },
      {
        "city": "Santa Clarita",
        "zip": "91350",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Providence",
        "zip": "02901",
        "country": "USA",
        "lon": "-71.414451",
        "cz": "#N/A",
        "state_id": "RI",
        "file": "0-14765",
        "lat": "41.82275"
      },
      {
        "city": "Garden Grove",
        "zip": "92840",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Chattanooga",
        "zip": "37401",
        "country": "USA",
        "lon": "-85.206426",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13882",
        "lat": "35.017818"
      },
      {
        "city": "Oceanside",
        "zip": "92049",
        "country": "USA",
        "lon": "-116.846046",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.016928"
      },
      {
        "city": "Jackson",
        "zip": "39201",
        "country": "USA",
        "lon": "-90.192687",
        "cz": "3A",
        "state_id": "MS",
        "file": "0-3940",
        "lat": "32.291095"
      },
      {
        "city": "Fort Lauderdale",
        "zip": "33301",
        "country": "USA",
        "lon": "-80.159317",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "26.085115"
      },
      {
        "city": "Santa Rosa",
        "zip": "95401",
        "country": "USA",
        "lon": "-122.783159",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "38.450412"
      },
      {
        "city": "Rancho Cucamonga",
        "zip": "91701",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Tempe",
        "zip": "85280",
        "country": "USA",
        "lon": "-111.931298",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.401395"
      },
      {
        "city": "Ontario",
        "zip": "91758",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Vancouver",
        "zip": "98660",
        "country": "USA",
        "lon": "-122.713366",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24229",
        "lat": "45.74327"
      },
      {
        "city": "Cape Coral",
        "zip": "33904",
        "country": "USA",
        "lon": "-81.95016",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.606491"
      },
      {
        "city": "Sioux Falls",
        "zip": "57101",
        "country": "USA",
        "lon": "-96.69063",
        "cz": "6A",
        "state_id": "SD",
        "file": "0-14944",
        "lat": "43.546358"
      },
      {
        "city": "Springfield",
        "zip": "65801",
        "country": "USA",
        "lon": "-93.343673",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-13995",
        "lat": "37.25807"
      },
      {
        "city": "Peoria",
        "zip": "85345",
        "country": "USA",
        "lon": "-112.18717",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.276539"
      },
      {
        "city": "Pembroke Pines",
        "zip": "33082",
        "country": "USA",
        "lon": "-80.448254",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "26.145724"
      },
      {
        "city": "Elk Grove",
        "zip": "95624",
        "country": "USA",
        "lon": "-121.307142",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.44148"
      },
      {
        "city": "Salem",
        "zip": "97301",
        "country": "USA",
        "lon": "-122.921721",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24232",
        "lat": "44.90472"
      },
      {
        "city": "Lancaster",
        "zip": "93534",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Corona",
        "zip": "92877",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Eugene",
        "zip": "97401",
        "country": "USA",
        "lon": "-123.074193",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24221",
        "lat": "44.117868"
      },
      {
        "city": "Palmdale",
        "zip": "93550",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Salinas",
        "zip": "93901",
        "country": "USA",
        "lon": "-121.416603",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "36.441768"
      },
      {
        "city": "Springfield",
        "zip": "01101",
        "country": "USA",
        "lon": "-72.604842",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14740",
        "lat": "42.170731"
      },
      {
        "city": "Pasadena",
        "zip": "77501",
        "country": "USA",
        "lon": "-95.434241",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.83399"
      },
      {
        "city": "Fort Collins",
        "zip": "80521",
        "country": "USA",
        "lon": "-105.298344",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "40.59227"
      },
      {
        "city": "Hayward",
        "zip": "94540",
        "country": "USA",
        "lon": "-121.921498",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.680181"
      },
      {
        "city": "Pomona",
        "zip": "91766",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Cary",
        "zip": "27511",
        "country": "USA",
        "lon": "-78.70732",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.751243"
      },
      {
        "city": "Rockford",
        "zip": "61101",
        "country": "USA",
        "lon": "-89.157198",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94822",
        "lat": "42.33342"
      },
      {
        "city": "Alexandria",
        "zip": "22301",
        "country": "USA",
        "lon": "-77.079622",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-93738",
        "lat": "38.823062"
      },
      {
        "city": "Escondido",
        "zip": "92025",
        "country": "USA",
        "lon": "-117.083403",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.057128"
      },
      {
        "city": "Kansas City",
        "zip": "66101",
        "country": "USA",
        "lon": "-94.630384",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-3947",
        "lat": "39.103053"
      },
      {
        "city": "Joliet",
        "zip": "60431",
        "country": "USA",
        "lon": "-87.93909",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.471206"
      },
      {
        "city": "Torrance",
        "zip": "90501",
        "country": "USA",
        "lon": "-118.303805",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.835665"
      },
      {
        "city": "Bridgeport",
        "zip": "06601",
        "country": "USA",
        "lon": "-73.363661",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.308873"
      },
      {
        "city": "Hollywood",
        "zip": "33019",
        "country": "USA",
        "lon": "-80.192966",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "26.091514"
      },
      {
        "city": "Paterson",
        "zip": "07501",
        "country": "USA",
        "lon": "-74.174488",
        "cz": "5A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.915045"
      },
      {
        "city": "Naperville",
        "zip": "60540",
        "country": "USA",
        "lon": "-88.152381",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.759029"
      },
      {
        "city": "Syracuse",
        "zip": "13201",
        "country": "USA",
        "lon": "-76.197701",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14771",
        "lat": "43.02143"
      },
      {
        "city": "Mesquite",
        "zip": "75149",
        "country": "USA",
        "lon": "-96.62315",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.777779"
      },
      {
        "city": "Dayton",
        "zip": "45401",
        "country": "USA",
        "lon": "-84.268593",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-93815",
        "lat": "39.750471"
      },
      {
        "city": "Savannah",
        "zip": "31401",
        "country": "USA",
        "lon": "-81.135618",
        "cz": "2A",
        "state_id": "GA",
        "file": "0-3822",
        "lat": "32.072257"
      },
      {
        "city": "Clarksville",
        "zip": "37040",
        "country": "USA",
        "lon": "-87.308491",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13897",
        "lat": "36.51674"
      },
      {
        "city": "Orange",
        "zip": "92856",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Pasadena",
        "zip": "91050",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Fullerton",
        "zip": "92831",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Killeen",
        "zip": "76540",
        "country": "USA",
        "lon": "-97.357099",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-13959",
        "lat": "31.085833"
      },
      {
        "city": "Frisco",
        "zip": "75034",
        "country": "USA",
        "lon": "-96.796437",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.152222"
      },
      {
        "city": "Hampton",
        "zip": "23630",
        "country": "USA",
        "lon": "-76.38992",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "37.072658"
      },
      {
        "city": "Bellevue",
        "zip": "98004",
        "country": "USA",
        "lon": "-122.207221",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.615471"
      },
      {
        "city": "Columbia",
        "zip": "29201",
        "country": "USA",
        "lon": "-81.024864",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13883",
        "lat": "33.987454"
      },
      {
        "city": "Olathe",
        "zip": "66051",
        "country": "USA",
        "lon": "-94.831991",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-3947",
        "lat": "38.899901"
      },
      {
        "city": "Sterling Heights",
        "zip": "48310",
        "country": "USA",
        "lon": "-83.068475",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.564395"
      },
      {
        "city": "New Haven",
        "zip": "06501",
        "country": "USA",
        "lon": "-72.927507",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.365709"
      },
      {
        "city": "Waco",
        "zip": "76701",
        "country": "USA",
        "lon": "-97.15508",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-13959",
        "lat": "31.551566"
      },
      {
        "city": "Thousand Oaks",
        "zip": "91358",
        "country": "USA",
        "lon": "-119.1343",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23174",
        "lat": "34.032383"
      },
      {
        "city": "Cedar Rapids",
        "zip": "52401",
        "country": "USA",
        "lon": "-91.657578",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-94910",
        "lat": "41.976612"
      },
      {
        "city": "Charleston",
        "zip": "29401",
        "country": "USA",
        "lon": "-79.940844",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13880",
        "lat": "32.780326"
      },
      {
        "city": "Visalia",
        "zip": "93277",
        "country": "USA",
        "lon": "-119.355559",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "36.13188"
      },
      {
        "city": "Topeka",
        "zip": "66601",
        "country": "USA",
        "lon": "-95.780662",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-13996",
        "lat": "38.988075"
      },
      {
        "city": "Elizabeth",
        "zip": "07201",
        "country": "USA",
        "lon": "-74.183438",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.672052"
      },
      {
        "city": "Gainesville",
        "zip": "32601",
        "country": "USA",
        "lon": "-82.345739",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-13889",
        "lat": "29.68041"
      },
      {
        "city": "Roseville",
        "zip": "95661",
        "country": "USA",
        "lon": "-121.25603",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.74073"
      },
      {
        "city": "Carrollton",
        "zip": "75006",
        "country": "USA",
        "lon": "-96.777626",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.767268"
      },
      {
        "city": "Stamford",
        "zip": "06901",
        "country": "USA",
        "lon": "-73.536216",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.054082"
      },
      {
        "city": "Simi Valley",
        "zip": "93062",
        "country": "USA",
        "lon": "-119.1343",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23174",
        "lat": "34.032383"
      },
      {
        "city": "Concord",
        "zip": "94518",
        "country": "USA",
        "lon": "-122.022872",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.953672"
      },
      {
        "city": "Hartford",
        "zip": "06101",
        "country": "USA",
        "lon": "-72.677099",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-14740",
        "lat": "41.78007"
      },
      {
        "city": "Kent",
        "zip": "98031",
        "country": "USA",
        "lon": "-122.16538",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.379972"
      },
      {
        "city": "Lafayette",
        "zip": "70501",
        "country": "USA",
        "lon": "-92.066574",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-13970",
        "lat": "30.232955"
      },
      {
        "city": "Midland",
        "zip": "79701",
        "country": "USA",
        "lon": "-102.091276",
        "cz": "3B",
        "state_id": "TX",
        "file": "0-23023",
        "lat": "31.861876"
      },
      {
        "city": "Surprise",
        "zip": "85374",
        "country": "USA",
        "lon": "-112.18717",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.276539"
      },
      {
        "city": "Denton",
        "zip": "76201",
        "country": "USA",
        "lon": "-97.200555",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.244683"
      },
      {
        "city": "Victorville",
        "zip": "92392",
        "country": "USA",
        "lon": "-114.754916",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23169",
        "lat": "34.491985"
      },
      {
        "city": "Evansville",
        "zip": "47701",
        "country": "USA",
        "lon": "-87.574963",
        "cz": "4A",
        "state_id": "IN",
        "file": "0-93817",
        "lat": "37.997128"
      },
      {
        "city": "Santa Clara",
        "zip": "95050",
        "country": "USA",
        "lon": "-121.705327",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.189396"
      },
      {
        "city": "Abilene",
        "zip": "79601",
        "country": "USA",
        "lon": "-99.80213",
        "cz": "3B",
        "state_id": "TX",
        "file": "0-13962",
        "lat": "32.344457"
      },
      {
        "city": "Vallejo",
        "zip": "94589",
        "country": "USA",
        "lon": "-122.280383",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "38.158221"
      },
      {
        "city": "Allentown",
        "zip": "18101",
        "country": "USA",
        "lon": "-75.470026",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-14737",
        "lat": "40.607497"
      },
      {
        "city": "Norman",
        "zip": "73019",
        "country": "USA",
        "lon": "-97.44451",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13967",
        "lat": "35.208566"
      },
      {
        "city": "Beaumont",
        "zip": "77701",
        "country": "USA",
        "lon": "-94.109705",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12917",
        "lat": "30.073712"
      },
      {
        "city": "Independence",
        "zip": "64050",
        "country": "USA",
        "lon": "-94.409828",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-3947",
        "lat": "39.107798"
      },
      {
        "city": "Murfreesboro",
        "zip": "37127",
        "country": "USA",
        "lon": "-86.372158",
        "cz": "3A",
        "state_id": "TN",
        "file": "0-13897",
        "lat": "35.762951"
      },
      {
        "city": "Ann Arbor",
        "zip": "48103",
        "country": "USA",
        "lon": "-83.849042",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.266638"
      },
      {
        "city": "Springfield",
        "zip": "62701",
        "country": "USA",
        "lon": "-89.598978",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-93822",
        "lat": "39.820839"
      },
      {
        "city": "Berkeley",
        "zip": "94701",
        "country": "USA",
        "lon": "-122.29673",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.860576"
      },
      {
        "city": "Peoria",
        "zip": "61601",
        "country": "USA",
        "lon": "-89.589847",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-14842",
        "lat": "40.693137"
      },
      {
        "city": "Provo",
        "zip": "84601",
        "country": "USA",
        "lon": "-111.695558",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.235053"
      },
      {
        "city": "El Monte",
        "zip": "91731",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Columbia",
        "zip": "65201",
        "country": "USA",
        "lon": "-92.274145",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-3945",
        "lat": "38.894165"
      },
      {
        "city": "Lansing",
        "zip": "48901",
        "country": "USA",
        "lon": "-84.371973",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14836",
        "lat": "42.599184"
      },
      {
        "city": "Fargo",
        "zip": "58102",
        "country": "USA",
        "lon": "-96.990615",
        "cz": "6A",
        "state_id": "ND",
        "file": "0-14914",
        "lat": "46.92536"
      },
      {
        "city": "Downey",
        "zip": "90239",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Costa Mesa",
        "zip": "92626",
        "country": "USA",
        "lon": "-117.778398",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.6829"
      },
      {
        "city": "Wilmington",
        "zip": "28401",
        "country": "USA",
        "lon": "-77.95481",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13748",
        "lat": "34.163503"
      },
      {
        "city": "Arvada",
        "zip": "80001",
        "country": "USA",
        "lon": "-105.223945",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "39.522014"
      },
      {
        "city": "Inglewood",
        "zip": "90301",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Carlsbad",
        "zip": "92008",
        "country": "USA",
        "lon": "-116.846046",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.016928"
      },
      {
        "city": "Westminster",
        "zip": "80030",
        "country": "USA",
        "lon": "-105.034487",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "39.872535"
      },
      {
        "city": "Rochester",
        "zip": "55901",
        "country": "USA",
        "lon": "-92.516916",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14925",
        "lat": "44.075285"
      },
      {
        "city": "Odessa",
        "zip": "79760",
        "country": "USA",
        "lon": "-102.354346",
        "cz": "3B",
        "state_id": "TX",
        "file": "0-23023",
        "lat": "31.765163"
      },
      {
        "city": "Manchester",
        "zip": "03101",
        "country": "USA",
        "lon": "-71.462111",
        "cz": "5A",
        "state_id": "NH",
        "file": "0-14745",
        "lat": "42.988483"
      },
      {
        "city": "Elgin",
        "zip": "60120",
        "country": "USA",
        "lon": "-88.429777",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.990689"
      },
      {
        "city": "West Jordan",
        "zip": "84084",
        "country": "USA",
        "lon": "-111.978898",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.606125"
      },
      {
        "city": "Round Rock",
        "zip": "78664",
        "country": "USA",
        "lon": "-97.635103",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-13958",
        "lat": "30.530497"
      },
      {
        "city": "Clearwater",
        "zip": "33755",
        "country": "USA",
        "lon": "-82.781523",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.978147"
      },
      {
        "city": "Waterbury",
        "zip": "06701",
        "country": "USA",
        "lon": "-72.927507",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.365709"
      },
      {
        "city": "Gresham",
        "zip": "97030",
        "country": "USA",
        "lon": "-122.430233",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24229",
        "lat": "45.508117"
      },
      {
        "city": "Fairfield",
        "zip": "94533",
        "country": "USA",
        "lon": "-122.020276",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.278428"
      },
      {
        "city": "Billings",
        "zip": "59101",
        "country": "USA",
        "lon": "-108.387392",
        "cz": "6B",
        "state_id": "MT",
        "file": "0-24033",
        "lat": "45.686966"
      },
      {
        "city": "Lowell",
        "zip": "01850",
        "country": "USA",
        "lon": "-71.459405",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.446396"
      },
      {
        "city": "Pueblo",
        "zip": "81001",
        "country": "USA",
        "lon": "-104.427776",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-93058",
        "lat": "38.344117"
      },
      {
        "city": "High Point",
        "zip": "27260",
        "country": "USA",
        "lon": "-79.988711",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13723",
        "lat": "35.993538"
      },
      {
        "city": "West Covina",
        "zip": "91790",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Richmond",
        "zip": "94801",
        "country": "USA",
        "lon": "-122.384032",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.947523"
      },
      {
        "city": "Murrieta",
        "zip": "92562",
        "country": "USA",
        "lon": "-116.861027",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.44204"
      },
      {
        "city": "Cambridge",
        "zip": "02138",
        "country": "USA",
        "lon": "-71.132947",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.380442"
      },
      {
        "city": "Antioch",
        "zip": "94509",
        "country": "USA",
        "lon": "-121.906748",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.931997"
      },
      {
        "city": "Temecula",
        "zip": "92589",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Norwalk",
        "zip": "90650",
        "country": "USA",
        "lon": "-118.076549",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.906763"
      },
      {
        "city": "Everett",
        "zip": "98201",
        "country": "USA",
        "lon": "-122.199795",
        "cz": "4C",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.988661"
      },
      {
        "city": "Palm Bay",
        "zip": "32905",
        "country": "USA",
        "lon": "-80.611642",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.96861"
      },
      {
        "city": "Wichita Falls",
        "zip": "76301",
        "country": "USA",
        "lon": "-98.48283",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-13966",
        "lat": "33.959758"
      },
      {
        "city": "Green Bay",
        "zip": "54301",
        "country": "USA",
        "lon": "-87.976051",
        "cz": "6A",
        "state_id": "WI",
        "file": "0-14898",
        "lat": "44.494385"
      },
      {
        "city": "Burbank",
        "zip": "91501",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Richardson",
        "zip": "75080",
        "country": "USA",
        "lon": "-96.726826",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.962811"
      },
      {
        "city": "Pompano Beach",
        "zip": "33060",
        "country": "USA",
        "lon": "-80.139816",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.240059"
      },
      {
        "city": "North Charleston",
        "zip": "29405",
        "country": "USA",
        "lon": "-79.991295",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13880",
        "lat": "32.853019"
      },
      {
        "city": "Broken Arrow",
        "zip": "74011",
        "country": "USA",
        "lon": "-95.818064",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13968",
        "lat": "35.986399"
      },
      {
        "city": "Boulder",
        "zip": "80301",
        "country": "USA",
        "lon": "-105.278083",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "40.094787"
      },
      {
        "city": "West Palm Beach",
        "zip": "33401",
        "country": "USA",
        "lon": "-80.070613",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.672643"
      },
      {
        "city": "Santa Maria",
        "zip": "93454",
        "country": "USA",
        "lon": "-120.340795",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23273",
        "lat": "34.875832"
      },
      {
        "city": "El Cajon",
        "zip": "92019",
        "country": "USA",
        "lon": "-117.041287",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.865113"
      },
      {
        "city": "Davenport",
        "zip": "52801",
        "country": "USA",
        "lon": "-90.573686",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14923",
        "lat": "41.527232"
      },
      {
        "city": "Rialto",
        "zip": "92376",
        "country": "USA",
        "lon": "-115.567483",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.202339"
      },
      {
        "city": "Las Cruces",
        "zip": "88001",
        "country": "USA",
        "lon": "-106.854065",
        "cz": "3B",
        "state_id": "NM",
        "file": "0-23044",
        "lat": "32.41467"
      },
      {
        "city": "San Mateo",
        "zip": "94401",
        "country": "USA",
        "lon": "-122.32253",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.573485"
      },
      {
        "city": "Lewisville",
        "zip": "75029",
        "country": "USA",
        "lon": "-97.116282",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.20743"
      },
      {
        "city": "South Bend",
        "zip": "46601",
        "country": "USA",
        "lon": "-86.251654",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-14848",
        "lat": "41.673383"
      },
      {
        "city": "Lakeland",
        "zip": "33801",
        "country": "USA",
        "lon": "-81.956122",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.059997"
      },
      {
        "city": "Erie",
        "zip": "16501",
        "country": "USA",
        "lon": "-80.087341",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-14860",
        "lat": "42.087337"
      },
      {
        "city": "Tyler",
        "zip": "75701",
        "country": "USA",
        "lon": "-95.200403",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-93987",
        "lat": "32.288029"
      },
      {
        "city": "Pearland",
        "zip": "77581",
        "country": "USA",
        "lon": "-95.316425",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.328311"
      },
      {
        "city": "College Station",
        "zip": "77840",
        "country": "USA",
        "lon": "-96.289328",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "30.582241"
      },
      {
        "city": "Kenosha",
        "zip": "53140",
        "country": "USA",
        "lon": "-87.830375",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "42.622449"
      },
      {
        "city": "Clovis",
        "zip": "93611",
        "country": "USA",
        "lon": "-119.592146",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "36.832583"
      },
      {
        "city": "Flint",
        "zip": "48501",
        "country": "USA",
        "lon": "-83.780835",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14826",
        "lat": "42.965926"
      },
      {
        "city": "Roanoke",
        "zip": "24001",
        "country": "USA",
        "lon": "-79.95786",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13741",
        "lat": "37.274175"
      },
      {
        "city": "Albany",
        "zip": "12201",
        "country": "USA",
        "lon": "-73.970812",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14735",
        "lat": "42.614852"
      },
      {
        "city": "Compton",
        "zip": "90220",
        "country": "USA",
        "lon": "-118.240208",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.874815"
      },
      {
        "city": "San Angelo",
        "zip": "76901",
        "country": "USA",
        "lon": "-100.533397",
        "cz": "3B",
        "state_id": "TX",
        "file": "0-23034",
        "lat": "31.44451"
      },
      {
        "city": "Hillsboro",
        "zip": "97123",
        "country": "USA",
        "lon": "-122.977963",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24229",
        "lat": "45.458397"
      },
      {
        "city": "Lawton",
        "zip": "73501",
        "country": "USA",
        "lon": "-98.448452",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13966",
        "lat": "34.635378"
      },
      {
        "city": "Renton",
        "zip": "98055",
        "country": "USA",
        "lon": "-122.02967",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.485348"
      },
      {
        "city": "Vista",
        "zip": "92083",
        "country": "USA",
        "lon": "-116.846046",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.016928"
      },
      {
        "city": "Greeley",
        "zip": "80631",
        "country": "USA",
        "lon": "-104.680631",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "40.384991"
      },
      {
        "city": "Mission Viejo",
        "zip": "92690",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Portsmouth",
        "zip": "23701",
        "country": "USA",
        "lon": "-76.367715",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "36.811498"
      },
      {
        "city": "Dearborn",
        "zip": "48120",
        "country": "USA",
        "lon": "-83.177625",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.310037"
      },
      {
        "city": "South Gate",
        "zip": "90280",
        "country": "USA",
        "lon": "-118.193403",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.937714"
      },
      {
        "city": "Tuscaloosa",
        "zip": "35401",
        "country": "USA",
        "lon": "-87.597599",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-13876",
        "lat": "33.241899"
      },
      {
        "city": "Livonia",
        "zip": "48150",
        "country": "USA",
        "lon": "-83.371753",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.369351"
      },
      {
        "city": "New Bedford",
        "zip": "02740",
        "country": "USA",
        "lon": "-70.951045",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14765",
        "lat": "41.633416"
      },
      {
        "city": "Vacaville",
        "zip": "95687",
        "country": "USA",
        "lon": "-121.912773",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.35056"
      },
      {
        "city": "Brockton",
        "zip": "02301",
        "country": "USA",
        "lon": "-71.03999",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.079399"
      },
      {
        "city": "Roswell",
        "zip": "30075",
        "country": "USA",
        "lon": "-84.370475",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-13874",
        "lat": "34.055198"
      },
      {
        "city": "Beaverton",
        "zip": "97005",
        "country": "USA",
        "lon": "-122.800146",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24229",
        "lat": "45.496289"
      },
      {
        "city": "Quincy",
        "zip": "02169",
        "country": "USA",
        "lon": "-71.006042",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.241799"
      },
      {
        "city": "Sparks",
        "zip": "89431",
        "country": "USA",
        "lon": "-119.640601",
        "cz": "5B",
        "state_id": "NV",
        "file": "0-23185",
        "lat": "40.039169"
      },
      {
        "city": "Yakima",
        "zip": "98901",
        "country": "USA",
        "lon": "-120.725557",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24243",
        "lat": "46.644476"
      },
      {
        "city": "Federal Way",
        "zip": "98003",
        "country": "USA",
        "lon": "-121.803388",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.432251"
      },
      {
        "city": "Carson",
        "zip": "90745",
        "country": "USA",
        "lon": "-118.261154",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.813317"
      },
      {
        "city": "Santa Monica",
        "zip": "90401",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Hesperia",
        "zip": "92340",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Allen",
        "zip": "75002",
        "country": "USA",
        "lon": "-96.646773",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.208033"
      },
      {
        "city": "Rio Rancho",
        "zip": "87124",
        "country": "USA",
        "lon": "-106.712495",
        "cz": "5B",
        "state_id": "NM",
        "file": "0-23050",
        "lat": "35.282859"
      },
      {
        "city": "Yuma",
        "zip": "85364",
        "country": "USA",
        "lon": "-114.648722",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23188",
        "lat": "32.615305"
      },
      {
        "city": "Westminster",
        "zip": "92683",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Orem",
        "zip": "84057",
        "country": "USA",
        "lon": "-111.72496",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.311353"
      },
      {
        "city": "Lynn",
        "zip": "01901",
        "country": "USA",
        "lon": "-70.946743",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.461246"
      },
      {
        "city": "Redding",
        "zip": "96001",
        "country": "USA",
        "lon": "-122.456982",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-24283",
        "lat": "40.675738"
      },
      {
        "city": "Miami Beach",
        "zip": "33109",
        "country": "USA",
        "lon": "-80.458168",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "25.558428"
      },
      {
        "city": "League City",
        "zip": "77573",
        "country": "USA",
        "lon": "-95.052262",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.496451"
      },
      {
        "city": "Lawrence",
        "zip": "66044",
        "country": "USA",
        "lon": "-95.283982",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-13996",
        "lat": "38.907518"
      },
      {
        "city": "Santa Barbara",
        "zip": "93101",
        "country": "USA",
        "lon": "-119.707135",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23273",
        "lat": "34.421897"
      },
      {
        "city": "Sandy",
        "zip": "84070",
        "country": "USA",
        "lon": "-111.859504",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.578597"
      },
      {
        "city": "Macon",
        "zip": "31201",
        "country": "USA",
        "lon": "-83.595066",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-3813",
        "lat": "32.827949"
      },
      {
        "city": "Longmont",
        "zip": "80501",
        "country": "USA",
        "lon": "-105.1633",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "40.165634"
      },
      {
        "city": "Boca Raton",
        "zip": "33427",
        "country": "USA",
        "lon": "-80.10717",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.375954"
      },
      {
        "city": "San Marcos",
        "zip": "92069",
        "country": "USA",
        "lon": "-117.215112",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.099573"
      },
      {
        "city": "Greenville",
        "zip": "27833",
        "country": "USA",
        "lon": "-77.392609",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.580444"
      },
      {
        "city": "Waukegan",
        "zip": "60079",
        "country": "USA",
        "lon": "-87.610053",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.322814"
      },
      {
        "city": "Fall River",
        "zip": "02720",
        "country": "USA",
        "lon": "-71.165971",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14765",
        "lat": "41.819766"
      },
      {
        "city": "Chico",
        "zip": "95926",
        "country": "USA",
        "lon": "-121.840083",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "39.746159"
      },
      {
        "city": "Newton",
        "zip": "02458",
        "country": "USA",
        "lon": "-71.208399",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.385096"
      },
      {
        "city": "San Leandro",
        "zip": "94577",
        "country": "USA",
        "lon": "-122.158621",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.715629"
      },
      {
        "city": "Reading",
        "zip": "19601",
        "country": "USA",
        "lon": "-75.940153",
        "cz": "4A",
        "state_id": "PA",
        "file": "0-14737",
        "lat": "40.357242"
      },
      {
        "city": "Norwalk",
        "zip": "06850",
        "country": "USA",
        "lon": "-73.442423",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.126146"
      },
      {
        "city": "Fort Smith",
        "zip": "72901",
        "country": "USA",
        "lon": "-94.339412",
        "cz": "3A",
        "state_id": "AR",
        "file": "0-13964",
        "lat": "35.231245"
      },
      {
        "city": "Newport Beach",
        "zip": "92658",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Asheville",
        "zip": "28801",
        "country": "USA",
        "lon": "-82.567281",
        "cz": "4A",
        "state_id": "NC",
        "file": "0-3812",
        "lat": "35.602711"
      },
      {
        "city": "Nashua",
        "zip": "03060",
        "country": "USA",
        "lon": "-71.626336",
        "cz": "5A",
        "state_id": "NH",
        "file": "0-14745",
        "lat": "42.771537"
      },
      {
        "city": "Edmond",
        "zip": "73003",
        "country": "USA",
        "lon": "-97.499681",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13967",
        "lat": "35.674777"
      },
      {
        "city": "Whittier",
        "zip": "90601",
        "country": "USA",
        "lon": "-118.0441",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "34.004311"
      },
      {
        "city": "Deltona",
        "zip": "32725",
        "country": "USA",
        "lon": "-81.245074",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12834",
        "lat": "28.900274"
      },
      {
        "city": "Hawthorne",
        "zip": "90250",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Duluth",
        "zip": "55801",
        "country": "USA",
        "lon": "-92.001934",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14913",
        "lat": "47.005566"
      },
      {
        "city": "Carmel",
        "zip": "46032",
        "country": "USA",
        "lon": "-86.117215",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "40.071102"
      },
      {
        "city": "Suffolk",
        "zip": "23432",
        "country": "USA",
        "lon": "-76.553061",
        "cz": "3A",
        "state_id": "VA",
        "file": "0-13737",
        "lat": "36.874916"
      },
      {
        "city": "Clifton",
        "zip": "07011",
        "country": "USA",
        "lon": "-74.141237",
        "cz": "5A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.877949"
      },
      {
        "city": "Citrus Heights",
        "zip": "95610",
        "country": "USA",
        "lon": "-121.26736",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.689802"
      },
      {
        "city": "Livermore",
        "zip": "94550",
        "country": "USA",
        "lon": "-121.91605",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.676781"
      },
      {
        "city": "Tracy",
        "zip": "95304",
        "country": "USA",
        "lon": "-121.253872",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.889849"
      },
      {
        "city": "Alhambra",
        "zip": "91801",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Kirkland",
        "zip": "98033",
        "country": "USA",
        "lon": "-122.187029",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.673263"
      },
      {
        "city": "Trenton",
        "zip": "08601",
        "country": "USA",
        "lon": "-74.712018",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-13739",
        "lat": "40.280531"
      },
      {
        "city": "Ogden",
        "zip": "84201",
        "country": "USA",
        "lon": "-112.007924",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "41.244261"
      },
      {
        "city": "Cicero",
        "zip": "60804",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Fishers",
        "zip": "46038",
        "country": "USA",
        "lon": "-85.964894",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "39.967406"
      },
      {
        "city": "Sugar Land",
        "zip": "77478",
        "country": "USA",
        "lon": "-95.756462",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.525461"
      },
      {
        "city": "Danbury",
        "zip": "06810",
        "country": "USA",
        "lon": "-73.471416",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.376242"
      },
      {
        "city": "Indio",
        "zip": "92201",
        "country": "USA",
        "lon": "-116.035705",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.728721"
      },
      {
        "city": "Concord",
        "zip": "28025",
        "country": "USA",
        "lon": "-80.562141",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13881",
        "lat": "35.371633"
      },
      {
        "city": "Menifee",
        "zip": "92584",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Champaign",
        "zip": "61820",
        "country": "USA",
        "lon": "-88.197166",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-93822",
        "lat": "40.101777"
      },
      {
        "city": "Buena Park",
        "zip": "90620",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Troy",
        "zip": "48007",
        "country": "USA",
        "lon": "-83.297593",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.606088"
      },
      {
        "city": "Bellingham",
        "zip": "98225",
        "country": "USA",
        "lon": "-122.454297",
        "cz": "4C",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "48.747687"
      },
      {
        "city": "Westland",
        "zip": "48185",
        "country": "USA",
        "lon": "-83.373093",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.31507"
      },
      {
        "city": "Bloomington",
        "zip": "47401",
        "country": "USA",
        "lon": "-86.435094",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "39.07881"
      },
      {
        "city": "Sioux City",
        "zip": "51101",
        "country": "USA",
        "lon": "-96.399356",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14943",
        "lat": "42.494745"
      },
      {
        "city": "Warwick",
        "zip": "02886",
        "country": "USA",
        "lon": "-71.47902",
        "cz": "#N/A",
        "state_id": "RI",
        "file": "0-14765",
        "lat": "41.70247"
      },
      {
        "city": "Hemet",
        "zip": "92543",
        "country": "USA",
        "lon": "-116.777014",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.651652"
      },
      {
        "city": "Longview",
        "zip": "75601",
        "country": "USA",
        "lon": "-94.730285",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-13957",
        "lat": "32.517846"
      },
      {
        "city": "Bend",
        "zip": "97701",
        "country": "USA",
        "lon": "-121.227125",
        "cz": "5B",
        "state_id": "OR",
        "file": "0-24230",
        "lat": "44.082037"
      },
      {
        "city": "Lakewood",
        "zip": "90711",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Merced",
        "zip": "95340",
        "country": "USA",
        "lon": "-120.475427",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "37.338191"
      },
      {
        "city": "Mission",
        "zip": "78572",
        "country": "USA",
        "lon": "-98.192732",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12919",
        "lat": "26.229639"
      },
      {
        "city": "Chino",
        "zip": "91708",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Redwood City",
        "zip": "94059",
        "country": "USA",
        "lon": "-122.334825",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.381144"
      },
      {
        "city": "Edinburg",
        "zip": "78539",
        "country": "USA",
        "lon": "-98.139672",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12919",
        "lat": "26.328674"
      },
      {
        "city": "Cranston",
        "zip": "02910",
        "country": "USA",
        "lon": "-71.435251",
        "cz": "#N/A",
        "state_id": "RI",
        "file": "0-14765",
        "lat": "41.7917"
      },
      {
        "city": "New Rochelle",
        "zip": "10801",
        "country": "USA",
        "lon": "-73.801401",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "41.035123"
      },
      {
        "city": "Lake Forest",
        "zip": "92630",
        "country": "USA",
        "lon": "-117.693074",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.64079"
      },
      {
        "city": "Napa",
        "zip": "94558",
        "country": "USA",
        "lon": "-122.311921",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.516158"
      },
      {
        "city": "Hammond",
        "zip": "46320",
        "country": "USA",
        "lon": "-87.487242",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-94846",
        "lat": "41.444246"
      },
      {
        "city": "Fayetteville",
        "zip": "72701",
        "country": "USA",
        "lon": "-94.190997",
        "cz": "4A",
        "state_id": "AR",
        "file": "0-13964",
        "lat": "35.974602"
      },
      {
        "city": "Bloomington",
        "zip": "61701",
        "country": "USA",
        "lon": "-88.850396",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-14842",
        "lat": "40.462041"
      },
      {
        "city": "Avondale",
        "zip": "85323",
        "country": "USA",
        "lon": "-112.18717",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.276539"
      },
      {
        "city": "Somerville",
        "zip": "02143",
        "country": "USA",
        "lon": "-71.098896",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.38092"
      },
      {
        "city": "Palm Coast",
        "zip": "32135",
        "country": "USA",
        "lon": "-81.282815",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12834",
        "lat": "29.466085"
      },
      {
        "city": "Bryan",
        "zip": "77801",
        "country": "USA",
        "lon": "-96.361631",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "30.667044"
      },
      {
        "city": "Gary",
        "zip": "46401",
        "country": "USA",
        "lon": "-87.319937",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-94846",
        "lat": "41.590686"
      },
      {
        "city": "Largo",
        "zip": "33770",
        "country": "USA",
        "lon": "-82.802668",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.916998"
      },
      {
        "city": "Tustin",
        "zip": "92780",
        "country": "USA",
        "lon": "-117.731534",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.579122"
      },
      {
        "city": "Racine",
        "zip": "53401",
        "country": "USA",
        "lon": "-87.675979",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "42.727153"
      },
      {
        "city": "Deerfield Beach",
        "zip": "33441",
        "country": "USA",
        "lon": "-80.140769",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.273761"
      },
      {
        "city": "Lynchburg",
        "zip": "24501",
        "country": "USA",
        "lon": "-79.178326",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13733",
        "lat": "37.383112"
      },
      {
        "city": "Mountain View",
        "zip": "94035",
        "country": "USA",
        "lon": "-121.705327",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.189396"
      },
      {
        "city": "Medford",
        "zip": "97501",
        "country": "USA",
        "lon": "-122.72255",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24225",
        "lat": "42.482623"
      },
      {
        "city": "Lawrence",
        "zip": "01840",
        "country": "USA",
        "lon": "-71.161052",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.70734"
      },
      {
        "city": "Bellflower",
        "zip": "90706",
        "country": "USA",
        "lon": "-118.12965",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.888014"
      },
      {
        "city": "Melbourne",
        "zip": "32901",
        "country": "USA",
        "lon": "-80.585519",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.012189"
      },
      {
        "city": "Camden",
        "zip": "08101",
        "country": "USA",
        "lon": "-74.938259",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-13739",
        "lat": "39.80237"
      },
      {
        "city": "Kennewick",
        "zip": "99336",
        "country": "USA",
        "lon": "-119.155671",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24155",
        "lat": "46.212306"
      },
      {
        "city": "Baldwin Park",
        "zip": "91706",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Chino Hills",
        "zip": "91709",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Alameda",
        "zip": "94501",
        "country": "USA",
        "lon": "-122.264779",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.770563"
      },
      {
        "city": "Albany",
        "zip": "31701",
        "country": "USA",
        "lon": "-84.176751",
        "cz": "2A",
        "state_id": "GA",
        "file": "0-93842",
        "lat": "31.560674"
      },
      {
        "city": "Arlington Heights",
        "zip": "60004",
        "country": "USA",
        "lon": "-87.99822",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.085626"
      },
      {
        "city": "Scranton",
        "zip": "18501",
        "country": "USA",
        "lon": "-75.637626",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-14777",
        "lat": "41.401881"
      },
      {
        "city": "Evanston",
        "zip": "60201",
        "country": "USA",
        "lon": "-87.702155",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.049148"
      },
      {
        "city": "Kalamazoo",
        "zip": "49001",
        "country": "USA",
        "lon": "-85.510095",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94860",
        "lat": "42.261596"
      },
      {
        "city": "Baytown",
        "zip": "77520",
        "country": "USA",
        "lon": "-95.434241",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.83399"
      },
      {
        "city": "Upland",
        "zip": "91784",
        "country": "USA",
        "lon": "-116.246997",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.128118"
      },
      {
        "city": "Springdale",
        "zip": "72762",
        "country": "USA",
        "lon": "-94.240112",
        "cz": "4A",
        "state_id": "AR",
        "file": "0-13964",
        "lat": "36.16722"
      },
      {
        "city": "Bethlehem",
        "zip": "18015",
        "country": "USA",
        "lon": "-75.351958",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-14737",
        "lat": "40.58883"
      },
      {
        "city": "Schaumburg",
        "zip": "60159",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Mount Pleasant",
        "zip": "29464",
        "country": "USA",
        "lon": "-79.820563",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13880",
        "lat": "32.847273"
      },
      {
        "city": "Auburn",
        "zip": "98001",
        "country": "USA",
        "lon": "-121.821908",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.465495"
      },
      {
        "city": "Decatur",
        "zip": "62521",
        "country": "USA",
        "lon": "-88.946486",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-93822",
        "lat": "39.839477"
      },
      {
        "city": "San Ramon",
        "zip": "94583",
        "country": "USA",
        "lon": "-121.975531",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.768556"
      },
      {
        "city": "Pleasanton",
        "zip": "94566",
        "country": "USA",
        "lon": "-121.862128",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.646081"
      },
      {
        "city": "Lake Charles",
        "zip": "70601",
        "country": "USA",
        "lon": "-93.214903",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-3937",
        "lat": "30.233355"
      },
      {
        "city": "Bolingbrook",
        "zip": "60440",
        "country": "USA",
        "lon": "-88.022464",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.623336"
      },
      {
        "city": "Pharr",
        "zip": "78577",
        "country": "USA",
        "lon": "-98.127765",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12919",
        "lat": "26.186698"
      },
      {
        "city": "Appleton",
        "zip": "54911",
        "country": "USA",
        "lon": "-88.370856",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14898",
        "lat": "44.275702"
      },
      {
        "city": "Gastonia",
        "zip": "28052",
        "country": "USA",
        "lon": "-81.178725",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13881",
        "lat": "35.267916"
      },
      {
        "city": "Folsom",
        "zip": "95630",
        "country": "USA",
        "lon": "-121.310796",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.594181"
      },
      {
        "city": "Southfield",
        "zip": "48034",
        "country": "USA",
        "lon": "-83.279164",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.478495"
      },
      {
        "city": "New Britain",
        "zip": "06050",
        "country": "USA",
        "lon": "-72.778391",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-14740",
        "lat": "41.666049"
      },
      {
        "city": "Goodyear",
        "zip": "85338",
        "country": "USA",
        "lon": "-112.18717",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.276539"
      },
      {
        "city": "Canton",
        "zip": "44701",
        "country": "USA",
        "lon": "-81.371185",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14895",
        "lat": "40.782408"
      },
      {
        "city": "Warner Robins",
        "zip": "31088",
        "country": "USA",
        "lon": "-83.644751",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-3813",
        "lat": "32.555039"
      },
      {
        "city": "Union City",
        "zip": "94587",
        "country": "USA",
        "lon": "-121.97362",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.589084"
      },
      {
        "city": "Perris",
        "zip": "92570",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Manteca",
        "zip": "95336",
        "country": "USA",
        "lon": "-121.177601",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.807297"
      },
      {
        "city": "Iowa City",
        "zip": "52240",
        "country": "USA",
        "lon": "-91.541579",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14923",
        "lat": "41.648207"
      },
      {
        "city": "Jonesboro",
        "zip": "72401",
        "country": "USA",
        "lon": "-90.623071",
        "cz": "3A",
        "state_id": "AR",
        "file": "0-13893",
        "lat": "35.835847"
      },
      {
        "city": "Wilmington",
        "zip": "19801",
        "country": "USA",
        "lon": "-75.547844",
        "cz": "4A",
        "state_id": "DE",
        "file": "0-13781",
        "lat": "39.727113"
      },
      {
        "city": "Lynwood",
        "zip": "90262",
        "country": "USA",
        "lon": "-118.202954",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.924538"
      },
      {
        "city": "Loveland",
        "zip": "80537",
        "country": "USA",
        "lon": "-105.266415",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "40.553704"
      },
      {
        "city": "Pawtucket",
        "zip": "02860",
        "country": "USA",
        "lon": "-71.392732",
        "cz": "#N/A",
        "state_id": "RI",
        "file": "0-14765",
        "lat": "41.875149"
      },
      {
        "city": "Boynton Beach",
        "zip": "33424",
        "country": "USA",
        "lon": "-80.430269",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.645895"
      },
      {
        "city": "Waukesha",
        "zip": "53186",
        "country": "USA",
        "lon": "-88.217715",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "42.987835"
      },
      {
        "city": "Gulfport",
        "zip": "39501",
        "country": "USA",
        "lon": "-89.064103",
        "cz": "3A",
        "state_id": "MS",
        "file": "0-13894",
        "lat": "30.396277"
      },
      {
        "city": "Apple Valley",
        "zip": "92307",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Passaic",
        "zip": "07055",
        "country": "USA",
        "lon": "-74.126916",
        "cz": "5A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.855103"
      },
      {
        "city": "Rapid City",
        "zip": "57701",
        "country": "USA",
        "lon": "-103.240024",
        "cz": "6A",
        "state_id": "SD",
        "file": "0-24090",
        "lat": "44.004363"
      },
      {
        "city": "Layton",
        "zip": "84040",
        "country": "USA",
        "lon": "-111.92614",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "41.088889"
      },
      {
        "city": "Lafayette",
        "zip": "47901",
        "country": "USA",
        "lon": "-86.830286",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "40.39905"
      },
      {
        "city": "Turlock",
        "zip": "95380",
        "country": "USA",
        "lon": "-120.844063",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.540206"
      },
      {
        "city": "Muncie",
        "zip": "47302",
        "country": "USA",
        "lon": "-85.389874",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "40.142124"
      },
      {
        "city": "Temple",
        "zip": "76501",
        "country": "USA",
        "lon": "-97.3047",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-13959",
        "lat": "31.068306"
      },
      {
        "city": "Missouri City",
        "zip": "77459",
        "country": "USA",
        "lon": "-95.649939",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.323965"
      },
      {
        "city": "Redlands",
        "zip": "92373",
        "country": "USA",
        "lon": "-116.889474",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.2409"
      },
      {
        "city": "Milpitas",
        "zip": "95035",
        "country": "USA",
        "lon": "-121.861989",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.436454"
      },
      {
        "city": "Palatine",
        "zip": "60038",
        "country": "USA",
        "lon": "-88.014072",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.097976"
      },
      {
        "city": "Missoula",
        "zip": "59801",
        "country": "USA",
        "lon": "-113.909123",
        "cz": "6B",
        "state_id": "MT",
        "file": "0-24153",
        "lat": "46.853606"
      },
      {
        "city": "Rock Hill",
        "zip": "29730",
        "country": "USA",
        "lon": "-81.052437",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13881",
        "lat": "34.909109"
      },
      {
        "city": "Jacksonville",
        "zip": "28540",
        "country": "USA",
        "lon": "-77.414673",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13748",
        "lat": "34.726587"
      },
      {
        "city": "Franklin",
        "zip": "37064",
        "country": "USA",
        "lon": "-86.965691",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13897",
        "lat": "35.890746"
      },
      {
        "city": "Flagstaff",
        "zip": "86001",
        "country": "USA",
        "lon": "-111.597853",
        "cz": "5B",
        "state_id": "AZ",
        "file": "0-3103",
        "lat": "35.932116"
      },
      {
        "city": "Flower Mound",
        "zip": "75022",
        "country": "USA",
        "lon": "-97.119331",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.026795"
      },
      {
        "city": "Waterloo",
        "zip": "50701",
        "country": "USA",
        "lon": "-92.339209",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-94910",
        "lat": "42.441117"
      },
      {
        "city": "Union City",
        "zip": "07087",
        "country": "USA",
        "lon": "-74.056335",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.758951"
      },
      {
        "city": "Mount Vernon",
        "zip": "10550",
        "country": "USA",
        "lon": "-73.83389",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "40.909838"
      },
      {
        "city": "Fort Myers",
        "zip": "33901",
        "country": "USA",
        "lon": "-81.925065",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "26.564355"
      },
      {
        "city": "Dothan",
        "zip": "36301",
        "country": "USA",
        "lon": "-85.371845",
        "cz": "2A",
        "state_id": "AL",
        "file": "0-93805",
        "lat": "31.148124"
      },
      {
        "city": "Rancho Cordova",
        "zip": "95670",
        "country": "USA",
        "lon": "-121.270426",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.597705"
      },
      {
        "city": "Redondo Beach",
        "zip": "90277",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Jackson",
        "zip": "38301",
        "country": "USA",
        "lon": "-88.862742",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13893",
        "lat": "35.570424"
      },
      {
        "city": "Pasco",
        "zip": "99301",
        "country": "USA",
        "lon": "-118.899447",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24155",
        "lat": "46.42066"
      },
      {
        "city": "Eau Claire",
        "zip": "54701",
        "country": "USA",
        "lon": "-91.473097",
        "cz": "6A",
        "state_id": "WI",
        "file": "0-14991",
        "lat": "44.75653"
      },
      {
        "city": "North Richland Hills",
        "zip": "76180",
        "country": "USA",
        "lon": "-97.217416",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.868023"
      },
      {
        "city": "Bismarck",
        "zip": "58501",
        "country": "USA",
        "lon": "-100.502724",
        "cz": "6A",
        "state_id": "ND",
        "file": "0-24011",
        "lat": "46.981207"
      },
      {
        "city": "Yorba Linda",
        "zip": "92885",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Kenner",
        "zip": "70062",
        "country": "USA",
        "lon": "-90.20446",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-12916",
        "lat": "29.994398"
      },
      {
        "city": "Walnut Creek",
        "zip": "94595",
        "country": "USA",
        "lon": "-122.070625",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.873343"
      },
      {
        "city": "Frederick",
        "zip": "21701",
        "country": "USA",
        "lon": "-77.369299",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93738",
        "lat": "39.512748"
      },
      {
        "city": "Oshkosh",
        "zip": "54901",
        "country": "USA",
        "lon": "-88.55756",
        "cz": "6A",
        "state_id": "WI",
        "file": "0-14898",
        "lat": "44.005661"
      },
      {
        "city": "Pittsburg",
        "zip": "94565",
        "country": "USA",
        "lon": "-121.917219",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "38.00307"
      },
      {
        "city": "Palo Alto",
        "zip": "94301",
        "country": "USA",
        "lon": "-121.705327",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.189396"
      },
      {
        "city": "Bossier City",
        "zip": "71111",
        "country": "USA",
        "lon": "-93.586698",
        "cz": "3A",
        "state_id": "LA",
        "file": "0-13957",
        "lat": "32.516708"
      },
      {
        "city": "Portland",
        "zip": "04101",
        "country": "USA",
        "lon": "-70.262393",
        "cz": "6A",
        "state_id": "ME",
        "file": "0-14764",
        "lat": "43.658784"
      },
      {
        "city": "Davis",
        "zip": "95616",
        "country": "USA",
        "lon": "-121.749016",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.521776"
      },
      {
        "city": "South San Francisco",
        "zip": "94080",
        "country": "USA",
        "lon": "-122.423483",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.657443"
      },
      {
        "city": "Camarillo",
        "zip": "93010",
        "country": "USA",
        "lon": "-119.084253",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23174",
        "lat": "34.307783"
      },
      {
        "city": "North Little Rock",
        "zip": "72114",
        "country": "USA",
        "lon": "-92.262899",
        "cz": "3A",
        "state_id": "AR",
        "file": "0-13963",
        "lat": "34.766561"
      },
      {
        "city": "Schenectady",
        "zip": "12301",
        "country": "USA",
        "lon": "-74.058015",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14735",
        "lat": "42.833261"
      },
      {
        "city": "Gaithersburg",
        "zip": "20877",
        "country": "USA",
        "lon": "-77.182953",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93738",
        "lat": "39.139336"
      },
      {
        "city": "Harlingen",
        "zip": "78550",
        "country": "USA",
        "lon": "-97.536807",
        "cz": "1A",
        "state_id": "TX",
        "file": "0-12919",
        "lat": "26.185202"
      },
      {
        "city": "Yuba City",
        "zip": "95991",
        "country": "USA",
        "lon": "-121.612481",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "39.048854"
      },
      {
        "city": "Youngstown",
        "zip": "44501",
        "country": "USA",
        "lon": "-80.802854",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14852",
        "lat": "41.017082"
      },
      {
        "city": "Skokie",
        "zip": "60076",
        "country": "USA",
        "lon": "-87.883566",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.057878"
      },
      {
        "city": "Kissimmee",
        "zip": "34741",
        "country": "USA",
        "lon": "-81.461385",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.307138"
      },
      {
        "city": "Johnson City",
        "zip": "37601",
        "country": "USA",
        "lon": "-82.387716",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13877",
        "lat": "36.3417"
      },
      {
        "city": "Victoria",
        "zip": "77901",
        "country": "USA",
        "lon": "-97.026681",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12912",
        "lat": "28.777373"
      },
      {
        "city": "San Clemente",
        "zip": "92672",
        "country": "USA",
        "lon": "-117.616082",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.568923"
      },
      {
        "city": "Bayonne",
        "zip": "07002",
        "country": "USA",
        "lon": "-74.109486",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.670858"
      },
      {
        "city": "Laguna Niguel",
        "zip": "92607",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "East Orange",
        "zip": "07017",
        "country": "USA",
        "lon": "-74.207039",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.77185"
      },
      {
        "city": "Homestead",
        "zip": "33030",
        "country": "USA",
        "lon": "-80.458168",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "25.558428"
      },
      {
        "city": "Rockville",
        "zip": "20847",
        "country": "USA",
        "lon": "-77.207617",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93738",
        "lat": "39.143979"
      },
      {
        "city": "Delray Beach",
        "zip": "33444",
        "country": "USA",
        "lon": "-80.081614",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.457598"
      },
      {
        "city": "Janesville",
        "zip": "53545",
        "country": "USA",
        "lon": "-89.112201",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14837",
        "lat": "42.710981"
      },
      {
        "city": "Conway",
        "zip": "72032",
        "country": "USA",
        "lon": "-92.376466",
        "cz": "3A",
        "state_id": "AR",
        "file": "0-13963",
        "lat": "35.146446"
      },
      {
        "city": "Pico Rivera",
        "zip": "90660",
        "country": "USA",
        "lon": "-118.088787",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.985812"
      },
      {
        "city": "Lorain",
        "zip": "44052",
        "country": "USA",
        "lon": "-82.166534",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.450991"
      },
      {
        "city": "Montebello",
        "zip": "90640",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Lodi",
        "zip": "95240",
        "country": "USA",
        "lon": "-121.172415",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.111356"
      },
      {
        "city": "New Braunfels",
        "zip": "78130",
        "country": "USA",
        "lon": "-98.221041",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12921",
        "lat": "29.776488"
      },
      {
        "city": "Marysville",
        "zip": "98270",
        "country": "USA",
        "lon": "-122.161951",
        "cz": "4C",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "48.049576"
      },
      {
        "city": "Madera",
        "zip": "93637",
        "country": "USA",
        "lon": "-120.182423",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "36.928286"
      },
      {
        "city": "Conroe",
        "zip": "77301",
        "country": "USA",
        "lon": "-95.51232",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "30.310041"
      },
      {
        "city": "Santa Cruz",
        "zip": "95060",
        "country": "USA",
        "lon": "-122.111126",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.052748"
      },
      {
        "city": "Eden Prairie",
        "zip": "55344",
        "country": "USA",
        "lon": "-93.440429",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.850563"
      },
      {
        "city": "Cheyenne",
        "zip": "82001",
        "country": "USA",
        "lon": "-104.56264",
        "cz": "5B",
        "state_id": "WY",
        "file": "0-24018",
        "lat": "41.25173"
      },
      {
        "city": "Daytona Beach",
        "zip": "32114",
        "country": "USA",
        "lon": "-81.053368",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12834",
        "lat": "29.146163"
      },
      {
        "city": "Alpharetta",
        "zip": "30004",
        "country": "USA",
        "lon": "-84.30205",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-13874",
        "lat": "34.112373"
      },
      {
        "city": "Hamilton",
        "zip": "45011",
        "country": "USA",
        "lon": "-84.472925",
        "cz": "4A",
        "state_id": "OH",
        "file": "0-93814",
        "lat": "39.425137"
      },
      {
        "city": "Waltham",
        "zip": "02451",
        "country": "USA",
        "lon": "-71.24505",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.398588"
      },
      {
        "city": "Haverhill",
        "zip": "01830",
        "country": "USA",
        "lon": "-71.072501",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.792639"
      },
      {
        "city": "Council Bluffs",
        "zip": "51501",
        "country": "USA",
        "lon": "-95.875086",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-94918",
        "lat": "41.23238"
      },
      {
        "city": "Taylor",
        "zip": "48180",
        "country": "USA",
        "lon": "-83.249067",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.245837"
      },
      {
        "city": "Utica",
        "zip": "13501",
        "country": "USA",
        "lon": "-75.233208",
        "cz": "6A",
        "state_id": "NY",
        "file": "0-14771",
        "lat": "43.077369"
      },
      {
        "city": "Ames",
        "zip": "50010",
        "country": "USA",
        "lon": "-93.600254",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14933",
        "lat": "42.037879"
      },
      {
        "city": "La Habra",
        "zip": "90631",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Encinitas",
        "zip": "92023",
        "country": "USA",
        "lon": "-116.846046",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.016928"
      },
      {
        "city": "Bowling Green",
        "zip": "42101",
        "country": "USA",
        "lon": "-86.451752",
        "cz": "4A",
        "state_id": "KY",
        "file": "0-13897",
        "lat": "37.017407"
      },
      {
        "city": "Burnsville",
        "zip": "55306",
        "country": "USA",
        "lon": "-93.221535",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.762208"
      },
      {
        "city": "Greenville",
        "zip": "29601",
        "country": "USA",
        "lon": "-82.402871",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-3870",
        "lat": "34.848567"
      },
      {
        "city": "West Des Moines",
        "zip": "50265",
        "country": "USA",
        "lon": "-93.717232",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14933",
        "lat": "41.567138"
      },
      {
        "city": "Cedar Park",
        "zip": "78613",
        "country": "USA",
        "lon": "-97.628724",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-13958",
        "lat": "30.494032"
      },
      {
        "city": "Tulare",
        "zip": "93274",
        "country": "USA",
        "lon": "-119.299856",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23155",
        "lat": "36.133527"
      },
      {
        "city": "Monterey Park",
        "zip": "91754",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Vineland",
        "zip": "08360",
        "country": "USA",
        "lon": "-75.025676",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-93730",
        "lat": "39.392671"
      },
      {
        "city": "Terre Haute",
        "zip": "47801",
        "country": "USA",
        "lon": "-87.410094",
        "cz": "4A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "39.433602"
      },
      {
        "city": "Mansfield",
        "zip": "76063",
        "country": "USA",
        "lon": "-97.291484",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.771419"
      },
      {
        "city": "Bristol",
        "zip": "06010",
        "country": "USA",
        "lon": "-72.939577",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-14740",
        "lat": "41.681198"
      },
      {
        "city": "Malden",
        "zip": "02148",
        "country": "USA",
        "lon": "-71.085396",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.436545"
      },
      {
        "city": "Meriden",
        "zip": "06450",
        "country": "USA",
        "lon": "-72.801901",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-14740",
        "lat": "41.536498"
      },
      {
        "city": "Cupertino",
        "zip": "95014",
        "country": "USA",
        "lon": "-121.705327",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.189396"
      },
      {
        "city": "Springfield",
        "zip": "97477",
        "country": "USA",
        "lon": "-123.444371",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24221",
        "lat": "43.937276"
      },
      {
        "city": "Rogers",
        "zip": "72756",
        "country": "USA",
        "lon": "-94.181568",
        "cz": "4A",
        "state_id": "AR",
        "file": "0-13964",
        "lat": "36.355417"
      },
      {
        "city": "Gardena",
        "zip": "90247",
        "country": "USA",
        "lon": "-118.295256",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23174",
        "lat": "33.888315"
      },
      {
        "city": "Pontiac",
        "zip": "48340",
        "country": "USA",
        "lon": "-83.289036",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14826",
        "lat": "42.670272"
      },
      {
        "city": "National City",
        "zip": "91950",
        "country": "USA",
        "lon": "-117.084353",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.671194"
      },
      {
        "city": "Grand Junction",
        "zip": "81501",
        "country": "USA",
        "lon": "-108.583126",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-23066",
        "lat": "39.069019"
      },
      {
        "city": "Rocklin",
        "zip": "95677",
        "country": "USA",
        "lon": "-121.230374",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.801233"
      },
      {
        "city": "Chapel Hill",
        "zip": "27514",
        "country": "USA",
        "lon": "-79.054475",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "36.004647"
      },
      {
        "city": "Casper",
        "zip": "82601",
        "country": "USA",
        "lon": "-106.312561",
        "cz": "6B",
        "state_id": "WY",
        "file": "0-24089",
        "lat": "42.859875"
      },
      {
        "city": "Broomfield",
        "zip": "80020",
        "country": "USA",
        "lon": "-105.097151",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "40.046064"
      },
      {
        "city": "Petaluma",
        "zip": "94952",
        "country": "USA",
        "lon": "-122.822588",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "38.265366"
      },
      {
        "city": "South Jordan",
        "zip": "84095",
        "country": "USA",
        "lon": "-111.953891",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.554098"
      },
      {
        "city": "Springfield",
        "zip": "45501",
        "country": "USA",
        "lon": "-83.813228",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-93815",
        "lat": "39.927059"
      },
      {
        "city": "Great Falls",
        "zip": "59401",
        "country": "USA",
        "lon": "-111.422948",
        "cz": "6B",
        "state_id": "MT",
        "file": "0-24143",
        "lat": "47.402024"
      },
      {
        "city": "Lancaster",
        "zip": "17601",
        "country": "USA",
        "lon": "-76.31068",
        "cz": "4A",
        "state_id": "PA",
        "file": "0-14751",
        "lat": "40.076553"
      },
      {
        "city": "North Port",
        "zip": "34286",
        "country": "USA",
        "lon": "-82.175602",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.074755"
      },
      {
        "city": "Lakewood",
        "zip": "98439",
        "country": "USA",
        "lon": "-122.510316",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24227",
        "lat": "47.128671"
      },
      {
        "city": "Marietta",
        "zip": "30006",
        "country": "USA",
        "lon": "-84.557181",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-13874",
        "lat": "33.912473"
      },
      {
        "city": "San Rafael",
        "zip": "94901",
        "country": "USA",
        "lon": "-122.504286",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.970948"
      },
      {
        "city": "Royal Oak",
        "zip": "48067",
        "country": "USA",
        "lon": "-83.145375",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.492485"
      },
      {
        "city": "Des Plaines",
        "zip": "60016",
        "country": "USA",
        "lon": "-87.890466",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.048278"
      },
      {
        "city": "Huntington Park",
        "zip": "90255",
        "country": "USA",
        "lon": "-118.213137",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.977987"
      },
      {
        "city": "La Mesa",
        "zip": "91941",
        "country": "USA",
        "lon": "-116.998102",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.76105"
      },
      {
        "city": "Orland Park",
        "zip": "60462",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Auburn",
        "zip": "36830",
        "country": "USA",
        "lon": "-85.468222",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-93842",
        "lat": "32.547542"
      },
      {
        "city": "Lakeville",
        "zip": "55044",
        "country": "USA",
        "lon": "-93.25812",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.633421"
      },
      {
        "city": "Owensboro",
        "zip": "42301",
        "country": "USA",
        "lon": "-87.257303",
        "cz": "4A",
        "state_id": "KY",
        "file": "0-93817",
        "lat": "37.751818"
      },
      {
        "city": "Jupiter",
        "zip": "33458",
        "country": "USA",
        "lon": "-80.430269",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "26.645895"
      },
      {
        "city": "Dubuque",
        "zip": "52001",
        "country": "USA",
        "lon": "-90.877135",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14923",
        "lat": "42.458876"
      },
      {
        "city": "Rowlett",
        "zip": "75030",
        "country": "USA",
        "lon": "-96.534737",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.91747"
      },
      {
        "city": "Novi",
        "zip": "48374",
        "country": "USA",
        "lon": "-83.522221",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.468959"
      },
      {
        "city": "White Plains",
        "zip": "10601",
        "country": "USA",
        "lon": "-73.769626",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "41.031397"
      },
      {
        "city": "Arcadia",
        "zip": "91006",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Redmond",
        "zip": "98052",
        "country": "USA",
        "lon": "-122.121034",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.678756"
      },
      {
        "city": "Lake Elsinore",
        "zip": "92530",
        "country": "USA",
        "lon": "-116.649216",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.658068"
      },
      {
        "city": "Ocala",
        "zip": "34470",
        "country": "USA",
        "lon": "-82.169401",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12834",
        "lat": "29.238672"
      },
      {
        "city": "Tinley Park",
        "zip": "60477",
        "country": "USA",
        "lon": "-88.026517",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.143475"
      },
      {
        "city": "Port Orange",
        "zip": "32129",
        "country": "USA",
        "lon": "-81.172169",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12834",
        "lat": "29.022729"
      },
      {
        "city": "Medford",
        "zip": "02153",
        "country": "USA",
        "lon": "-71.459405",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.446396"
      },
      {
        "city": "Oak Lawn",
        "zip": "60453",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Rocky Mount",
        "zip": "27801",
        "country": "USA",
        "lon": "-77.641181",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.949051"
      },
      {
        "city": "Kokomo",
        "zip": "46901",
        "country": "USA",
        "lon": "-86.171054",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "40.506851"
      },
      {
        "city": "Bowie",
        "zip": "20715",
        "country": "USA",
        "lon": "-76.74379",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93721",
        "lat": "38.982612"
      },
      {
        "city": "Berwyn",
        "zip": "60402",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Fountain Valley",
        "zip": "92708",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Buckeye",
        "zip": "85326",
        "country": "USA",
        "lon": "-112.18717",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.276539"
      },
      {
        "city": "Dearborn Heights",
        "zip": "48125",
        "country": "USA",
        "lon": "-83.298123",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.342272"
      },
      {
        "city": "Woodland",
        "zip": "95695",
        "country": "USA",
        "lon": "-121.915653",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.74388"
      },
      {
        "city": "Noblesville",
        "zip": "46060",
        "country": "USA",
        "lon": "-85.999521",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "40.073328"
      },
      {
        "city": "Valdosta",
        "zip": "31601",
        "country": "USA",
        "lon": "-83.332068",
        "cz": "2A",
        "state_id": "GA",
        "file": "0-93805",
        "lat": "30.753904"
      },
      {
        "city": "Diamond Bar",
        "zip": "91765",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Manhattan",
        "zip": "66502",
        "country": "USA",
        "lon": "-96.564589",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-13996",
        "lat": "39.209375"
      },
      {
        "city": "Santee",
        "zip": "92071",
        "country": "USA",
        "lon": "-116.994511",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.843956"
      },
      {
        "city": "Taunton",
        "zip": "02780",
        "country": "USA",
        "lon": "-71.092827",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14765",
        "lat": "41.858851"
      },
      {
        "city": "Sanford",
        "zip": "32771",
        "country": "USA",
        "lon": "-81.299169",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.793491"
      },
      {
        "city": "New Brunswick",
        "zip": "08901",
        "country": "USA",
        "lon": "-74.444395",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.486754"
      },
      {
        "city": "Decatur",
        "zip": "35601",
        "country": "USA",
        "lon": "-86.995551",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-3856",
        "lat": "34.549833"
      },
      {
        "city": "Chicopee",
        "zip": "01013",
        "country": "USA",
        "lon": "-72.667341",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14740",
        "lat": "42.161492"
      },
      {
        "city": "Anderson",
        "zip": "46011",
        "country": "USA",
        "lon": "-85.766164",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "40.141033"
      },
      {
        "city": "Hempstead",
        "zip": "11549",
        "country": "USA",
        "lon": "-73.601772",
        "cz": "4A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "40.754757"
      },
      {
        "city": "Corvallis",
        "zip": "97330",
        "country": "USA",
        "lon": "-123.275969",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24232",
        "lat": "44.639931"
      },
      {
        "city": "Porterville",
        "zip": "93257",
        "country": "USA",
        "lon": "-118.703592",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23155",
        "lat": "35.973777"
      },
      {
        "city": "West Haven",
        "zip": "06516",
        "country": "USA",
        "lon": "-72.940335",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.272452"
      },
      {
        "city": "Brentwood",
        "zip": "94513",
        "country": "USA",
        "lon": "-121.843071",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.912274"
      },
      {
        "city": "Paramount",
        "zip": "90723",
        "country": "USA",
        "lon": "-118.165152",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.899015"
      },
      {
        "city": "Grand Forks",
        "zip": "58201",
        "country": "USA",
        "lon": "-97.431501",
        "cz": "7",
        "state_id": "ND",
        "file": "0-14914",
        "lat": "47.9041"
      },
      {
        "city": "Georgetown",
        "zip": "78626",
        "country": "USA",
        "lon": "-97.574706",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-13958",
        "lat": "30.668034"
      },
      {
        "city": "Mount Prospect",
        "zip": "60056",
        "country": "USA",
        "lon": "-87.931797",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.062377"
      },
      {
        "city": "Hanford",
        "zip": "93230",
        "country": "USA",
        "lon": "-119.71776",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-93193",
        "lat": "36.220468"
      },
      {
        "city": "Normal",
        "zip": "61761",
        "country": "USA",
        "lon": "-88.7989",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-14842",
        "lat": "40.530375"
      },
      {
        "city": "Rosemead",
        "zip": "91770",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Lehi",
        "zip": "84043",
        "country": "USA",
        "lon": "-111.921078",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.318139"
      },
      {
        "city": "Highland",
        "zip": "92346",
        "country": "USA",
        "lon": "-117.14027",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.156543"
      },
      {
        "city": "Novato",
        "zip": "94945",
        "country": "USA",
        "lon": "-122.540951",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "38.027654"
      },
      {
        "city": "Port Arthur",
        "zip": "77640",
        "country": "USA",
        "lon": "-93.964278",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12917",
        "lat": "29.870904"
      },
      {
        "city": "Carson City",
        "zip": "89701",
        "country": "USA",
        "lon": "-119.73074",
        "cz": "4B",
        "state_id": "NV",
        "file": "0-23185",
        "lat": "39.154485"
      },
      {
        "city": "San Marcos",
        "zip": "78666",
        "country": "USA",
        "lon": "-97.997166",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-13958",
        "lat": "29.972907"
      },
      {
        "city": "Hendersonville",
        "zip": "37075",
        "country": "USA",
        "lon": "-86.612045",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13897",
        "lat": "36.347551"
      },
      {
        "city": "Elyria",
        "zip": "44035",
        "country": "USA",
        "lon": "-82.105112",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.371396"
      },
      {
        "city": "Revere",
        "zip": "02151",
        "country": "USA",
        "lon": "-71.020494",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.366303"
      },
      {
        "city": "Pflugerville",
        "zip": "78660",
        "country": "USA",
        "lon": "-97.597055",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-13958",
        "lat": "30.442737"
      },
      {
        "city": "Greenwood",
        "zip": "46142",
        "country": "USA",
        "lon": "-86.100454",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "39.491246"
      },
      {
        "city": "Bellevue",
        "zip": "68005",
        "country": "USA",
        "lon": "-95.903956",
        "cz": "5A",
        "state_id": "NE",
        "file": "0-94918",
        "lat": "41.132948"
      },
      {
        "city": "Wheaton",
        "zip": "60187",
        "country": "USA",
        "lon": "-88.088716",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.839679"
      },
      {
        "city": "Smyrna",
        "zip": "30080",
        "country": "USA",
        "lon": "-84.556181",
        "cz": "3A",
        "state_id": "GA",
        "file": "0-13874",
        "lat": "33.864604"
      },
      {
        "city": "Sarasota",
        "zip": "34230",
        "country": "USA",
        "lon": "-82.537169",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.335023"
      },
      {
        "city": "Blue Springs",
        "zip": "64013",
        "country": "USA",
        "lon": "-94.370275",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-3947",
        "lat": "38.964518"
      },
      {
        "city": "Colton",
        "zip": "92324",
        "country": "USA",
        "lon": "-116.900557",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.151161"
      },
      {
        "city": "Euless",
        "zip": "76039",
        "country": "USA",
        "lon": "-97.075688",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.859305"
      },
      {
        "city": "Castle Rock",
        "zip": "80104",
        "country": "USA",
        "lon": "-104.849988",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-93037",
        "lat": "39.374779"
      },
      {
        "city": "Cathedral City",
        "zip": "92234",
        "country": "USA",
        "lon": "-116.277152",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.647301"
      },
      {
        "city": "Kingsport",
        "zip": "37660",
        "country": "USA",
        "lon": "-82.547615",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13877",
        "lat": "36.514384"
      },
      {
        "city": "Lake Havasu City",
        "zip": "86403",
        "country": "USA",
        "lon": "-114.310294",
        "cz": "3B",
        "state_id": "AZ",
        "file": "0-23184",
        "lat": "34.500556"
      },
      {
        "city": "Pensacola",
        "zip": "32501",
        "country": "USA",
        "lon": "-87.256471",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-13894",
        "lat": "30.424838"
      },
      {
        "city": "Hoboken",
        "zip": "07030",
        "country": "USA",
        "lon": "-74.033934",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.746851"
      },
      {
        "city": "Yucaipa",
        "zip": "92399",
        "country": "USA",
        "lon": "-116.971138",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.06226"
      },
      {
        "city": "Watsonville",
        "zip": "95076",
        "country": "USA",
        "lon": "-121.74696",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "36.98025"
      },
      {
        "city": "Richland",
        "zip": "99352",
        "country": "USA",
        "lon": "-119.491659",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24155",
        "lat": "46.282031"
      },
      {
        "city": "Delano",
        "zip": "93215",
        "country": "USA",
        "lon": "-118.905173",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23155",
        "lat": "35.294405"
      },
      {
        "city": "Hoffman Estates",
        "zip": "60179",
        "country": "USA",
        "lon": "-88.223655",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.079336"
      },
      {
        "city": "Florissant",
        "zip": "63031",
        "country": "USA",
        "lon": "-90.351314",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-13994",
        "lat": "38.805499"
      },
      {
        "city": "Placentia",
        "zip": "92870",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "West New York",
        "zip": "07093",
        "country": "USA",
        "lon": "-74.011533",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.7888"
      },
      {
        "city": "Dublin",
        "zip": "94568",
        "country": "USA",
        "lon": "-121.906329",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.714402"
      },
      {
        "city": "Oak Park",
        "zip": "60301",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Peabody",
        "zip": "01960",
        "country": "USA",
        "lon": "-70.973646",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.536996"
      },
      {
        "city": "Perth Amboy",
        "zip": "08861",
        "country": "USA",
        "lon": "-74.279144",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.520654"
      },
      {
        "city": "Battle Creek",
        "zip": "49014",
        "country": "USA",
        "lon": "-85.13044",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14836",
        "lat": "42.303041"
      },
      {
        "city": "Bradenton",
        "zip": "34201",
        "country": "USA",
        "lon": "-82.470456",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.404731"
      },
      {
        "city": "Gilroy",
        "zip": "95020",
        "country": "USA",
        "lon": "-121.493849",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.023347"
      },
      {
        "city": "Milford",
        "zip": "06460",
        "country": "USA",
        "lon": "-72.951273",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.343773"
      },
      {
        "city": "Albany",
        "zip": "97321",
        "country": "USA",
        "lon": "-122.581676",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24232",
        "lat": "44.594489"
      },
      {
        "city": "Ankeny",
        "zip": "50015",
        "country": "USA",
        "lon": "-93.572173",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14933",
        "lat": "41.672687"
      },
      {
        "city": "La Crosse",
        "zip": "54601",
        "country": "USA",
        "lon": "-91.132072",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14920",
        "lat": "43.85456"
      },
      {
        "city": "Burlington",
        "zip": "27215",
        "country": "USA",
        "lon": "-79.430129",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13723",
        "lat": "36.091025"
      },
      {
        "city": "Harrisonburg",
        "zip": "22801",
        "country": "USA",
        "lon": "-78.877137",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13729",
        "lat": "38.422777"
      },
      {
        "city": "Minnetonka",
        "zip": "55345",
        "country": "USA",
        "lon": "-93.481749",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.916963"
      },
      {
        "city": "Elkhart",
        "zip": "46514",
        "country": "USA",
        "lon": "-85.937024",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-14848",
        "lat": "41.71873"
      },
      {
        "city": "Lakewood",
        "zip": "44107",
        "country": "USA",
        "lon": "-81.637249",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.514849"
      },
      {
        "city": "Glendora",
        "zip": "91740",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Southaven",
        "zip": "38671",
        "country": "USA",
        "lon": "-89.99624",
        "cz": "3A",
        "state_id": "MS",
        "file": "0-13893",
        "lat": "34.956633"
      },
      {
        "city": "Charleston",
        "zip": "25301",
        "country": "USA",
        "lon": "-81.605094",
        "cz": "4A",
        "state_id": "WV",
        "file": "0-13866",
        "lat": "38.328948"
      },
      {
        "city": "Joplin",
        "zip": "64801",
        "country": "USA",
        "lon": "-94.502663",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-13995",
        "lat": "37.113343"
      },
      {
        "city": "Enid",
        "zip": "73701",
        "country": "USA",
        "lon": "-97.843091",
        "cz": "4A",
        "state_id": "OK",
        "file": "0-13967",
        "lat": "36.402681"
      },
      {
        "city": "Plainfield",
        "zip": "07060",
        "country": "USA",
        "lon": "-74.414995",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.615202"
      },
      {
        "city": "Grand Island",
        "zip": "68801",
        "country": "USA",
        "lon": "-98.368698",
        "cz": "5A",
        "state_id": "NE",
        "file": "0-14935",
        "lat": "40.87227"
      },
      {
        "city": "Palm Desert",
        "zip": "92211",
        "country": "USA",
        "lon": "-116.339766",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.76437"
      },
      {
        "city": "Huntersville",
        "zip": "28070",
        "country": "USA",
        "lon": "-80.898668",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13881",
        "lat": "35.462187"
      },
      {
        "city": "Saginaw",
        "zip": "48601",
        "country": "USA",
        "lon": "-83.886873",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14826",
        "lat": "43.41242"
      },
      {
        "city": "Grapevine",
        "zip": "76051",
        "country": "USA",
        "lon": "-97.080802",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.932843"
      },
      {
        "city": "Aliso Viejo",
        "zip": "92656",
        "country": "USA",
        "lon": "-117.751341",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.603459"
      },
      {
        "city": "Casa Grande",
        "zip": "85222",
        "country": "USA",
        "lon": "-111.753991",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "32.89055"
      },
      {
        "city": "Pinellas Park",
        "zip": "33780",
        "country": "USA",
        "lon": "-82.724763",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "27.891809"
      },
      {
        "city": "Troy",
        "zip": "12179",
        "country": "USA",
        "lon": "-73.525561",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14735",
        "lat": "42.71144"
      },
      {
        "city": "West Sacramento",
        "zip": "95605",
        "country": "USA",
        "lon": "-121.537801",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.591854"
      },
      {
        "city": "Commerce City",
        "zip": "80022",
        "country": "USA",
        "lon": "-104.771527",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "39.869835"
      },
      {
        "city": "Monroe",
        "zip": "71201",
        "country": "USA",
        "lon": "-92.094583",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-13957",
        "lat": "32.502833"
      },
      {
        "city": "Cerritos",
        "zip": "90703",
        "country": "USA",
        "lon": "-118.068648",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.866914"
      },
      {
        "city": "Downers Grove",
        "zip": "60515",
        "country": "USA",
        "lon": "-88.012267",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.796279"
      },
      {
        "city": "Wilson",
        "zip": "27893",
        "country": "USA",
        "lon": "-77.925269",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.723264"
      },
      {
        "city": "Niagara Falls",
        "zip": "14301",
        "country": "USA",
        "lon": "-79.009414",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-14733",
        "lat": "43.089805"
      },
      {
        "city": "Poway",
        "zip": "92064",
        "country": "USA",
        "lon": "-117.030299",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.994097"
      },
      {
        "city": "Cuyahoga Falls",
        "zip": "44221",
        "country": "USA",
        "lon": "-81.473585",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14895",
        "lat": "41.143906"
      },
      {
        "city": "Rancho Santa Margarita",
        "zip": "92688",
        "country": "USA",
        "lon": "-117.603684",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.601944"
      },
      {
        "city": "Harrisburg",
        "zip": "17101",
        "country": "USA",
        "lon": "-76.869644",
        "cz": "4A",
        "state_id": "PA",
        "file": "0-14751",
        "lat": "40.264589"
      },
      {
        "city": "Huntington",
        "zip": "25701",
        "country": "USA",
        "lon": "-82.412866",
        "cz": "4A",
        "state_id": "WV",
        "file": "0-3860",
        "lat": "38.371727"
      },
      {
        "city": "La Mirada",
        "zip": "90637",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Cypress",
        "zip": "90630",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Logan",
        "zip": "84321",
        "country": "USA",
        "lon": "-111.885084",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "41.76695"
      },
      {
        "city": "Galveston",
        "zip": "77550",
        "country": "USA",
        "lon": "-94.913451",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.323619"
      },
      {
        "city": "Sheboygan",
        "zip": "53081",
        "country": "USA",
        "lon": "-87.856793",
        "cz": "6A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "43.722489"
      },
      {
        "city": "Middletown",
        "zip": "45042",
        "country": "USA",
        "lon": "-84.442394",
        "cz": "4A",
        "state_id": "OH",
        "file": "0-93815",
        "lat": "39.543446"
      },
      {
        "city": "Roswell",
        "zip": "88201",
        "country": "USA",
        "lon": "-104.593687",
        "cz": "3B",
        "state_id": "NM",
        "file": "0-23048",
        "lat": "33.346666"
      },
      {
        "city": "Parker",
        "zip": "80134",
        "country": "USA",
        "lon": "-104.844731",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-93037",
        "lat": "39.489472"
      },
      {
        "city": "Bedford",
        "zip": "76021",
        "country": "USA",
        "lon": "-97.136289",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.852546"
      },
      {
        "city": "East Lansing",
        "zip": "48823",
        "country": "USA",
        "lon": "-84.503527",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14836",
        "lat": "42.737334"
      },
      {
        "city": "Methuen",
        "zip": "01844",
        "country": "USA",
        "lon": "-71.186915",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.73184"
      },
      {
        "city": "Covina",
        "zip": "91722",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Alexandria",
        "zip": "71301",
        "country": "USA",
        "lon": "-92.431184",
        "cz": "2A",
        "state_id": "LA",
        "file": "0-3937",
        "lat": "31.175885"
      },
      {
        "city": "Olympia",
        "zip": "98501",
        "country": "USA",
        "lon": "-122.869079",
        "cz": "4C",
        "state_id": "WA",
        "file": "0-24227",
        "lat": "46.984767"
      },
      {
        "city": "Euclid",
        "zip": "44117",
        "country": "USA",
        "lon": "-81.526142",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.566834"
      },
      {
        "city": "Mishawaka",
        "zip": "46544",
        "country": "USA",
        "lon": "-86.287884",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-14848",
        "lat": "41.61536"
      },
      {
        "city": "Salina",
        "zip": "67401",
        "country": "USA",
        "lon": "-97.642113",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-3928",
        "lat": "38.823673"
      },
      {
        "city": "Azusa",
        "zip": "91702",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Newark",
        "zip": "43055",
        "country": "USA",
        "lon": "-82.485985",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14821",
        "lat": "40.085963"
      },
      {
        "city": "Chesterfield",
        "zip": "63005",
        "country": "USA",
        "lon": "-90.650024",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-13994",
        "lat": "38.637002"
      },
      {
        "city": "Leesburg",
        "zip": "20175",
        "country": "USA",
        "lon": "-77.605404",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-93738",
        "lat": "39.041987"
      },
      {
        "city": "Hattiesburg",
        "zip": "39401",
        "country": "USA",
        "lon": "-89.28071",
        "cz": "3A",
        "state_id": "MS",
        "file": "0-13894",
        "lat": "31.245138"
      },
      {
        "city": "Roseville",
        "zip": "48066",
        "country": "USA",
        "lon": "-82.936069",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.509635"
      },
      {
        "city": "Bonita Springs",
        "zip": "34133",
        "country": "USA",
        "lon": "-81.94861",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "26.552895"
      },
      {
        "city": "Portage",
        "zip": "49002",
        "country": "USA",
        "lon": "-85.563901",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94860",
        "lat": "42.193793"
      },
      {
        "city": "Collierville",
        "zip": "38017",
        "country": "USA",
        "lon": "-89.699083",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13893",
        "lat": "35.080992"
      },
      {
        "city": "Middletown",
        "zip": "06457",
        "country": "USA",
        "lon": "-72.655357",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-14740",
        "lat": "41.550139"
      },
      {
        "city": "Stillwater",
        "zip": "74074",
        "country": "USA",
        "lon": "-97.069061",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13967",
        "lat": "36.101521"
      },
      {
        "city": "East Providence",
        "zip": "02914",
        "country": "USA",
        "lon": "-71.363348",
        "cz": "#N/A",
        "state_id": "RI",
        "file": "0-14765",
        "lat": "41.813429"
      },
      {
        "city": "Mentor",
        "zip": "44060",
        "country": "USA",
        "lon": "-81.328779",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.679291"
      },
      {
        "city": "Ceres",
        "zip": "95307",
        "country": "USA",
        "lon": "-120.967976",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.561889"
      },
      {
        "city": "Cedar Hill",
        "zip": "75104",
        "country": "USA",
        "lon": "-96.777626",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.767268"
      },
      {
        "city": "Mansfield",
        "zip": "44901",
        "country": "USA",
        "lon": "-82.511369",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14891",
        "lat": "40.850833"
      },
      {
        "city": "Binghamton",
        "zip": "13901",
        "country": "USA",
        "lon": "-75.890685",
        "cz": "5A",
        "state_id": "NY",
        "file": "0-4725",
        "lat": "42.165629"
      },
      {
        "city": "San Luis Obispo",
        "zip": "93401",
        "country": "USA",
        "lon": "-120.62122",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23273",
        "lat": "35.265573"
      },
      {
        "city": "Minot",
        "zip": "58701",
        "country": "USA",
        "lon": "-101.419006",
        "cz": "7",
        "state_id": "ND",
        "file": "0-24013",
        "lat": "48.084625"
      },
      {
        "city": "Palm Springs",
        "zip": "92262",
        "country": "USA",
        "lon": "-116.527996",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.842567"
      },
      {
        "city": "Pine Bluff",
        "zip": "71601",
        "country": "USA",
        "lon": "-91.985946",
        "cz": "3A",
        "state_id": "AR",
        "file": "0-13963",
        "lat": "34.208962"
      },
      {
        "city": "Texas City",
        "zip": "77590",
        "country": "USA",
        "lon": "-94.921474",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.376057"
      },
      {
        "city": "Summerville",
        "zip": "29483",
        "country": "USA",
        "lon": "-80.431751",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13880",
        "lat": "33.040201"
      },
      {
        "city": "Jeffersonville",
        "zip": "47130",
        "country": "USA",
        "lon": "-85.716548",
        "cz": "5A",
        "state_id": "IN",
        "file": "0-93821",
        "lat": "38.404909"
      },
      {
        "city": "San Jacinto",
        "zip": "92581",
        "country": "USA",
        "lon": "-116.055617",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.752886"
      },
      {
        "city": "Madison",
        "zip": "35756",
        "country": "USA",
        "lon": "-86.754233",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-3856",
        "lat": "34.651409"
      },
      {
        "city": "Altoona",
        "zip": "16601",
        "country": "USA",
        "lon": "-78.349874",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-14751",
        "lat": "40.489433"
      },
      {
        "city": "Columbus",
        "zip": "47201",
        "country": "USA",
        "lon": "-85.891338",
        "cz": "4A",
        "state_id": "IN",
        "file": "0-93819",
        "lat": "39.191447"
      },
      {
        "city": "Apopka",
        "zip": "32703",
        "country": "USA",
        "lon": "-81.488843",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.635425"
      },
      {
        "city": "Elmhurst",
        "zip": "60126",
        "country": "USA",
        "lon": "-87.946413",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.88353"
      },
      {
        "city": "Maricopa",
        "zip": "85239",
        "country": "USA",
        "lon": "-112.053351",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "32.957645"
      },
      {
        "city": "Farmington",
        "zip": "87401",
        "country": "USA",
        "lon": "-108.100535",
        "cz": "5B",
        "state_id": "NM",
        "file": "0-23061",
        "lat": "36.826248"
      },
      {
        "city": "Glenview",
        "zip": "60025",
        "country": "USA",
        "lon": "-87.819714",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.082715"
      },
      {
        "city": "Draper",
        "zip": "84020",
        "country": "USA",
        "lon": "-111.869671",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.506148"
      },
      {
        "city": "Lincoln",
        "zip": "95648",
        "country": "USA",
        "lon": "-121.327143",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "38.929305"
      },
      {
        "city": "Sierra Vista",
        "zip": "85635",
        "country": "USA",
        "lon": "-109.997623",
        "cz": "3B",
        "state_id": "AZ",
        "file": "0-23160",
        "lat": "31.810649"
      },
      {
        "city": "Lacey",
        "zip": "98503",
        "country": "USA",
        "lon": "-122.796695",
        "cz": "4C",
        "state_id": "WA",
        "file": "0-24227",
        "lat": "47.026418"
      },
      {
        "city": "Biloxi",
        "zip": "39530",
        "country": "USA",
        "lon": "-88.978634",
        "cz": "3A",
        "state_id": "MS",
        "file": "0-13894",
        "lat": "30.432454"
      },
      {
        "city": "Strongsville",
        "zip": "44136",
        "country": "USA",
        "lon": "-81.831656",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14820",
        "lat": "41.313268"
      },
      {
        "city": "Wylie",
        "zip": "75098",
        "country": "USA",
        "lon": "-96.552397",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.970309"
      },
      {
        "city": "Sayreville",
        "zip": "08871",
        "country": "USA",
        "lon": "-74.417344",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.430006"
      },
      {
        "city": "Kannapolis",
        "zip": "28081",
        "country": "USA",
        "lon": "-80.672531",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13881",
        "lat": "35.462981"
      },
      {
        "city": "Charlottesville",
        "zip": "22901",
        "country": "USA",
        "lon": "-78.561139",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13733",
        "lat": "38.093604"
      },
      {
        "city": "Littleton",
        "zip": "80120",
        "country": "USA",
        "lon": "-105.010182",
        "cz": "5B",
        "state_id": "CO",
        "file": "0-94018",
        "lat": "39.597937"
      },
      {
        "city": "Titusville",
        "zip": "32780",
        "country": "USA",
        "lon": "-80.853421",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.548877"
      },
      {
        "city": "Hackensack",
        "zip": "07601",
        "country": "USA",
        "lon": "-74.001623",
        "cz": "5A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.913482"
      },
      {
        "city": "Newark",
        "zip": "94560",
        "country": "USA",
        "lon": "-122.025352",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.534102"
      },
      {
        "city": "Pittsfield",
        "zip": "01201",
        "country": "USA",
        "lon": "-73.24807",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14735",
        "lat": "42.479475"
      },
      {
        "city": "York",
        "zip": "17401",
        "country": "USA",
        "lon": "-76.727139",
        "cz": "4A",
        "state_id": "PA",
        "file": "0-14751",
        "lat": "39.962998"
      },
      {
        "city": "Lombard",
        "zip": "60148",
        "country": "USA",
        "lon": "-88.003864",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.869829"
      },
      {
        "city": "Attleboro",
        "zip": "02703",
        "country": "USA",
        "lon": "-71.302297",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14765",
        "lat": "41.938976"
      },
      {
        "city": "Blacksburg",
        "zip": "24060",
        "country": "USA",
        "lon": "-80.43473",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13741",
        "lat": "37.256283"
      },
      {
        "city": "Dublin",
        "zip": "43016",
        "country": "USA",
        "lon": "-83.011389",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14821",
        "lat": "39.969036"
      },
      {
        "city": "Haltom City",
        "zip": "76117",
        "country": "USA",
        "lon": "-97.263393",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.801874"
      },
      {
        "city": "Lompoc",
        "zip": "93436",
        "country": "USA",
        "lon": "-120.336594",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23273",
        "lat": "34.629464"
      },
      {
        "city": "El Centro",
        "zip": "92243",
        "country": "USA",
        "lon": "-115.503842",
        "cz": "2B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.900509"
      },
      {
        "city": "Danville",
        "zip": "94506",
        "country": "USA",
        "lon": "-121.916718",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.832075"
      },
      {
        "city": "Jefferson City",
        "zip": "65101",
        "country": "USA",
        "lon": "-92.165194",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-3945",
        "lat": "38.494029"
      },
      {
        "city": "North Miami Beach",
        "zip": "33160",
        "country": "USA",
        "lon": "-80.139067",
        "cz": "1A",
        "state_id": "FL",
        "file": "0-12839",
        "lat": "25.944859"
      },
      {
        "city": "Freeport",
        "zip": "11520",
        "country": "USA",
        "lon": "-73.585222",
        "cz": "4A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "40.651251"
      },
      {
        "city": "Moline",
        "zip": "61265",
        "country": "USA",
        "lon": "-90.528972",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-14923",
        "lat": "41.529431"
      },
      {
        "city": "Coachella",
        "zip": "92236",
        "country": "USA",
        "lon": "-116.143588",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.646405"
      },
      {
        "city": "Fort Pierce",
        "zip": "34945",
        "country": "USA",
        "lon": "-80.534169",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12844",
        "lat": "27.412128"
      },
      {
        "city": "Smyrna",
        "zip": "37167",
        "country": "USA",
        "lon": "-86.45336",
        "cz": "3A",
        "state_id": "TN",
        "file": "0-13897",
        "lat": "35.856167"
      },
      {
        "city": "Bountiful",
        "zip": "84010",
        "country": "USA",
        "lon": "-111.870994",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.874038"
      },
      {
        "city": "Everett",
        "zip": "02149",
        "country": "USA",
        "lon": "-71.051183",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.407396"
      },
      {
        "city": "Danville",
        "zip": "24540",
        "country": "USA",
        "lon": "-79.376228",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-13723",
        "lat": "36.603501"
      },
      {
        "city": "Keller",
        "zip": "76244",
        "country": "USA",
        "lon": "-97.291484",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.771419"
      },
      {
        "city": "Belleville",
        "zip": "62220",
        "country": "USA",
        "lon": "-89.973877",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-13994",
        "lat": "38.46435"
      },
      {
        "city": "Bell Gardens",
        "zip": "90202",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Cleveland",
        "zip": "37311",
        "country": "USA",
        "lon": "-84.874031",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13882",
        "lat": "35.149223"
      },
      {
        "city": "Fairfield",
        "zip": "45014",
        "country": "USA",
        "lon": "-84.499743",
        "cz": "4A",
        "state_id": "OH",
        "file": "0-93814",
        "lat": "39.387357"
      },
      {
        "city": "Salem",
        "zip": "01970",
        "country": "USA",
        "lon": "-70.904237",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.512946"
      },
      {
        "city": "Rancho Palos Verdes",
        "zip": "90275",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "San Bruno",
        "zip": "94066",
        "country": "USA",
        "lon": "-122.436034",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.623159"
      },
      {
        "city": "Concord",
        "zip": "03301",
        "country": "USA",
        "lon": "-71.536101",
        "cz": "5A",
        "state_id": "NH",
        "file": "0-14745",
        "lat": "43.230314"
      },
      {
        "city": "Burlington",
        "zip": "05401",
        "country": "USA",
        "lon": "-73.151384",
        "cz": "6A",
        "state_id": "VT",
        "file": "0-14742",
        "lat": "44.507404"
      },
      {
        "city": "Apex",
        "zip": "27502",
        "country": "USA",
        "lon": "-78.834012",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13722",
        "lat": "35.748012"
      },
      {
        "city": "Midland",
        "zip": "48640",
        "country": "USA",
        "lon": "-84.33803",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94814",
        "lat": "43.626132"
      },
      {
        "city": "Altamonte Springs",
        "zip": "32701",
        "country": "USA",
        "lon": "-81.365039",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.666625"
      },
      {
        "city": "Hutchinson",
        "zip": "67501",
        "country": "USA",
        "lon": "-97.88569",
        "cz": "4A",
        "state_id": "KS",
        "file": "0-3928",
        "lat": "37.954312"
      },
      {
        "city": "Buffalo Grove",
        "zip": "60089",
        "country": "USA",
        "lon": "-87.960771",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.180411"
      },
      {
        "city": "Urbandale",
        "zip": "50322",
        "country": "USA",
        "lon": "-93.753628",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-14933",
        "lat": "41.630449"
      },
      {
        "city": "State College",
        "zip": "16801",
        "country": "USA",
        "lon": "-77.867822",
        "cz": "5A",
        "state_id": "PA",
        "file": "0-14778",
        "lat": "40.881935"
      },
      {
        "city": "Urbana",
        "zip": "61801",
        "country": "USA",
        "lon": "-88.182071",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-93822",
        "lat": "40.133657"
      },
      {
        "city": "Plainfield",
        "zip": "60544",
        "country": "USA",
        "lon": "-88.169698",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.632457"
      },
      {
        "city": "Manassas",
        "zip": "20108",
        "country": "USA",
        "lon": "-77.487162",
        "cz": "4A",
        "state_id": "VA",
        "file": "0-93738",
        "lat": "38.744685"
      },
      {
        "city": "Bartlett",
        "zip": "60103",
        "country": "USA",
        "lon": "-88.157756",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.033376"
      },
      {
        "city": "Kearny",
        "zip": "07032",
        "country": "USA",
        "lon": "-74.122937",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.75175"
      },
      {
        "city": "Findlay",
        "zip": "45839",
        "country": "USA",
        "lon": "-83.650714",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-94830",
        "lat": "40.993263"
      },
      {
        "city": "Rohnert Park",
        "zip": "94927",
        "country": "USA",
        "lon": "-122.989975",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "38.463088"
      },
      {
        "city": "Westfield",
        "zip": "01085",
        "country": "USA",
        "lon": "-72.501887",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14740",
        "lat": "42.14869"
      },
      {
        "city": "Linden",
        "zip": "07036",
        "country": "USA",
        "lon": "-74.250939",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-14734",
        "lat": "40.626953"
      },
      {
        "city": "Sumter",
        "zip": "29150",
        "country": "USA",
        "lon": "-80.354238",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13883",
        "lat": "33.913678"
      },
      {
        "city": "Woonsocket",
        "zip": "02895",
        "country": "USA",
        "lon": "-71.51939",
        "cz": "#N/A",
        "state_id": "RI",
        "file": "0-14765",
        "lat": "41.984598"
      },
      {
        "city": "Leominster",
        "zip": "01453",
        "country": "USA",
        "lon": "-71.837509",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-94746",
        "lat": "42.471316"
      },
      {
        "city": "Shelton",
        "zip": "06484",
        "country": "USA",
        "lon": "-73.137143",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-94702",
        "lat": "41.314347"
      },
      {
        "city": "Brea",
        "zip": "92821",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Covington",
        "zip": "41011",
        "country": "USA",
        "lon": "-84.542748",
        "cz": "4A",
        "state_id": "KY",
        "file": "0-93814",
        "lat": "39.05906"
      },
      {
        "city": "Rockwall",
        "zip": "75032",
        "country": "USA",
        "lon": "-96.409502",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.886"
      },
      {
        "city": "Meridian",
        "zip": "39301",
        "country": "USA",
        "lon": "-88.663489",
        "cz": "3A",
        "state_id": "MS",
        "file": "0-13865",
        "lat": "32.381605"
      },
      {
        "city": "Riverton",
        "zip": "84065",
        "country": "USA",
        "lon": "-112.00627",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.495129"
      },
      {
        "city": "Quincy",
        "zip": "62301",
        "country": "USA",
        "lon": "-91.200676",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-93822",
        "lat": "39.952413"
      },
      {
        "city": "Morgan Hill",
        "zip": "95037",
        "country": "USA",
        "lon": "-121.681557",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.130238"
      },
      {
        "city": "Warren",
        "zip": "44481",
        "country": "USA",
        "lon": "-80.867431",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14852",
        "lat": "41.248912"
      },
      {
        "city": "Edmonds",
        "zip": "98020",
        "country": "USA",
        "lon": "-122.372401",
        "cz": "4C",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.806068"
      },
      {
        "city": "Burleson",
        "zip": "76028",
        "country": "USA",
        "lon": "-97.264359",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.492683"
      },
      {
        "city": "Beverly",
        "zip": "01915",
        "country": "USA",
        "lon": "-70.853843",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.565145"
      },
      {
        "city": "Mankato",
        "zip": "56001",
        "country": "USA",
        "lon": "-94.003112",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.061451"
      },
      {
        "city": "Hagerstown",
        "zip": "21740",
        "country": "USA",
        "lon": "-77.700213",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93738",
        "lat": "39.58887"
      },
      {
        "city": "Prescott",
        "zip": "86301",
        "country": "USA",
        "lon": "-112.567165",
        "cz": "4B",
        "state_id": "AZ",
        "file": "0-23184",
        "lat": "34.620826"
      },
      {
        "city": "Campbell",
        "zip": "95008",
        "country": "USA",
        "lon": "-121.705327",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.189396"
      },
      {
        "city": "Cedar Falls",
        "zip": "50613",
        "country": "USA",
        "lon": "-92.43585",
        "cz": "5A",
        "state_id": "IA",
        "file": "0-94910",
        "lat": "42.517091"
      },
      {
        "city": "Beaumont",
        "zip": "92223",
        "country": "USA",
        "lon": "-116.954753",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "33.92703"
      },
      {
        "city": "La Puente",
        "zip": "91744",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Crystal Lake",
        "zip": "60012",
        "country": "USA",
        "lon": "-88.305292",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.232096"
      },
      {
        "city": "Fitchburg",
        "zip": "01420",
        "country": "USA",
        "lon": "-71.816767",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-94746",
        "lat": "42.583689"
      },
      {
        "city": "Carol Stream",
        "zip": "60116",
        "country": "USA",
        "lon": "-88.088716",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.839679"
      },
      {
        "city": "Hickory",
        "zip": "28601",
        "country": "USA",
        "lon": "-81.308224",
        "cz": "3A",
        "state_id": "NC",
        "file": "0-13881",
        "lat": "35.758221"
      },
      {
        "city": "Streamwood",
        "zip": "60107",
        "country": "USA",
        "lon": "-88.178475",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.019093"
      },
      {
        "city": "Norwich",
        "zip": "06360",
        "country": "USA",
        "lon": "-71.994757",
        "cz": "5A",
        "state_id": "CT",
        "file": "0-14765",
        "lat": "41.499398"
      },
      {
        "city": "Coppell",
        "zip": "75019",
        "country": "USA",
        "lon": "-96.777626",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.767268"
      },
      {
        "city": "San Gabriel",
        "zip": "91775",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Holyoke",
        "zip": "01040",
        "country": "USA",
        "lon": "-72.64207",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14740",
        "lat": "42.198291"
      },
      {
        "city": "Bentonville",
        "zip": "72712",
        "country": "USA",
        "lon": "-94.223419",
        "cz": "4A",
        "state_id": "AR",
        "file": "0-13964",
        "lat": "36.347107"
      },
      {
        "city": "Florence",
        "zip": "35630",
        "country": "USA",
        "lon": "-87.686915",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-3856",
        "lat": "34.869601"
      },
      {
        "city": "Brentwood",
        "zip": "37024",
        "country": "USA",
        "lon": "-86.907565",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13897",
        "lat": "35.874553"
      },
      {
        "city": "Bozeman",
        "zip": "59715",
        "country": "USA",
        "lon": "-111.168212",
        "cz": "6B",
        "state_id": "MT",
        "file": "0-24144",
        "lat": "45.809998"
      },
      {
        "city": "New Berlin",
        "zip": "53146",
        "country": "USA",
        "lon": "-88.177554",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "42.969924"
      },
      {
        "city": "Goose Creek",
        "zip": "29445",
        "country": "USA",
        "lon": "-80.010088",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13880",
        "lat": "33.057972"
      },
      {
        "city": "Huntsville",
        "zip": "77320",
        "country": "USA",
        "lon": "-95.597029",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-93987",
        "lat": "30.846986"
      },
      {
        "city": "Prescott Valley",
        "zip": "86312",
        "country": "USA",
        "lon": "-112.307777",
        "cz": "4B",
        "state_id": "AZ",
        "file": "0-23184",
        "lat": "34.668291"
      },
      {
        "city": "Romeoville",
        "zip": "60446",
        "country": "USA",
        "lon": "-88.069621",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.64038"
      },
      {
        "city": "Duncanville",
        "zip": "75116",
        "country": "USA",
        "lon": "-96.777626",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.767268"
      },
      {
        "city": "Atlantic City",
        "zip": "08401",
        "country": "USA",
        "lon": "-74.643014",
        "cz": "4A",
        "state_id": "NJ",
        "file": "0-93730",
        "lat": "39.486848"
      },
      {
        "city": "Clovis",
        "zip": "88101",
        "country": "USA",
        "lon": "-103.294978",
        "cz": "4B",
        "state_id": "NM",
        "file": "0-23048",
        "lat": "34.497241"
      },
      {
        "city": "The Colony",
        "zip": "75056",
        "country": "USA",
        "lon": "-96.901605",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.077136"
      },
      {
        "city": "Culver City",
        "zip": "90230",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Marlborough",
        "zip": "01752",
        "country": "USA",
        "lon": "-71.459405",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.446396"
      },
      {
        "city": "Hilton Head Island",
        "zip": "29925",
        "country": "USA",
        "lon": "-80.799698",
        "cz": "2A",
        "state_id": "SC",
        "file": "0-3822",
        "lat": "32.21319"
      },
      {
        "city": "Moorhead",
        "zip": "56560",
        "country": "USA",
        "lon": "-96.557389",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14914",
        "lat": "46.803546"
      },
      {
        "city": "Calexico",
        "zip": "92231",
        "country": "USA",
        "lon": "-115.518355",
        "cz": "2B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "32.946832"
      },
      {
        "city": "Bullhead City",
        "zip": "86429",
        "country": "USA",
        "lon": "-114.538599",
        "cz": "3B",
        "state_id": "AZ",
        "file": "0-23169",
        "lat": "35.171497"
      },
      {
        "city": "Germantown",
        "zip": "38138",
        "country": "USA",
        "lon": "-89.792638",
        "cz": "4A",
        "state_id": "TN",
        "file": "0-13893",
        "lat": "35.08753"
      },
      {
        "city": "La Quinta",
        "zip": "92253",
        "country": "USA",
        "lon": "-116.259176",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23188",
        "lat": "33.670978"
      },
      {
        "city": "Lancaster",
        "zip": "43130",
        "country": "USA",
        "lon": "-82.609282",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14821",
        "lat": "39.68965"
      },
      {
        "city": "Wausau",
        "zip": "54401",
        "country": "USA",
        "lon": "-89.794002",
        "cz": "6A",
        "state_id": "WI",
        "file": "0-14991",
        "lat": "44.961874"
      },
      {
        "city": "Sherman",
        "zip": "75090",
        "country": "USA",
        "lon": "-96.659726",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "33.647425"
      },
      {
        "city": "Ocoee",
        "zip": "34761",
        "country": "USA",
        "lon": "-81.530251",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.569438"
      },
      {
        "city": "Shakopee",
        "zip": "55379",
        "country": "USA",
        "lon": "-93.474144",
        "cz": "6A",
        "state_id": "MN",
        "file": "0-14922",
        "lat": "44.731113"
      },
      {
        "city": "Woburn",
        "zip": "01801",
        "country": "USA",
        "lon": "-71.157271",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.488595"
      },
      {
        "city": "Bremerton",
        "zip": "98310",
        "country": "USA",
        "lon": "-122.634712",
        "cz": "5C",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.606478"
      },
      {
        "city": "Rock Island",
        "zip": "61201",
        "country": "USA",
        "lon": "-90.604899",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-14923",
        "lat": "41.495083"
      },
      {
        "city": "Muskogee",
        "zip": "74401",
        "country": "USA",
        "lon": "-95.50025",
        "cz": "3A",
        "state_id": "OK",
        "file": "0-13968",
        "lat": "35.70582"
      },
      {
        "city": "Cape Girardeau",
        "zip": "63701",
        "country": "USA",
        "lon": "-89.611016",
        "cz": "4A",
        "state_id": "MO",
        "file": "0-13994",
        "lat": "37.341398"
      },
      {
        "city": "Annapolis",
        "zip": "21401",
        "country": "USA",
        "lon": "-76.610143",
        "cz": "4A",
        "state_id": "MD",
        "file": "0-93721",
        "lat": "38.967311"
      },
      {
        "city": "Ormond Beach",
        "zip": "32173",
        "country": "USA",
        "lon": "-81.172169",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12834",
        "lat": "29.022729"
      },
      {
        "city": "Stanton",
        "zip": "90680",
        "country": "USA",
        "lon": "-117.769442",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.640302"
      },
      {
        "city": "Puyallup",
        "zip": "98371",
        "country": "USA",
        "lon": "-122.317148",
        "cz": "5B",
        "state_id": "WA",
        "file": "0-24233",
        "lat": "47.212822"
      },
      {
        "city": "Pacifica",
        "zip": "94044",
        "country": "USA",
        "lon": "-122.480015",
        "cz": "3C",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.616774"
      },
      {
        "city": "Hurst",
        "zip": "76053",
        "country": "USA",
        "lon": "-97.227779",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.882663"
      },
      {
        "city": "Lima",
        "zip": "45801",
        "country": "USA",
        "lon": "-84.0471",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-94830",
        "lat": "40.772147"
      },
      {
        "city": "Marana",
        "zip": "85653",
        "country": "USA",
        "lon": "-111.159344",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23160",
        "lat": "32.442979"
      },
      {
        "city": "Carpentersville",
        "zip": "60110",
        "country": "USA",
        "lon": "-88.297238",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.125008"
      },
      {
        "city": "Oakley",
        "zip": "94561",
        "country": "USA",
        "lon": "-121.68972",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23232",
        "lat": "37.996516"
      },
      {
        "city": "Lancaster",
        "zip": "75134",
        "country": "USA",
        "lon": "-96.76442",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-3927",
        "lat": "32.621185"
      },
      {
        "city": "Montclair",
        "zip": "91763",
        "country": "USA",
        "lon": "-115.967051",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23161",
        "lat": "34.839964"
      },
      {
        "city": "Wheeling",
        "zip": "60090",
        "country": "USA",
        "lon": "-87.933819",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.124176"
      },
      {
        "city": "Brookfield",
        "zip": "53005",
        "country": "USA",
        "lon": "-88.105906",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-14839",
        "lat": "43.062707"
      },
      {
        "city": "Park Ridge",
        "zip": "60068",
        "country": "USA",
        "lon": "-87.843714",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "42.037228"
      },
      {
        "city": "Florence",
        "zip": "29501",
        "country": "USA",
        "lon": "-79.685361",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-13883",
        "lat": "34.049849"
      },
      {
        "city": "Roy",
        "zip": "84067",
        "country": "USA",
        "lon": "-112.052525",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "41.178743"
      },
      {
        "city": "Winter Garden",
        "zip": "34777",
        "country": "USA",
        "lon": "-81.605846",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-12842",
        "lat": "28.541579"
      },
      {
        "city": "Chelsea",
        "zip": "02150",
        "country": "USA",
        "lon": "-71.038894",
        "cz": "5A",
        "state_id": "MA",
        "file": "0-14739",
        "lat": "42.378197"
      },
      {
        "city": "Valley Stream",
        "zip": "11580",
        "country": "USA",
        "lon": "-73.697828",
        "cz": "4A",
        "state_id": "NY",
        "file": "0-94728",
        "lat": "40.676052"
      },
      {
        "city": "Spartanburg",
        "zip": "29301",
        "country": "USA",
        "lon": "-81.993403",
        "cz": "3A",
        "state_id": "SC",
        "file": "0-3870",
        "lat": "35.011309"
      },
      {
        "city": "Lake Oswego",
        "zip": "97034",
        "country": "USA",
        "lon": "-122.691388",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24229",
        "lat": "45.408374"
      },
      {
        "city": "Friendswood",
        "zip": "77546",
        "country": "USA",
        "lon": "-95.156545",
        "cz": "2A",
        "state_id": "TX",
        "file": "0-12960",
        "lat": "29.482185"
      },
      {
        "city": "Westerville",
        "zip": "43081",
        "country": "USA",
        "lon": "-82.896457",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14821",
        "lat": "40.10447"
      },
      {
        "city": "Phenix City",
        "zip": "36867",
        "country": "USA",
        "lon": "-85.203923",
        "cz": "3A",
        "state_id": "AL",
        "file": "0-93842",
        "lat": "32.375318"
      },
      {
        "city": "Grove City",
        "zip": "43123",
        "country": "USA",
        "lon": "-83.011389",
        "cz": "5A",
        "state_id": "OH",
        "file": "0-14821",
        "lat": "39.969036"
      },
      {
        "city": "Texarkana",
        "zip": "75501",
        "country": "USA",
        "lon": "-94.177354",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-13957",
        "lat": "33.411321"
      },
      {
        "city": "Addison",
        "zip": "60101",
        "country": "USA",
        "lon": "-88.022516",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.887579"
      },
      {
        "city": "Dover",
        "zip": "19901",
        "country": "USA",
        "lon": "-75.49549",
        "cz": "4A",
        "state_id": "DE",
        "file": "0-13781",
        "lat": "39.156376"
      },
      {
        "city": "Lincoln Park",
        "zip": "48146",
        "country": "USA",
        "lon": "-83.180375",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-94847",
        "lat": "42.242038"
      },
      {
        "city": "Calumet City",
        "zip": "60409",
        "country": "USA",
        "lon": "-87.68732",
        "cz": "5A",
        "state_id": "IL",
        "file": "0-94846",
        "lat": "41.811929"
      },
      {
        "city": "Muskegon",
        "zip": "49440",
        "country": "USA",
        "lon": "-86.232858",
        "cz": "5A",
        "state_id": "MI",
        "file": "0-14840",
        "lat": "43.233764"
      },
      {
        "city": "Martinez",
        "zip": "94553",
        "country": "USA",
        "lon": "-122.134976",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23234",
        "lat": "37.986421"
      },
      {
        "city": "Apache Junction",
        "zip": "85217",
        "country": "USA",
        "lon": "-111.478975",
        "cz": "2B",
        "state_id": "AZ",
        "file": "0-23183",
        "lat": "33.393398"
      },
      {
        "city": "Monrovia",
        "zip": "91016",
        "country": "USA",
        "lon": "-118.298662",
        "cz": "3B",
        "state_id": "CA",
        "file": "0-23129",
        "lat": "33.786594"
      },
      {
        "city": "Weslaco",
        "zip": "78596",
        "country": "USA",
        "lon": "-98.134649",
        "cz": "3A",
        "state_id": "TX",
        "file": "0-12919",
        "lat": "26.178744"
      },
      {
        "city": "Keizer",
        "zip": "97307",
        "country": "USA",
        "lon": "-122.457024",
        "cz": "4C",
        "state_id": "OR",
        "file": "0-24232",
        "lat": "44.984465"
      },
      {
        "city": "Spanish Fork",
        "zip": "84660",
        "country": "USA",
        "lon": "-111.649086",
        "cz": "5B",
        "state_id": "UT",
        "file": "0-24127",
        "lat": "40.069946"
      },
      {
        "city": "Beloit",
        "zip": "53511",
        "country": "USA",
        "lon": "-89.086045",
        "cz": "5A",
        "state_id": "WI",
        "file": "0-94822",
        "lat": "42.562039"
      },
      {
        "city": "Panama City",
        "zip": "32401",
        "country": "USA",
        "lon": "-85.663027",
        "cz": "2A",
        "state_id": "FL",
        "file": "0-93805",
        "lat": "30.1922"
      }
    ]
  },
  {
    "country": "Canada",
    "cities": [
      {
        "city": "Banff Cs",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.19",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.55"
      },
      {
        "city": "Bow Island",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.73",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.45"
      },
      {
        "city": "Bow Valley",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.07"
      },
      {
        "city": "Breton Plots",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.09",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.44"
      },
      {
        "city": "Brooks",
        "state": "Alberta",
        "cz": "6B",
        "state_id": "AB",
        "lat": "50.55",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.85"
      },
      {
        "city": "Calgary Int'L A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.02"
      },
      {
        "city": "Camrose",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.81"
      },
      {
        "city": "Cardston",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.2",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.29"
      },
      {
        "city": "Claresholm",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "50",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.64"
      },
      {
        "city": "Cold Lake A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "54.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-110.28"
      },
      {
        "city": "Cop Upper",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.22"
      },
      {
        "city": "Coronation (Aut)",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.45"
      },
      {
        "city": "Crowsnest",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "49.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.48"
      },
      {
        "city": "Drumheller East",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.67"
      },
      {
        "city": "Edmonton City Centre A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.52"
      },
      {
        "city": "Edmonton Int'L A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.58"
      },
      {
        "city": "Edmonton Namao A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.67",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.47"
      },
      {
        "city": "Edson A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.6",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-116.48"
      },
      {
        "city": "Elk Island Nat Park",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.68",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.87"
      },
      {
        "city": "Esther 1",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.67",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-110.21"
      },
      {
        "city": "Fort Chipewyan A",
        "state": "Alberta",
        "cz": "8",
        "state_id": "AB",
        "lat": "58.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.12"
      },
      {
        "city": "Fort Mcmurray A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "56.65",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.22"
      },
      {
        "city": "Garden River",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "58.71",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.87"
      },
      {
        "city": "Grande Prairie A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "55.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-118.88"
      },
      {
        "city": "High Level A",
        "state": "Alberta",
        "cz": "8",
        "state_id": "AB",
        "lat": "58.62",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.16"
      },
      {
        "city": "Highvale",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.47"
      },
      {
        "city": "Jasper",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.88",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-118.07"
      },
      {
        "city": "Jasper Warden",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.93",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-118.03"
      },
      {
        "city": "Lac La Biche (Aut)",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "54.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.02"
      },
      {
        "city": "Lacombe Cda 2",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.76"
      },
      {
        "city": "Lethbridge A",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.8"
      },
      {
        "city": "Lethbridge Cda",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.7",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.78"
      },
      {
        "city": "Lloydminster A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.31",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-110.07"
      },
      {
        "city": "Medicine Hat A",
        "state": "Alberta",
        "cz": "6B",
        "state_id": "AB",
        "lat": "50.02",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-110.72"
      },
      {
        "city": "Mildred Lake",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "57.04",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.56"
      },
      {
        "city": "Milk River",
        "state": "Alberta",
        "cz": "6B",
        "state_id": "AB",
        "lat": "49.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.05"
      },
      {
        "city": "Nakiska Ridgetop",
        "state": "Alberta",
        "cz": "8",
        "state_id": "AB",
        "lat": "50.94",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.19"
      },
      {
        "city": "Onefour Cda",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-110.47"
      },
      {
        "city": "Peace River A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "56.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.45"
      },
      {
        "city": "Pincher Creek (Aut)",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.52",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.98"
      },
      {
        "city": "Red Deer A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.89"
      },
      {
        "city": "Red Earth",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "56.55",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.28"
      },
      {
        "city": "Rocky Mtn House (Aut)",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.91"
      },
      {
        "city": "Slave Lake A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "55.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.78"
      },
      {
        "city": "Springbank A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.1",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.37"
      },
      {
        "city": "Stavely Aafc",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "50.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.88"
      },
      {
        "city": "Sundre A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.68"
      },
      {
        "city": "Three Hills",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "51.83",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.21"
      },
      {
        "city": "Vauxhall Cda Cs",
        "state": "Alberta",
        "cz": "6B",
        "state_id": "AB",
        "lat": "50.05",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.13"
      },
      {
        "city": "Vegreville",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "53.51",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-112.1"
      },
      {
        "city": "Wainwright Cfb Airfield 21",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "52.83",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.1"
      },
      {
        "city": "Waterton Park Gate",
        "state": "Alberta",
        "cz": "6A",
        "state_id": "AB",
        "lat": "49.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.81"
      },
      {
        "city": "Whitecourt A",
        "state": "Alberta",
        "cz": "7",
        "state_id": "AB",
        "lat": "54.14",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.79"
      },
      {
        "city": "Abbotsford A",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.36"
      },
      {
        "city": "Agassiz Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.77"
      },
      {
        "city": "Alert Bay",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.58",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-126.93"
      },
      {
        "city": "Amphitrite Point",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "48.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-125.54"
      },
      {
        "city": "Ballenas Island",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.35",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-124.16"
      },
      {
        "city": "Blue River Cs",
        "state": "British Columbia",
        "cz": "6A",
        "state_id": "BC",
        "lat": "52.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.29"
      },
      {
        "city": "Bonilla Island (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "53.5",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.63"
      },
      {
        "city": "Burns Lake Decker Lake",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "54.38",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-125.96"
      },
      {
        "city": "Cape Scott",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-128.43"
      },
      {
        "city": "Cape St James Cs",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "51.94",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-131.02"
      },
      {
        "city": "Cathedral Point (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "52.19",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-127.47"
      },
      {
        "city": "Clinton (Aut)",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "51.14",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.5"
      },
      {
        "city": "Comox A",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "49.72",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-124.9"
      },
      {
        "city": "Cranbrook A",
        "state": "British Columbia",
        "cz": "6A",
        "state_id": "BC",
        "lat": "49.61",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.78"
      },
      {
        "city": "Creston Campbell Scientific",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "49.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-116.5"
      },
      {
        "city": "Cumshewa Island",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "53.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-131.6"
      },
      {
        "city": "Dease Lake",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "58.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.01"
      },
      {
        "city": "Discovery Island",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "48.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.23"
      },
      {
        "city": "Entrance Island Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.8"
      },
      {
        "city": "Esquimalt Harbour",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "48.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.44"
      },
      {
        "city": "Estevan Point Cs",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "49.38",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-126.54"
      },
      {
        "city": "Fort Nelson A",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "58.84",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.6"
      },
      {
        "city": "Fort St John A",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "56.24",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-120.74"
      },
      {
        "city": "Grey Islet (Aut)",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "54.58",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.7"
      },
      {
        "city": "Herbert Island (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.94",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-127.64"
      },
      {
        "city": "Holland Rock",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "54.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.36"
      },
      {
        "city": "Hope",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "49.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.48"
      },
      {
        "city": "Howe Sound - Pam Rocks",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.49",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.3"
      },
      {
        "city": "Kamloops A",
        "state": "British Columbia",
        "cz": "5B",
        "state_id": "BC",
        "lat": "50.7",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-120.44"
      },
      {
        "city": "Kelowna A",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "49.96",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.38"
      },
      {
        "city": "Kindakun Rocks (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "53.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-132.77"
      },
      {
        "city": "Langara",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "54.26",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-133.06"
      },
      {
        "city": "Lillooet",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "50.68",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.93"
      },
      {
        "city": "Lucy Island Lightstation",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "54.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.61"
      },
      {
        "city": "Lytton",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.58"
      },
      {
        "city": "Mackenzie A",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "55.31",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.14"
      },
      {
        "city": "Malahat",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "48.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.53"
      },
      {
        "city": "Nakusp Cs",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "50.27",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.81"
      },
      {
        "city": "Nelson Cs",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "49.49",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.31"
      },
      {
        "city": "Osoyoos Cs",
        "state": "British Columbia",
        "cz": "5B",
        "state_id": "BC",
        "lat": "49.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.44"
      },
      {
        "city": "Pemberton Airport Cs",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.74"
      },
      {
        "city": "Penticton A",
        "state": "British Columbia",
        "cz": "5B",
        "state_id": "BC",
        "lat": "49.46",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.6"
      },
      {
        "city": "Pitt Meadows Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.21",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.69"
      },
      {
        "city": "Point Atkinson",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.26"
      },
      {
        "city": "Port Alberni (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "49.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-124.93"
      },
      {
        "city": "Port Hardy A",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.68",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-127.37"
      },
      {
        "city": "Prince George A",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "53.89",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.68"
      },
      {
        "city": "Prince Rupert A",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "54.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.44"
      },
      {
        "city": "Princeton Cs",
        "state": "British Columbia",
        "cz": "6A",
        "state_id": "BC",
        "lat": "49.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-120.51"
      },
      {
        "city": "Puntzi Mountain (Aut)",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "52.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-124.14"
      },
      {
        "city": "Quesnel A",
        "state": "British Columbia",
        "cz": "6A",
        "state_id": "BC",
        "lat": "53.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.51"
      },
      {
        "city": "Revelstoke A",
        "state": "British Columbia",
        "cz": "6A",
        "state_id": "BC",
        "lat": "50.96",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-118.18"
      },
      {
        "city": "Rose Spit (Aut)",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "54.16",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-131.66"
      },
      {
        "city": "Salmon Arm Cs",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "50.7",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.29"
      },
      {
        "city": "Sandheads Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.3"
      },
      {
        "city": "Sandspit A",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "53.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-131.81"
      },
      {
        "city": "Sartine Island (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-128.91"
      },
      {
        "city": "Sheringham Point",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "48.38",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.92"
      },
      {
        "city": "Sisters Island",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.49",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-124.43"
      },
      {
        "city": "Smithers A",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "54.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-127.18"
      },
      {
        "city": "Solander Island (Aut)",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "50.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-127.94"
      },
      {
        "city": "Sparwood Cs",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "49.75",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.89"
      },
      {
        "city": "Squamish",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "49.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.16"
      },
      {
        "city": "Summerland Cs",
        "state": "British Columbia",
        "cz": "5B",
        "state_id": "BC",
        "lat": "49.56",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.64"
      },
      {
        "city": "Terrace A",
        "state": "British Columbia",
        "cz": "6A",
        "state_id": "BC",
        "lat": "54.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-128.58"
      },
      {
        "city": "Vancouver Harbour Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.12"
      },
      {
        "city": "Vancouver Int'L A",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.2",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.18"
      },
      {
        "city": "Vernon Cs",
        "state": "British Columbia",
        "cz": "5A",
        "state_id": "BC",
        "lat": "50.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.19"
      },
      {
        "city": "Victoria Gonzales Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "48.41",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.33"
      },
      {
        "city": "Victoria Hartland Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "48.53",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.46"
      },
      {
        "city": "Victoria Int'L A",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "48.65",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.43"
      },
      {
        "city": "Victoria Marine",
        "state": "British Columbia",
        "cz": "5C",
        "state_id": "BC",
        "lat": "48.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.75"
      },
      {
        "city": "Victoria University Cs",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "48.46",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.3"
      },
      {
        "city": "West Vancouver Aut",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.35",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.19"
      },
      {
        "city": "White Rock Campbell Scientifi",
        "state": "British Columbia",
        "cz": "4C",
        "state_id": "BC",
        "lat": "49.02",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.78"
      },
      {
        "city": "Williams Lake A",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "52.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.05"
      },
      {
        "city": "Yoho Park",
        "state": "British Columbia",
        "cz": "7",
        "state_id": "BC",
        "lat": "51.44",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-116.34"
      },
      {
        "city": "Berens River Cs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "52.36",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.02"
      },
      {
        "city": "Brandon A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.91",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-99.95"
      },
      {
        "city": "Carberry Cs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.91",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-99.36"
      },
      {
        "city": "Carman U Of M Cs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.5",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-98.03"
      },
      {
        "city": "Churchill A",
        "state": "Manitoba",
        "cz": "8",
        "state_id": "MB",
        "lat": "58.74",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-94.06"
      },
      {
        "city": "Dauphin A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "51.1",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-100.05"
      },
      {
        "city": "Delta Marsh Cs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-98.38"
      },
      {
        "city": "Emerson Aut",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.24"
      },
      {
        "city": "Fisher Branch (Aut)",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "51.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.55"
      },
      {
        "city": "George Island (Aut)",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "52.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.62"
      },
      {
        "city": "Gillam A",
        "state": "Manitoba",
        "cz": "8",
        "state_id": "MB",
        "lat": "56.36",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-94.71"
      },
      {
        "city": "Gimli Industrial Park",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.05"
      },
      {
        "city": "Grand Rapids (Aut)",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "53.19",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-99.27"
      },
      {
        "city": "Gretna (Aut)",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.56"
      },
      {
        "city": "Hunters Point Marine",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "53.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-100.93"
      },
      {
        "city": "Island Lake A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "53.85",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-94.65"
      },
      {
        "city": "Lynn Lake A",
        "state": "Manitoba",
        "cz": "8",
        "state_id": "MB",
        "lat": "56.86",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-101.08"
      },
      {
        "city": "Melita",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-100.99"
      },
      {
        "city": "Norway House A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "53.95",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.85"
      },
      {
        "city": "Oakpoint Marine",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.5",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-98.04"
      },
      {
        "city": "Pilot Mound (Aut)",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.19",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-98.9"
      },
      {
        "city": "Pinawa",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-96.06"
      },
      {
        "city": "Portage Southport A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.9",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-98.27"
      },
      {
        "city": "Roblin",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "51.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-101.36"
      },
      {
        "city": "Shoal Lake Cs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-100.6"
      },
      {
        "city": "Sprague",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.02",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-95.6"
      },
      {
        "city": "Swan River Rcs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "52.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-101.23"
      },
      {
        "city": "The Pas A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "53.97",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-101.1"
      },
      {
        "city": "Thompson A",
        "state": "Manitoba",
        "cz": "8",
        "state_id": "MB",
        "lat": "55.8",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.86"
      },
      {
        "city": "Victoria Beach (Aut)",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.7",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-96.57"
      },
      {
        "city": "Wasagaming",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.66",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-99.94"
      },
      {
        "city": "Wilson Creek Weir Cs",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "50.71",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-99.53"
      },
      {
        "city": "Winnipeg Richardson Int'L A",
        "state": "Manitoba",
        "cz": "7",
        "state_id": "MB",
        "lat": "49.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.23"
      },
      {
        "city": "Bas Caraquet",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "47.8",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.83"
      },
      {
        "city": "Charlo A",
        "state": "New Brunswick",
        "cz": "7",
        "state_id": "NB",
        "lat": "47.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.33"
      },
      {
        "city": "Fredericton A",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "45.87",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.53"
      },
      {
        "city": "Miramichi A",
        "state": "New Brunswick",
        "cz": "7",
        "state_id": "NB",
        "lat": "47.01",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.47"
      },
      {
        "city": "Miramichi Rcs",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "47.01",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.46"
      },
      {
        "city": "Miscou Island (Aut)",
        "state": "New Brunswick",
        "cz": "7",
        "state_id": "NB",
        "lat": "48.01",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.49"
      },
      {
        "city": "Moncton A",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "46.1",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.69"
      },
      {
        "city": "Point Escuminac (Aut)",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "47.07",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.8"
      },
      {
        "city": "Point Lepreau Cs",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "45.07",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.45"
      },
      {
        "city": "Saint John A",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "45.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.89"
      },
      {
        "city": "St Leonard Cs",
        "state": "New Brunswick",
        "cz": "7",
        "state_id": "NB",
        "lat": "47.16",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-67.83"
      },
      {
        "city": "St Stephen (Aut)",
        "state": "New Brunswick",
        "cz": "6A",
        "state_id": "NB",
        "lat": "45.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-67.25"
      },
      {
        "city": "Argentia (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "47.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-53.99"
      },
      {
        "city": "Badger (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "48.97",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-56.07"
      },
      {
        "city": "Bonavista",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "48.67",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-53.11"
      },
      {
        "city": "Burgeo 2",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "47.62",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.62"
      },
      {
        "city": "Cape Kakkiviak",
        "state": "Newfoundland And Labrador",
        "cz": "8",
        "state_id": "NL",
        "lat": "59.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.16"
      },
      {
        "city": "Cape Race (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "46.66",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-53.08"
      },
      {
        "city": "Cartwright",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "53.71",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.04"
      },
      {
        "city": "Churchill Falls",
        "state": "Newfoundland And Labrador",
        "cz": "8",
        "state_id": "NL",
        "lat": "53.56",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.09"
      },
      {
        "city": "Comfort Cove",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "49.27",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-54.88"
      },
      {
        "city": "Corner Brook",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "48.93",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.92"
      },
      {
        "city": "Daniels Harbour",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "50.24",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.58"
      },
      {
        "city": "Deer Lake A",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "49.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.4"
      },
      {
        "city": "Englee (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "50.72",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-56.11"
      },
      {
        "city": "Ferolle Point (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "51.02",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.1"
      },
      {
        "city": "Gander Int'L A",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "48.95",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-54.58"
      },
      {
        "city": "Goose A",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "53.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-60.42"
      },
      {
        "city": "Grates Cove",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "48.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-52.94"
      },
      {
        "city": "Hopedale (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "8",
        "state_id": "NL",
        "lat": "55.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-60.22"
      },
      {
        "city": "La Scie",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "49.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-55.67"
      },
      {
        "city": "Marticot Island",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "47.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-54.59"
      },
      {
        "city": "Pools Island",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "49.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-53.58"
      },
      {
        "city": "Port Aux Basques",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "47.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-59.15"
      },
      {
        "city": "Saglek",
        "state": "Newfoundland And Labrador",
        "cz": "8",
        "state_id": "NL",
        "lat": "58.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-62.59"
      },
      {
        "city": "Sagona Island",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "47.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-55.79"
      },
      {
        "city": "St Anthony",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "51.38",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-56.1"
      },
      {
        "city": "St John'S A",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "47.62",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-52.74"
      },
      {
        "city": "St Lawrence",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "46.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-55.38"
      },
      {
        "city": "Stephenville A",
        "state": "Newfoundland And Labrador",
        "cz": "6A",
        "state_id": "NL",
        "lat": "48.53",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-58.55"
      },
      {
        "city": "Twillingate (Aut)",
        "state": "Newfoundland And Labrador",
        "cz": "7",
        "state_id": "NL",
        "lat": "49.68",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-54.8"
      },
      {
        "city": "Wabush Lake A",
        "state": "Newfoundland And Labrador",
        "cz": "8",
        "state_id": "NL",
        "lat": "52.93",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.87"
      },
      {
        "city": "Amherst (Aut)",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "45.85",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.27"
      },
      {
        "city": "Baccaro Point",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "43.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.47"
      },
      {
        "city": "Beaver Island (Aut)",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "44.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-62.33"
      },
      {
        "city": "Brier Island",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "44.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.35"
      },
      {
        "city": "Caribou Point (Aut)",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "45.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-62.68"
      },
      {
        "city": "Grand Etang",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "46.55",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-61.05"
      },
      {
        "city": "Greenwood A",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "44.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.92"
      },
      {
        "city": "Halifax Stanfield Int'L A",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "44.88",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.52"
      },
      {
        "city": "Hart Island (Aut)",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "45.35",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-60.98"
      },
      {
        "city": "Kejimkujik 1",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "44.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.2"
      },
      {
        "city": "Sable Island",
        "state": "Nova Scotia",
        "cz": "5A",
        "state_id": "NS",
        "lat": "43.93",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-60.01"
      },
      {
        "city": "Shearwater A",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "44.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.5"
      },
      {
        "city": "Shearwater Jetty",
        "state": "Nova Scotia",
        "cz": "5A",
        "state_id": "NS",
        "lat": "44.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.52"
      },
      {
        "city": "St Paul Island (Aut)",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "47.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-60.14"
      },
      {
        "city": "Sydney A",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "46.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-60.05"
      },
      {
        "city": "Western Head",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "43.99",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.66"
      },
      {
        "city": "Yarmouth A",
        "state": "Nova Scotia",
        "cz": "6A",
        "state_id": "NS",
        "lat": "43.83",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.09"
      },
      {
        "city": "Aulavik National Park",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "74.14",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.99"
      },
      {
        "city": "Cape Parry A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "70.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-124.72"
      },
      {
        "city": "Colville Lake",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "67.04",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-126.08"
      },
      {
        "city": "Fort Good Hope Cs",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "66.24",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-128.64"
      },
      {
        "city": "Fort Liard",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "60.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.47"
      },
      {
        "city": "Fort Providence",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "61.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.6"
      },
      {
        "city": "Fort Reliance",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "62.72",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-109.17"
      },
      {
        "city": "Fort Simpson A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "61.76",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.24"
      },
      {
        "city": "Fort Smith A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "60.02",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-111.96"
      },
      {
        "city": "Hanbury River                          ",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "63.6",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.13"
      },
      {
        "city": "Hay River A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "60.84",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.78"
      },
      {
        "city": "Inner Whalebacks",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "61.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.73"
      },
      {
        "city": "Inuvik A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "68.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-133.48"
      },
      {
        "city": "Lac La Martre",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "63.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.25"
      },
      {
        "city": "Lindburg Landing",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "61.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-122.85"
      },
      {
        "city": "Little Chicago",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "67.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.23"
      },
      {
        "city": "Liverpool Bay",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "69.6",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-130.91"
      },
      {
        "city": "Mould Bay A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "76.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.33"
      },
      {
        "city": "Nicholson Peninsula",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "69.93",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-128.97"
      },
      {
        "city": "Norman Wells A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "65.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-126.8"
      },
      {
        "city": "Pelly Island",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "69.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-135.44"
      },
      {
        "city": "Rae Lakes",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "64.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-117.33"
      },
      {
        "city": "Sachs Harbour Climate",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "71.99",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-125.25"
      },
      {
        "city": "Trout Lake",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "60.44",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-121.24"
      },
      {
        "city": "Tuktoyaktuk",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "69.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-133.02"
      },
      {
        "city": "Yellowknife A",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "62.46",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-114.44"
      },
      {
        "city": "Yohin",
        "state": "Northwest Territories",
        "cz": "8",
        "state_id": "NT",
        "lat": "61.24",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-123.74"
      },
      {
        "city": "Alert",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "82.52",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-62.28"
      },
      {
        "city": "Baker Lake A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "64.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-96.08"
      },
      {
        "city": "Brevoort Island",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "63.34",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.15"
      },
      {
        "city": "Byron Bay A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.75",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-109.07"
      },
      {
        "city": "Cambridge Bay A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "69.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.14"
      },
      {
        "city": "Cape Dorset A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "64.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-76.53"
      },
      {
        "city": "Cape Dyer",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "66.65",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-61.38"
      },
      {
        "city": "Cape Hooper",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.82"
      },
      {
        "city": "Cape Mercy",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "64.96",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.58"
      },
      {
        "city": "Cape Peel West",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "69.04",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.82"
      },
      {
        "city": "Clinton Point",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "69.58",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-120.8"
      },
      {
        "city": "Clyde A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "70.49",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.52"
      },
      {
        "city": "Coral Harbour A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "64.19",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-83.36"
      },
      {
        "city": "Croker River",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "69.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-119.22"
      },
      {
        "city": "Dewar Lakes",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.65",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.17"
      },
      {
        "city": "Ennadai Lake (Aut)",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "61.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-100.88"
      },
      {
        "city": "Eureka",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "79.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-85.93"
      },
      {
        "city": "Fox Five",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "67.54",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.79"
      },
      {
        "city": "Gladman Point A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.67",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-97.8"
      },
      {
        "city": "Hall Beach A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.24"
      },
      {
        "city": "Hat Island",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-100.09"
      },
      {
        "city": "Iqaluit A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "63.75",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.55"
      },
      {
        "city": "Jenny Lind Island A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.65",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-101.73"
      },
      {
        "city": "Kugluktuk A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "67.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-115.14"
      },
      {
        "city": "Lady Franklin Point A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.5",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-113.22"
      },
      {
        "city": "Longstaff Bluff",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.9",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-75.14"
      },
      {
        "city": "Mackar Inlet",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-85.67"
      },
      {
        "city": "Pangnirtung",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "66.15",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.72"
      },
      {
        "city": "Pelly Bay",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-89.72"
      },
      {
        "city": "Pond Inlet A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "72.68",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.98"
      },
      {
        "city": "Rankin Inlet A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "62.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-92.12"
      },
      {
        "city": "Resolute Cars",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "74.72",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-94.99"
      },
      {
        "city": "Resolution Island",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "61.6",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.63"
      },
      {
        "city": "Robertson Lake (Aut)",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "65.1",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-102.43"
      },
      {
        "city": "Shepherd Bay A",
        "state": "Nunavut",
        "cz": "8",
        "state_id": "NU",
        "lat": "68.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-93.43"
      },
      {
        "city": "Armstrong (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "50.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-88.91"
      },
      {
        "city": "Atikokan (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "48.76",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-91.63"
      },
      {
        "city": "Bancroft Auto",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "45.07",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.88"
      },
      {
        "city": "Beausoleil",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "44.85",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.87"
      },
      {
        "city": "Belle River",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "42.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-82.7"
      },
      {
        "city": "Big Trout Lake",
        "state": "Ontario",
        "cz": "8",
        "state_id": "ON",
        "lat": "53.83",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-89.87"
      },
      {
        "city": "Big Trout Lake Readac",
        "state": "Ontario",
        "cz": "8",
        "state_id": "ON",
        "lat": "53.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-89.9"
      },
      {
        "city": "Burlington Piers (Aut)",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "43.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.8"
      },
      {
        "city": "Cobourg (Aut)",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "43.95",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-78.17"
      },
      {
        "city": "Collingwood",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "44.5",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-80.22"
      },
      {
        "city": "Cove Island (Aut)",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "45.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.73"
      },
      {
        "city": "Earlton A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "47.7",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.85"
      },
      {
        "city": "Erieau (Aut)",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "42.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.9"
      },
      {
        "city": "Geraldton A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "49.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-86.93"
      },
      {
        "city": "Goderich",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "43.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.72"
      },
      {
        "city": "Gore Bay A",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "45.88",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-82.57"
      },
      {
        "city": "Great Duck Island (Aut)",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "45.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-82.95"
      },
      {
        "city": "Grenadier Island",
        "state": "Ontario",
        "cz": "6A  ",
        "state_id": "ON",
        "lat": "44.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-75.85"
      },
      {
        "city": "Kapuskasing A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "49.41",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-82.47"
      },
      {
        "city": "Kenora A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "49.79",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-94.37"
      },
      {
        "city": "Killarney (Aut)",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "45.97",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.48"
      },
      {
        "city": "Lagoon City",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "44.55",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.22"
      },
      {
        "city": "Lansdowne House (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "52.2",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-87.94"
      },
      {
        "city": "London Int'L Airport",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "43.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.15"
      },
      {
        "city": "Long Point (Aut)",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "42.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-80.05"
      },
      {
        "city": "Moosonee A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "51.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-80.61"
      },
      {
        "city": "Mount Forest (Aut)",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "43.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-80.75"
      },
      {
        "city": "Nagagami (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "49.75",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-84.16"
      },
      {
        "city": "North Bay A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "46.36",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.42"
      },
      {
        "city": "Ottawa Macdonald-Cartier Int'",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "45.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-75.67"
      },
      {
        "city": "Peawanuck (Aut)",
        "state": "Ontario",
        "cz": "8",
        "state_id": "ON",
        "lat": "54.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-85.43"
      },
      {
        "city": "Petawawa A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "45.95",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.32"
      },
      {
        "city": "Peterborough A",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "44.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-78.37"
      },
      {
        "city": "Pickle Lake (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "51.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-90.22"
      },
      {
        "city": "Point Petre (Aut)",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "43.83",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.15"
      },
      {
        "city": "Port Colborne (Aut)",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "42.87",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.25"
      },
      {
        "city": "Port Weller (Aut)",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "43.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.22"
      },
      {
        "city": "Pukaskwa (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "48.59",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-86.29"
      },
      {
        "city": "Red Lake A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "51.07",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-93.79"
      },
      {
        "city": "Royal Island (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "49.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-94.76"
      },
      {
        "city": "Sault Ste Marie A",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "46.48",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-84.51"
      },
      {
        "city": "Sioux Lookout A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "50.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-91.9"
      },
      {
        "city": "Sudbury A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "46.62",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-80.8"
      },
      {
        "city": "Thunder Bay A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "48.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-89.33"
      },
      {
        "city": "Timmins Victor Power A",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "48.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.38"
      },
      {
        "city": "Toronto Buttonville A",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "43.86",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.37"
      },
      {
        "city": "Toronto Island A",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "43.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.4"
      },
      {
        "city": "Toronto Lester B. Pearson Int",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "43.68",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.63"
      },
      {
        "city": "Trenton A",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "44.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.53"
      },
      {
        "city": "Upsala (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "49.03",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-90.47"
      },
      {
        "city": "Wawa (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "47.97",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-84.78"
      },
      {
        "city": "Welcome Island (Aut)",
        "state": "Ontario",
        "cz": "7",
        "state_id": "ON",
        "lat": "48.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-89.12"
      },
      {
        "city": "Wiarton A",
        "state": "Ontario",
        "cz": "6A",
        "state_id": "ON",
        "lat": "44.75",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-81.11"
      },
      {
        "city": "Windsor A",
        "state": "Ontario",
        "cz": "5A",
        "state_id": "ON",
        "lat": "42.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-82.96"
      },
      {
        "city": "Charlottetown A",
        "state": "Prince Edward Island",
        "cz": "6A",
        "state_id": "PE",
        "lat": "46.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.13"
      },
      {
        "city": "East Point (Aut)",
        "state": "Prince Edward Island",
        "cz": "6A",
        "state_id": "PE",
        "lat": "46.46",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-61.99"
      },
      {
        "city": "North Cape",
        "state": "Prince Edward Island",
        "cz": "6A",
        "state_id": "PE",
        "lat": "47.06",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64"
      },
      {
        "city": "Summerside",
        "state": "Prince Edward Island",
        "cz": "6A",
        "state_id": "PE",
        "lat": "46.44",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.84"
      },
      {
        "city": "Amqui",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-67.43"
      },
      {
        "city": "Bagotville A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71"
      },
      {
        "city": "Baie-Comeau",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.26",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.15"
      },
      {
        "city": "Baie-Comeau A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.2"
      },
      {
        "city": "Barrage Temiscamingue",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "46.71",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.1"
      },
      {
        "city": "Beauceville",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.2",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.78"
      },
      {
        "city": "Blanc-Sablon A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "51.45",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-57.18"
      },
      {
        "city": "Bonnard 1",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.73",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.01"
      },
      {
        "city": "Cap-Chat",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.11",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.65"
      },
      {
        "city": "Cap-D'Espoir",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.32"
      },
      {
        "city": "Cap-Madeleine",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.32"
      },
      {
        "city": "Cap-Rouge",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.54"
      },
      {
        "city": "Cap-Tourmente",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "47.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.78"
      },
      {
        "city": "Charlevoix (Mrc)",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "47.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.64"
      },
      {
        "city": "Chevery",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.46",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-59.64"
      },
      {
        "city": "Chibougamau Chapais A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-74.53"
      },
      {
        "city": "Chute-Des-Passes",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.84",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.17"
      },
      {
        "city": "Deschambault",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.69",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.97"
      },
      {
        "city": "Frelighsburg",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.05",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.86"
      },
      {
        "city": "Gaspe A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.48"
      },
      {
        "city": "Havre-Saint-Pierre A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.6"
      },
      {
        "city": "Heath Point",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.09",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-61.7"
      },
      {
        "city": "Ile Aux Perroquets",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.21"
      },
      {
        "city": "Ile Rouge",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.07",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-69.56"
      },
      {
        "city": "Iles De La Madeleine",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "47.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-61.77"
      },
      {
        "city": "Inukjuak A",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "58.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-78.08"
      },
      {
        "city": "Jonquiere",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.15"
      },
      {
        "city": "Kuujjuaq A",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "58.1",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.42"
      },
      {
        "city": "Kuujjuarapik A",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "55.28",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.75"
      },
      {
        "city": "La Baie",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.92"
      },
      {
        "city": "La Grande Iv A",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "53.76",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.68"
      },
      {
        "city": "La Grande Riviere A",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "53.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.7"
      },
      {
        "city": "La Pocatiere",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "47.36",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.03"
      },
      {
        "city": "La Tuque",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "47.41",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.79"
      },
      {
        "city": "Lac Benoit",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "51.53",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.11"
      },
      {
        "city": "Lac Eon",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "51.87",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-63.28"
      },
      {
        "city": "Lac Saint-Pierre",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "46.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.92"
      },
      {
        "city": "L'Acadie",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.29",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.35"
      },
      {
        "city": "L'Assomption",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.81",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.43"
      },
      {
        "city": "Lennoxville",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.82"
      },
      {
        "city": "L'Etape",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "47.56",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.23"
      },
      {
        "city": "Longue-Pointe-De-Mingan",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.27",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.23"
      },
      {
        "city": "Maniwaki Airport",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.27",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-75.99"
      },
      {
        "city": "Maniwaki Ua",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-76.01"
      },
      {
        "city": "Manouane Est",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "50.66",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.53"
      },
      {
        "city": "Matagami A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.8"
      },
      {
        "city": "Mctavish",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.5",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.58"
      },
      {
        "city": "Mistook",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.6",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.72"
      },
      {
        "city": "Mont-Joli A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.6",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.22"
      },
      {
        "city": "Mont-Orford",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "45.31",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.24"
      },
      {
        "city": "Montreal/Mirabel Int'L A",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.67",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-74.03"
      },
      {
        "city": "Montreal/Pierre Elliott Trude",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.47",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.75"
      },
      {
        "city": "Montreal/St-Hubert A",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.52",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.42"
      },
      {
        "city": "Montreal-Est",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.63",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.55"
      },
      {
        "city": "Natashquan A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-61.82"
      },
      {
        "city": "New Carlisle 1",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.01",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-65.33"
      },
      {
        "city": "Nicolet",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "46.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.66"
      },
      {
        "city": "Normandin",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.84",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.55"
      },
      {
        "city": "Onatchiway",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.89",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.03"
      },
      {
        "city": "Parent",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "47.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-74.62"
      },
      {
        "city": "Pointe Claveau",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.26",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-70.11"
      },
      {
        "city": "Pointe Noire Cs",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.16",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.43"
      },
      {
        "city": "Pointe-Au-Pere (Inrs)",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.51",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-68.47"
      },
      {
        "city": "Pointe-Des-Monts",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.32",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-67.38"
      },
      {
        "city": "Port-Menier",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "49.84",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-64.29"
      },
      {
        "city": "Quebec/Jean Lesage Intl A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.8",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.38"
      },
      {
        "city": "Riviere-Du-Loup",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "47.81",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-69.55"
      },
      {
        "city": "Roberval A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.52",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.27"
      },
      {
        "city": "Rouyn",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-79.03"
      },
      {
        "city": "Rouyn A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-78.83"
      },
      {
        "city": "Schefferville A",
        "state": "Quebec",
        "cz": "8",
        "state_id": "QC",
        "lat": "54.8",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.82"
      },
      {
        "city": "Sept-Iles A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "50.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-66.27"
      },
      {
        "city": "Sherbrooke A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "45.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.68"
      },
      {
        "city": "St-Anicet 1",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-74.29"
      },
      {
        "city": "Ste Agathe Des Monts",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.05",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-74.28"
      },
      {
        "city": "Ste-Anne-De-Bellevue 1",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.93"
      },
      {
        "city": "Ste-Clothilde",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.68"
      },
      {
        "city": "Ste-Foy (U. Laval)",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "46.78",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-71.29"
      },
      {
        "city": "St-Jovite",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "46.08",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-74.56"
      },
      {
        "city": "Trois-Rivieres",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "46.35",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-72.52"
      },
      {
        "city": "Val-D'Or A",
        "state": "Quebec",
        "cz": "7",
        "state_id": "QC",
        "lat": "48.06",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-77.79"
      },
      {
        "city": "Varennes",
        "state": "Quebec",
        "cz": "6A",
        "state_id": "QC",
        "lat": "45.72",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-73.38"
      },
      {
        "city": "Assiniboia Airport",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.73",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.93"
      },
      {
        "city": "Broadview",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-102.57"
      },
      {
        "city": "Collins Bay",
        "state": "Saskatchewan",
        "cz": "8",
        "state_id": "SK",
        "lat": "58.18",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-103.7"
      },
      {
        "city": "Coronach Spc",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.05",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.48"
      },
      {
        "city": "Cree Lake",
        "state": "Saskatchewan",
        "cz": "8",
        "state_id": "SK",
        "lat": "57.35",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.13"
      },
      {
        "city": "Cypress Hills Park",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.65",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-109.52"
      },
      {
        "city": "Eastend Cypress (Aut)",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-108.98"
      },
      {
        "city": "Elbow Cs",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-106.58"
      },
      {
        "city": "Estevan A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-102.97"
      },
      {
        "city": "Hudson Bay",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "52.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-102.32"
      },
      {
        "city": "Indian Head Cda",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.55",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-103.65"
      },
      {
        "city": "Key Lake A",
        "state": "Saskatchewan",
        "cz": "8",
        "state_id": "SK",
        "lat": "57.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.6"
      },
      {
        "city": "Kindersley A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.52",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-109.18"
      },
      {
        "city": "La Ronge A                                                           ",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "55.15",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.27"
      },
      {
        "city": "Last Mountain Cs",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.42",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.25"
      },
      {
        "city": "Leader Airport",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.9",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-109.5"
      },
      {
        "city": "Lucky Lake",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.95",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.15"
      },
      {
        "city": "Maple Creek",
        "state": "Saskatchewan",
        "cz": "6A",
        "state_id": "SK",
        "lat": "49.9",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-109.47"
      },
      {
        "city": "Meadow Lake A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "54.13",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-108.52"
      },
      {
        "city": "Melfort",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "52.82",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-104.6"
      },
      {
        "city": "Moose Jaw A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.55"
      },
      {
        "city": "Moose Jaw Cs",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.56"
      },
      {
        "city": "Nipawin A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "53.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-104"
      },
      {
        "city": "North Battleford A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "52.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-108.26"
      },
      {
        "city": "Outlook Pfra",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.48",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.05"
      },
      {
        "city": "Prince Albert A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "53.22",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.67"
      },
      {
        "city": "Regina A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.43",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-104.67"
      },
      {
        "city": "Rockglen (Aut)",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.98"
      },
      {
        "city": "Rosetown East",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.92"
      },
      {
        "city": "Saskatoon Diefenbaker Int'L A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "52.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-106.72"
      },
      {
        "city": "Saskatoon Kernen Farm",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "52.15",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-106.55"
      },
      {
        "city": "Scott Cda",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "52.36",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-108.83"
      },
      {
        "city": "Southend",
        "state": "Saskatchewan",
        "cz": "8",
        "state_id": "SK",
        "lat": "56.33",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-103.28"
      },
      {
        "city": "Spiritwood West",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "53.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.55"
      },
      {
        "city": "Stony Rapids A",
        "state": "Saskatchewan",
        "cz": "8",
        "state_id": "SK",
        "lat": "59.25",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.83"
      },
      {
        "city": "Swift Current A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.3",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.68"
      },
      {
        "city": "Swift Current Cda",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "50.27",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.73"
      },
      {
        "city": "Uranium City (Aut)",
        "state": "Saskatchewan",
        "cz": "8",
        "state_id": "SK",
        "lat": "59.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-108.48"
      },
      {
        "city": "Val Marie Southeast",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.07",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-107.58"
      },
      {
        "city": "Waskesiu Lake",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "53.92",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-106.07"
      },
      {
        "city": "Watrous East",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.67",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-105.4"
      },
      {
        "city": "Weyburn",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "49.7",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-103.8"
      },
      {
        "city": "Wynyard (Aut)",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-104.2"
      },
      {
        "city": "Yorkton A",
        "state": "Saskatchewan",
        "cz": "7",
        "state_id": "SK",
        "lat": "51.27",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-102.47"
      },
      {
        "city": "Burwash A",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "61.37",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-139.05"
      },
      {
        "city": "Faro (Aut)",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "62.23",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-133.35"
      },
      {
        "city": "Haines Junction",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "60.77",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-137.58"
      },
      {
        "city": "Herschel Island",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "69.57",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-138.91"
      },
      {
        "city": "Ivvavik Nat. Park",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "69.16",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-140.15"
      },
      {
        "city": "Komakuk Beach",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "69.61",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-140.2"
      },
      {
        "city": "Mayo A",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "63.62",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-135.87"
      },
      {
        "city": "Rock River",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "66.98",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-136.22"
      },
      {
        "city": "Shingle Point A",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "68.95",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-137.22"
      },
      {
        "city": "Teslin (Aut)",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "60.17",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-132.73"
      },
      {
        "city": "Watson Lake A",
        "state": "Yukon",
        "cz": "8",
        "state_id": "YT",
        "lat": "60.12",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-128.82"
      },
      {
        "city": "Whitehorse A",
        "state": "Yukon",
        "cz": "7",
        "state_id": "YT",
        "lat": "60.71",
        "country": "Canada",
        "country_id": "CAN",
        "lon": "-135.07"
      }
    ]
  },
  {
    "country": "Albania",
    "cities": [
      {
        "city": "Tirana",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.33",
        "country": "Albania",
        "country_id": "ALB",
        "lon": "19.78"
      }
    ]
  },
  {
    "country": "Algeria",
    "cities": [
      {
        "city": "Adrar",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "27.88",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "-0.18"
      },
      {
        "city": "Annaba",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.83",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "7.82"
      },
      {
        "city": "Batna",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.75",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "6.32"
      },
      {
        "city": "Bechar",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.5",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "-2.25"
      },
      {
        "city": "Bejaia-Aeroport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.72",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.07"
      },
      {
        "city": "Biskra",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "34.8",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.73"
      },
      {
        "city": "Bordj-Bou-Arreridj",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.07",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "4.77"
      },
      {
        "city": "Constantine",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.28",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "6.62"
      },
      {
        "city": "Dar-El-Beida",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.68",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "3.22"
      },
      {
        "city": "Djanet",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "24.27",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "9.47"
      },
      {
        "city": "El-Bayadh",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "33.67",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "1"
      },
      {
        "city": "El-Golea",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.57",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "2.87"
      },
      {
        "city": "El-Oued",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "33.5",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "6.78"
      },
      {
        "city": "Ghardaia",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "32.4",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "3.8"
      },
      {
        "city": "Hassi-Messaoud",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.67",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "6.15"
      },
      {
        "city": "Illizi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.5",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "8.42"
      },
      {
        "city": "In-Amenas",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "28.05",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "9.63"
      },
      {
        "city": "In-Salah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "27.23",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "2.5"
      },
      {
        "city": "Jijel-Achouat",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.8",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.88"
      },
      {
        "city": "Mascara-Matemore",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.6",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "0.3"
      },
      {
        "city": "Mecheria",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "33.58",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "-0.28"
      },
      {
        "city": "Oran-Senia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.63",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "-0.6"
      },
      {
        "city": "Ouargla",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.93",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.4"
      },
      {
        "city": "Setif",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.18",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.25"
      },
      {
        "city": "Skikda",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.88",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "6.9"
      },
      {
        "city": "Tamanrasset",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "22.8",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.43"
      },
      {
        "city": "Tamanrasset",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "22.8",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "5.45"
      },
      {
        "city": "Tebessa",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.42",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "8.12"
      },
      {
        "city": "Tiaret",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.35",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "1.47"
      },
      {
        "city": "Tindouf",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "27.7",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "-8.17"
      },
      {
        "city": "Tlemcen-Zenata",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.02",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "-1.47"
      },
      {
        "city": "Touggourt",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "33.12",
        "country": "Algeria",
        "country_id": "DZA",
        "lon": "6.13"
      }
    ]
  },
  {
    "country": "American Samoa",
    "cities": [
      {
        "city": "Pago Pago Wso Ap",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-14.33",
        "country": "American Samoa",
        "country_id": "ASM",
        "lon": "-170.71"
      }
    ]
  },
  {
    "country": "Antarctica",
    "cities": [
      {
        "city": "Base Arturo Prat",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-62.5",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-59.68"
      },
      {
        "city": "Base Esperanza",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-63.4",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-56.98"
      },
      {
        "city": "Base Jubany",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-62.23",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-58.63"
      },
      {
        "city": "Base Marambio",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-64.23",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-56.72"
      },
      {
        "city": "Base Orcadas",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-60.73",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-44.73"
      },
      {
        "city": "Base San Martin",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-68.12",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-67.13"
      },
      {
        "city": "Bellingshausen Aws",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-62.2",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-58.93"
      },
      {
        "city": "Bernardo O`Higgins",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-63.32",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-57.9"
      },
      {
        "city": "Butler Island",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-72.21",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-60.17"
      },
      {
        "city": "Casey",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-66.28",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "110.52"
      },
      {
        "city": "Davis",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-68.58",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "77.95"
      },
      {
        "city": "Dinamet-Uruguay",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-62.18",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-58.83"
      },
      {
        "city": "Dumont Durville",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-66.67",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "140.02"
      },
      {
        "city": "Frei Chi-Base",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-62.18",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-58.98"
      },
      {
        "city": "Great Wall",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-62.22",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-58.97"
      },
      {
        "city": "Halley",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-75.5",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-26.65"
      },
      {
        "city": "Manuela",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-74.95",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "163.69"
      },
      {
        "city": "Marble Point",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-77.43",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "163.75"
      },
      {
        "city": "Mawson",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-67.6",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "62.87"
      },
      {
        "city": "Mirnyj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-66.55",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "93.02"
      },
      {
        "city": "Molodeznaja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-67.67",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "45.85"
      },
      {
        "city": "Mount Siple",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-73.2",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-127.05"
      },
      {
        "city": "Neumayer",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-70.67",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-8.25"
      },
      {
        "city": "Novolazarevskaja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-70.77",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "11.83"
      },
      {
        "city": "Possession Island",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-71.89",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "171.21"
      },
      {
        "city": "Siple Dome",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-81.65",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "-148.77"
      },
      {
        "city": "Syowa",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "-69",
        "country": "Antarctica",
        "country_id": "ATA",
        "lon": "39.58"
      }
    ]
  },
  {
    "country": "Antigua and Barbuda",
    "cities": [
      {
        "city": "Vc Bird Intl Airpor",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.12",
        "country": "Antigua and Barbuda",
        "country_id": "ATG",
        "lon": "-61.78"
      }
    ]
  },
  {
    "country": "Argentina",
    "cities": [
      {
        "city": "Aeroparque Bs. As.",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.57",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-58.42"
      },
      {
        "city": "Bahia Blanca Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.73",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-62.17"
      },
      {
        "city": "Bariloche Aero",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "-41.15",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-71.17"
      },
      {
        "city": "Catamarca Aero.",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.6",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.77"
      },
      {
        "city": "Ceres Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-29.88",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-61.95"
      },
      {
        "city": "Comodoro Rivadavia",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-45.78",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-67.5"
      },
      {
        "city": "Concordia Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.3",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-58.02"
      },
      {
        "city": "Cordoba Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.32",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-64.22"
      },
      {
        "city": "Corrientes Aero.",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.45",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-58.77"
      },
      {
        "city": "Esquel Aero",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "-42.93",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-71.15"
      },
      {
        "city": "Ezeiza Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.82",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-58.53"
      },
      {
        "city": "Formosa Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.2",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-58.23"
      },
      {
        "city": "Gualeguaychu Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-58.62"
      },
      {
        "city": "Iguazu Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.73",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-54.47"
      },
      {
        "city": "Jujuy Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-24.38",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.08"
      },
      {
        "city": "Junin Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.55",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-60.92"
      },
      {
        "city": "La Rioja Aero.",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-29.38",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-66.82"
      },
      {
        "city": "Lago Argentino Arpt",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "-50.33",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-72.3"
      },
      {
        "city": "Malargue Aero",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "-35.5",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-69.58"
      },
      {
        "city": "Mar Del Plata Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.93",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-57.58"
      },
      {
        "city": "Marcos Juarez Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.7",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-62.15"
      },
      {
        "city": "Mendoza Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-32.83",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-68.78"
      },
      {
        "city": "Monte Caseros Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-30.27",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-57.65"
      },
      {
        "city": "Neuquen Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-38.95",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-68.13"
      },
      {
        "city": "Parana Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.78",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-60.48"
      },
      {
        "city": "Paso De Los Libres",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-29.68",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-57.15"
      },
      {
        "city": "Posadas Aero.",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.37",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-55.97"
      },
      {
        "city": "Presidencia Roque S",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.82",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-60.45"
      },
      {
        "city": "Reconquista Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-29.18",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-59.7"
      },
      {
        "city": "Resistencia Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.45",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-59.05"
      },
      {
        "city": "Rio Cuarto Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.12",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-64.23"
      },
      {
        "city": "Rio Gallegos Aero",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "-51.62",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-69.28"
      },
      {
        "city": "Rio Grande B.A.",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-53.8",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-67.75"
      },
      {
        "city": "Rosario Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.92",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-60.78"
      },
      {
        "city": "Salta Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-24.85",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.48"
      },
      {
        "city": "San Antonio Oeste A",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-40.78",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.1"
      },
      {
        "city": "San Juan Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.4",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-68.42"
      },
      {
        "city": "San Julian Aero",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "-49.32",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-67.75"
      },
      {
        "city": "San Luis Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-33.27",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-66.35"
      },
      {
        "city": "San Martin",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-33.08",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-68.42"
      },
      {
        "city": "San Rafael Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.58",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-68.4"
      },
      {
        "city": "Santa Rosa Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.57",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-64.27"
      },
      {
        "city": "Santiago Del Estero",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-27.77",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-64.3"
      },
      {
        "city": "Sauce Viejo Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.7",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-60.82"
      },
      {
        "city": "Tandil Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.23",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-59.25"
      },
      {
        "city": "Tartagal Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-22.65",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-63.82"
      },
      {
        "city": "Trelew Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-43.2",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.27"
      },
      {
        "city": "Tucuman Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.85",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.1"
      },
      {
        "city": "Ushuaia Aero",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-54.8",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-68.32"
      },
      {
        "city": "Viedma Aero",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-40.85",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-63.02"
      },
      {
        "city": "Villa Reynolds Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.73",
        "country": "Argentina",
        "country_id": "ARG",
        "lon": "-65.38"
      }
    ]
  },
  {
    "country": "Armenia",
    "cities": [
      {
        "city": "Amasia",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "40.78",
        "country": "Armenia",
        "country_id": "ARM",
        "lon": "43.83"
      },
      {
        "city": "Sevan",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "40.55",
        "country": "Armenia",
        "country_id": "ARM",
        "lon": "44.93"
      },
      {
        "city": "Yerevan/Yerevan-Ara",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.13",
        "country": "Armenia",
        "country_id": "ARM",
        "lon": "44.47"
      }
    ]
  },
  {
    "country": "Aruba",
    "cities": [
      {
        "city": "Queen Beatrix Airpo",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "12.5",
        "country": "Aruba",
        "country_id": "ABW",
        "lon": "-70.02"
      }
    ]
  },
  {
    "country": "Australia",
    "cities": [
      {
        "city": "Adelaide Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.95",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "138.53"
      },
      {
        "city": "Adelaide Regional O",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "138.62"
      },
      {
        "city": "Adele Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "123.15"
      },
      {
        "city": "Aireys Inlet",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.45",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.1"
      },
      {
        "city": "Albany Airport",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-34.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "117.8"
      },
      {
        "city": "Albury Aeroport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.07",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.95"
      },
      {
        "city": "Alice Springs Airpo",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-23.8",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "133.88"
      },
      {
        "city": "Amberley Amo",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.63",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.72"
      },
      {
        "city": "Applethorpe Gbhrs A",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-28.62",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.95"
      },
      {
        "city": "Ararat Prison",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.28",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.98"
      },
      {
        "city": "Archerfield Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.57",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153"
      },
      {
        "city": "Argyle Aerodrome",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-16.63",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "128.45"
      },
      {
        "city": "Avalon Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.47"
      },
      {
        "city": "Ballarat Aerodrome",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.78"
      },
      {
        "city": "Bankstown Airport A",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.98"
      },
      {
        "city": "Batchelor Aero",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-13.05",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "131.02"
      },
      {
        "city": "Bathurst Airport Aw",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-33.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.65"
      },
      {
        "city": "Bega Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.82"
      },
      {
        "city": "Bendigo Airport Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.73",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.32"
      },
      {
        "city": "Biloela Thangool Ai",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-24.48",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.57"
      },
      {
        "city": "Birdsville Police S",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-25.9",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "139.35"
      },
      {
        "city": "Bombala Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.23"
      },
      {
        "city": "Borroloola",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.08",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.3"
      },
      {
        "city": "Boulia Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-22.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "139.9"
      },
      {
        "city": "Bourke Airport Aws",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-30.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.95"
      },
      {
        "city": "Bowen Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.02",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.2"
      },
      {
        "city": "Braidwood Racecours",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-35.43",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.78"
      },
      {
        "city": "Brisbane Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.38",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.13"
      },
      {
        "city": "Broken Hill Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-32",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "141.47"
      },
      {
        "city": "Broome Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-17.95",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "122.23"
      },
      {
        "city": "Bundaberg Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-24.9",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.32"
      },
      {
        "city": "Burketown Post Offi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-17.75",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "139.55"
      },
      {
        "city": "Busselton Aero",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.68",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.4"
      },
      {
        "city": "Cairns Aero",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.75"
      },
      {
        "city": "Camden Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.68"
      },
      {
        "city": "Canberra Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-35.3",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.2"
      },
      {
        "city": "Canterbury Racecour",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.9",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.12"
      },
      {
        "city": "Cape Borda",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-35.75",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.58"
      },
      {
        "city": "Cape Bruny Lighthou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.5",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.15"
      },
      {
        "city": "Cape Don Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-11.32",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "131.77"
      },
      {
        "city": "Cape Fourcroy",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-11.78",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "130.02"
      },
      {
        "city": "Cape Grim B.A.P.S.",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-40.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.68"
      },
      {
        "city": "Cape Jaffa Aws",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-36.97",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "139.72"
      },
      {
        "city": "Cape Leeuwin",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-34.37",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.13"
      },
      {
        "city": "Cape Moreton Lighth",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.47"
      },
      {
        "city": "Cape Naturaliste",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.02"
      },
      {
        "city": "Cape Nelson Lightho",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-38.43",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "141.55"
      },
      {
        "city": "Cape Otway Lighthou",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.85",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.52"
      },
      {
        "city": "Cape Sorell",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.2",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.17"
      },
      {
        "city": "Cape Wessel Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-11.02",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.75"
      },
      {
        "city": "Carnarvon Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-24.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "113.67"
      },
      {
        "city": "Cato Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-23.25",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "155.53"
      },
      {
        "city": "Ceduna Amo",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-32.13",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "133.7"
      },
      {
        "city": "Centre Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.75",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.8"
      },
      {
        "city": "Cerberus Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.35",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.17"
      },
      {
        "city": "Cessnock Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.78",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.33"
      },
      {
        "city": "Charleville Aero",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-26.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.27"
      },
      {
        "city": "Cobar Airport Aws",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.8"
      },
      {
        "city": "Cobar Mo",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.48",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.83"
      },
      {
        "city": "Coconut Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-10.05",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.07"
      },
      {
        "city": "Coffs Harbour Mo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-30.32",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.12"
      },
      {
        "city": "Combienbar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.33",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.02"
      },
      {
        "city": "Condobolin Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-33.07",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.22"
      },
      {
        "city": "Coober Pedy Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-29.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "134.72"
      },
      {
        "city": "Cooktown Mission",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.43",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.18"
      },
      {
        "city": "Coolangatta Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-28.17",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.5"
      },
      {
        "city": "Cooma Airport Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-36.3",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.97"
      },
      {
        "city": "Coonabarabran Namoi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.27",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.27"
      },
      {
        "city": "Creal Reef",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.38"
      },
      {
        "city": "Cunderdin Airfield",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.62",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "117.22"
      },
      {
        "city": "Cunnamulla Post Off",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.07",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.68"
      },
      {
        "city": "Curtin Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-17.58",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "123.82"
      },
      {
        "city": "Darwin Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "130.88"
      },
      {
        "city": "Derby Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-17.37",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "123.67"
      },
      {
        "city": "Devonport Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-41.17",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.42"
      },
      {
        "city": "Double Island Point",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.18"
      },
      {
        "city": "Dubbo Airport Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.22",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.57"
      },
      {
        "city": "Dunns Hill",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.33"
      },
      {
        "city": "East Sale Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.1",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.13"
      },
      {
        "city": "Eddystone Point",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-41",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.35"
      },
      {
        "city": "Edithburgh Aws",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-35.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "137.73"
      },
      {
        "city": "Eildon Fire Tower",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.22",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.83"
      },
      {
        "city": "Emerald Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-23.57",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.18"
      },
      {
        "city": "Esperance",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.83",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "121.88"
      },
      {
        "city": "Esperance Aero",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.68",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "121.83"
      },
      {
        "city": "Falls Creek Aws",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-36.87",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.27"
      },
      {
        "city": "Flinders Island Air",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-40.1",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148"
      },
      {
        "city": "Flinders Reef",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.72",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.45"
      },
      {
        "city": "Forbes Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.37",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.92"
      },
      {
        "city": "Forrest",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-30.83",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "128.12"
      },
      {
        "city": "Frankston Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.15",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.12"
      },
      {
        "city": "Gabo Island",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.57",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.9"
      },
      {
        "city": "Gannet Cay",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-21.97",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.47"
      },
      {
        "city": "Gayndah Post Office",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.63",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.62"
      },
      {
        "city": "Geelong Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.33"
      },
      {
        "city": "Gelantipy",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.22",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.27"
      },
      {
        "city": "Georgetown Post Off",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.3",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.55"
      },
      {
        "city": "Geraldton Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-28.8",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "114.7"
      },
      {
        "city": "Giles Meteorologica",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-25.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "128.3"
      },
      {
        "city": "Gladstone Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.87",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.22"
      },
      {
        "city": "Gladstone Radar",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.85",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.27"
      },
      {
        "city": "Glen Innes Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-29.68",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.7"
      },
      {
        "city": "Gold Coast Seaway",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.43"
      },
      {
        "city": "Goldstream",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.73",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.4"
      },
      {
        "city": "Goondiwindi Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-28.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.32"
      },
      {
        "city": "Goulburn Airport Aw",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-34.82",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.73"
      },
      {
        "city": "Gove Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.28",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.82"
      },
      {
        "city": "Green Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.77",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.97"
      },
      {
        "city": "Griffith Aws",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.32",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.07"
      },
      {
        "city": "Halls Creek Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-18.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "127.67"
      },
      {
        "city": "Hamilton Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.65",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.07"
      },
      {
        "city": "Hay Aws",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.87"
      },
      {
        "city": "Heron Island Res St",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-23.45",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.92"
      },
      {
        "city": "Hobart Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.83",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.5"
      },
      {
        "city": "Hobart Ellerslie Ro",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.33"
      },
      {
        "city": "Holmes Reef",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.47",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.87"
      },
      {
        "city": "Homebush (Olympic Site)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.85",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.07"
      },
      {
        "city": "Hunters Hill",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-36.22",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.53"
      },
      {
        "city": "Jabiru Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "132.9"
      },
      {
        "city": "Jacup",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-33.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "119.1"
      },
      {
        "city": "Jandakot Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.1",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.88"
      },
      {
        "city": "Kalgoorlie-Boulder",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-30.78",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "121.45"
      },
      {
        "city": "Karratha Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-20.72",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "116.77"
      },
      {
        "city": "Karratha Legendre I",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-20.37",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "116.85"
      },
      {
        "city": "Katanning",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.68",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "117.55"
      },
      {
        "city": "Katoomba",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-33.72",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.28"
      },
      {
        "city": "Khancoban",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.13"
      },
      {
        "city": "Kilmore Gap",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.38",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.97"
      },
      {
        "city": "King Island Airport",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-39.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.88"
      },
      {
        "city": "King Island Currie",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-39.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.85"
      },
      {
        "city": "Kununurra Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-15.78",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "128.72"
      },
      {
        "city": "Kyancutta",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-33.13",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "135.55"
      },
      {
        "city": "Lady Elliot Island",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-24.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.72"
      },
      {
        "city": "Lake Grace",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-33.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "118.47"
      },
      {
        "city": "Lancelin",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.02",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.32"
      },
      {
        "city": "Latrobe Valley Airp",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.22",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.47"
      },
      {
        "city": "Launceston Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-41.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.2"
      },
      {
        "city": "Laverton Aero",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.62",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "122.42"
      },
      {
        "city": "Laverton Aerodrome",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.87",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.75"
      },
      {
        "city": "Learmonth Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-22.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "114.08"
      },
      {
        "city": "Leinster Aero",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-27.85",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "120.7"
      },
      {
        "city": "Leonora Post Office",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "121.33"
      },
      {
        "city": "Lihou Reef",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152"
      },
      {
        "city": "Longerenong",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-36.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.3"
      },
      {
        "city": "Longreach Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-23.43",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.28"
      },
      {
        "city": "Lookout Hill",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-37.28",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.25"
      },
      {
        "city": "Lord Howe Island Ae",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "159.07"
      },
      {
        "city": "Low Rocky Point (Aws)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.98",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.5"
      },
      {
        "city": "Lucinda Point Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.4"
      },
      {
        "city": "Maatsuyker Island L",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.65",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.27"
      },
      {
        "city": "Mackay Mo",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-21.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.22"
      },
      {
        "city": "Macquarie Island",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-54.5",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "158.95"
      },
      {
        "city": "Mallacoota",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.6",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.73"
      },
      {
        "city": "Mandurah",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.72"
      },
      {
        "city": "Mangalore Aws Compo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.18"
      },
      {
        "city": "Mangrove Mountain A",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.28",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.22"
      },
      {
        "city": "Marion Reef",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.08",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.38"
      },
      {
        "city": "Marla Police Statio",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-27.3",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "133.62"
      },
      {
        "city": "Maroochydore Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.6",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.1"
      },
      {
        "city": "Maryborough Composi",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.72"
      },
      {
        "city": "Meekatharra Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-26.62",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "118.55"
      },
      {
        "city": "Melbourne",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.82",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.97"
      },
      {
        "city": "Melbourne Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.85"
      },
      {
        "city": "Mildura Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.08"
      },
      {
        "city": "Milingimbi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "134.9"
      },
      {
        "city": "Moomba Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.1",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "140.2"
      },
      {
        "city": "Moorabbin Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.98",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.1"
      },
      {
        "city": "Moree",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-29.47",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.85"
      },
      {
        "city": "Moree Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-29.48",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.83"
      },
      {
        "city": "Mortlake Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-38.07",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.77"
      },
      {
        "city": "Moruya Heads Pilot",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-35.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.15"
      },
      {
        "city": "Mount Boyce",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-33.62",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.27"
      },
      {
        "city": "Mount Buller Aws",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-37.15",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.43"
      },
      {
        "city": "Mount Gambier Aero",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-37.73",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "140.78"
      },
      {
        "city": "Mount Isa Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-20.68",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "139.48"
      },
      {
        "city": "Mount Lawley Perth",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.87"
      },
      {
        "city": "Mt Hotham Aws",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "-36.97",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.12"
      },
      {
        "city": "Mt Lofty Aws",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-34.97",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "138.7"
      },
      {
        "city": "Mt Moornapa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.75",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.13"
      },
      {
        "city": "Mt Wellington",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "-42.88",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.23"
      },
      {
        "city": "Mudgee Airport Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.57",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.62"
      },
      {
        "city": "Nambour Dpi",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.65",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.93"
      },
      {
        "city": "Neptune Island",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-35.33",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.12"
      },
      {
        "city": "Newcastle Nobbys Si",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.78"
      },
      {
        "city": "Norah Head Lighthou",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.27",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.57"
      },
      {
        "city": "Normanton",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "141.08"
      },
      {
        "city": "Norseman",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-32.2",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "121.78"
      },
      {
        "city": "North East Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-13.65",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.93"
      },
      {
        "city": "Nowra Ran Air Stati",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.95",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.53"
      },
      {
        "city": "Nullarbor Roadhouse",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.45",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "130.9"
      },
      {
        "city": "Nullo Mountain Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-32.73",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.23"
      },
      {
        "city": "Oakey Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-27.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.73"
      },
      {
        "city": "Onslow",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-21.63",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.12"
      },
      {
        "city": "Ouse Fire Station",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.48",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.72"
      },
      {
        "city": "Paraburdoo Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-23.17",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "117.75"
      },
      {
        "city": "Parkes (Macarthur St)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.13",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.17"
      },
      {
        "city": "Paynes Find",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-29.27",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "117.68"
      },
      {
        "city": "Pearce Raaf",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.67",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "116.02"
      },
      {
        "city": "Penrith",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.72",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.68"
      },
      {
        "city": "Perth Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.97"
      },
      {
        "city": "Point Wilson",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.1",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.53"
      },
      {
        "city": "Port Arthur (Palm.)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.17",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.83"
      },
      {
        "city": "Port Fairy Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.4",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.23"
      },
      {
        "city": "Port Hedland Airpor",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-20.37",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "118.63"
      },
      {
        "city": "Port Keats Aws Aut",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-14.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "129.45"
      },
      {
        "city": "Port Macquarie Airp",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.43",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.85"
      },
      {
        "city": "Portland Cashmore A",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-38.32",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "141.47"
      },
      {
        "city": "Proserpine Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-20.5",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.53"
      },
      {
        "city": "Quilpie Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-26.62",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.25"
      },
      {
        "city": "Ravensthorpe Hopeto",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "120.13"
      },
      {
        "city": "Redesdale",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.02",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.53"
      },
      {
        "city": "Renmark",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.17",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "140.75"
      },
      {
        "city": "Rhyll (Aws)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.45",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.3"
      },
      {
        "city": "Richmond Post Offic",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-20.73",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.13"
      },
      {
        "city": "Richmond Raaf",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.6",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.78"
      },
      {
        "city": "Rockhampton Aero",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.38",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.48"
      },
      {
        "city": "Roebourne Post Offi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-20.78",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "117.15"
      },
      {
        "city": "Roma Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.55",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.78"
      },
      {
        "city": "Rottnest Island",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.02",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.5"
      },
      {
        "city": "Rundle Island",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.53",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.28"
      },
      {
        "city": "Scone Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.83"
      },
      {
        "city": "Scoresby Research",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.87",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.25"
      },
      {
        "city": "Shark Bay (Denham)",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-25.92",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "113.52"
      },
      {
        "city": "Sheoaks Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-37.9",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "144.12"
      },
      {
        "city": "Shepparton Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.43",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.4"
      },
      {
        "city": "Smithton Aerodrome",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-40.83",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.08"
      },
      {
        "city": "Southern Cross",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "119.33"
      },
      {
        "city": "Southern Cross Airf",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.23",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "119.35"
      },
      {
        "city": "St Lawrence Post Of",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-22.35",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.53"
      },
      {
        "city": "Strahan Aerodrome",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.15",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.28"
      },
      {
        "city": "Swan Hill Aerodrome",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-35.38",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.53"
      },
      {
        "city": "Swanbourne",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.95",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "115.77"
      },
      {
        "city": "Sydney Airport Amo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.93",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.18"
      },
      {
        "city": "Sydney Regional Off",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.85",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.2"
      },
      {
        "city": "Tamworth Airport Aw",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.07",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.83"
      },
      {
        "city": "Telfer Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-21.72",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "122.22"
      },
      {
        "city": "Tennant Creek Met O",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-19.63",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "134.18"
      },
      {
        "city": "Tewantin Rsl Park",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-26.38",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "153.03"
      },
      {
        "city": "Thredbo (Crackenback)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "-36.5",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.28"
      },
      {
        "city": "Tindal Raaf",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-14.52",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "132.37"
      },
      {
        "city": "Toowoomba Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-27.55",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.92"
      },
      {
        "city": "Town Of 1770",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-24.15",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.88"
      },
      {
        "city": "Townsville Aero",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.25",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.77"
      },
      {
        "city": "Troughton Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-13.75",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "126.15"
      },
      {
        "city": "Tuggeranong Isabell",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-35.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.1"
      },
      {
        "city": "Tunnak Fire Station",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-42.45",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.47"
      },
      {
        "city": "Ulladulla Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-35.35",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.48"
      },
      {
        "city": "Urandangie",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-21.6",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "138.3"
      },
      {
        "city": "Victoria River Down",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-16.4",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "131.02"
      },
      {
        "city": "Wagga Wagga Amo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-35.17",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "147.45"
      },
      {
        "city": "Walgett Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-30.03",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.12"
      },
      {
        "city": "Wangaratta Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-36.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.3"
      },
      {
        "city": "Warburto Point",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "137.53"
      },
      {
        "city": "Warrnambool Airport",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-38.28",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.43"
      },
      {
        "city": "Warwick",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-28.2",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "152.1"
      },
      {
        "city": "Weipa Aero",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.68",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "141.92"
      },
      {
        "city": "Williamtown Raaf",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.8",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "151.83"
      },
      {
        "city": "Willis Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.3",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "149.97"
      },
      {
        "city": "Wilsons Promontory",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-39.12",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "146.42"
      },
      {
        "city": "Windorah",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-25.42",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "142.65"
      },
      {
        "city": "Winton (Post Office)",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-22.38",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "143.03"
      },
      {
        "city": "Wonthaggi Composite",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.6",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.58"
      },
      {
        "city": "Woomera Aerodrome",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.15",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "136.82"
      },
      {
        "city": "Wyndham",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-15.48",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "128.12"
      },
      {
        "city": "Wynyard Airport",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-41",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "145.73"
      },
      {
        "city": "Yeppoon Aws",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.13",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "150.75"
      },
      {
        "city": "Young Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.25",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "148.25"
      },
      {
        "city": "Yulara Aero",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-25.2",
        "country": "Australia",
        "country_id": "AUS",
        "lon": "130.98"
      }
    ]
  },
  {
    "country": "Austria",
    "cities": [
      {
        "city": "Aigen Im Ennstal",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.53",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.13"
      },
      {
        "city": "Allentsteig",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.68",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.37"
      },
      {
        "city": "Alpinzentrum Rudolf",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.13",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.63"
      },
      {
        "city": "Amstetten",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.1",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.9"
      },
      {
        "city": "Arriach",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.73",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.85"
      },
      {
        "city": "Bad Radkersburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.68",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.98"
      },
      {
        "city": "Bischofshofen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.4",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.22"
      },
      {
        "city": "Bregenz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.5",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "9.75"
      },
      {
        "city": "Brenner",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "11.52"
      },
      {
        "city": "Dellach Im Drautal",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.73",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.08"
      },
      {
        "city": "Dornbirn",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.43",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "9.73"
      },
      {
        "city": "Eisenstadt",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.85",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.53"
      },
      {
        "city": "Feldkirch",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.27",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "9.62"
      },
      {
        "city": "Feuerkogel",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.82",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.72"
      },
      {
        "city": "Freistadt",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.5",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.5"
      },
      {
        "city": "Gmunden",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.9",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.8"
      },
      {
        "city": "Graz-Thalerhof-Flug",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.43"
      },
      {
        "city": "Gumpoldskirchen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.03",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.28"
      },
      {
        "city": "Hahnenkamm/Ehrenbac",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.42",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.37"
      },
      {
        "city": "Hohe Wand/Hochkogel",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.82",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.03"
      },
      {
        "city": "Innsbruck-Flughafen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.27",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "11.35"
      },
      {
        "city": "Ischgl/Idalpe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.98",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "10.32"
      },
      {
        "city": "Jauerling",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.33",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.33"
      },
      {
        "city": "Klagenfurt-Flughafe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.65",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.33"
      },
      {
        "city": "Kleinzicken",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.2",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.33"
      },
      {
        "city": "Koetschach-Mauthen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.68",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13"
      },
      {
        "city": "Kremsmuenster",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.05",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.13"
      },
      {
        "city": "Kufstein",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.58",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.17"
      },
      {
        "city": "Landeck",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.13",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "10.57"
      },
      {
        "city": "Langenlois",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.47",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.7"
      },
      {
        "city": "Lassnitzhoehe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.07",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.58"
      },
      {
        "city": "Lienz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.83",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.82"
      },
      {
        "city": "Lilienfeld/Tarschbe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.03",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.58"
      },
      {
        "city": "Linz/Hoersching-Flu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.23",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.18"
      },
      {
        "city": "Linz/Stadt",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.3",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.28"
      },
      {
        "city": "Litschau",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.95",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.03"
      },
      {
        "city": "Lunz",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.85",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.07"
      },
      {
        "city": "Mariapfarr",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.15",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.75"
      },
      {
        "city": "Mariazell",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.77",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.32"
      },
      {
        "city": "Mattsee",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.98",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.1"
      },
      {
        "city": "Moenichkirchen",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.52",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.03"
      },
      {
        "city": "Murau",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.12",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.18"
      },
      {
        "city": "Neusiedl",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.95",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.85"
      },
      {
        "city": "Obertauern",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.25",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.57"
      },
      {
        "city": "Poysdorf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.67",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.63"
      },
      {
        "city": "Puchberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.78",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.9"
      },
      {
        "city": "Ramsau/Dachstein",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.43",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.63"
      },
      {
        "city": "Rax/Seilbahn-Bergst",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.72",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.78"
      },
      {
        "city": "Reichenau/Rax",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.7",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.83"
      },
      {
        "city": "Retz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.77",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.95"
      },
      {
        "city": "Salzburg-Flughafen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.8",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13"
      },
      {
        "city": "Schmittenhoehe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.33",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.73"
      },
      {
        "city": "Schoeckl",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.2",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.47"
      },
      {
        "city": "St. Michael/Leoben",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.33",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15"
      },
      {
        "city": "St. Poelten",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.18",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.62"
      },
      {
        "city": "St. Wolfgang",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.73",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.45"
      },
      {
        "city": "Stift Zwettl",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.62",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "15.2"
      },
      {
        "city": "Tulln",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.32",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.12"
      },
      {
        "city": "Villach",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.62",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "13.88"
      },
      {
        "city": "Virgen",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.45"
      },
      {
        "city": "Warth",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.25",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "10.18"
      },
      {
        "city": "Wien/City",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.2",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.37"
      },
      {
        "city": "Wien/Hohe Warte",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.25",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.37"
      },
      {
        "city": "Wien/Schwechat-Flug",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.12",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.57"
      },
      {
        "city": "Wiener Neustadt",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.83",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "16.22"
      },
      {
        "city": "Windischgarsten",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.73",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.33"
      },
      {
        "city": "Zell Am See",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.33",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "12.8"
      },
      {
        "city": "Zeltweg",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.2",
        "country": "Austria",
        "country_id": "AUT",
        "lon": "14.75"
      }
    ]
  },
  {
    "country": "Azerbaijan",
    "cities": [
      {
        "city": "Lankaran",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.73",
        "country": "Azerbaijan",
        "country_id": "AZE",
        "lon": "48.83"
      },
      {
        "city": "Zakatala",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.67",
        "country": "Azerbaijan",
        "country_id": "AZE",
        "lon": "46.65"
      }
    ]
  },
  {
    "country": "Bahamas",
    "cities": [
      {
        "city": "Nassau Airport New",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "25.05",
        "country": "Bahamas",
        "country_id": "BHS",
        "lon": "-77.47"
      },
      {
        "city": "Settlement Point",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "26.68",
        "country": "Bahamas",
        "country_id": "BHS",
        "lon": "-79"
      }
    ]
  },
  {
    "country": "Bahrain",
    "cities": [
      {
        "city": "Bahrain (Int. Airport)",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.27",
        "country": "Bahrain",
        "country_id": "BHR",
        "lon": "50.65"
      }
    ]
  },
  {
    "country": "Barbados",
    "cities": [
      {
        "city": "Grantley Adams",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.07",
        "country": "Barbados",
        "country_id": "BRB",
        "lon": "-59.48"
      }
    ]
  },
  {
    "country": "Belarus",
    "cities": [
      {
        "city": "Baranovichi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.12",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "26"
      },
      {
        "city": "Bobruisk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.22",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "29.18"
      },
      {
        "city": "Brest",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.12",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "23.68"
      },
      {
        "city": "Gomel",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.4",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "30.95"
      },
      {
        "city": "Grodno",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.6",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "24.05"
      },
      {
        "city": "Kostuckovichi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.35",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "32.07"
      },
      {
        "city": "Lepel",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.88",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "28.7"
      },
      {
        "city": "Lida",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.85",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "25.32"
      },
      {
        "city": "Lyntupy",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.05",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "26.32"
      },
      {
        "city": "Minsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.93",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "27.63"
      },
      {
        "city": "Mogilev",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.95",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "30.07"
      },
      {
        "city": "Mozyr",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.95",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "29.17"
      },
      {
        "city": "Orsha",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.5",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "30.42"
      },
      {
        "city": "Pinsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.12",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "26.12"
      },
      {
        "city": "Slutsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.03",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "27.55"
      },
      {
        "city": "Verhnedvinsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.82",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "27.95"
      },
      {
        "city": "Vitebsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.17",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "30.22"
      },
      {
        "city": "Zhitckovichi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.22",
        "country": "Belarus",
        "country_id": "BLR",
        "lon": "27.87"
      }
    ]
  },
  {
    "country": "Belgium",
    "cities": [
      {
        "city": "Antwerpen/Deurne",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.2",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "4.47"
      },
      {
        "city": "Beauvechain",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.75",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "4.77"
      },
      {
        "city": "Bierset",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.65",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "5.45"
      },
      {
        "city": "Bruxelles National",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.9",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "4.53"
      },
      {
        "city": "Charleroi/Gosselies",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.47",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "4.45"
      },
      {
        "city": "Chievres",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.57",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "3.83"
      },
      {
        "city": "Elsenborn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.47",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "6.18"
      },
      {
        "city": "Florennes",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.23",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "4.65"
      },
      {
        "city": "Gent/Industrie-Zone",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.18",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "3.82"
      },
      {
        "city": "Kleine Brogel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.17",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "5.47"
      },
      {
        "city": "Koksijde",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.08",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "2.65"
      },
      {
        "city": "Liege",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.63",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "5.45"
      },
      {
        "city": "Oostende (Airport)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.2",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "2.87"
      },
      {
        "city": "Oostende (Pier)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.23",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "2.92"
      },
      {
        "city": "Semmerzake",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.93",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "3.67"
      },
      {
        "city": "St-Hubert",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.03",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "5.4"
      },
      {
        "city": "St. Truiden (Bafb)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.8",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "5.2"
      },
      {
        "city": "Uccle",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.8",
        "country": "Belgium",
        "country_id": "BEL",
        "lon": "4.35"
      }
    ]
  },
  {
    "country": "Belize",
    "cities": [
      {
        "city": "Belize/Phillip Gold",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.53",
        "country": "Belize",
        "country_id": "BLZ",
        "lon": "-88.3"
      }
    ]
  },
  {
    "country": "Benin",
    "cities": [
      {
        "city": "Bohicon",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.17",
        "country": "Benin",
        "country_id": "BEN",
        "lon": "2.07"
      },
      {
        "city": "Cotonou",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.35",
        "country": "Benin",
        "country_id": "BEN",
        "lon": "2.38"
      },
      {
        "city": "Kandi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.13",
        "country": "Benin",
        "country_id": "BEN",
        "lon": "2.93"
      },
      {
        "city": "Natitingou",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.32",
        "country": "Benin",
        "country_id": "BEN",
        "lon": "1.38"
      },
      {
        "city": "Parakou",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.35",
        "country": "Benin",
        "country_id": "BEN",
        "lon": "2.62"
      },
      {
        "city": "Save",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.03",
        "country": "Benin",
        "country_id": "BEN",
        "lon": "2.47"
      }
    ]
  },
  {
    "country": "Bermuda",
    "cities": [
      {
        "city": "Bermuda Intl",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "32.37",
        "country": "Bermuda",
        "country_id": "BMU",
        "lon": "-64.68"
      }
    ]
  },
  {
    "country": "Bolivia",
    "cities": [
      {
        "city": "Cochabamba",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-17.42",
        "country": "Bolivia",
        "country_id": "BOL",
        "lon": "-66.18"
      },
      {
        "city": "La Paz/Alto",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-16.52",
        "country": "Bolivia",
        "country_id": "BOL",
        "lon": "-68.18"
      },
      {
        "city": "Viru-Viru",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.63",
        "country": "Bolivia",
        "country_id": "BOL",
        "lon": "-63.13"
      }
    ]
  },
  {
    "country": "Bosnia and Herzegovina",
    "cities": [
      {
        "city": "Banja Luka",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.78",
        "country": "Bosnia and Herzegovina",
        "country_id": "BIH",
        "lon": "17.22"
      },
      {
        "city": "Bihac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.82",
        "country": "Bosnia and Herzegovina",
        "country_id": "BIH",
        "lon": "15.88"
      },
      {
        "city": "Bjelasnica",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.72",
        "country": "Bosnia and Herzegovina",
        "country_id": "BIH",
        "lon": "18.27"
      },
      {
        "city": "Mostar",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.33",
        "country": "Bosnia and Herzegovina",
        "country_id": "BIH",
        "lon": "17.78"
      },
      {
        "city": "Sarajevo-Bjelave",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.87",
        "country": "Bosnia and Herzegovina",
        "country_id": "BIH",
        "lon": "18.43"
      },
      {
        "city": "Sarajevo/Butmir",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.82",
        "country": "Bosnia and Herzegovina",
        "country_id": "BIH",
        "lon": "18.33"
      }
    ]
  },
  {
    "country": "Botswana",
    "cities": [
      {
        "city": "Seretse Khama Inter",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-24.55",
        "country": "Botswana",
        "country_id": "BWA",
        "lon": "25.92"
      }
    ]
  },
  {
    "country": "Brazil",
    "cities": [
      {
        "city": "Anapolis (Braz-Afb)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-16.23",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-48.97"
      },
      {
        "city": "Aracaju (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-10.98",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-37.07"
      },
      {
        "city": "Belem (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-1.38",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-48.48"
      },
      {
        "city": "Belo Horizonte",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-19.93",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-43.93"
      },
      {
        "city": "Belo Horizonte (Aero)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-19.85",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-43.95"
      },
      {
        "city": "Boa Vista (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "2.83",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-60.7"
      },
      {
        "city": "Brasilia (Aeroporto)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-15.87",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-47.93"
      },
      {
        "city": "Campinas (Aeroporto)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-47.13"
      },
      {
        "city": "Campo Grande (Aero)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.47",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-54.67"
      },
      {
        "city": "Caravelas (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.63",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-39.25"
      },
      {
        "city": "Cuiaba (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.65",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-56.1"
      },
      {
        "city": "Curitiba (Aeroporto)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-25.52",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-49.17"
      },
      {
        "city": "Eduardo Gomes Intl",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-3.03",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-60.05"
      },
      {
        "city": "Fernando De Noronha",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-3.85",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-32.42"
      },
      {
        "city": "Florianopolis (Aero)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.67",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-48.55"
      },
      {
        "city": "Fortaleza (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-3.78",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-38.53"
      },
      {
        "city": "Foz Do Iguacu (Aero)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.52",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-54.58"
      },
      {
        "city": "Galeao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-22.82",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-43.25"
      },
      {
        "city": "Goiania (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.63",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-49.22"
      },
      {
        "city": "Guarulhos",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.43",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-46.47"
      },
      {
        "city": "Londrina (Aeroporto)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.33",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-51.13"
      },
      {
        "city": "Macapa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "0.03",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-51.05"
      },
      {
        "city": "Maceio (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-9.52",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-35.78"
      },
      {
        "city": "Manaus (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-3.15",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-59.98"
      },
      {
        "city": "Natal Aeroporto",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-5.92",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-35.25"
      },
      {
        "city": "Porto Alegre (Aero)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-30",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-51.18"
      },
      {
        "city": "Porto Velho (Aero)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-8.77",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-63.92"
      },
      {
        "city": "Recife (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-8.07",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-34.85"
      },
      {
        "city": "Rio Branco",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-10",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-67.8"
      },
      {
        "city": "Rio De Janeiro (Aero)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-22.9",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-43.17"
      },
      {
        "city": "Salvador (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.9",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-38.33"
      },
      {
        "city": "Santarem-Aeroporto",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-2.43",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-54.72"
      },
      {
        "city": "Sao Luiz (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-2.6",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-44.23"
      },
      {
        "city": "Sao Paulo (Aeroporto)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.62",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-46.65"
      },
      {
        "city": "Teresina (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-5.05",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-42.82"
      },
      {
        "city": "Uberaba",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-19.78",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-47.97"
      },
      {
        "city": "Vitoria (Aeroporto)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.27",
        "country": "Brazil",
        "country_id": "BRA",
        "lon": "-40.28"
      }
    ]
  },
  {
    "country": "British Indian Ocean Territory",
    "cities": [
      {
        "city": "Diego Garcia Naf",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-7.3",
        "country": "British Indian Ocean Territory",
        "country_id": "IOT",
        "lon": "72.4"
      }
    ]
  },
  {
    "country": "Brunei",
    "cities": [
      {
        "city": "Brunei Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "4.93",
        "country": "Brunei",
        "country_id": "BRN",
        "lon": "114.93"
      }
    ]
  },
  {
    "country": "Bulgaria",
    "cities": [
      {
        "city": "Botev Vrah (Top/Sommet)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "42.67",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "24.83"
      },
      {
        "city": "Burgas",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.48",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "27.48"
      },
      {
        "city": "Cherni Vrah (Top/Sommet)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "42.58",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.27"
      },
      {
        "city": "Chirpan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.2",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "25.33"
      },
      {
        "city": "Elhovo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.18",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "26.57"
      },
      {
        "city": "Ivailo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.22",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "24.33"
      },
      {
        "city": "Kaliakpa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.37",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "28.47"
      },
      {
        "city": "Kurdjali",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.65",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "25.37"
      },
      {
        "city": "Kustendil",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.27",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "22.77"
      },
      {
        "city": "Lom",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.82",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.25"
      },
      {
        "city": "Lovetch",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.13",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "24.72"
      },
      {
        "city": "Mourgash",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "42.83",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.67"
      },
      {
        "city": "Mussala (Top/Sommet)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "42.18",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.58"
      },
      {
        "city": "Pleven",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.42",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "24.6"
      },
      {
        "city": "Plovdiv",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.13",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "24.75"
      },
      {
        "city": "Razgrad",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.52",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "26.53"
      },
      {
        "city": "Rousse",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.85",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "25.95"
      },
      {
        "city": "Sandanski",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.52",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.27"
      },
      {
        "city": "Shabla",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.53",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "28.53"
      },
      {
        "city": "Shumen",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.27",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "26.93"
      },
      {
        "city": "Sliven",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.67",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "26.32"
      },
      {
        "city": "Sofia (Observ.)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.65",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.38"
      },
      {
        "city": "Svichtov",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.62",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "25.35"
      },
      {
        "city": "Svilengrad",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.77",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "26.2"
      },
      {
        "city": "Varna",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.2",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "27.92"
      },
      {
        "city": "Veliko Tarnovo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.08",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "25.65"
      },
      {
        "city": "Vidin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.82",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "22.88"
      },
      {
        "city": "Vratza",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.2",
        "country": "Bulgaria",
        "country_id": "BGR",
        "lon": "23.53"
      }
    ]
  },
  {
    "country": "Burkina Faso",
    "cities": [
      {
        "city": "Bobo-Dioulasso",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.17",
        "country": "Burkina Faso",
        "country_id": "BFA",
        "lon": "-4.32"
      },
      {
        "city": "Boromo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.75",
        "country": "Burkina Faso",
        "country_id": "BFA",
        "lon": "-2.93"
      },
      {
        "city": "Dori",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.03",
        "country": "Burkina Faso",
        "country_id": "BFA",
        "lon": "-0.03"
      },
      {
        "city": "Ouagadougou",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "12.35",
        "country": "Burkina Faso",
        "country_id": "BFA",
        "lon": "-1.52"
      },
      {
        "city": "Ouahigouya",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.57",
        "country": "Burkina Faso",
        "country_id": "BFA",
        "lon": "-2.42"
      }
    ]
  },
  {
    "country": "Cape Verde",
    "cities": [
      {
        "city": "Sal",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "16.73",
        "country": "Cape Verde",
        "country_id": "CPV",
        "lon": "-22.95"
      }
    ]
  },
  {
    "country": "Chad",
    "cities": [
      {
        "city": "Ndjamena",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "12.13",
        "country": "Chad",
        "country_id": "TCD",
        "lon": "15.03"
      }
    ]
  },
  {
    "country": "Chile",
    "cities": [
      {
        "city": "Antofagasta",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-23.43",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-70.45"
      },
      {
        "city": "Arica",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-18.47",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-70.17"
      },
      {
        "city": "Balmaceda",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-45.92",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-71.7"
      },
      {
        "city": "Concepcion",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-36.77",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-73.07"
      },
      {
        "city": "Faro Evangelistas",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-52.4",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-75.1"
      },
      {
        "city": "Iquique",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-20.53",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-70.18"
      },
      {
        "city": "Isla Diego Ramirez",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-56.5",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-68.67"
      },
      {
        "city": "La Serena",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-29.92",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-71.2"
      },
      {
        "city": "Pudahuel",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.38",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-70.78"
      },
      {
        "city": "Puerto Montt",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-41.43",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-73.1"
      },
      {
        "city": "Punta Arenas",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-53",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-70.97"
      },
      {
        "city": "Temuco",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "-38.75",
        "country": "Chile",
        "country_id": "CHL",
        "lon": "-72.63"
      }
    ]
  },
  {
    "country": "China",
    "cities": [
      {
        "city": "Abag Qi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.02",
        "country": "China",
        "country_id": "",
        "lon": "114.95"
      },
      {
        "city": "Aihui",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.25",
        "country": "China",
        "country_id": "",
        "lon": "127.45"
      },
      {
        "city": "Akqi",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "40.93",
        "country": "China",
        "country_id": "",
        "lon": "78.45"
      },
      {
        "city": "Alar",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "40.5",
        "country": "China",
        "country_id": "",
        "lon": "81.05"
      },
      {
        "city": "Altay",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.73",
        "country": "China",
        "country_id": "",
        "lon": "88.08"
      },
      {
        "city": "Anda",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.38",
        "country": "China",
        "country_id": "",
        "lon": "125.32"
      },
      {
        "city": "Andir",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.93",
        "country": "China",
        "country_id": "",
        "lon": "83.65"
      },
      {
        "city": "Ankang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.72",
        "country": "China",
        "country_id": "",
        "lon": "109.03"
      },
      {
        "city": "Anqing",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.53",
        "country": "China",
        "country_id": "",
        "lon": "117.05"
      },
      {
        "city": "Anyang",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "36.05",
        "country": "China",
        "country_id": "",
        "lon": "114.4"
      },
      {
        "city": "Arxan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "47.17",
        "country": "China",
        "country_id": "",
        "lon": "119.93"
      },
      {
        "city": "Bachu",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "39.8",
        "country": "China",
        "country_id": "",
        "lon": "78.57"
      },
      {
        "city": "Bailing-Miao",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.7",
        "country": "China",
        "country_id": "",
        "lon": "110.43"
      },
      {
        "city": "Baingoin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "31.37",
        "country": "China",
        "country_id": "",
        "lon": "90.02"
      },
      {
        "city": "Baise",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.9",
        "country": "China",
        "country_id": "",
        "lon": "106.6"
      },
      {
        "city": "Balguntay",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "42.67",
        "country": "China",
        "country_id": "",
        "lon": "86.33"
      },
      {
        "city": "Baoding",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.85",
        "country": "China",
        "country_id": "",
        "lon": "115.57"
      },
      {
        "city": "Baoji",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.35",
        "country": "China",
        "country_id": "",
        "lon": "107.13"
      },
      {
        "city": "Baoqing",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.32",
        "country": "China",
        "country_id": "",
        "lon": "132.18"
      },
      {
        "city": "Baoshan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.12",
        "country": "China",
        "country_id": "",
        "lon": "99.18"
      },
      {
        "city": "Barkam",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "31.9",
        "country": "China",
        "country_id": "",
        "lon": "102.23"
      },
      {
        "city": "Batang",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "30",
        "country": "China",
        "country_id": "",
        "lon": "99.1"
      },
      {
        "city": "Bayan Mod",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "40.75",
        "country": "China",
        "country_id": "",
        "lon": "104.5"
      },
      {
        "city": "Bayanbulak",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "43.03",
        "country": "China",
        "country_id": "",
        "lon": "84.15"
      },
      {
        "city": "Baytik Shan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.37",
        "country": "China",
        "country_id": "",
        "lon": "90.53"
      },
      {
        "city": "Beihai",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.48",
        "country": "China",
        "country_id": "",
        "lon": "109.1"
      },
      {
        "city": "Beijing",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.93",
        "country": "China",
        "country_id": "",
        "lon": "116.28"
      },
      {
        "city": "Bengbu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.95",
        "country": "China",
        "country_id": "",
        "lon": "117.37"
      },
      {
        "city": "Benxi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.32",
        "country": "China",
        "country_id": "",
        "lon": "123.78"
      },
      {
        "city": "Bijie",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "27.3",
        "country": "China",
        "country_id": "",
        "lon": "105.23"
      },
      {
        "city": "Binhai",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.12",
        "country": "China",
        "country_id": "",
        "lon": "117.33"
      },
      {
        "city": "Boxian",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33.88",
        "country": "China",
        "country_id": "",
        "lon": "115.77"
      },
      {
        "city": "Bugt",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.77",
        "country": "China",
        "country_id": "",
        "lon": "121.92"
      },
      {
        "city": "Bugt",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "42.33",
        "country": "China",
        "country_id": "",
        "lon": "120.7"
      },
      {
        "city": "Cangzhou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.33",
        "country": "China",
        "country_id": "",
        "lon": "116.83"
      },
      {
        "city": "Chang Dao",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.93",
        "country": "China",
        "country_id": "",
        "lon": "120.72"
      },
      {
        "city": "Changbai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.35",
        "country": "China",
        "country_id": "",
        "lon": "128.17"
      },
      {
        "city": "Changchun",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.9",
        "country": "China",
        "country_id": "",
        "lon": "125.22"
      },
      {
        "city": "Changde",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.05",
        "country": "China",
        "country_id": "",
        "lon": "111.68"
      },
      {
        "city": "Changling",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.25",
        "country": "China",
        "country_id": "",
        "lon": "123.97"
      },
      {
        "city": "Changsha",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.23",
        "country": "China",
        "country_id": "",
        "lon": "112.87"
      },
      {
        "city": "Changting",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.85",
        "country": "China",
        "country_id": "",
        "lon": "116.37"
      },
      {
        "city": "Chaoyang",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.55",
        "country": "China",
        "country_id": "",
        "lon": "120.45"
      },
      {
        "city": "Chengde",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.98",
        "country": "China",
        "country_id": "",
        "lon": "117.95"
      },
      {
        "city": "Chengdu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.67",
        "country": "China",
        "country_id": "",
        "lon": "104.02"
      },
      {
        "city": "Chengshantou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.4",
        "country": "China",
        "country_id": "",
        "lon": "122.68"
      },
      {
        "city": "Chenzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.8",
        "country": "China",
        "country_id": "",
        "lon": "113.03"
      },
      {
        "city": "Chifeng",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "42.27",
        "country": "China",
        "country_id": "",
        "lon": "118.97"
      },
      {
        "city": "Chongqing",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.58",
        "country": "China",
        "country_id": "",
        "lon": "106.47"
      },
      {
        "city": "Chuxiong",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.02",
        "country": "China",
        "country_id": "",
        "lon": "101.52"
      },
      {
        "city": "Da Xian",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.2",
        "country": "China",
        "country_id": "",
        "lon": "107.5"
      },
      {
        "city": "Da-Qaidam",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "37.85",
        "country": "China",
        "country_id": "",
        "lon": "95.37"
      },
      {
        "city": "Dachen Dao",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.45",
        "country": "China",
        "country_id": "",
        "lon": "121.88"
      },
      {
        "city": "Dali",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "25.7",
        "country": "China",
        "country_id": "",
        "lon": "100.18"
      },
      {
        "city": "Dalian",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.9",
        "country": "China",
        "country_id": "",
        "lon": "121.63"
      },
      {
        "city": "Dandong",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.05",
        "country": "China",
        "country_id": "",
        "lon": "124.33"
      },
      {
        "city": "Danxian",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.52",
        "country": "China",
        "country_id": "",
        "lon": "109.58"
      },
      {
        "city": "Daocheng",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "29.05",
        "country": "China",
        "country_id": "",
        "lon": "100.3"
      },
      {
        "city": "Darlag",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "33.75",
        "country": "China",
        "country_id": "",
        "lon": "99.65"
      },
      {
        "city": "Datong",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "40.1",
        "country": "China",
        "country_id": "",
        "lon": "113.33"
      },
      {
        "city": "Dawu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "30.98",
        "country": "China",
        "country_id": "",
        "lon": "101.12"
      },
      {
        "city": "Dege",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "31.8",
        "country": "China",
        "country_id": "",
        "lon": "98.57"
      },
      {
        "city": "Delingha",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "37.37",
        "country": "China",
        "country_id": "",
        "lon": "97.37"
      },
      {
        "city": "Dengqen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "31.42",
        "country": "China",
        "country_id": "",
        "lon": "95.6"
      },
      {
        "city": "Deqen",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "28.45",
        "country": "China",
        "country_id": "",
        "lon": "98.88"
      },
      {
        "city": "Dezhou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.43",
        "country": "China",
        "country_id": "",
        "lon": "116.32"
      },
      {
        "city": "Dinghai",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.03",
        "country": "China",
        "country_id": "",
        "lon": "122.12"
      },
      {
        "city": "Dingtao",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.07",
        "country": "China",
        "country_id": "",
        "lon": "115.57"
      },
      {
        "city": "Diwopu",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "43.9",
        "country": "China",
        "country_id": "",
        "lon": "87.47"
      },
      {
        "city": "Dongfang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.1",
        "country": "China",
        "country_id": "",
        "lon": "108.62"
      },
      {
        "city": "Dongsheng",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "39.83",
        "country": "China",
        "country_id": "",
        "lon": "109.98"
      },
      {
        "city": "Dongtai",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "32.85",
        "country": "China",
        "country_id": "",
        "lon": "120.28"
      },
      {
        "city": "Dulan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "36.3",
        "country": "China",
        "country_id": "",
        "lon": "98.1"
      },
      {
        "city": "Dunhua",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.37",
        "country": "China",
        "country_id": "",
        "lon": "128.2"
      },
      {
        "city": "Dunhuang",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "40.15",
        "country": "China",
        "country_id": "",
        "lon": "94.68"
      },
      {
        "city": "Duolun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "42.18",
        "country": "China",
        "country_id": "",
        "lon": "116.47"
      },
      {
        "city": "Dushan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.83",
        "country": "China",
        "country_id": "",
        "lon": "107.55"
      },
      {
        "city": "Ejin Qi",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "41.95",
        "country": "China",
        "country_id": "",
        "lon": "101.07"
      },
      {
        "city": "Emei Shan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "29.52",
        "country": "China",
        "country_id": "",
        "lon": "103.33"
      },
      {
        "city": "Enshi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.28",
        "country": "China",
        "country_id": "",
        "lon": "109.47"
      },
      {
        "city": "Erenhot",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.65",
        "country": "China",
        "country_id": "",
        "lon": "112"
      },
      {
        "city": "Fangxian",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "32.03",
        "country": "China",
        "country_id": "",
        "lon": "110.77"
      },
      {
        "city": "Fengjie",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.02",
        "country": "China",
        "country_id": "",
        "lon": "109.53"
      },
      {
        "city": "Fengning",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.22",
        "country": "China",
        "country_id": "",
        "lon": "116.63"
      },
      {
        "city": "Fezxzan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.25",
        "country": "China",
        "country_id": "",
        "lon": "117.95"
      },
      {
        "city": "Fogang",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.87",
        "country": "China",
        "country_id": "",
        "lon": "113.53"
      },
      {
        "city": "Fuding",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.33",
        "country": "China",
        "country_id": "",
        "lon": "120.2"
      },
      {
        "city": "Fujin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.23",
        "country": "China",
        "country_id": "",
        "lon": "131.98"
      },
      {
        "city": "Fuyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.87",
        "country": "China",
        "country_id": "",
        "lon": "115.73"
      },
      {
        "city": "Fuyun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.98",
        "country": "China",
        "country_id": "",
        "lon": "89.52"
      },
      {
        "city": "Fuzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.08",
        "country": "China",
        "country_id": "",
        "lon": "119.28"
      },
      {
        "city": "Gangca",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "37.33",
        "country": "China",
        "country_id": "",
        "lon": "100.13"
      },
      {
        "city": "Ganyu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.83",
        "country": "China",
        "country_id": "",
        "lon": "119.13"
      },
      {
        "city": "Ganzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.87",
        "country": "China",
        "country_id": "",
        "lon": "115"
      },
      {
        "city": "Gaoyao",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.05",
        "country": "China",
        "country_id": "",
        "lon": "112.47"
      },
      {
        "city": "Garze",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "31.62",
        "country": "China",
        "country_id": "",
        "lon": "100"
      },
      {
        "city": "Gengma",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.55",
        "country": "China",
        "country_id": "",
        "lon": "99.4"
      },
      {
        "city": "Golmud",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "36.42",
        "country": "China",
        "country_id": "",
        "lon": "94.9"
      },
      {
        "city": "Guaizihu",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "41.37",
        "country": "China",
        "country_id": "",
        "lon": "102.37"
      },
      {
        "city": "Guangchang",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.85",
        "country": "China",
        "country_id": "",
        "lon": "116.33"
      },
      {
        "city": "Guanghua",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.38",
        "country": "China",
        "country_id": "",
        "lon": "111.67"
      },
      {
        "city": "Guangnan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "24.07",
        "country": "China",
        "country_id": "",
        "lon": "105.07"
      },
      {
        "city": "Guangzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.17",
        "country": "China",
        "country_id": "",
        "lon": "113.33"
      },
      {
        "city": "Guilin",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.33",
        "country": "China",
        "country_id": "",
        "lon": "110.3"
      },
      {
        "city": "Guiping",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.4",
        "country": "China",
        "country_id": "",
        "lon": "110.08"
      },
      {
        "city": "Guiyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.58",
        "country": "China",
        "country_id": "",
        "lon": "106.73"
      },
      {
        "city": "Gushi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.17",
        "country": "China",
        "country_id": "",
        "lon": "115.67"
      },
      {
        "city": "Haikou",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.03",
        "country": "China",
        "country_id": "",
        "lon": "110.35"
      },
      {
        "city": "Hailar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.22",
        "country": "China",
        "country_id": "",
        "lon": "119.75"
      },
      {
        "city": "Hails",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "41.45",
        "country": "China",
        "country_id": "",
        "lon": "106.38"
      },
      {
        "city": "Hailun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.43",
        "country": "China",
        "country_id": "",
        "lon": "126.97"
      },
      {
        "city": "Haiyang",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.77",
        "country": "China",
        "country_id": "",
        "lon": "121.17"
      },
      {
        "city": "Haiyang Dao",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.05",
        "country": "China",
        "country_id": "",
        "lon": "123.22"
      },
      {
        "city": "Haliut",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "41.57",
        "country": "China",
        "country_id": "",
        "lon": "108.52"
      },
      {
        "city": "Hami",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "42.82",
        "country": "China",
        "country_id": "",
        "lon": "93.52"
      },
      {
        "city": "Hangzhou",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.23",
        "country": "China",
        "country_id": "",
        "lon": "120.17"
      },
      {
        "city": "Hanzhong",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33.07",
        "country": "China",
        "country_id": "",
        "lon": "107.03"
      },
      {
        "city": "Harbin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.75",
        "country": "China",
        "country_id": "",
        "lon": "126.77"
      },
      {
        "city": "Hechi",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.7",
        "country": "China",
        "country_id": "",
        "lon": "108.05"
      },
      {
        "city": "Hefei",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.87",
        "country": "China",
        "country_id": "",
        "lon": "117.23"
      },
      {
        "city": "Henan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "34.73",
        "country": "China",
        "country_id": "",
        "lon": "101.6"
      },
      {
        "city": "Hequ",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "39.38",
        "country": "China",
        "country_id": "",
        "lon": "111.15"
      },
      {
        "city": "Heyuan",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.8",
        "country": "China",
        "country_id": "",
        "lon": "114.73"
      },
      {
        "city": "Heze/Caozhou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.25",
        "country": "China",
        "country_id": "",
        "lon": "115.43"
      },
      {
        "city": "Hezuo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "35",
        "country": "China",
        "country_id": "",
        "lon": "102.9"
      },
      {
        "city": "Hoboksar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.78",
        "country": "China",
        "country_id": "",
        "lon": "85.72"
      },
      {
        "city": "Hohhot",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "40.82",
        "country": "China",
        "country_id": "",
        "lon": "111.68"
      },
      {
        "city": "Hong Kong Internati",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.32",
        "country": "China",
        "country_id": "",
        "lon": "113.92"
      },
      {
        "city": "Hong Kong Observato",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.3",
        "country": "China",
        "country_id": "",
        "lon": "114.17"
      },
      {
        "city": "Hotan",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "37.13",
        "country": "China",
        "country_id": "",
        "lon": "79.93"
      },
      {
        "city": "Hua Shan",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "34.48",
        "country": "China",
        "country_id": "",
        "lon": "110.08"
      },
      {
        "city": "Huade",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.9",
        "country": "China",
        "country_id": "",
        "lon": "114"
      },
      {
        "city": "Huadian",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "42.98",
        "country": "China",
        "country_id": "",
        "lon": "126.75"
      },
      {
        "city": "Huailai",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "40.4",
        "country": "China",
        "country_id": "",
        "lon": "115.5"
      },
      {
        "city": "Huajialing",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "35.38",
        "country": "China",
        "country_id": "",
        "lon": "105"
      },
      {
        "city": "Huang Shan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "30.13",
        "country": "China",
        "country_id": "",
        "lon": "118.15"
      },
      {
        "city": "Huili",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "26.65",
        "country": "China",
        "country_id": "",
        "lon": "102.25"
      },
      {
        "city": "Huimin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.5",
        "country": "China",
        "country_id": "",
        "lon": "117.53"
      },
      {
        "city": "Huize",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.42",
        "country": "China",
        "country_id": "",
        "lon": "103.28"
      },
      {
        "city": "Hulin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.77",
        "country": "China",
        "country_id": "",
        "lon": "132.97"
      },
      {
        "city": "Huma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.72",
        "country": "China",
        "country_id": "",
        "lon": "126.65"
      },
      {
        "city": "Huoshan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.4",
        "country": "China",
        "country_id": "",
        "lon": "116.33"
      },
      {
        "city": "Jartai",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "39.78",
        "country": "China",
        "country_id": "",
        "lon": "105.75"
      },
      {
        "city": "Jarud Qi",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "44.57",
        "country": "China",
        "country_id": "",
        "lon": "120.9"
      },
      {
        "city": "Jian",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "41.1",
        "country": "China",
        "country_id": "",
        "lon": "126.15"
      },
      {
        "city": "Jian",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "27.12",
        "country": "China",
        "country_id": "",
        "lon": "114.97"
      },
      {
        "city": "Jiangcheng",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.62",
        "country": "China",
        "country_id": "",
        "lon": "101.82"
      },
      {
        "city": "Jiangling",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.33",
        "country": "China",
        "country_id": "",
        "lon": "112.18"
      },
      {
        "city": "Jiexiu",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.03",
        "country": "China",
        "country_id": "",
        "lon": "111.92"
      },
      {
        "city": "Jinan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.6",
        "country": "China",
        "country_id": "",
        "lon": "117.05"
      },
      {
        "city": "Jingdezhen",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.3",
        "country": "China",
        "country_id": "",
        "lon": "117.2"
      },
      {
        "city": "Jinghe",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "44.62",
        "country": "China",
        "country_id": "",
        "lon": "82.9"
      },
      {
        "city": "Jinghong",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22",
        "country": "China",
        "country_id": "",
        "lon": "100.78"
      },
      {
        "city": "Jining",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.03",
        "country": "China",
        "country_id": "",
        "lon": "113.07"
      },
      {
        "city": "Jinzhou",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.13",
        "country": "China",
        "country_id": "",
        "lon": "121.12"
      },
      {
        "city": "Jiulong",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "29",
        "country": "China",
        "country_id": "",
        "lon": "101.5"
      },
      {
        "city": "Jiuquan",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "39.77",
        "country": "China",
        "country_id": "",
        "lon": "98.48"
      },
      {
        "city": "Jiuxian Shan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "25.72",
        "country": "China",
        "country_id": "",
        "lon": "118.1"
      },
      {
        "city": "Jixi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.28",
        "country": "China",
        "country_id": "",
        "lon": "130.95"
      },
      {
        "city": "Jurh",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "42.4",
        "country": "China",
        "country_id": "",
        "lon": "112.9"
      },
      {
        "city": "Kaba He",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "48.05",
        "country": "China",
        "country_id": "",
        "lon": "86.35"
      },
      {
        "city": "Kangding",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "30.05",
        "country": "China",
        "country_id": "",
        "lon": "101.97"
      },
      {
        "city": "Karamay",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "45.6",
        "country": "China",
        "country_id": "",
        "lon": "84.85"
      },
      {
        "city": "Kashi",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "39.47",
        "country": "China",
        "country_id": "",
        "lon": "75.98"
      },
      {
        "city": "Keshan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.05",
        "country": "China",
        "country_id": "",
        "lon": "125.88"
      },
      {
        "city": "Korla",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "41.75",
        "country": "China",
        "country_id": "",
        "lon": "86.13"
      },
      {
        "city": "Kuandian",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "40.72",
        "country": "China",
        "country_id": "",
        "lon": "124.78"
      },
      {
        "city": "Kunming",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "25.02",
        "country": "China",
        "country_id": "",
        "lon": "102.68"
      },
      {
        "city": "Kuocang Shan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "28.82",
        "country": "China",
        "country_id": "",
        "lon": "120.92"
      },
      {
        "city": "Kuqa",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "41.72",
        "country": "China",
        "country_id": "",
        "lon": "82.95"
      },
      {
        "city": "Lancang",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.57",
        "country": "China",
        "country_id": "",
        "lon": "99.93"
      },
      {
        "city": "Langzhong",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.58",
        "country": "China",
        "country_id": "",
        "lon": "105.97"
      },
      {
        "city": "Lanzhou",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "36.05",
        "country": "China",
        "country_id": "",
        "lon": "103.88"
      },
      {
        "city": "Lenghu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "38.83",
        "country": "China",
        "country_id": "",
        "lon": "93.38"
      },
      {
        "city": "Leting",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.43",
        "country": "China",
        "country_id": "",
        "lon": "118.9"
      },
      {
        "city": "Lhasa",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "29.67",
        "country": "China",
        "country_id": "",
        "lon": "91.13"
      },
      {
        "city": "Lhunze",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "28.42",
        "country": "China",
        "country_id": "",
        "lon": "92.47"
      },
      {
        "city": "Lian Xian",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.78",
        "country": "China",
        "country_id": "",
        "lon": "112.38"
      },
      {
        "city": "Liangping",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.68",
        "country": "China",
        "country_id": "",
        "lon": "107.8"
      },
      {
        "city": "Lianping",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.37",
        "country": "China",
        "country_id": "",
        "lon": "114.48"
      },
      {
        "city": "Lijing",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "26.83",
        "country": "China",
        "country_id": "",
        "lon": "100.47"
      },
      {
        "city": "Lincang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "23.95",
        "country": "China",
        "country_id": "",
        "lon": "100.22"
      },
      {
        "city": "Lindong",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "43.98",
        "country": "China",
        "country_id": "",
        "lon": "119.4"
      },
      {
        "city": "Lingling",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.23",
        "country": "China",
        "country_id": "",
        "lon": "111.62"
      },
      {
        "city": "Lingxian",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "37.33",
        "country": "China",
        "country_id": "",
        "lon": "116.57"
      },
      {
        "city": "Linhai",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.85",
        "country": "China",
        "country_id": "",
        "lon": "121.13"
      },
      {
        "city": "Linhe",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "40.77",
        "country": "China",
        "country_id": "",
        "lon": "107.4"
      },
      {
        "city": "Linjiang",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.72",
        "country": "China",
        "country_id": "",
        "lon": "126.92"
      },
      {
        "city": "Linxi",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "43.6",
        "country": "China",
        "country_id": "",
        "lon": "118.07"
      },
      {
        "city": "Linyi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.05",
        "country": "China",
        "country_id": "",
        "lon": "118.35"
      },
      {
        "city": "Lishi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "37.5",
        "country": "China",
        "country_id": "",
        "lon": "111.1"
      },
      {
        "city": "Lishui",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.45",
        "country": "China",
        "country_id": "",
        "lon": "119.92"
      },
      {
        "city": "Litang",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "30",
        "country": "China",
        "country_id": "",
        "lon": "100.27"
      },
      {
        "city": "Liuzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.35",
        "country": "China",
        "country_id": "",
        "lon": "109.4"
      },
      {
        "city": "Liyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.43",
        "country": "China",
        "country_id": "",
        "lon": "119.48"
      },
      {
        "city": "Longkou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.62",
        "country": "China",
        "country_id": "",
        "lon": "120.32"
      },
      {
        "city": "Longyan",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.1",
        "country": "China",
        "country_id": "",
        "lon": "117.02"
      },
      {
        "city": "Longzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.37",
        "country": "China",
        "country_id": "",
        "lon": "106.75"
      },
      {
        "city": "Lu Shan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "29.58",
        "country": "China",
        "country_id": "",
        "lon": "115.98"
      },
      {
        "city": "Luodian",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.43",
        "country": "China",
        "country_id": "",
        "lon": "106.77"
      },
      {
        "city": "Lushi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.05",
        "country": "China",
        "country_id": "",
        "lon": "111.03"
      },
      {
        "city": "Lusi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.07",
        "country": "China",
        "country_id": "",
        "lon": "121.6"
      },
      {
        "city": "Luxi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "24.53",
        "country": "China",
        "country_id": "",
        "lon": "103.77"
      },
      {
        "city": "Luzhou",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.88",
        "country": "China",
        "country_id": "",
        "lon": "105.43"
      },
      {
        "city": "Macheng",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.18",
        "country": "China",
        "country_id": "",
        "lon": "114.97"
      },
      {
        "city": "Madoi",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "34.92",
        "country": "China",
        "country_id": "",
        "lon": "98.22"
      },
      {
        "city": "Mandal",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "42.53",
        "country": "China",
        "country_id": "",
        "lon": "110.13"
      },
      {
        "city": "Mangnai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "38.25",
        "country": "China",
        "country_id": "",
        "lon": "90.85"
      },
      {
        "city": "Mazong Shan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.8",
        "country": "China",
        "country_id": "",
        "lon": "97.03"
      },
      {
        "city": "Mei Xian",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.3",
        "country": "China",
        "country_id": "",
        "lon": "116.12"
      },
      {
        "city": "Mengding",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.57",
        "country": "China",
        "country_id": "",
        "lon": "99.08"
      },
      {
        "city": "Mengjin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.82",
        "country": "China",
        "country_id": "",
        "lon": "112.43"
      },
      {
        "city": "Mengla",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.5",
        "country": "China",
        "country_id": "",
        "lon": "101.58"
      },
      {
        "city": "Mengshan",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.2",
        "country": "China",
        "country_id": "",
        "lon": "110.52"
      },
      {
        "city": "Mengzi",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.38",
        "country": "China",
        "country_id": "",
        "lon": "103.38"
      },
      {
        "city": "Mianyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.45",
        "country": "China",
        "country_id": "",
        "lon": "104.73"
      },
      {
        "city": "Minfeng",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.07",
        "country": "China",
        "country_id": "",
        "lon": "82.72"
      },
      {
        "city": "Minqin",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "38.63",
        "country": "China",
        "country_id": "",
        "lon": "103.08"
      },
      {
        "city": "Mohe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.13",
        "country": "China",
        "country_id": "",
        "lon": "122.52"
      },
      {
        "city": "Mudanjiang",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.57",
        "country": "China",
        "country_id": "",
        "lon": "129.6"
      },
      {
        "city": "Nagqu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "31.48",
        "country": "China",
        "country_id": "",
        "lon": "92.07"
      },
      {
        "city": "Nanchang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.6",
        "country": "China",
        "country_id": "",
        "lon": "115.92"
      },
      {
        "city": "Nancheng",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.58",
        "country": "China",
        "country_id": "",
        "lon": "116.65"
      },
      {
        "city": "Nanchong",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.8",
        "country": "China",
        "country_id": "",
        "lon": "106.08"
      },
      {
        "city": "Nanjing",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32",
        "country": "China",
        "country_id": "",
        "lon": "118.8"
      },
      {
        "city": "Nanning",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.82",
        "country": "China",
        "country_id": "",
        "lon": "108.35"
      },
      {
        "city": "Nanping",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.63",
        "country": "China",
        "country_id": "",
        "lon": "118"
      },
      {
        "city": "Nanyang",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33.03",
        "country": "China",
        "country_id": "",
        "lon": "112.58"
      },
      {
        "city": "Nanyue",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "27.3",
        "country": "China",
        "country_id": "",
        "lon": "112.7"
      },
      {
        "city": "Napo",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.3",
        "country": "China",
        "country_id": "",
        "lon": "105.95"
      },
      {
        "city": "Naran Bulag",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.62",
        "country": "China",
        "country_id": "",
        "lon": "114.15"
      },
      {
        "city": "Neijiang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.58",
        "country": "China",
        "country_id": "",
        "lon": "105.05"
      },
      {
        "city": "Nenjiang",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.17",
        "country": "China",
        "country_id": "",
        "lon": "125.23"
      },
      {
        "city": "Nyingchi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "29.57",
        "country": "China",
        "country_id": "",
        "lon": "94.47"
      },
      {
        "city": "Otog Qi",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "39.1",
        "country": "China",
        "country_id": "",
        "lon": "107.98"
      },
      {
        "city": "Pagri",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "27.73",
        "country": "China",
        "country_id": "",
        "lon": "89.08"
      },
      {
        "city": "Pingliang",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "35.55",
        "country": "China",
        "country_id": "",
        "lon": "106.67"
      },
      {
        "city": "Pingtan",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.52",
        "country": "China",
        "country_id": "",
        "lon": "119.78"
      },
      {
        "city": "Pingwu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.42",
        "country": "China",
        "country_id": "",
        "lon": "104.52"
      },
      {
        "city": "Pishan",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "37.62",
        "country": "China",
        "country_id": "",
        "lon": "78.28"
      },
      {
        "city": "Potou",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.08",
        "country": "China",
        "country_id": "",
        "lon": "116.55"
      },
      {
        "city": "Pucheng",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.92",
        "country": "China",
        "country_id": "",
        "lon": "118.53"
      },
      {
        "city": "Qamdo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "31.15",
        "country": "China",
        "country_id": "",
        "lon": "97.17"
      },
      {
        "city": "Qian Gorlos",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "45.08",
        "country": "China",
        "country_id": "",
        "lon": "124.87"
      },
      {
        "city": "Qiemo/Qarqan",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "38.15",
        "country": "China",
        "country_id": "",
        "lon": "85.55"
      },
      {
        "city": "Qingdao",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.07",
        "country": "China",
        "country_id": "",
        "lon": "120.33"
      },
      {
        "city": "Qingjiang",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33.6",
        "country": "China",
        "country_id": "",
        "lon": "119.03"
      },
      {
        "city": "Qinglong",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.4",
        "country": "China",
        "country_id": "",
        "lon": "118.95"
      },
      {
        "city": "Qingyuan",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.1",
        "country": "China",
        "country_id": "",
        "lon": "124.95"
      },
      {
        "city": "Qinzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.95",
        "country": "China",
        "country_id": "",
        "lon": "108.62"
      },
      {
        "city": "Qionghai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.23",
        "country": "China",
        "country_id": "",
        "lon": "110.47"
      },
      {
        "city": "Qiqihar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.38",
        "country": "China",
        "country_id": "",
        "lon": "123.92"
      },
      {
        "city": "Qitai",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "44.02",
        "country": "China",
        "country_id": "",
        "lon": "89.57"
      },
      {
        "city": "Qixian Shan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "27.95",
        "country": "China",
        "country_id": "",
        "lon": "117.83"
      },
      {
        "city": "Qu Xian",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.97",
        "country": "China",
        "country_id": "",
        "lon": "118.87"
      },
      {
        "city": "Qumarleb",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "34.13",
        "country": "China",
        "country_id": "",
        "lon": "95.78"
      },
      {
        "city": "Rizhao",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.43",
        "country": "China",
        "country_id": "",
        "lon": "119.53"
      },
      {
        "city": "Rongjiang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.97",
        "country": "China",
        "country_id": "",
        "lon": "108.53"
      },
      {
        "city": "Ruili",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.02",
        "country": "China",
        "country_id": "",
        "lon": "97.83"
      },
      {
        "city": "Ruoergai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "33.58",
        "country": "China",
        "country_id": "",
        "lon": "102.97"
      },
      {
        "city": "Ruoqiang",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "39.03",
        "country": "China",
        "country_id": "",
        "lon": "88.17"
      },
      {
        "city": "Sangzhi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.4",
        "country": "China",
        "country_id": "",
        "lon": "110.17"
      },
      {
        "city": "Sanhu Dao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.53",
        "country": "China",
        "country_id": "",
        "lon": "111.62"
      },
      {
        "city": "Sansui",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.97",
        "country": "China",
        "country_id": "",
        "lon": "108.67"
      },
      {
        "city": "Sertar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "32.28",
        "country": "China",
        "country_id": "",
        "lon": "100.33"
      },
      {
        "city": "Shache",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.43",
        "country": "China",
        "country_id": "",
        "lon": "77.27"
      },
      {
        "city": "Shangchuan Dao",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.73",
        "country": "China",
        "country_id": "",
        "lon": "112.77"
      },
      {
        "city": "Shanghai",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.4",
        "country": "China",
        "country_id": "",
        "lon": "121.47"
      },
      {
        "city": "Shanghai/Hongqiao",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.17",
        "country": "China",
        "country_id": "",
        "lon": "121.43"
      },
      {
        "city": "Shangzhi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.22",
        "country": "China",
        "country_id": "",
        "lon": "127.97"
      },
      {
        "city": "Shantou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.4",
        "country": "China",
        "country_id": "",
        "lon": "116.68"
      },
      {
        "city": "Shanwei",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.78",
        "country": "China",
        "country_id": "",
        "lon": "115.37"
      },
      {
        "city": "Shaoguan",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.8",
        "country": "China",
        "country_id": "",
        "lon": "113.58"
      },
      {
        "city": "Shaowu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.33",
        "country": "China",
        "country_id": "",
        "lon": "117.47"
      },
      {
        "city": "Shaoyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.23",
        "country": "China",
        "country_id": "",
        "lon": "111.47"
      },
      {
        "city": "Sheng Shang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.72",
        "country": "China",
        "country_id": "",
        "lon": "122.82"
      },
      {
        "city": "Shengsi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.73",
        "country": "China",
        "country_id": "",
        "lon": "122.45"
      },
      {
        "city": "Shengxian",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.6",
        "country": "China",
        "country_id": "",
        "lon": "120.82"
      },
      {
        "city": "Shenyang",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.73",
        "country": "China",
        "country_id": "",
        "lon": "123.52"
      },
      {
        "city": "Shenzhen",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.55",
        "country": "China",
        "country_id": "",
        "lon": "114.1"
      },
      {
        "city": "Sheyang",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33.77",
        "country": "China",
        "country_id": "",
        "lon": "120.25"
      },
      {
        "city": "Shijiazhuang",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.03",
        "country": "China",
        "country_id": "",
        "lon": "114.42"
      },
      {
        "city": "Shipu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.2",
        "country": "China",
        "country_id": "",
        "lon": "121.95"
      },
      {
        "city": "Shiquanhe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "32.5",
        "country": "China",
        "country_id": "",
        "lon": "80.08"
      },
      {
        "city": "Shisanjianfang",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "43.22",
        "country": "China",
        "country_id": "",
        "lon": "91.73"
      },
      {
        "city": "Simao",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.77",
        "country": "China",
        "country_id": "",
        "lon": "100.98"
      },
      {
        "city": "Sinan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.95",
        "country": "China",
        "country_id": "",
        "lon": "108.25"
      },
      {
        "city": "Siping",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.18",
        "country": "China",
        "country_id": "",
        "lon": "124.33"
      },
      {
        "city": "Sog Xian",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "31.88",
        "country": "China",
        "country_id": "",
        "lon": "93.78"
      },
      {
        "city": "Songpan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "32.65",
        "country": "China",
        "country_id": "",
        "lon": "103.57"
      },
      {
        "city": "Suifenhe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.38",
        "country": "China",
        "country_id": "",
        "lon": "131.15"
      },
      {
        "city": "Sunwu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.43",
        "country": "China",
        "country_id": "",
        "lon": "127.35"
      },
      {
        "city": "Tacheng",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "46.73",
        "country": "China",
        "country_id": "",
        "lon": "83"
      },
      {
        "city": "Tai Shan",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "36.25",
        "country": "China",
        "country_id": "",
        "lon": "117.1"
      },
      {
        "city": "Tailai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.4",
        "country": "China",
        "country_id": "",
        "lon": "123.42"
      },
      {
        "city": "Taishan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27",
        "country": "China",
        "country_id": "",
        "lon": "120.7"
      },
      {
        "city": "Taiyuan",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.78",
        "country": "China",
        "country_id": "",
        "lon": "112.55"
      },
      {
        "city": "Tangshan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.67",
        "country": "China",
        "country_id": "",
        "lon": "118.15"
      },
      {
        "city": "Taoxian",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.63",
        "country": "China",
        "country_id": "",
        "lon": "123.48"
      },
      {
        "city": "Tengchong",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.12",
        "country": "China",
        "country_id": "",
        "lon": "98.48"
      },
      {
        "city": "Tianjin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.1",
        "country": "China",
        "country_id": "",
        "lon": "117.17"
      },
      {
        "city": "Tianmu Shan (Mtns)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "30.35",
        "country": "China",
        "country_id": "",
        "lon": "119.42"
      },
      {
        "city": "Tianshui",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.58",
        "country": "China",
        "country_id": "",
        "lon": "105.75"
      },
      {
        "city": "Tikanlik",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "40.63",
        "country": "China",
        "country_id": "",
        "lon": "87.7"
      },
      {
        "city": "Tingri",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "28.63",
        "country": "China",
        "country_id": "",
        "lon": "87.08"
      },
      {
        "city": "Tongchuan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "35.17",
        "country": "China",
        "country_id": "",
        "lon": "109.05"
      },
      {
        "city": "Tongdao",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.17",
        "country": "China",
        "country_id": "",
        "lon": "109.78"
      },
      {
        "city": "Tongde",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "35.27",
        "country": "China",
        "country_id": "",
        "lon": "100.65"
      },
      {
        "city": "Tonghe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.97",
        "country": "China",
        "country_id": "",
        "lon": "128.73"
      },
      {
        "city": "Tongliao",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "43.6",
        "country": "China",
        "country_id": "",
        "lon": "122.27"
      },
      {
        "city": "Truong Sa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.65",
        "country": "China",
        "country_id": "",
        "lon": "111.92"
      },
      {
        "city": "Tulihe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.45",
        "country": "China",
        "country_id": "",
        "lon": "121.7"
      },
      {
        "city": "Tuotuohe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "34.22",
        "country": "China",
        "country_id": "",
        "lon": "92.43"
      },
      {
        "city": "Turpan",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "42.93",
        "country": "China",
        "country_id": "",
        "lon": "89.2"
      },
      {
        "city": "Uliastai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.52",
        "country": "China",
        "country_id": "",
        "lon": "116.97"
      },
      {
        "city": "Wanyuan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.07",
        "country": "China",
        "country_id": "",
        "lon": "108.03"
      },
      {
        "city": "Weichang",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.93",
        "country": "China",
        "country_id": "",
        "lon": "117.75"
      },
      {
        "city": "Weifang",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.77",
        "country": "China",
        "country_id": "",
        "lon": "119.18"
      },
      {
        "city": "Weining",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "26.87",
        "country": "China",
        "country_id": "",
        "lon": "104.28"
      },
      {
        "city": "Wenzhou",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.02",
        "country": "China",
        "country_id": "",
        "lon": "120.67"
      },
      {
        "city": "Wu Lu Mu Qi",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "43.8",
        "country": "China",
        "country_id": "",
        "lon": "87.65"
      },
      {
        "city": "Wudaoliang",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "35.22",
        "country": "China",
        "country_id": "",
        "lon": "93.08"
      },
      {
        "city": "Wudu",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "33.4",
        "country": "China",
        "country_id": "",
        "lon": "104.92"
      },
      {
        "city": "Wugang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.73",
        "country": "China",
        "country_id": "",
        "lon": "110.63"
      },
      {
        "city": "Wuhan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.62",
        "country": "China",
        "country_id": "",
        "lon": "114.13"
      },
      {
        "city": "Wuhu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.33",
        "country": "China",
        "country_id": "",
        "lon": "118.35"
      },
      {
        "city": "Wushaoling",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "37.2",
        "country": "China",
        "country_id": "",
        "lon": "102.87"
      },
      {
        "city": "Wutai Shan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "38.95",
        "country": "China",
        "country_id": "",
        "lon": "113.52"
      },
      {
        "city": "Wuyishan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.77",
        "country": "China",
        "country_id": "",
        "lon": "118.03"
      },
      {
        "city": "Wuzhou",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.48",
        "country": "China",
        "country_id": "",
        "lon": "111.3"
      },
      {
        "city": "Xainza",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "30.95",
        "country": "China",
        "country_id": "",
        "lon": "88.63"
      },
      {
        "city": "Xi Ujimqin Qi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.58",
        "country": "China",
        "country_id": "",
        "lon": "117.6"
      },
      {
        "city": "Xiamen",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.48",
        "country": "China",
        "country_id": "",
        "lon": "118.08"
      },
      {
        "city": "Xian",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "34.3",
        "country": "China",
        "country_id": "",
        "lon": "108.93"
      },
      {
        "city": "Xiaoergou",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.2",
        "country": "China",
        "country_id": "",
        "lon": "123.72"
      },
      {
        "city": "Xichang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.9",
        "country": "China",
        "country_id": "",
        "lon": "102.27"
      },
      {
        "city": "Xifengzhen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "35.73",
        "country": "China",
        "country_id": "",
        "lon": "107.63"
      },
      {
        "city": "Xigaze",
        "state": "",
        "cz": "6C",
        "state_id": "",
        "lat": "29.25",
        "country": "China",
        "country_id": "",
        "lon": "88.88"
      },
      {
        "city": "Xihua",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33.78",
        "country": "China",
        "country_id": "",
        "lon": "114.52"
      },
      {
        "city": "Xilin Hot",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.95",
        "country": "China",
        "country_id": "",
        "lon": "116.12"
      },
      {
        "city": "Xin Barag Youqi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.67",
        "country": "China",
        "country_id": "",
        "lon": "116.82"
      },
      {
        "city": "Xingren",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "25.43",
        "country": "China",
        "country_id": "",
        "lon": "105.18"
      },
      {
        "city": "Xingtai",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "37.07",
        "country": "China",
        "country_id": "",
        "lon": "114.5"
      },
      {
        "city": "Xining",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "36.62",
        "country": "China",
        "country_id": "",
        "lon": "101.77"
      },
      {
        "city": "Xinxian",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.23",
        "country": "China",
        "country_id": "",
        "lon": "115.67"
      },
      {
        "city": "Xinyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.13",
        "country": "China",
        "country_id": "",
        "lon": "114.05"
      },
      {
        "city": "Xinyi",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.35",
        "country": "China",
        "country_id": "",
        "lon": "110.93"
      },
      {
        "city": "Xisha Dao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.83",
        "country": "China",
        "country_id": "",
        "lon": "112.33"
      },
      {
        "city": "Xiushui",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.03",
        "country": "China",
        "country_id": "",
        "lon": "114.58"
      },
      {
        "city": "Xunwu",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.95",
        "country": "China",
        "country_id": "",
        "lon": "115.65"
      },
      {
        "city": "Xuzhou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.28",
        "country": "China",
        "country_id": "",
        "lon": "117.15"
      },
      {
        "city": "Yaan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.98",
        "country": "China",
        "country_id": "",
        "lon": "103"
      },
      {
        "city": "Yan An",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "36.6",
        "country": "China",
        "country_id": "",
        "lon": "109.5"
      },
      {
        "city": "Yanchi",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.8",
        "country": "China",
        "country_id": "",
        "lon": "107.38"
      },
      {
        "city": "Yangcheng",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.48",
        "country": "China",
        "country_id": "",
        "lon": "112.4"
      },
      {
        "city": "Yangjiang",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.87",
        "country": "China",
        "country_id": "",
        "lon": "111.97"
      },
      {
        "city": "Yanji",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.87",
        "country": "China",
        "country_id": "",
        "lon": "129.5"
      },
      {
        "city": "Yanzhou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.57",
        "country": "China",
        "country_id": "",
        "lon": "116.85"
      },
      {
        "city": "Yaxian",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.23",
        "country": "China",
        "country_id": "",
        "lon": "109.52"
      },
      {
        "city": "Yibin",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.8",
        "country": "China",
        "country_id": "",
        "lon": "104.6"
      },
      {
        "city": "Yichang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "30.7",
        "country": "China",
        "country_id": "",
        "lon": "111.3"
      },
      {
        "city": "Yichun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.72",
        "country": "China",
        "country_id": "",
        "lon": "128.9"
      },
      {
        "city": "Yichun",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.8",
        "country": "China",
        "country_id": "",
        "lon": "114.38"
      },
      {
        "city": "Yinchuan",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "38.47",
        "country": "China",
        "country_id": "",
        "lon": "106.2"
      },
      {
        "city": "Yingkou",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.67",
        "country": "China",
        "country_id": "",
        "lon": "122.2"
      },
      {
        "city": "Yining",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "43.95",
        "country": "China",
        "country_id": "",
        "lon": "81.33"
      },
      {
        "city": "Yiwu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.27",
        "country": "China",
        "country_id": "",
        "lon": "94.7"
      },
      {
        "city": "Yiyuan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.18",
        "country": "China",
        "country_id": "",
        "lon": "118.15"
      },
      {
        "city": "Yongan",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.97",
        "country": "China",
        "country_id": "",
        "lon": "117.35"
      },
      {
        "city": "Youyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.83",
        "country": "China",
        "country_id": "",
        "lon": "108.77"
      },
      {
        "city": "Yu Xian",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "39.83",
        "country": "China",
        "country_id": "",
        "lon": "114.57"
      },
      {
        "city": "Yuanjiang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.6",
        "country": "China",
        "country_id": "",
        "lon": "101.98"
      },
      {
        "city": "Yuanling",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "28.47",
        "country": "China",
        "country_id": "",
        "lon": "110.4"
      },
      {
        "city": "Yuanmou",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "25.73",
        "country": "China",
        "country_id": "",
        "lon": "101.87"
      },
      {
        "city": "Yuanping",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "38.75",
        "country": "China",
        "country_id": "",
        "lon": "112.7"
      },
      {
        "city": "Yueyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.38",
        "country": "China",
        "country_id": "",
        "lon": "113.08"
      },
      {
        "city": "Yulin",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "38.23",
        "country": "China",
        "country_id": "",
        "lon": "109.7"
      },
      {
        "city": "Yumenzhen",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "40.27",
        "country": "China",
        "country_id": "",
        "lon": "97.03"
      },
      {
        "city": "Yumenzhen",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "40.27",
        "country": "China",
        "country_id": "",
        "lon": "97.03"
      },
      {
        "city": "Yuncheng",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "35.05",
        "country": "China",
        "country_id": "",
        "lon": "111.05"
      },
      {
        "city": "Yushe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "37.07",
        "country": "China",
        "country_id": "",
        "lon": "112.98"
      },
      {
        "city": "Yushu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "33.02",
        "country": "China",
        "country_id": "",
        "lon": "97.02"
      },
      {
        "city": "Yutian/Keriya",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "36.87",
        "country": "China",
        "country_id": "",
        "lon": "81.7"
      },
      {
        "city": "Zadoi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "32.9",
        "country": "China",
        "country_id": "",
        "lon": "95.3"
      },
      {
        "city": "Zaoyang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.15",
        "country": "China",
        "country_id": "",
        "lon": "112.67"
      },
      {
        "city": "Zhang Ping",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.3",
        "country": "China",
        "country_id": "",
        "lon": "117.4"
      },
      {
        "city": "Zhangjiakou",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "40.78",
        "country": "China",
        "country_id": "",
        "lon": "114.88"
      },
      {
        "city": "Zhangwu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.42",
        "country": "China",
        "country_id": "",
        "lon": "122.53"
      },
      {
        "city": "Zhangye",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "38.93",
        "country": "China",
        "country_id": "",
        "lon": "100.43"
      },
      {
        "city": "Zhanjiang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.22",
        "country": "China",
        "country_id": "",
        "lon": "110.4"
      },
      {
        "city": "Zhanyi",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "25.58",
        "country": "China",
        "country_id": "",
        "lon": "103.83"
      },
      {
        "city": "Zhaotong",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "27.33",
        "country": "China",
        "country_id": "",
        "lon": "103.75"
      },
      {
        "city": "Zhengzhou",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.72",
        "country": "China",
        "country_id": "",
        "lon": "113.65"
      },
      {
        "city": "Zhijiang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.45",
        "country": "China",
        "country_id": "",
        "lon": "109.68"
      },
      {
        "city": "Zhongning",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.48",
        "country": "China",
        "country_id": "",
        "lon": "105.68"
      },
      {
        "city": "Zhongxiang",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.17",
        "country": "China",
        "country_id": "",
        "lon": "112.57"
      },
      {
        "city": "Zhumadian",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "33",
        "country": "China",
        "country_id": "",
        "lon": "114.02"
      },
      {
        "city": "Zunyi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "27.7",
        "country": "China",
        "country_id": "",
        "lon": "106.88"
      }
    ]
  },
  {
    "country": "Christmas Island",
    "cities": [
      {
        "city": "Christmas Island Ae",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-10.45",
        "country": "Christmas Island",
        "country_id": "CRX",
        "lon": "105.68"
      }
    ]
  },
  {
    "country": "Cocos (Keeling) Islands",
    "cities": [
      {
        "city": "Cocos Island Aero",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.18",
        "country": "Cocos (Keeling) Islands",
        "country_id": "CCK",
        "lon": "96.83"
      }
    ]
  },
  {
    "country": "Colombia",
    "cities": [
      {
        "city": "Barranquilla/Ernest",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.88",
        "country": "Colombia",
        "country_id": "COL",
        "lon": "-74.78"
      },
      {
        "city": "Bogota/Eldorado",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "4.7",
        "country": "Colombia",
        "country_id": "COL",
        "lon": "-74.13"
      },
      {
        "city": "Cali/Alfonso Bonill",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "3.55",
        "country": "Colombia",
        "country_id": "COL",
        "lon": "-76.38"
      },
      {
        "city": "Cartagena/Rafael Nu",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.45",
        "country": "Colombia",
        "country_id": "COL",
        "lon": "-75.52"
      },
      {
        "city": "Rionegro/J.M.Cordov",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "6.13",
        "country": "Colombia",
        "country_id": "COL",
        "lon": "-75.43"
      }
    ]
  },
  {
    "country": "Congo",
    "cities": [
      {
        "city": "Brazzaville/Maya-M",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-4.25",
        "country": "Congo",
        "country_id": "COG",
        "lon": "15.25"
      }
    ]
  },
  {
    "country": "Cook Islands",
    "cities": [
      {
        "city": "Amuri/Aitutaki Isl",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.83",
        "country": "Cook Islands",
        "country_id": "COK",
        "lon": "-159.77"
      },
      {
        "city": "Mangaia Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-21.92",
        "country": "Cook Islands",
        "country_id": "COK",
        "lon": "-157.95"
      },
      {
        "city": "Mauke Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.13",
        "country": "Cook Islands",
        "country_id": "COK",
        "lon": "-157.35"
      },
      {
        "city": "Penrhyn Island",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-9.02",
        "country": "Cook Islands",
        "country_id": "COK",
        "lon": "-158.07"
      },
      {
        "city": "Pukapuka",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-10.88",
        "country": "Cook Islands",
        "country_id": "COK",
        "lon": "-165.82"
      },
      {
        "city": "Rarotonga",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-21.2",
        "country": "Cook Islands",
        "country_id": "COK",
        "lon": "-159.82"
      }
    ]
  },
  {
    "country": "Costa Rica",
    "cities": [
      {
        "city": "Juan Santamaria Int",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "9.98",
        "country": "Costa Rica",
        "country_id": "CRI",
        "lon": "-84.22"
      }
    ]
  },
  {
    "country": "Côte D'Ivoire",
    "cities": [
      {
        "city": "Abidjan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "5.25",
        "country": "Côte D'Ivoire",
        "country_id": "CIV",
        "lon": "-3.93"
      }
    ]
  },
  {
    "country": "Croatia",
    "cities": [
      {
        "city": "Daruvar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.6",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "17.23"
      },
      {
        "city": "Dubrovnik-Cilipi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.57",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "18.27"
      },
      {
        "city": "Gospic",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.55",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "15.37"
      },
      {
        "city": "Pula Aerodrome",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.9",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "13.92"
      },
      {
        "city": "Split/Marjan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.52",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "16.43"
      },
      {
        "city": "Split/Resnik",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.53",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "16.3"
      },
      {
        "city": "Zadar/Zemunik",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "44.1",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "15.35"
      },
      {
        "city": "Zagreb/Maksimir",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.82",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "16.03"
      },
      {
        "city": "Zagreb/Pleso",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.73",
        "country": "Croatia",
        "country_id": "HRV",
        "lon": "16.07"
      }
    ]
  },
  {
    "country": "Cuba",
    "cities": [
      {
        "city": "Aeropuerto Jose Mar",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.98",
        "country": "Cuba",
        "country_id": "CUB",
        "lon": "-82.4"
      },
      {
        "city": "Camaguey Aeropuerto",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.42",
        "country": "Cuba",
        "country_id": "CUB",
        "lon": "-77.85"
      },
      {
        "city": "Guantanamo Bay Nas",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "19.9",
        "country": "Cuba",
        "country_id": "CUB",
        "lon": "-75.22"
      },
      {
        "city": "Santiago De Cuba",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.97",
        "country": "Cuba",
        "country_id": "CUB",
        "lon": "-75.85"
      },
      {
        "city": "Varadero/Mt",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.02",
        "country": "Cuba",
        "country_id": "CUB",
        "lon": "-81.43"
      }
    ]
  },
  {
    "country": "Curaçao",
    "cities": [
      {
        "city": "Hato Arpt (Civ/Mil)",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "12.2",
        "country": "Curaçao",
        "country_id": "CUW",
        "lon": "-68.97"
      }
    ]
  },
  {
    "country": "Cyprus",
    "cities": [
      {
        "city": "Akrotiri",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.58",
        "country": "Cyprus",
        "country_id": "CYP",
        "lon": "32.98"
      },
      {
        "city": "Larnaca Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "34.88",
        "country": "Cyprus",
        "country_id": "CYP",
        "lon": "33.63"
      },
      {
        "city": "Paphos Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.72",
        "country": "Cyprus",
        "country_id": "CYP",
        "lon": "32.48"
      }
    ]
  },
  {
    "country": "Czech Republic",
    "cities": [
      {
        "city": "Brno/Turany",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.15",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.7"
      },
      {
        "city": "Caslav",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.93",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.38"
      },
      {
        "city": "Cervena",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.77",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "17.55"
      },
      {
        "city": "Ceske Budejovice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.95",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.43"
      },
      {
        "city": "Cheb",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.08",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "12.4"
      },
      {
        "city": "Churanov",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.07",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "13.62"
      },
      {
        "city": "Doksany",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.47",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.17"
      },
      {
        "city": "Dukovany",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.1",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.13"
      },
      {
        "city": "Holesov",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.32",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "17.57"
      },
      {
        "city": "Hradec Kralove",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.25",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.85"
      },
      {
        "city": "Karlovy Vary",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.2",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "12.92"
      },
      {
        "city": "Kocelovice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.47",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "13.83"
      },
      {
        "city": "Kostelni Myslova",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.18",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.47"
      },
      {
        "city": "Kresin-Kramolin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.58",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.08"
      },
      {
        "city": "Kucharovice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.88",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.08"
      },
      {
        "city": "Liberec",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.77",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.02"
      },
      {
        "city": "Luka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.65",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.95"
      },
      {
        "city": "Lysa Hora",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.55",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "18.45"
      },
      {
        "city": "Marianske Lazne",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.92",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "12.72"
      },
      {
        "city": "Milesovka",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.55",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "13.93"
      },
      {
        "city": "Namest Nad Oslav",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.17",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.12"
      },
      {
        "city": "Ostrava/Mosnov",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.68",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "18.12"
      },
      {
        "city": "Pardubice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.02",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.73"
      },
      {
        "city": "Pec Pod Snezkou",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.67",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.75"
      },
      {
        "city": "Plzen Line",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.68",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "13.27"
      },
      {
        "city": "Praded Mountain",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.07",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "17.23"
      },
      {
        "city": "Praha-Kbely",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.12",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.53"
      },
      {
        "city": "Praha-Libus",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.02",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.45"
      },
      {
        "city": "Praha/Ruzyne",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.1",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.25"
      },
      {
        "city": "Prerov",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.42",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "17.4"
      },
      {
        "city": "Pribyslav",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.58",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "15.77"
      },
      {
        "city": "Primda",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.67",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "12.67"
      },
      {
        "city": "Svratouch",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.73",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.03"
      },
      {
        "city": "Temelin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.2",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.33"
      },
      {
        "city": "Tusimice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.38",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "13.33"
      },
      {
        "city": "Usti Nad Labem",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.68",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "14.03"
      },
      {
        "city": "Usti Nad Orlici",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.98",
        "country": "Czech Republic",
        "country_id": "CZE",
        "lon": "16.43"
      }
    ]
  },
  {
    "country": "Denmark",
    "cities": [
      {
        "city": "Aalborg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.1",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "9.85"
      },
      {
        "city": "Billund",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.73",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "9.17"
      },
      {
        "city": "Blaavandshuk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.55",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "8.08"
      },
      {
        "city": "Christianso (Lgt-H)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.32",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "15.18"
      },
      {
        "city": "Drogden",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.53",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "12.72"
      },
      {
        "city": "Esbjerg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.53",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "8.57"
      },
      {
        "city": "Fornaes (Cape)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.45",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "10.97"
      },
      {
        "city": "Frederikshavn",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.4",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "10.52"
      },
      {
        "city": "Gniben",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.02",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "11.28"
      },
      {
        "city": "Hammer Odde",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.3",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "14.78"
      },
      {
        "city": "Holbaek",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.73",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "11.6"
      },
      {
        "city": "Hvide Sande",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "8.13"
      },
      {
        "city": "Karup",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.3",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "9.12"
      },
      {
        "city": "Kegnaes",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.85",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "9.98"
      },
      {
        "city": "Koebenhavn/Kastrup",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.62",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "12.65"
      },
      {
        "city": "Langoe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.82",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "11"
      },
      {
        "city": "Maribo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.7",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "11.45"
      },
      {
        "city": "Moen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.95",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "12.53"
      },
      {
        "city": "Nakkehoved",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.12",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "12.35"
      },
      {
        "city": "Odense/Beldringe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.48",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "10.33"
      },
      {
        "city": "Omoe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.17",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "11.13"
      },
      {
        "city": "Roemoe/Juvre",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.18",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "8.57"
      },
      {
        "city": "Roenne",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.07",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "14.75"
      },
      {
        "city": "Roesnaes",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.75",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "10.87"
      },
      {
        "city": "Roskilde/Tune",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.58",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "12.13"
      },
      {
        "city": "Saedenstrand",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.5",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "8.4"
      },
      {
        "city": "Skagen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.73",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "10.63"
      },
      {
        "city": "Skrydstrup",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.23",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "9.27"
      },
      {
        "city": "Thyboroen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.7",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "8.22"
      },
      {
        "city": "Tirstrup",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.32",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "10.63"
      },
      {
        "city": "Vaerloese",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.77",
        "country": "Denmark",
        "country_id": "DNK",
        "lon": "12.33"
      }
    ]
  },
  {
    "country": "Dominican Republic",
    "cities": [
      {
        "city": "Las Americas",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.43",
        "country": "Dominican Republic",
        "country_id": "DOM",
        "lon": "-69.67"
      },
      {
        "city": "Santo Domingo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.43",
        "country": "Dominican Republic",
        "country_id": "DOM",
        "lon": "-69.88"
      }
    ]
  },
  {
    "country": "Ecuador",
    "cities": [
      {
        "city": "Guayaquil Aeropuert",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-2.15",
        "country": "Ecuador",
        "country_id": "ECU",
        "lon": "-79.88"
      },
      {
        "city": "Manta",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-0.95",
        "country": "Ecuador",
        "country_id": "ECU",
        "lon": "-80.68"
      },
      {
        "city": "Quito Aeropuerto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-0.13",
        "country": "Ecuador",
        "country_id": "ECU",
        "lon": "-78.48"
      }
    ]
  },
  {
    "country": "Egypt",
    "cities": [
      {
        "city": "Alexandria/Nouzha",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.2",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "29.95"
      },
      {
        "city": "Asswan",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "23.97",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "32.78"
      },
      {
        "city": "Asyut",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "27.05",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "31.02"
      },
      {
        "city": "Baltim",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.55",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "31.1"
      },
      {
        "city": "Cairo Airport",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.13",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "31.4"
      },
      {
        "city": "Dakhla",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.48",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "29"
      },
      {
        "city": "El Arish",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.08",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "33.82"
      },
      {
        "city": "El Tor",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "28.23",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "33.62"
      },
      {
        "city": "Hurguada",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "27.15",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "33.72"
      },
      {
        "city": "Ismailia",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.6",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "32.25"
      },
      {
        "city": "Kharga",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.45",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "30.53"
      },
      {
        "city": "Kosseir",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.13",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "34.15"
      },
      {
        "city": "Luxor",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.67",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "32.7"
      },
      {
        "city": "Mersa Matruh",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.33",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "27.22"
      },
      {
        "city": "Minya",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "28.08",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "30.73"
      },
      {
        "city": "Port Said",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.27",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "32.3"
      },
      {
        "city": "Port Said/El Gamil",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.28",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "32.23"
      },
      {
        "city": "Siwa",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "29.2",
        "country": "Egypt",
        "country_id": "EGY",
        "lon": "25.32"
      }
    ]
  },
  {
    "country": "Estonia",
    "cities": [
      {
        "city": "Kunda",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.52",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "26.53"
      },
      {
        "city": "Kuressaare",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.23",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "22.5"
      },
      {
        "city": "Narva",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.37",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "28.12"
      },
      {
        "city": "Pjarnu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.37",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "24.5"
      },
      {
        "city": "Ristna",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.92",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "22.07"
      },
      {
        "city": "Tallinn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.47",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "24.82"
      },
      {
        "city": "Tartu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.3",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "26.73"
      },
      {
        "city": "Turi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.82",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "25.42"
      },
      {
        "city": "Valke-Maarja",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.13",
        "country": "Estonia",
        "country_id": "EST",
        "lon": "26.23"
      }
    ]
  },
  {
    "country": "Falkland Islands (Malvinas)",
    "cities": [
      {
        "city": "Mount Pleasant Airp",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-51.82",
        "country": "Falkland Islands (Malvinas)",
        "country_id": "FLK",
        "lon": "-58.45"
      }
    ]
  },
  {
    "country": "Faroe Islands",
    "cities": [
      {
        "city": "Akraberg",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "61.4",
        "country": "Faroe Islands",
        "country_id": "FRO",
        "lon": "-6.67"
      },
      {
        "city": "Torshavn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "62.02",
        "country": "Faroe Islands",
        "country_id": "FRO",
        "lon": "-6.77"
      }
    ]
  },
  {
    "country": "Fiji",
    "cities": [
      {
        "city": "Lakeba Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.23",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "-178.8"
      },
      {
        "city": "Matuku Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.13",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "179.75"
      },
      {
        "city": "Nadi Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.75",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "177.45"
      },
      {
        "city": "Nausori",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.05",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "178.57"
      },
      {
        "city": "Ono-I-Lau Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.67",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "-178.72"
      },
      {
        "city": "Rotuma",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.5",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "177.05"
      },
      {
        "city": "Udu Point Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.13",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "-179.98"
      },
      {
        "city": "Viwa Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.15",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "176.9"
      },
      {
        "city": "Vunisea",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.05",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "178.17"
      },
      {
        "city": "Yasawa-I-Rara Aws",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.7",
        "country": "Fiji",
        "country_id": "FJI",
        "lon": "177.58"
      }
    ]
  },
  {
    "country": "Finland",
    "cities": [
      {
        "city": "Ahtari",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.53",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24.02"
      },
      {
        "city": "Bagaskar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.93",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24.02"
      },
      {
        "city": "Halli",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.85",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24.8"
      },
      {
        "city": "Helsinki-Vantaa",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.32",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24.97"
      },
      {
        "city": "Ilomantsi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.68",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "30.95"
      },
      {
        "city": "Isosaari",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.1",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "25.07"
      },
      {
        "city": "Ivalo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "68.62",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "27.42"
      },
      {
        "city": "Joensuu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.67",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "29.63"
      },
      {
        "city": "Jokioinen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.82",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "23.5"
      },
      {
        "city": "Jomala",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.15",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "19.87"
      },
      {
        "city": "Jyvaskyla",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.4",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "25.68"
      },
      {
        "city": "Kajaani",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.28",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "27.68"
      },
      {
        "city": "Kauhava",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.1",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "23.03"
      },
      {
        "city": "Kemi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.78",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24.58"
      },
      {
        "city": "Kevo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69.75",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "27.03"
      },
      {
        "city": "Kruunupyy",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.72",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "23.15"
      },
      {
        "city": "Kumlinge Island",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.3",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "20.75"
      },
      {
        "city": "Kuopio",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.02",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "27.8"
      },
      {
        "city": "Kuusamo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.97",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "29.18"
      },
      {
        "city": "Kuuskajaskari",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "61.13",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "21.37"
      },
      {
        "city": "Lahti",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.97",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "25.63"
      },
      {
        "city": "Lappeenranta",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.05",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "28.2"
      },
      {
        "city": "Mariehamn/Aland Isl",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.12",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "19.9"
      },
      {
        "city": "Masskar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.73",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "22.58"
      },
      {
        "city": "Mikkeli",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.73",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "27.3"
      },
      {
        "city": "Moikipaa",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.88",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "21.1"
      },
      {
        "city": "Muonio",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.97",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "23.68"
      },
      {
        "city": "Niinisalo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.85",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "22.47"
      },
      {
        "city": "Nivala",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.92",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24.97"
      },
      {
        "city": "Nyhamn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.97",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "19.97"
      },
      {
        "city": "Oulu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.93",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "25.37"
      },
      {
        "city": "Pello",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.8",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "24"
      },
      {
        "city": "Pori",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "61.47",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "21.8"
      },
      {
        "city": "Pudasjarvi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.37",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "27.02"
      },
      {
        "city": "Rankki",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.37",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "26.97"
      },
      {
        "city": "Rovaniemi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.57",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "25.83"
      },
      {
        "city": "Russaro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.77",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "22.95"
      },
      {
        "city": "Salla Kk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.83",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "28.68"
      },
      {
        "city": "Savonlinna",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.95",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "28.95"
      },
      {
        "city": "Sodankyla",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "67.37",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "26.65"
      },
      {
        "city": "Suomussalmi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.9",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "29.02"
      },
      {
        "city": "Tampere/Pirkkala",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.42",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "23.58"
      },
      {
        "city": "Turku",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.52",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "22.27"
      },
      {
        "city": "Uto",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.78",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "21.38"
      },
      {
        "city": "Utti",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.9",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "26.93"
      },
      {
        "city": "Vaasa Airport",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.05",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "21.77"
      },
      {
        "city": "Valassaaret",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.43",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "21.07"
      },
      {
        "city": "Viitasaari",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.08",
        "country": "Finland",
        "country_id": "FIN",
        "lon": "25.87"
      }
    ]
  },
  {
    "country": "France",
    "cities": [
      {
        "city": "Abbeville",
        "state": "France",
        "cz": "4A",
        "state_id": "FRA",
        "lat": "50.13",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.83"
      },
      {
        "city": "Agen",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.18",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.6"
      },
      {
        "city": "Ajaccio",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.92",
        "country": "France",
        "country_id": "FRA",
        "lon": "8.8"
      },
      {
        "city": "Albi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.92",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.12"
      },
      {
        "city": "Alencon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.43",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.1"
      },
      {
        "city": "Amberieu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.98",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.33"
      },
      {
        "city": "Auch",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.68",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.6"
      },
      {
        "city": "Aurillac",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.88",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.42"
      },
      {
        "city": "Auxerre",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.8",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.55"
      },
      {
        "city": "Bale-Mulhouse",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.6",
        "country": "France",
        "country_id": "FRA",
        "lon": "7.52"
      },
      {
        "city": "Bastia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.55",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.48"
      },
      {
        "city": "Beaucouze",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.48",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.6"
      },
      {
        "city": "Beauvais",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.47",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.12"
      },
      {
        "city": "Belfort",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.63",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.88"
      },
      {
        "city": "Belle Iie Le Talut",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.3",
        "country": "France",
        "country_id": "FRA",
        "lon": "-3.17"
      },
      {
        "city": "Bergerac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.82",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.52"
      },
      {
        "city": "Besancon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.25",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.98"
      },
      {
        "city": "Biarritz",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.47",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.53"
      },
      {
        "city": "Biscarosse",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "44.43",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.25"
      },
      {
        "city": "Bordeaux Merignac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.83",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.68"
      },
      {
        "city": "Boulogne",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.73",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.6"
      },
      {
        "city": "Bourg St-Maurice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.62",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.77"
      },
      {
        "city": "Bourges",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.07",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.37"
      },
      {
        "city": "Brehat Island",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.85",
        "country": "France",
        "country_id": "FRA",
        "lon": "-3"
      },
      {
        "city": "Brest",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.45",
        "country": "France",
        "country_id": "FRA",
        "lon": "-4.42"
      },
      {
        "city": "Brignogan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.68",
        "country": "France",
        "country_id": "FRA",
        "lon": "-4.33"
      },
      {
        "city": "Brive",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.15",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.47"
      },
      {
        "city": "Caen Carpiquet",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.18",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.45"
      },
      {
        "city": "Calvi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.53",
        "country": "France",
        "country_id": "FRA",
        "lon": "8.8"
      },
      {
        "city": "Cap Bear",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.52",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.13"
      },
      {
        "city": "Cap Cepet",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.08",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.93"
      },
      {
        "city": "Cap Corse",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.35"
      },
      {
        "city": "Cap Couronne",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.33",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.05"
      },
      {
        "city": "Cap De La Heve",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.5",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.07"
      },
      {
        "city": "Cap Ferret",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "44.63",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.25"
      },
      {
        "city": "Cap Pertusato",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.37",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.17"
      },
      {
        "city": "Cap Pomegues",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.27",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.3"
      },
      {
        "city": "Cape Ferrat",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.68",
        "country": "France",
        "country_id": "FRA",
        "lon": "7.33"
      },
      {
        "city": "Cape Sagro",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.8",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.48"
      },
      {
        "city": "Carcassonne",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.22",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.32"
      },
      {
        "city": "Carpentras",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "44.08",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.05"
      },
      {
        "city": "Cazaux",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.53",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.13"
      },
      {
        "city": "Chambery/Aix-Les-Ba",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.63",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.87"
      },
      {
        "city": "Charleville",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.78",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.63"
      },
      {
        "city": "Chartres",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.47",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.5"
      },
      {
        "city": "Chassiron",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "46.05",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.42"
      },
      {
        "city": "Chateauroux/Deols",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.85",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.72"
      },
      {
        "city": "Clermont-Ferrand",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.78",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.17"
      },
      {
        "city": "Cognac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.67",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.32"
      },
      {
        "city": "Dieppe",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.93",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.1"
      },
      {
        "city": "Dijon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.27",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.08"
      },
      {
        "city": "Dinard",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.58",
        "country": "France",
        "country_id": "FRA",
        "lon": "-2.07"
      },
      {
        "city": "Dunkerque",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.05",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.33"
      },
      {
        "city": "Embrun",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.57",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.5"
      },
      {
        "city": "Evreux/Fauville Faf",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.02",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.22"
      },
      {
        "city": "Figari",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.5",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.1"
      },
      {
        "city": "Gourdon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.75",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.4"
      },
      {
        "city": "Grenoble-St-Geoirs",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.37",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.33"
      },
      {
        "city": "Grouin De Cancale",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.72",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.85"
      },
      {
        "city": "Hyeres",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.1",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.15"
      },
      {
        "city": "Ile Rousse",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.63",
        "country": "France",
        "country_id": "FRA",
        "lon": "8.92"
      },
      {
        "city": "Istres",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.52",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.92"
      },
      {
        "city": "L Iie D Yeu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.7",
        "country": "France",
        "country_id": "FRA",
        "lon": "-2.33"
      },
      {
        "city": "La Chiappa",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.6",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.37"
      },
      {
        "city": "La Roche-Sur-Yon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.7",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.38"
      },
      {
        "city": "La Rochelle",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.15",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.15"
      },
      {
        "city": "Langres",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.85",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.33"
      },
      {
        "city": "Le Bourget",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.97",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.43"
      },
      {
        "city": "Le Luc",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.38",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.38"
      },
      {
        "city": "Le Mans",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.93",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.2"
      },
      {
        "city": "Le Puy",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.08",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.77"
      },
      {
        "city": "Le Raizet/Guadeloup",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.27",
        "country": "France",
        "country_id": "FRA",
        "lon": "-61.6"
      },
      {
        "city": "Lille Lesqui",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.57",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.1"
      },
      {
        "city": "Limoges",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.87",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.18"
      },
      {
        "city": "Lons-Le-Saunier",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.68",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.52"
      },
      {
        "city": "Lorient Lan Bihoue",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.77",
        "country": "France",
        "country_id": "FRA",
        "lon": "-3.45"
      },
      {
        "city": "Luxeuil",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.8",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.38"
      },
      {
        "city": "Lyon-Bron",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.72",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.93"
      },
      {
        "city": "Lyon-Satolas",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.73",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.08"
      },
      {
        "city": "Macon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.3",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.8"
      },
      {
        "city": "Marignane",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.45",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.23"
      },
      {
        "city": "Martin De Vivies (Ile Amst.)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.8",
        "country": "France",
        "country_id": "FRA",
        "lon": "77.5"
      },
      {
        "city": "Maupertus",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.65",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.48"
      },
      {
        "city": "Melun",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.62",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.68"
      },
      {
        "city": "Metz/Frescaty",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.08",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.13"
      },
      {
        "city": "Meyenheim-Colmar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.92",
        "country": "France",
        "country_id": "FRA",
        "lon": "7.4"
      },
      {
        "city": "Millau",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.12",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.02"
      },
      {
        "city": "Mont Aigoual",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.12",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.58"
      },
      {
        "city": "Mont-De-Marsan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.92",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.5"
      },
      {
        "city": "Montelimar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.58",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.73"
      },
      {
        "city": "Montpellier",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.58",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.97"
      },
      {
        "city": "Nancy-Essey",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.68",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.22"
      },
      {
        "city": "Nancy-Ochey",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.58",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.97"
      },
      {
        "city": "Nantes",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.15",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.6"
      },
      {
        "city": "Nevers",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.1"
      },
      {
        "city": "Nice",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.65",
        "country": "France",
        "country_id": "FRA",
        "lon": "7.2"
      },
      {
        "city": "Nimes-Courbessac",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.87",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.4"
      },
      {
        "city": "Nimes/Garons (Navy)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.75",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.42"
      },
      {
        "city": "Niort",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.32",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.4"
      },
      {
        "city": "Orange",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "44.13",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.83"
      },
      {
        "city": "Orleans",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.98",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.78"
      },
      {
        "city": "Ouessant",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.48",
        "country": "France",
        "country_id": "FRA",
        "lon": "-5.05"
      },
      {
        "city": "Paris-Aeroport Char",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.02",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.53"
      },
      {
        "city": "Paris-Montsouris",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.82",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.33"
      },
      {
        "city": "Paris-Orly",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.72",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.38"
      },
      {
        "city": "Pau",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.38",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.42"
      },
      {
        "city": "Perpignan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.73",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.87"
      },
      {
        "city": "Pointe Du Raz",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.03",
        "country": "France",
        "country_id": "FRA",
        "lon": "-4.73"
      },
      {
        "city": "Poitiers",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.58",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.3"
      },
      {
        "city": "Porquerolles",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43",
        "country": "France",
        "country_id": "FRA",
        "lon": "6.23"
      },
      {
        "city": "Port En Bessin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.35",
        "country": "France",
        "country_id": "FRA",
        "lon": "-0.77"
      },
      {
        "city": "Pte De La Hague",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.72",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.93"
      },
      {
        "city": "Pte De Penmarch",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.8",
        "country": "France",
        "country_id": "FRA",
        "lon": "-4.37"
      },
      {
        "city": "Quimper",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.97",
        "country": "France",
        "country_id": "FRA",
        "lon": "-4.17"
      },
      {
        "city": "Reims",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.3",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.03"
      },
      {
        "city": "Rennes",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.07",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.73"
      },
      {
        "city": "Rouen",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.38",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.18"
      },
      {
        "city": "Saint Girons",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.1"
      },
      {
        "city": "Saint-Dizier",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.63",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.9"
      },
      {
        "city": "Saint-Nazaire-Monto",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.32",
        "country": "France",
        "country_id": "FRA",
        "lon": "-2.17"
      },
      {
        "city": "Saint-Quentin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.82",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.2"
      },
      {
        "city": "Saint-Yan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.42",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.02"
      },
      {
        "city": "Serge-Frolow (Ile Tromelin)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.8",
        "country": "France",
        "country_id": "FRA",
        "lon": "54.5"
      },
      {
        "city": "Sete",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.4",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.68"
      },
      {
        "city": "Socoa",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.4",
        "country": "France",
        "country_id": "FRA",
        "lon": "-1.68"
      },
      {
        "city": "Solenzara",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.92",
        "country": "France",
        "country_id": "FRA",
        "lon": "9.4"
      },
      {
        "city": "St-Auban-Sur-Duranc",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.07",
        "country": "France",
        "country_id": "FRA",
        "lon": "6"
      },
      {
        "city": "St-Etienne Boutheon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.53",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.3"
      },
      {
        "city": "Strasbourg-Entzheim",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.55",
        "country": "France",
        "country_id": "FRA",
        "lon": "7.63"
      },
      {
        "city": "Tarbes-Ossun",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.18",
        "country": "France",
        "country_id": "FRA",
        "lon": "0"
      },
      {
        "city": "Toulon",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.1",
        "country": "France",
        "country_id": "FRA",
        "lon": "5.93"
      },
      {
        "city": "Toulouse Blagnac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.63",
        "country": "France",
        "country_id": "FRA",
        "lon": "1.37"
      },
      {
        "city": "Tours",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.45",
        "country": "France",
        "country_id": "FRA",
        "lon": "0.73"
      },
      {
        "city": "Trappes",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.77",
        "country": "France",
        "country_id": "FRA",
        "lon": "2"
      },
      {
        "city": "Tremuson-St-Brieuc",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.53",
        "country": "France",
        "country_id": "FRA",
        "lon": "-2.85"
      },
      {
        "city": "Troyes Barberey",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.33",
        "country": "France",
        "country_id": "FRA",
        "lon": "4.02"
      },
      {
        "city": "Vannes/Sene",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.6",
        "country": "France",
        "country_id": "FRA",
        "lon": "-2.72"
      },
      {
        "city": "Vichy",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.17",
        "country": "France",
        "country_id": "FRA",
        "lon": "3.4"
      },
      {
        "city": "Villacoublay",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.77",
        "country": "France",
        "country_id": "FRA",
        "lon": "2.2"
      }
    ]
  },
  {
    "country": "French Guiana",
    "cities": [
      {
        "city": "Rochambeau",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "4.83",
        "country": "French Guiana",
        "country_id": "GUF",
        "lon": "-52.37"
      }
    ]
  },
  {
    "country": "French Polynesia",
    "cities": [
      {
        "city": "Atuona",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-9.8",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-139.03"
      },
      {
        "city": "Bora-Bora",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-16.43",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-151.75"
      },
      {
        "city": "Hao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.07",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-140.95"
      },
      {
        "city": "Mururoa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-21.82",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-138.8"
      },
      {
        "city": "Rapa",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-27.62",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-144.33"
      },
      {
        "city": "Rikitea",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.13",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-134.97"
      },
      {
        "city": "Tahiti-Faaa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.55",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-149.62"
      },
      {
        "city": "Takaroa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-14.48",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-145.03"
      },
      {
        "city": "Tubuai",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-23.35",
        "country": "French Polynesia",
        "country_id": "PYF",
        "lon": "-149.48"
      }
    ]
  },
  {
    "country": "French Southern Territories",
    "cities": [
      {
        "city": "Port-Aux-Francais",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-49.3",
        "country": "French Southern Territories",
        "country_id": "ATF",
        "lon": "70.2"
      }
    ]
  },
  {
    "country": "Gabon",
    "cities": [
      {
        "city": "Libreville",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "0.45",
        "country": "Gabon",
        "country_id": "GAB",
        "lon": "9.42"
      }
    ]
  },
  {
    "country": "Gambia",
    "cities": [
      {
        "city": "Banjul/Yundum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.2",
        "country": "Gambia",
        "country_id": "GMB",
        "lon": "-16.63"
      }
    ]
  },
  {
    "country": "Georgia",
    "cities": [
      {
        "city": "Batumi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.62",
        "country": "Georgia",
        "country_id": "GEO",
        "lon": "41.6"
      },
      {
        "city": "Kutaisi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.27",
        "country": "Georgia",
        "country_id": "GEO",
        "lon": "42.63"
      },
      {
        "city": "Pasanauri",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.35",
        "country": "Georgia",
        "country_id": "GEO",
        "lon": "44.7"
      },
      {
        "city": "Suhumi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.87",
        "country": "Georgia",
        "country_id": "GEO",
        "lon": "41.13"
      },
      {
        "city": "Tbilisi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.68",
        "country": "Georgia",
        "country_id": "GEO",
        "lon": "44.95"
      }
    ]
  },
  {
    "country": "Germany",
    "cities": [
      {
        "city": "Aachen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.78",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.1"
      },
      {
        "city": "Ahlhorn(Gafb)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.88",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.23"
      },
      {
        "city": "Angermuende",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.03",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "14"
      },
      {
        "city": "Arkona (Cape)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.68",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.43"
      },
      {
        "city": "Artern",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.38",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.3"
      },
      {
        "city": "Augsberg/Mulhausen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.43",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.93"
      },
      {
        "city": "Bergen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.82",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.93"
      },
      {
        "city": "Berlin/Dahlem",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.47",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.3"
      },
      {
        "city": "Berlin/Schonefeld",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.38",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.52"
      },
      {
        "city": "Berlin/Tegel (Fafb)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.57",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.32"
      },
      {
        "city": "Berlin/Tempelhof",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.47",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.4"
      },
      {
        "city": "Bitburg(Us Army)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.95",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.57"
      },
      {
        "city": "Boizenburg (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.4",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.68"
      },
      {
        "city": "Boltenhagen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.2"
      },
      {
        "city": "Braunschweig",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.3",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.45"
      },
      {
        "city": "Bremen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.05",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.8"
      },
      {
        "city": "Bremerhaven",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.53",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.58"
      },
      {
        "city": "Bremgarten(Gafb)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "47.9",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.62"
      },
      {
        "city": "Brocken (Peak)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.8",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.62"
      },
      {
        "city": "Bruggen (Raf)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.2",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.13"
      },
      {
        "city": "Buechel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.17",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.07"
      },
      {
        "city": "Bueckeburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.28",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.08"
      },
      {
        "city": "Celle",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.6",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.02"
      },
      {
        "city": "Chemnitz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.8",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.87"
      },
      {
        "city": "Cottbus (Flugplatz)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.77",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "14.3"
      },
      {
        "city": "Cuxhaven",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.87",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.7"
      },
      {
        "city": "Diepholz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.58",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.35"
      },
      {
        "city": "Doberlug/Kirchhain",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.65",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.58"
      },
      {
        "city": "Dresden/Klotzsche",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.13",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.77"
      },
      {
        "city": "Dusseldorf",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.28",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.78"
      },
      {
        "city": "Eggebek",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.63",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.35"
      },
      {
        "city": "Emden-Flugplatz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.38",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.23"
      },
      {
        "city": "Erfurt/Binderslebn",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.98",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.97"
      },
      {
        "city": "Essen/Mulheim",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.4",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.97"
      },
      {
        "city": "Fassberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.92",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.18"
      },
      {
        "city": "Fichtelberg Mtn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.43",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.95"
      },
      {
        "city": "Frankfurt Main Arpt",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.05",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.6"
      },
      {
        "city": "Freiburg (Civ/Fafb)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.85"
      },
      {
        "city": "Fritzlar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.12",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.28"
      },
      {
        "city": "Fuerstenfeldbruck",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.2",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.27"
      },
      {
        "city": "Fuerstenzell",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.55",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.35"
      },
      {
        "city": "Gardelegen (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.52",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.4"
      },
      {
        "city": "Geilenkirchen",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.97",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.05"
      },
      {
        "city": "Gera/Leumnitz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.88",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.13"
      },
      {
        "city": "Giessen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.58",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.7"
      },
      {
        "city": "Gluecksburg/Meierwi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.83",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.5"
      },
      {
        "city": "Goerlitz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.17",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "14.95"
      },
      {
        "city": "Grafenwoehr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.7",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.95"
      },
      {
        "city": "Greifswald",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.1",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.38"
      },
      {
        "city": "Guetersloh",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.93",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.32"
      },
      {
        "city": "Hahn",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.95",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.27"
      },
      {
        "city": "Hamburg/Fuhlsbuttel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.63",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10"
      },
      {
        "city": "Hannover",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.47",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.7"
      },
      {
        "city": "Heidelberg (Usa-Af)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.4",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.65"
      },
      {
        "city": "Hof",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.32",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.88"
      },
      {
        "city": "Hohenpeissenberg",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.8",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.02"
      },
      {
        "city": "Hohn",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.32",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.53"
      },
      {
        "city": "Holzdorf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.77",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.17"
      },
      {
        "city": "Hopsten",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.33",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.53"
      },
      {
        "city": "Idar-Oberstein",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.7",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.33"
      },
      {
        "city": "Itzehoe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.98",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.57"
      },
      {
        "city": "Jever",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.53",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.9"
      },
      {
        "city": "Kahler Asten(Mount)",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.18",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.48"
      },
      {
        "city": "Kalkar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.73",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.27"
      },
      {
        "city": "Karlsruhe Baden Bad",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.77",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.07"
      },
      {
        "city": "Kassel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.3",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.45"
      },
      {
        "city": "Kiel Holtenau",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.37",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.13"
      },
      {
        "city": "Koln/Bonn (Civ/Mil)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.87",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.17"
      },
      {
        "city": "Konstanz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.68",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.18"
      },
      {
        "city": "Kuemmersbruck",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.43",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.9"
      },
      {
        "city": "Laage",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.92",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.28"
      },
      {
        "city": "Lahr",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "48.37",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.83"
      },
      {
        "city": "Landsberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.07",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.9"
      },
      {
        "city": "Laupheim",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.22",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.92"
      },
      {
        "city": "Lechfeld",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.18",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.85"
      },
      {
        "city": "Leck",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.8",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.95"
      },
      {
        "city": "Leinefelde (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.4",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.32"
      },
      {
        "city": "Leipzig",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.32",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.42"
      },
      {
        "city": "Leipzig/Schkeuditz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.42",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.23"
      },
      {
        "city": "Lindenberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.22",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "14.12"
      },
      {
        "city": "Luedenscheid",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.25",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.65"
      },
      {
        "city": "Magdeburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.1",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.58"
      },
      {
        "city": "Marnitz (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.32",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.93"
      },
      {
        "city": "Meiningen",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.57",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.38"
      },
      {
        "city": "Memmingen (Ger-Afb)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.98",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.23"
      },
      {
        "city": "Mendig",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.37",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.32"
      },
      {
        "city": "Messstetten",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.18",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9"
      },
      {
        "city": "Munich",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.13",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.55"
      },
      {
        "city": "Munich/Riem",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.13",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.7"
      },
      {
        "city": "Neuburg/Donau",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.72",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.22"
      },
      {
        "city": "Neuhausen Ob Eck",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.98",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.9"
      },
      {
        "city": "Neuruppin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.9",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.82"
      },
      {
        "city": "Niederstetten",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.38",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.97"
      },
      {
        "city": "Noervenich",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.83",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.67"
      },
      {
        "city": "Nordholz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.77",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.67"
      },
      {
        "city": "Nuerburg-Barweiler",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.37",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.87"
      },
      {
        "city": "Nurnberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.5",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.08"
      },
      {
        "city": "Oldenburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.18",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "8.17"
      },
      {
        "city": "Oschatz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.3",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.1"
      },
      {
        "city": "Passau",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.58",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.47"
      },
      {
        "city": "Pferdsfeld (Ger-Af)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.85",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.6"
      },
      {
        "city": "Plauen (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.48",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.13"
      },
      {
        "city": "Potsdam",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.38",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.07"
      },
      {
        "city": "Quickborn",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.73",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.88"
      },
      {
        "city": "Ramstein",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.43",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.6"
      },
      {
        "city": "Regensburg/Oberhub",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.05",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.1"
      },
      {
        "city": "Rheine-Bentlage",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.3",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.38"
      },
      {
        "city": "Roth",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.22",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.1"
      },
      {
        "city": "Saarbrucken/Ensheim",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.22",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.12"
      },
      {
        "city": "Schleswig-Jagel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.47",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.52"
      },
      {
        "city": "Schmuecke (Ridge)",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.65",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.77"
      },
      {
        "city": "Schwerin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.63",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.42"
      },
      {
        "city": "Seehausen/Altmark",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.9",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.73"
      },
      {
        "city": "Sonneberg/Neufang",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.38",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.18"
      },
      {
        "city": "Spangdahlem",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.98",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.7"
      },
      {
        "city": "Straubing",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.83",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.57"
      },
      {
        "city": "Stuttgart/Echterdi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.68",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.22"
      },
      {
        "city": "Stuttgart/Schnarren",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.83",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.2"
      },
      {
        "city": "Teterow",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.77",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.62"
      },
      {
        "city": "Trier/Petrisberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.75",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.67"
      },
      {
        "city": "Trollenhagen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.6",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.32"
      },
      {
        "city": "Ueckermuende (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.75",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "14.07"
      },
      {
        "city": "Waren",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.52",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.67"
      },
      {
        "city": "Warnemuende",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.18",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.08"
      },
      {
        "city": "Wasserkuppe (Mount)",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.5",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.95"
      },
      {
        "city": "Weimar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.98",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.32"
      },
      {
        "city": "Wernigerode (Aut)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.85",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.77"
      },
      {
        "city": "Westermarkelsdorf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.53",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "11.07"
      },
      {
        "city": "Wiesenburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.12",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.47"
      },
      {
        "city": "Wildenrath(Gafb)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.12",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "6.22"
      },
      {
        "city": "Wittenberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.88",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "12.65"
      },
      {
        "city": "Wittmundhaven",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.55",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "7.67"
      },
      {
        "city": "Wunstorf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.47",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "9.43"
      },
      {
        "city": "Zinnwald/Georgenfe",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.73",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "13.75"
      },
      {
        "city": "Zugspitze Mountain",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "47.42",
        "country": "Germany",
        "country_id": "DEU",
        "lon": "10.98"
      }
    ]
  },
  {
    "country": "Gibraltar",
    "cities": [
      {
        "city": "Gibraltar",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.15",
        "country": "Gibraltar",
        "country_id": "GIB",
        "lon": "-5.35"
      }
    ]
  },
  {
    "country": "Greece",
    "cities": [
      {
        "city": "Aktion (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.95",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "20.77"
      },
      {
        "city": "Alexandroupoli (Air)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.85",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "25.92"
      },
      {
        "city": "Andravida (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.92",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "21.28"
      },
      {
        "city": "Araxos (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.15",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "21.42"
      },
      {
        "city": "Athinai (Airport)",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.9",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "23.73"
      },
      {
        "city": "Chrysopouli (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.98",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "24.6"
      },
      {
        "city": "Elefsis (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.07",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "23.55"
      },
      {
        "city": "Heraklion (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.33",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "25.18"
      },
      {
        "city": "Kalamata (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.07",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "22.02"
      },
      {
        "city": "Kerkyra (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.62",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "19.92"
      },
      {
        "city": "Kos (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.78",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "27.07"
      },
      {
        "city": "Larissa (Airport)",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "39.63",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "22.42"
      },
      {
        "city": "Limnos (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.92",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "25.23"
      },
      {
        "city": "Methoni",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.83",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "21.7"
      },
      {
        "city": "Milos",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.72",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "24.45"
      },
      {
        "city": "Mytilini (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.07",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "26.6"
      },
      {
        "city": "Naxos",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.1",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "25.38"
      },
      {
        "city": "Rhodes (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.4",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "28.08"
      },
      {
        "city": "Samos (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.7",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "26.92"
      },
      {
        "city": "Skyros (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.97",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "24.48"
      },
      {
        "city": "Souda (Airport)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.48",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "24.12"
      },
      {
        "city": "Souda Bay Crete",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.53",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "24.15"
      },
      {
        "city": "Thessaloniki (Airport)",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "40.52",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "22.97"
      },
      {
        "city": "Tripolis (Airport)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.53",
        "country": "Greece",
        "country_id": "GRC",
        "lon": "22.4"
      }
    ]
  },
  {
    "country": "Greenland",
    "cities": [
      {
        "city": "Aasiaat (Egedesminde)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.7",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-52.85"
      },
      {
        "city": "Angisoq",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.98",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-45.2"
      },
      {
        "city": "Aputiteeq",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.78",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-32.3"
      },
      {
        "city": "Daneborg",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "74.3",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-20.22"
      },
      {
        "city": "Danmarkshavn",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.77",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-18.67"
      },
      {
        "city": "Hall Land",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "81.68",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-59.93"
      },
      {
        "city": "Henrik Kroeyer Holm",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "80.65",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-13.72"
      },
      {
        "city": "Ikermiit",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.78",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-40.3"
      },
      {
        "city": "Ikermiuarsuk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.93",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-42.07"
      },
      {
        "city": "Ilulissat (Jakobshavn)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69.23",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-51.07"
      },
      {
        "city": "Ittoqqortoormiit",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.48",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-21.95"
      },
      {
        "city": "Kangerlussuaq (Sdr.)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.02",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-50.7"
      },
      {
        "city": "Kap Morris Jesup",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "83.65",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-33.37"
      },
      {
        "city": "Kitsissorsuit",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "74.03",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-57.82"
      },
      {
        "city": "Kitsissut (Attu)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.78",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-53.97"
      },
      {
        "city": "Kitsissut (Carey Oeer)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.63",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-73"
      },
      {
        "city": "Narsarsuaq",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.13",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-45.43"
      },
      {
        "city": "Nunarsuit",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.77",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-48.42"
      },
      {
        "city": "Nuuk (Godthaab)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.17",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-51.75"
      },
      {
        "city": "Nuussuaataa",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.68",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-54.62"
      },
      {
        "city": "Paamiut (Frederikshaab)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-49.67"
      },
      {
        "city": "Pituffik (Thule A.B.)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.53",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-68.75"
      },
      {
        "city": "Prins Christian Sun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.05",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-43.17"
      },
      {
        "city": "Qaqortoq (Julianehaab)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.72",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-46.05"
      },
      {
        "city": "Sioralik",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.02",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-52.55"
      },
      {
        "city": "Sisimiut (Holsteins)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.92",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-53.67"
      },
      {
        "city": "Station Nord Aws",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "81.6",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-16.68"
      },
      {
        "city": "Tasiilaq (Ammassalik)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.6",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-37.63"
      },
      {
        "city": "Ukiivik (Frederikshaab)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.57",
        "country": "Greenland",
        "country_id": "GRL",
        "lon": "-50.42"
      }
    ]
  },
  {
    "country": "Grenada",
    "cities": [
      {
        "city": "Point Salines Airpo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12",
        "country": "Grenada",
        "country_id": "GRD",
        "lon": "-61.78"
      }
    ]
  },
  {
    "country": "Guam",
    "cities": [
      {
        "city": "Andersen Afb",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.57",
        "country": "Guam",
        "country_id": "GUM",
        "lon": "144.92"
      },
      {
        "city": "Guam Wfo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.48",
        "country": "Guam",
        "country_id": "GUM",
        "lon": "144.8"
      }
    ]
  },
  {
    "country": "Guatemala",
    "cities": [
      {
        "city": "Guatemala (Aeropuerto)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "14.58",
        "country": "Guatemala",
        "country_id": "GTM",
        "lon": "-90.52"
      }
    ]
  },
  {
    "country": "Guernsey",
    "cities": [
      {
        "city": "Guernsey Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.43",
        "country": "Guernsey",
        "country_id": "GGY",
        "lon": "-2.6"
      }
    ]
  },
  {
    "country": "Guyana",
    "cities": [
      {
        "city": "Timehri\\Cheddi Jag",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.5",
        "country": "Guyana",
        "country_id": "GUY",
        "lon": "-58.25"
      }
    ]
  },
  {
    "country": "Honduras",
    "cities": [
      {
        "city": "La Mesa (San Pedro Sula)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.45",
        "country": "Honduras",
        "country_id": "HND",
        "lon": "-87.93"
      },
      {
        "city": "Tegucigalpa",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "14.05",
        "country": "Honduras",
        "country_id": "HND",
        "lon": "-87.22"
      }
    ]
  },
  {
    "country": "Hungary",
    "cities": [
      {
        "city": "Baja",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.18",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "19.02"
      },
      {
        "city": "Bekescsaba",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.68",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "21.17"
      },
      {
        "city": "Budaors",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.45",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "18.97"
      },
      {
        "city": "Budapest/Ferihegy I",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.43",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "19.27"
      },
      {
        "city": "Budapest/Pestszentl",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.43",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "19.18"
      },
      {
        "city": "Debrecen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.48",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "21.6"
      },
      {
        "city": "Gyor",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.72",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "17.68"
      },
      {
        "city": "Kecskemet",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.92",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "19.75"
      },
      {
        "city": "Kekesteto",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.87",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "20.02"
      },
      {
        "city": "Keszthely",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.73",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "17.23"
      },
      {
        "city": "Miskolc",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.1",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "20.77"
      },
      {
        "city": "Mosonmagyarovar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.88",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "17.28"
      },
      {
        "city": "Nagykanizsa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.45",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "16.97"
      },
      {
        "city": "Nyiregyhaza/Napkor",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.97",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "21.88"
      },
      {
        "city": "Paks",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.58",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "18.85"
      },
      {
        "city": "Papa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.2",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "17.5"
      },
      {
        "city": "Pecs/Pogany",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "18.23"
      },
      {
        "city": "Siofok",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.92",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "18.05"
      },
      {
        "city": "Sopron",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.68",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "16.6"
      },
      {
        "city": "Szeged",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.25",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "20.1"
      },
      {
        "city": "Szentgotthard/Farka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.92",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "16.32"
      },
      {
        "city": "Szolnok",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.12",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "20.23"
      },
      {
        "city": "Szombathely",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.27",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "16.63"
      },
      {
        "city": "Taszar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.4",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "17.92"
      },
      {
        "city": "Veszprem/Szentkiral",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.07",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "17.83"
      },
      {
        "city": "Zalaegerszeg/Andras",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.87",
        "country": "Hungary",
        "country_id": "HUN",
        "lon": "16.8"
      }
    ]
  },
  {
    "country": "Iceland",
    "cities": [
      {
        "city": "Akureyri",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.68",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-18.08"
      },
      {
        "city": "Akurnes",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.3",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-15.22"
      },
      {
        "city": "Bergstadir",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.7",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-19.62"
      },
      {
        "city": "Bolungavik",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.15",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-23.25"
      },
      {
        "city": "Dalatangi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.27",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-13.58"
      },
      {
        "city": "Egilsstadir",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.28",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-14.4"
      },
      {
        "city": "Gufuskalar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.9",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-23.93"
      },
      {
        "city": "Hornbjargsviti (Lh)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.42",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-22.38"
      },
      {
        "city": "Hveravellir",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.87",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-19.57"
      },
      {
        "city": "Keflavik",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.97",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-22.6"
      },
      {
        "city": "Kirkjubaejarklaustu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.78",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-18.07"
      },
      {
        "city": "Raufarhofn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.45",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-15.95"
      },
      {
        "city": "Reykjavik",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "64.13",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-21.9"
      },
      {
        "city": "Vestmannaeyjar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.4",
        "country": "Iceland",
        "country_id": "ISL",
        "lon": "-20.28"
      }
    ]
  },
  {
    "country": "India",
    "cities": [
      {
        "city": "Agartala",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.88",
        "country": "India",
        "country_id": "",
        "lon": "91.25"
      },
      {
        "city": "Ahmadabad",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "23.07",
        "country": "India",
        "country_id": "",
        "lon": "72.63"
      },
      {
        "city": "Akola",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "20.7",
        "country": "India",
        "country_id": "",
        "lon": "77.07"
      },
      {
        "city": "Aurangabad Chikalth",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.85",
        "country": "India",
        "country_id": "",
        "lon": "75.4"
      },
      {
        "city": "Balasore",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.52",
        "country": "India",
        "country_id": "",
        "lon": "86.93"
      },
      {
        "city": "Bangalore",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.97",
        "country": "India",
        "country_id": "",
        "lon": "77.58"
      },
      {
        "city": "Belgaum/Sambra",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.85",
        "country": "India",
        "country_id": "",
        "lon": "74.62"
      },
      {
        "city": "Bhopal/Bairagarh",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.28",
        "country": "India",
        "country_id": "",
        "lon": "77.35"
      },
      {
        "city": "Bhubaneswar",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.25",
        "country": "India",
        "country_id": "",
        "lon": "85.83"
      },
      {
        "city": "Bhuj-Rudramata",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "23.25",
        "country": "India",
        "country_id": "",
        "lon": "69.67"
      },
      {
        "city": "Bikaner",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "28",
        "country": "India",
        "country_id": "",
        "lon": "73.3"
      },
      {
        "city": "Bombay/Santacruz",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.12",
        "country": "India",
        "country_id": "",
        "lon": "72.85"
      },
      {
        "city": "Calcutta/Dum Dum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.65",
        "country": "India",
        "country_id": "",
        "lon": "88.45"
      },
      {
        "city": "Chitradurga",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.23",
        "country": "India",
        "country_id": "",
        "lon": "76.43"
      },
      {
        "city": "Coimbatore/Peelamed",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "11.03",
        "country": "India",
        "country_id": "",
        "lon": "77.05"
      },
      {
        "city": "Cuddalore",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.77",
        "country": "India",
        "country_id": "",
        "lon": "79.77"
      },
      {
        "city": "Cwc Vishakhapatnam",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.7",
        "country": "India",
        "country_id": "",
        "lon": "83.3"
      },
      {
        "city": "Gadag",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "15.42",
        "country": "India",
        "country_id": "",
        "lon": "75.63"
      },
      {
        "city": "Gauhati",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "26.1",
        "country": "India",
        "country_id": "",
        "lon": "91.58"
      },
      {
        "city": "Goa/Panjim",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.48",
        "country": "India",
        "country_id": "",
        "lon": "73.82"
      },
      {
        "city": "Gwalior",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "26.23",
        "country": "India",
        "country_id": "",
        "lon": "78.25"
      },
      {
        "city": "Hissar",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "29.17",
        "country": "India",
        "country_id": "",
        "lon": "75.73"
      },
      {
        "city": "Hyderabad Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.45",
        "country": "India",
        "country_id": "",
        "lon": "78.47"
      },
      {
        "city": "Indore",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.72",
        "country": "India",
        "country_id": "",
        "lon": "75.8"
      },
      {
        "city": "Jabalpur",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.2",
        "country": "India",
        "country_id": "",
        "lon": "79.95"
      },
      {
        "city": "Jagdalpur",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.08",
        "country": "India",
        "country_id": "",
        "lon": "82.03"
      },
      {
        "city": "Jaipur/Sanganer",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.82",
        "country": "India",
        "country_id": "",
        "lon": "75.8"
      },
      {
        "city": "Jamshedpur",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.82",
        "country": "India",
        "country_id": "",
        "lon": "86.18"
      },
      {
        "city": "Jodhpur",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.3",
        "country": "India",
        "country_id": "",
        "lon": "73.02"
      },
      {
        "city": "Kakinada",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.95",
        "country": "India",
        "country_id": "",
        "lon": "82.23"
      },
      {
        "city": "Kozhikode",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.25",
        "country": "India",
        "country_id": "",
        "lon": "75.78"
      },
      {
        "city": "Kurnool",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "15.8",
        "country": "India",
        "country_id": "",
        "lon": "78.07"
      },
      {
        "city": "Lucknow/Amausi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "26.75",
        "country": "India",
        "country_id": "",
        "lon": "80.88"
      },
      {
        "city": "Machilipatnam",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.2",
        "country": "India",
        "country_id": "",
        "lon": "81.15"
      },
      {
        "city": "Madras/Minambakkam",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13",
        "country": "India",
        "country_id": "",
        "lon": "80.18"
      },
      {
        "city": "Mangalore/Bajpe",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.92",
        "country": "India",
        "country_id": "",
        "lon": "74.88"
      },
      {
        "city": "Nagpur Sonegaon",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.1",
        "country": "India",
        "country_id": "",
        "lon": "79.05"
      },
      {
        "city": "Nellore",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.45",
        "country": "India",
        "country_id": "",
        "lon": "79.98"
      },
      {
        "city": "New Delhi/Palam",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "28.57",
        "country": "India",
        "country_id": "",
        "lon": "77.12"
      },
      {
        "city": "New Delhi/Safdarjun",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "28.58",
        "country": "India",
        "country_id": "",
        "lon": "77.2"
      },
      {
        "city": "Patiala",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.33",
        "country": "India",
        "country_id": "",
        "lon": "76.47"
      },
      {
        "city": "Patna",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "25.6",
        "country": "India",
        "country_id": "",
        "lon": "85.1"
      },
      {
        "city": "Pbo Anantapur",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.58",
        "country": "India",
        "country_id": "",
        "lon": "77.63"
      },
      {
        "city": "Poona",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "18.53",
        "country": "India",
        "country_id": "",
        "lon": "73.85"
      },
      {
        "city": "Rajkot",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "22.3",
        "country": "India",
        "country_id": "",
        "lon": "70.78"
      },
      {
        "city": "Ratnagiri",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.98",
        "country": "India",
        "country_id": "",
        "lon": "73.33"
      },
      {
        "city": "Sholapur",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "17.67",
        "country": "India",
        "country_id": "",
        "lon": "75.9"
      },
      {
        "city": "Surat",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.2",
        "country": "India",
        "country_id": "",
        "lon": "72.83"
      },
      {
        "city": "Thiruvananthapuram",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.48",
        "country": "India",
        "country_id": "",
        "lon": "76.95"
      },
      {
        "city": "Tiruchchirapalli",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.77",
        "country": "India",
        "country_id": "",
        "lon": "78.72"
      },
      {
        "city": "Veraval",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "20.9",
        "country": "India",
        "country_id": "",
        "lon": "70.37"
      }
    ]
  },
  {
    "country": "Indonesia",
    "cities": [
      {
        "city": "Denpasar/Ngurah Rai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-8.75",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "115.17"
      },
      {
        "city": "Jakarta/Soekarno-Ha",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-6.12",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "106.65"
      },
      {
        "city": "Medan/Polonia",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "3.57",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "98.68"
      },
      {
        "city": "Menado/ Sam Ratulan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "1.53",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "124.92"
      },
      {
        "city": "Padang/Tabing",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-0.88",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "100.35"
      },
      {
        "city": "Pekan Baru/Simpangt",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "0.47",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "101.45"
      },
      {
        "city": "Rengat/Japura",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-0.33",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "102.32"
      },
      {
        "city": "Sibolga/Pinangsori",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "1.55",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "98.88"
      },
      {
        "city": "Surabaya/Juanda",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-7.37",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "112.77"
      },
      {
        "city": "Ujung Pandang/Hasan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-5.07",
        "country": "Indonesia",
        "country_id": "IDN",
        "lon": "119.55"
      }
    ]
  },
  {
    "country": "Iran, Islamic Republic of",
    "cities": [
      {
        "city": "Abadan",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "30.37",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "48.25"
      },
      {
        "city": "Ahwaz",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "31.33",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "48.67"
      },
      {
        "city": "Anzali",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.47",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "49.47"
      },
      {
        "city": "Arak",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.1",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "49.77"
      },
      {
        "city": "Babulsar",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.72",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "52.65"
      },
      {
        "city": "Bandarabbass",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "27.22",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "56.37"
      },
      {
        "city": "Birjand",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "32.87",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "59.2"
      },
      {
        "city": "Esfahan",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "32.47",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "51.67"
      },
      {
        "city": "Hamedan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.85",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "48.53"
      },
      {
        "city": "Kashan",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "33.98",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "51.45"
      },
      {
        "city": "Kerman",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "30.25",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "56.97"
      },
      {
        "city": "Kermanshah",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.27",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "47.12"
      },
      {
        "city": "Khoy",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.55",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "44.97"
      },
      {
        "city": "Mashhad",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "36.27",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "59.63"
      },
      {
        "city": "Orumieh",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "37.53",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "45.08"
      },
      {
        "city": "Ramsar",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.9",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "50.67"
      },
      {
        "city": "Sabzevar",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.22",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "57.67"
      },
      {
        "city": "Shahrud",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "36.42",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "54.95"
      },
      {
        "city": "Shiraz",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "29.53",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "52.53"
      },
      {
        "city": "Tabriz",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.08",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "46.28"
      },
      {
        "city": "Tehran-Mehrabad",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.68",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "51.32"
      },
      {
        "city": "Torbat-Heydarieh",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "35.27",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "59.22"
      },
      {
        "city": "Zahedan",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "29.47",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "60.88"
      },
      {
        "city": "Zanjan",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "36.68",
        "country": "Iran, Islamic Republic of",
        "country_id": "IRN",
        "lon": "48.48"
      }
    ]
  },
  {
    "country": "Ireland",
    "cities": [
      {
        "city": "Belmullet",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "54.23",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-10"
      },
      {
        "city": "Birr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.08",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-7.88"
      },
      {
        "city": "Casement Aerodrome",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.3",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-6.43"
      },
      {
        "city": "Claremorris",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.72",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-8.98"
      },
      {
        "city": "Clones",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.18",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-7.23"
      },
      {
        "city": "Connaught Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.9",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-8.82"
      },
      {
        "city": "Cork Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.85",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-8.48"
      },
      {
        "city": "Dublin Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.43",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-6.25"
      },
      {
        "city": "Kilkenny",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.67",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-7.27"
      },
      {
        "city": "Malin Head",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.37",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-7.33"
      },
      {
        "city": "Mullingar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.53",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-7.37"
      },
      {
        "city": "Roches Point",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.8",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-8.25"
      },
      {
        "city": "Rosslare",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.25",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-6.33"
      },
      {
        "city": "Shannon Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.7",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-8.92"
      },
      {
        "city": "Valentia Observator",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.93",
        "country": "Ireland",
        "country_id": "IRL",
        "lon": "-10.25"
      }
    ]
  },
  {
    "country": "Isle of Man",
    "cities": [
      {
        "city": "Isle Of Man/Ronalds",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.08",
        "country": "Isle of Man",
        "country_id": "IMN",
        "lon": "-4.63"
      },
      {
        "city": "Point Of Ayre (Lh)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "54.42",
        "country": "Isle of Man",
        "country_id": "IMN",
        "lon": "-4.37"
      }
    ]
  },
  {
    "country": "Israel",
    "cities": [
      {
        "city": "Beer-Sheva",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.23",
        "country": "Israel",
        "country_id": "ISR",
        "lon": "34.78"
      },
      {
        "city": "Ben-Gurion Int. Air",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "32",
        "country": "Israel",
        "country_id": "ISR",
        "lon": "34.9"
      },
      {
        "city": "Eilat",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "29.55",
        "country": "Israel",
        "country_id": "ISR",
        "lon": "34.95"
      },
      {
        "city": "Haifa",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "32.8",
        "country": "Israel",
        "country_id": "ISR",
        "lon": "35.03"
      },
      {
        "city": "Ovda",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30",
        "country": "Israel",
        "country_id": "ISR",
        "lon": "34.83"
      },
      {
        "city": "Sde-Dov (Tel-Aviv)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "32.1",
        "country": "Israel",
        "country_id": "ISR",
        "lon": "34.78"
      }
    ]
  },
  {
    "country": "Italy",
    "cities": [
      {
        "city": "Alghero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.63",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.28"
      },
      {
        "city": "Amendola",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.53",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "15.72"
      },
      {
        "city": "Aviano (Usaf)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.02",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.62"
      },
      {
        "city": "Bari/Palese Macchie",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.13",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "16.78"
      },
      {
        "city": "Bergamo/Orio Al Ser",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.67",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "9.7"
      },
      {
        "city": "Bologna/Borgo Panig",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.53",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.3"
      },
      {
        "city": "Bolzano",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.47",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.33"
      },
      {
        "city": "Brescia/Ghedi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.42",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "10.28"
      },
      {
        "city": "Brindisi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.65",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "17.95"
      },
      {
        "city": "Cagliari/Elmas",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.25",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "9.07"
      },
      {
        "city": "Campobasso",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.57",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.65"
      },
      {
        "city": "Capo Bellavista",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.93",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "9.72"
      },
      {
        "city": "Capo Caccia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.57",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.17"
      },
      {
        "city": "Capo Frasca",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.75",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.47"
      },
      {
        "city": "Capo Mele",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.95",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.17"
      },
      {
        "city": "Capo Palinuro",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.02",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "15.28"
      },
      {
        "city": "Catania/Fontanaross",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.47",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "15.05"
      },
      {
        "city": "Catania/Sigonella",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.4",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.92"
      },
      {
        "city": "Cervia",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.22",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.3"
      },
      {
        "city": "Cozzo Spadaro",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.68",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "15.13"
      },
      {
        "city": "Crotone",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "17.07"
      },
      {
        "city": "Dobbiaco",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.73",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.22"
      },
      {
        "city": "Falconara",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.62",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.37"
      },
      {
        "city": "Firenze/Peretola",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.8",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.2"
      },
      {
        "city": "Forli",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.2",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.07"
      },
      {
        "city": "Fucino",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "41.88",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.58"
      },
      {
        "city": "Gela",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.08",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.22"
      },
      {
        "city": "Genova/Sestri",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "44.42",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.85"
      },
      {
        "city": "Gioia Del Colle",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.77",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "16.93"
      },
      {
        "city": "Grazzanise",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.05",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.07"
      },
      {
        "city": "Grosseto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.75",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.07"
      },
      {
        "city": "Isola Di Carloforte",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.13",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.32"
      },
      {
        "city": "Lamezia Terme",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.9",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "16.25"
      },
      {
        "city": "Lampedusa",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "35.5",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.6"
      },
      {
        "city": "Latina",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.55",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.9"
      },
      {
        "city": "Lecce",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.23",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "18.15"
      },
      {
        "city": "Marina Di Ginosa",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "40.43",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "16.88"
      },
      {
        "city": "Messina",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.2",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "15.55"
      },
      {
        "city": "Milano/Linate",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.43",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "9.28"
      },
      {
        "city": "Milano/Malpensa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.62",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.73"
      },
      {
        "city": "Monte Argentario",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.38",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.17"
      },
      {
        "city": "Monte Cimone",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.2",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "10.7"
      },
      {
        "city": "Monte Scuro",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "39.33",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "16.4"
      },
      {
        "city": "Monte Terminillo",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.47",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.98"
      },
      {
        "city": "Naples",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.9",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.3"
      },
      {
        "city": "Napoli/Capodichino",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.85",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.3"
      },
      {
        "city": "Novara/Cameri",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.52",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.67"
      },
      {
        "city": "Novi Ligure",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.77",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "8.78"
      },
      {
        "city": "Olbia/Costa Smerald",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.9",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "9.52"
      },
      {
        "city": "Paganella",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.15",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.03"
      },
      {
        "city": "Palermo/Punta Raisi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.18",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.1"
      },
      {
        "city": "Pantelleria",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.82",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.97"
      },
      {
        "city": "Passo Rolle",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.3",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "11.78"
      },
      {
        "city": "Perugia",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.08",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.5"
      },
      {
        "city": "Pescara",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.43",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "14.2"
      },
      {
        "city": "Piacenza",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.92",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "9.73"
      },
      {
        "city": "Pian Rosa",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "45.93",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "7.7"
      },
      {
        "city": "Pisa/S. Giusto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.68",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "10.38"
      },
      {
        "city": "Ponza",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.92",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.95"
      },
      {
        "city": "Pratica Di Mare",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.65",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.45"
      },
      {
        "city": "Reggio Calabria",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.07",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "15.65"
      },
      {
        "city": "Resia Pass",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.83",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "10.5"
      },
      {
        "city": "Rimini",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.03",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.62"
      },
      {
        "city": "Roma Fiumicino",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.8",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.23"
      },
      {
        "city": "Roma/Ciampino",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.78",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.58"
      },
      {
        "city": "Ronchi Dei Legionar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.82",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.48"
      },
      {
        "city": "S. Maria Di Leuca",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.82",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "18.35"
      },
      {
        "city": "S. Valentino Alla M",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.75",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "10.53"
      },
      {
        "city": "Torino/Bric Della C",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.03",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "7.73"
      },
      {
        "city": "Torino/Caselle",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.22",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "7.65"
      },
      {
        "city": "Trapani/Birgi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.92",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.5"
      },
      {
        "city": "Treviso/Istrana",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.68",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.1"
      },
      {
        "city": "Treviso/S. Angelo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.65",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.18"
      },
      {
        "city": "Trieste",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "45.65",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.75"
      },
      {
        "city": "Udine/Rivolto",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.98",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.03"
      },
      {
        "city": "Ustica",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.7",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "13.18"
      },
      {
        "city": "Venezia/Tessera",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.5",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "12.33"
      },
      {
        "city": "Verona/Villafranca",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.38",
        "country": "Italy",
        "country_id": "ITA",
        "lon": "10.87"
      }
    ]
  },
  {
    "country": "Jamaica",
    "cities": [
      {
        "city": "Kingston/Norman Man",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.93",
        "country": "Jamaica",
        "country_id": "JAM",
        "lon": "-76.78"
      },
      {
        "city": "Montego Bay/Sangste",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.5",
        "country": "Jamaica",
        "country_id": "JAM",
        "lon": "-77.92"
      }
    ]
  },
  {
    "country": "Japan",
    "cities": [
      {
        "city": "Abashiri",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.02",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "144.28"
      },
      {
        "city": "Aburatsu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.58",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.4"
      },
      {
        "city": "Aikawa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.03",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.23"
      },
      {
        "city": "Ajiro",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.05",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.1"
      },
      {
        "city": "Akita",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.72",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.1"
      },
      {
        "city": "Akune",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.03",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.2"
      },
      {
        "city": "Aomori",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.82",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.77"
      },
      {
        "city": "Asahikawa",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.77",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "142.37"
      },
      {
        "city": "Ashiya Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.88",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.65"
      },
      {
        "city": "Asosan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "32.88",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.07"
      },
      {
        "city": "Atsugi Nas",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.45",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.45"
      },
      {
        "city": "Chiba",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.6",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.1"
      },
      {
        "city": "Chichibu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.98",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.07"
      },
      {
        "city": "Chichijima Island",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "27.08",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "142.18"
      },
      {
        "city": "Chitose (Jasdf)",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.82",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.68"
      },
      {
        "city": "Chitose Ab",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.8",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.67"
      },
      {
        "city": "Choshi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.85"
      },
      {
        "city": "Esashi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.87",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.12"
      },
      {
        "city": "Fujisan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "35.37",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.73"
      },
      {
        "city": "Fukaura",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.65",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.93"
      },
      {
        "city": "Fukue",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.7",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "128.83"
      },
      {
        "city": "Fukui",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.05",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.22"
      },
      {
        "city": "Fukuoka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.58",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.38"
      },
      {
        "city": "Fukuoka Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.58",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.45"
      },
      {
        "city": "Fukushima",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.77",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.47"
      },
      {
        "city": "Fukuyama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.45",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.25"
      },
      {
        "city": "Fushiki",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.8",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.05"
      },
      {
        "city": "Futenma",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.27",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "127.75"
      },
      {
        "city": "Gifu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.4",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.77"
      },
      {
        "city": "Gifu Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.38",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.87"
      },
      {
        "city": "Haboro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.37",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.7"
      },
      {
        "city": "Hachijojima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.12",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.78"
      },
      {
        "city": "Hachinohe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.53",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.52"
      },
      {
        "city": "Hachinohe Ab",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.55",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.47"
      },
      {
        "city": "Hagi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.42",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.4"
      },
      {
        "city": "Hakodate",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.82",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.75"
      },
      {
        "city": "Hamada",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.9",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.07"
      },
      {
        "city": "Hamamatsu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.72",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.72"
      },
      {
        "city": "Hamamatsu Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.75",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.7"
      },
      {
        "city": "Hikone",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.28",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.25"
      },
      {
        "city": "Himeji",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.83",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.67"
      },
      {
        "city": "Hirado",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.37",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "129.55"
      },
      {
        "city": "Hiroo",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.3",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "143.32"
      },
      {
        "city": "Hiroshima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.4",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.47"
      },
      {
        "city": "Hita",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.32",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.93"
      },
      {
        "city": "Hitoyoshi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.22",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.75"
      },
      {
        "city": "Hofu Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.03",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.55"
      },
      {
        "city": "Hyakuri Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.18",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.42"
      },
      {
        "city": "Iida",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.52",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.82"
      },
      {
        "city": "Iizuka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.65",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.7"
      },
      {
        "city": "Irako",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.63",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.1"
      },
      {
        "city": "Irozaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.6",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.85"
      },
      {
        "city": "Iruma Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.83",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.42"
      },
      {
        "city": "Ishigakijima",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "24.33",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "124.17"
      },
      {
        "city": "Ishinomaki",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.43",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.3"
      },
      {
        "city": "Iwakuni",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.15",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.23"
      },
      {
        "city": "Iwamizawa",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.22",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.78"
      },
      {
        "city": "Iwojima",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "24.78",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.32"
      },
      {
        "city": "Izuhara",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.2",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "129.3"
      },
      {
        "city": "Kadena (Usaf\\Navy)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.35",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "127.77"
      },
      {
        "city": "Kagoshima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.55",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.55"
      },
      {
        "city": "Kanazawa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.58",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.63"
      },
      {
        "city": "Kanoya Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.37",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.83"
      },
      {
        "city": "Kansai Internationa",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.43",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.25"
      },
      {
        "city": "Karuizawa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "36.35",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.55"
      },
      {
        "city": "Katsuura",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.15",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.32"
      },
      {
        "city": "Kawaguchiko",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.5",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.77"
      },
      {
        "city": "Kitamiesashi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.93",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "142.58"
      },
      {
        "city": "Kobe",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.7",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.22"
      },
      {
        "city": "Kochi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.57",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.55"
      },
      {
        "city": "Kofu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.67",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.55"
      },
      {
        "city": "Komatsu Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.4",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.4"
      },
      {
        "city": "Komatsushima Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.63"
      },
      {
        "city": "Kumagaya",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.15",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.38"
      },
      {
        "city": "Kumamoto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.82",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.7"
      },
      {
        "city": "Kumejima",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.33",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "126.8"
      },
      {
        "city": "Kure",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.23",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.55"
      },
      {
        "city": "Kushiro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.98",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "144.38"
      },
      {
        "city": "Kutchan",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.9",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.75"
      },
      {
        "city": "Kyoto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.02",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.73"
      },
      {
        "city": "Maebashi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.4",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.07"
      },
      {
        "city": "Maizuru",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.45",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.32"
      },
      {
        "city": "Makurazaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.27",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.3"
      },
      {
        "city": "Matsue",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.45",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.07"
      },
      {
        "city": "Matsumoto",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.25",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.97"
      },
      {
        "city": "Matsushima Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.4",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.22"
      },
      {
        "city": "Matsuyama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.85",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.78"
      },
      {
        "city": "Miho Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.48",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.23"
      },
      {
        "city": "Minamidaitojima",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.83",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.23"
      },
      {
        "city": "Minamitorishima",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "24.28",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "153.98"
      },
      {
        "city": "Misawa Ab",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.7",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.37"
      },
      {
        "city": "Mishima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.12",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.93"
      },
      {
        "city": "Mito",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.38",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.47"
      },
      {
        "city": "Miyakejima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.12",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.52"
      },
      {
        "city": "Miyako",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.65",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.97"
      },
      {
        "city": "Miyakojima",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "24.8",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "125.28"
      },
      {
        "city": "Miyakonojo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.08"
      },
      {
        "city": "Miyazaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.93",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.42"
      },
      {
        "city": "Mombetsu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.35",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "143.35"
      },
      {
        "city": "Morioka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.7",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.17"
      },
      {
        "city": "Muroran",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.32",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.98"
      },
      {
        "city": "Murotomisaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.25",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.18"
      },
      {
        "city": "Mutsu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.28",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.22"
      },
      {
        "city": "Nagano",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.67",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.2"
      },
      {
        "city": "Nagasaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "129.87"
      },
      {
        "city": "Nagoya",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.17",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.97"
      },
      {
        "city": "Nagoya Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.25",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.92"
      },
      {
        "city": "Naha",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.2",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "127.68"
      },
      {
        "city": "Naha Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "26.2",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "127.65"
      },
      {
        "city": "Nara",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.7",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.83"
      },
      {
        "city": "Naze",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "28.38",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "129.5"
      },
      {
        "city": "Nemuro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.33",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "145.58"
      },
      {
        "city": "New Tokyo Internati",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.77",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.38"
      },
      {
        "city": "Niigata",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.92",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.05"
      },
      {
        "city": "Nikko",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "36.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.5"
      },
      {
        "city": "Nobeoka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.58",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.65"
      },
      {
        "city": "Nyutabaru Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.08",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.45"
      },
      {
        "city": "Obihiro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.92",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "143.22"
      },
      {
        "city": "Ofunato",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.07",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.72"
      },
      {
        "city": "Oita",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.23",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.62"
      },
      {
        "city": "Okayama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.67",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.92"
      },
      {
        "city": "Okinoerabu",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "27.43",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "128.7"
      },
      {
        "city": "Omaezaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.6",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.22"
      },
      {
        "city": "Ominato Ab",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.23",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.13"
      },
      {
        "city": "Omu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.58",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "142.97"
      },
      {
        "city": "Onahama",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.95",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.9"
      },
      {
        "city": "Osaka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.68",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.52"
      },
      {
        "city": "Osaka International",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.78",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.43"
      },
      {
        "city": "Oshima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.75",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.38"
      },
      {
        "city": "Otaru",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.18",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.02"
      },
      {
        "city": "Owase",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.07",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.2"
      },
      {
        "city": "Ozuki Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.05",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.05"
      },
      {
        "city": "Rumoi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.95",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.63"
      },
      {
        "city": "Saga",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.27",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.3"
      },
      {
        "city": "Saigo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.2",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.33"
      },
      {
        "city": "Sakai",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.55",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.23"
      },
      {
        "city": "Sakata",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.92",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.85"
      },
      {
        "city": "Sapporo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.07",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.33"
      },
      {
        "city": "Sasebo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.15",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "129.73"
      },
      {
        "city": "Sendai",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.27",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.9"
      },
      {
        "city": "Shimizu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.72",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.02"
      },
      {
        "city": "Shimofusa Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.8",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.02"
      },
      {
        "city": "Shimonoseki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.95",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.93"
      },
      {
        "city": "Shinjo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.75",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.32"
      },
      {
        "city": "Shionomisaki",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.45",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.77"
      },
      {
        "city": "Shirakawa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.13",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.22"
      },
      {
        "city": "Shizuhama Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.82",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.3"
      },
      {
        "city": "Shizuoka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.98",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.4"
      },
      {
        "city": "Sukumo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.92",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.7"
      },
      {
        "city": "Sumoto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.33",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.9"
      },
      {
        "city": "Suttsu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.8",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.22"
      },
      {
        "city": "Suwa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.05",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.12"
      },
      {
        "city": "Tadotsu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.28",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.75"
      },
      {
        "city": "Takada",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.1",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "138.25"
      },
      {
        "city": "Takamatsu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.32",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.05"
      },
      {
        "city": "Takayama",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.15",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.25"
      },
      {
        "city": "Tanegashima",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "30.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.98"
      },
      {
        "city": "Tateyama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.98",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.87"
      },
      {
        "city": "Tateyama Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.98",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.83"
      },
      {
        "city": "Tokushima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.07",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.57"
      },
      {
        "city": "Tokushima Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.13",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.62"
      },
      {
        "city": "Tokyo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.68",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.77"
      },
      {
        "city": "Tokyo International",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.55",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.78"
      },
      {
        "city": "Tomakomai",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.62",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.55"
      },
      {
        "city": "Tottori",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.48",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.2"
      },
      {
        "city": "Toyama",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.72",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "137.2"
      },
      {
        "city": "Toyooka",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.53",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.82"
      },
      {
        "city": "Tsu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.52"
      },
      {
        "city": "Tsuiki Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.68",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.05"
      },
      {
        "city": "Tsuruga",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.65",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.07"
      },
      {
        "city": "Tsuyama",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.07",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "134.02"
      },
      {
        "city": "Ueno",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.77",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.15"
      },
      {
        "city": "Unzendake",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "32.73",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.27"
      },
      {
        "city": "Urakawa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.17",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "142.78"
      },
      {
        "city": "Ushibuka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.72",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.03"
      },
      {
        "city": "Utsunomiya",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.55",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.87"
      },
      {
        "city": "Uwajima",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.23",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "132.55"
      },
      {
        "city": "Wajima",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.4",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.9"
      },
      {
        "city": "Wakamatsu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.48",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.92"
      },
      {
        "city": "Wakayama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.23",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "135.17"
      },
      {
        "city": "Wakkanai",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "45.42",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "141.68"
      },
      {
        "city": "Yakushima",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "30.38",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "130.67"
      },
      {
        "city": "Yamagata",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.25",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "140.35"
      },
      {
        "city": "Yamaguchi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.17",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "131.45"
      },
      {
        "city": "Yokkaichi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.93",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "136.58"
      },
      {
        "city": "Yokohama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.43",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.65"
      },
      {
        "city": "Yokosuka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.28",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.67"
      },
      {
        "city": "Yokota (Jasdf/Usaf)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.75",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "139.35"
      },
      {
        "city": "Yonago",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.43",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "133.33"
      },
      {
        "city": "Yonagunijima",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "24.47",
        "country": "Japan",
        "country_id": "JPN",
        "lon": "123.02"
      }
    ]
  },
  {
    "country": "Jersey",
    "cities": [
      {
        "city": "Jersey Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.22",
        "country": "Jersey",
        "country_id": "JEY",
        "lon": "-2.2"
      }
    ]
  },
  {
    "country": "Jordan",
    "cities": [
      {
        "city": "Amman Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "31.98",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "35.98"
      },
      {
        "city": "Aqaba Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "29.55",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "35"
      },
      {
        "city": "H-4 Irwaished",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "32.5",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "38.2"
      },
      {
        "city": "Irbed",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.55",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "35.85"
      },
      {
        "city": "Maan",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "30.17",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "35.78"
      },
      {
        "city": "Mafraq",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "32.37",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "36.25"
      },
      {
        "city": "Queen Alia Airport",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "31.72",
        "country": "Jordan",
        "country_id": "JOR",
        "lon": "35.98"
      }
    ]
  },
  {
    "country": "Kazakhstan",
    "cities": [
      {
        "city": "Akkol",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "70.95"
      },
      {
        "city": "Akkuduk",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "42.97",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "54.12"
      },
      {
        "city": "Aktobe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.28",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "57.15"
      },
      {
        "city": "Almaty",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.23",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "76.93"
      },
      {
        "city": "Amangeldy",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.13",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "65.23"
      },
      {
        "city": "Aralskoe More",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "46.78",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "61.65"
      },
      {
        "city": "Astana",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.13",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "71.37"
      },
      {
        "city": "Atbasar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.82",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "68.37"
      },
      {
        "city": "Atyrau",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "47.12",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "51.92"
      },
      {
        "city": "Balhash",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "46.8",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "75.08"
      },
      {
        "city": "Balkasino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.53",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "68.75"
      },
      {
        "city": "Barshatas",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.17",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "78.67"
      },
      {
        "city": "Berlik",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.88",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.52"
      },
      {
        "city": "Blacoveschenka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.37",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "66.97"
      },
      {
        "city": "Bolshe Narymskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.2",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "84.52"
      },
      {
        "city": "Cardara",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "41.37",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "68"
      },
      {
        "city": "Celkar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.85",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "59.62"
      },
      {
        "city": "Cirik-Rabat",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "44.07",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "62.9"
      },
      {
        "city": "Dzhambejty",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "50.25",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "52.57"
      },
      {
        "city": "Dzhusaly",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "45.5",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "64.08"
      },
      {
        "city": "Esil",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.88",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "66.33"
      },
      {
        "city": "Fort Shevchenko",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "44.55",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "50.25"
      },
      {
        "city": "Irgiz",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.62",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "61.27"
      },
      {
        "city": "Irtyshsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.35",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "75.45"
      },
      {
        "city": "Isilkul",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.9",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "71.25"
      },
      {
        "city": "Karaganda",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.8",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "73.15"
      },
      {
        "city": "Karsakpaj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.83",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "66.75"
      },
      {
        "city": "Kazalinsk",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "45.77",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "62.12"
      },
      {
        "city": "Kokpekty",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.75",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "82.37"
      },
      {
        "city": "Kokshetay",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.28",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.38"
      },
      {
        "city": "Kozasaj",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "48.22",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "57.12"
      },
      {
        "city": "Kulsary",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "46.8",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "53.92"
      },
      {
        "city": "Kustanai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.22",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "63.62"
      },
      {
        "city": "Kyzylorda",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "44.85",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "65.5"
      },
      {
        "city": "Kzylzar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.3",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.65"
      },
      {
        "city": "Leninogorsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.33",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "83.55"
      },
      {
        "city": "Mointy",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.2",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "73.35"
      },
      {
        "city": "Mugodzarskaja",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.63",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "58.5"
      },
      {
        "city": "Novyj Ushtogan",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "47.9",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "48.8"
      },
      {
        "city": "Pavlodar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.3",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "76.93"
      },
      {
        "city": "Petropavlovsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.83",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.15"
      },
      {
        "city": "Poltavka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.37",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "71.75"
      },
      {
        "city": "Ruzaevka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.82",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "66.97"
      },
      {
        "city": "Sam",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "45.4",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "56.12"
      },
      {
        "city": "Semijarka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.87",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "78.35"
      },
      {
        "city": "Semipalatinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.42",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "80.3"
      },
      {
        "city": "Shymkent",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.32",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.7"
      },
      {
        "city": "Sucinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.95",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "70.22"
      },
      {
        "city": "Taipak",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "49.05",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "51.87"
      },
      {
        "city": "Tasty",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "44.8",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.12"
      },
      {
        "city": "Temir",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.15",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "57.12"
      },
      {
        "city": "Torgai",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.63",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "63.5"
      },
      {
        "city": "Turkestan",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "43.27",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "68.22"
      },
      {
        "city": "Uc-Aral",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "46.17",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "80.93"
      },
      {
        "city": "Uil",
        "state": "",
        "cz": "6B",
        "state_id": "",
        "lat": "49.07",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "54.68"
      },
      {
        "city": "Ujuk",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "43.78",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "70.93"
      },
      {
        "city": "Ulanbel`",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "44.8",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "71.07"
      },
      {
        "city": "Uralsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.25",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "51.28"
      },
      {
        "city": "Urdzhar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.12",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "81.62"
      },
      {
        "city": "Uricky",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.32",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "65.55"
      },
      {
        "city": "Volodarskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.32",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "68.1"
      },
      {
        "city": "Zajsan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.47",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "84.92"
      },
      {
        "city": "Zhaltyr",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.62",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "69.8"
      },
      {
        "city": "Zhambyl",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "42.85",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "71.38"
      },
      {
        "city": "Zharkent",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "44.17",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "80.07"
      },
      {
        "city": "Zharyk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.85",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "72.87"
      },
      {
        "city": "Zhezkazgan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.8",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "67.72"
      },
      {
        "city": "Zlikha",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "45.25",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "67.07"
      },
      {
        "city": "Zmeinogorsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.15",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "82.2"
      },
      {
        "city": "Zverinogolovskaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.47",
        "country": "Kazakhstan",
        "country_id": "KAZ",
        "lon": "64.87"
      }
    ]
  },
  {
    "country": "Kenya",
    "cities": [
      {
        "city": "Embu",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-0.5",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "37.45"
      },
      {
        "city": "Garissa",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-0.47",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "39.63"
      },
      {
        "city": "Kisumu",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-0.1",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "34.75"
      },
      {
        "city": "Kitale",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "1.02",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "35"
      },
      {
        "city": "Lodwar",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "3.12",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "35.62"
      },
      {
        "city": "Makindu",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-2.28",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "37.83"
      },
      {
        "city": "Marsabit",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "2.3",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "37.9"
      },
      {
        "city": "Meru",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "0.08",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "37.65"
      },
      {
        "city": "Mombasa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-4.03",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "39.62"
      },
      {
        "city": "Moyale",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "3.53",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "39.03"
      },
      {
        "city": "Nairobi/Kenyatta Ai",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-1.32",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "36.92"
      },
      {
        "city": "Nakuru",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-0.27",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "36.1"
      },
      {
        "city": "Nyeri",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-0.5",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "36.97"
      },
      {
        "city": "Voi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-3.4",
        "country": "Kenya",
        "country_id": "KEN",
        "lon": "38.57"
      }
    ]
  },
  {
    "country": "Kiribati",
    "cities": [
      {
        "city": "Tarawa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "1.35",
        "country": "Kiribati",
        "country_id": "KIR",
        "lon": "172.92"
      }
    ]
  },
  {
    "country": "Korea, Democratic People's Republic of",
    "cities": [
      {
        "city": "Anju",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.62",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "125.65"
      },
      {
        "city": "Changjin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "40.37",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "127.25"
      },
      {
        "city": "Changjon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.73",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "128.18"
      },
      {
        "city": "Chongjin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "41.78",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "129.82"
      },
      {
        "city": "Chunggang",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "41.78",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "126.88"
      },
      {
        "city": "Haeju",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.03",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "125.7"
      },
      {
        "city": "Hamheung",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.93",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "127.55"
      },
      {
        "city": "Huichon",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.17",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "126.25"
      },
      {
        "city": "Hyesan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.4",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "128.17"
      },
      {
        "city": "Kaesong",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "37.97",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "126.57"
      },
      {
        "city": "Kanggye",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "40.97",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "126.6"
      },
      {
        "city": "Kimchaek",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.67",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "129.2"
      },
      {
        "city": "Kusong",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.98",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "125.25"
      },
      {
        "city": "Nampo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.72",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "125.38"
      },
      {
        "city": "Pungsan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "40.82",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "128.15"
      },
      {
        "city": "Pyonggang",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.42",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "127.28"
      },
      {
        "city": "Pyongyang",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.03",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "125.78"
      },
      {
        "city": "Ryongyon",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.15",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "124.88"
      },
      {
        "city": "Samjiyon",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.82",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "128.3"
      },
      {
        "city": "Sariwon",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.52",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "125.77"
      },
      {
        "city": "Senbong",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.32",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "130.4"
      },
      {
        "city": "Singye",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.5",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "126.53"
      },
      {
        "city": "Sinpo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.03",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "128.18"
      },
      {
        "city": "Sinuiju",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.1",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "124.38"
      },
      {
        "city": "Supung",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "40.45",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "124.93"
      },
      {
        "city": "Wonsan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.18",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "127.43"
      },
      {
        "city": "Yangdok",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.22",
        "country": "Korea, Democratic People's Republic of",
        "country_id": "PRK",
        "lon": "126.65"
      }
    ]
  },
  {
    "country": "Korea, Republic of",
    "cities": [
      {
        "city": "Andong",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.57",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.72"
      },
      {
        "city": "Baengnyeongdo Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.93",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "124.67"
      },
      {
        "city": "Busan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.1",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "129.03"
      },
      {
        "city": "Cheju",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.28",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.17"
      },
      {
        "city": "Cheongju",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.63",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.45"
      },
      {
        "city": "Cheongju Intl Airpo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.72",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.5"
      },
      {
        "city": "Cheorwon",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "38.15",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.3"
      },
      {
        "city": "Chuncheon",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "37.9",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.73"
      },
      {
        "city": "Chupungnyeong",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.22",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128"
      },
      {
        "city": "Daegu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.88",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.62"
      },
      {
        "city": "Daegu Intl Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.9",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.67"
      },
      {
        "city": "Daegwallyeong",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "37.68",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.77"
      },
      {
        "city": "Daejeon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.37",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.37"
      },
      {
        "city": "Donghae Radar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.5",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "129.13"
      },
      {
        "city": "Gangneung",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.75",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.9"
      },
      {
        "city": "Gangneung Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.75",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.95"
      },
      {
        "city": "Gimhae Intl Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.18",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.93"
      },
      {
        "city": "Gimpo Intl Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "37.57",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.78"
      },
      {
        "city": "Gunsan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.77"
      },
      {
        "city": "Gwangju",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.17",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.9"
      },
      {
        "city": "Gwangju Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.12",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.82"
      },
      {
        "city": "Incheon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.47",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.63"
      },
      {
        "city": "Jeju",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.52",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.53"
      },
      {
        "city": "Jeju Intl Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.52",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.5"
      },
      {
        "city": "Jeonju",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.82",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.15"
      },
      {
        "city": "Jinju",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.2",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.12"
      },
      {
        "city": "Kunsan (Us/Kor-Afb)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.9",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.62"
      },
      {
        "city": "Masan",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.18",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.57"
      },
      {
        "city": "Moesulpo Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.2",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.27"
      },
      {
        "city": "Mokpo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.82",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.38"
      },
      {
        "city": "Osan Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.1",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.03"
      },
      {
        "city": "Pohang",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.03",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "129.38"
      },
      {
        "city": "Pohang Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.98",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "129.42"
      },
      {
        "city": "Pyongtaek (A-511)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.97",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.03"
      },
      {
        "city": "Sachon (Kor-Afb)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.08",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.08"
      },
      {
        "city": "Seogwipo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.25",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.57"
      },
      {
        "city": "Seosan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.77",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.5"
      },
      {
        "city": "Seoul",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.57",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.97"
      },
      {
        "city": "Seoul (Kor-Af Hq)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.5",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.93"
      },
      {
        "city": "Seoul Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.43",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.12"
      },
      {
        "city": "Sokcho",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.25",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.57"
      },
      {
        "city": "Suwon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.27",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.98"
      },
      {
        "city": "Tongyeong",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.85",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.43"
      },
      {
        "city": "Uljin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.98",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "129.42"
      },
      {
        "city": "Ulleungdo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.48",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "130.9"
      },
      {
        "city": "Ulsan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "35.55",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "129.32"
      },
      {
        "city": "Wando",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.4",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "126.7"
      },
      {
        "city": "Wonju",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.33",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.95"
      },
      {
        "city": "Yecheon Ab",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "36.63",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.35"
      },
      {
        "city": "Yeongwol",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "37.18",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "128.47"
      },
      {
        "city": "Yeosu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.73",
        "country": "Korea, Republic of",
        "country_id": "KOR",
        "lon": "127.75"
      }
    ]
  },
  {
    "country": "Kuwait",
    "cities": [
      {
        "city": "Kuwait Internationa",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "29.22",
        "country": "Kuwait",
        "country_id": "KWT",
        "lon": "47.97"
      }
    ]
  },
  {
    "country": "Kyrgyzstan",
    "cities": [
      {
        "city": "Bishkek",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.85",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "74.53"
      },
      {
        "city": "Dzhalal-Abad",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.92",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "72.95"
      },
      {
        "city": "Naryn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "41.43",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "76"
      },
      {
        "city": "Osh",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.53",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "72.8"
      },
      {
        "city": "Talas",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.52",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "72.22"
      },
      {
        "city": "Tian-Shan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "41.88",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "78.23"
      },
      {
        "city": "Tokmak",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.83",
        "country": "Kyrgyzstan",
        "country_id": "KGZ",
        "lon": "75.28"
      }
    ]
  },
  {
    "country": "Latvia",
    "cities": [
      {
        "city": "Ainazi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.87",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "24.37"
      },
      {
        "city": "Daugavpils",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.87",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "26.62"
      },
      {
        "city": "Gulbene",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.13",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "26.72"
      },
      {
        "city": "Kolka",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.75",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "22.6"
      },
      {
        "city": "Liepaja",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.48",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "21.02"
      },
      {
        "city": "Mersrags",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.35",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "23.12"
      },
      {
        "city": "Rezekne",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.53",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "27.27"
      },
      {
        "city": "Riga",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.97",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "24.05"
      },
      {
        "city": "Riga",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.92",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "23.97"
      },
      {
        "city": "Valga",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.78",
        "country": "Latvia",
        "country_id": "LTV",
        "lon": "26.03"
      }
    ]
  },
  {
    "country": "Lebanon",
    "cities": [
      {
        "city": "Beyrouth (Aeroport)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "33.82",
        "country": "Lebanon",
        "country_id": "LBN",
        "lon": "35.48"
      }
    ]
  },
  {
    "country": "Libya",
    "cities": [
      {
        "city": "Agedabia",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.72",
        "country": "Libya",
        "country_id": "LBY",
        "lon": "20.17"
      },
      {
        "city": "Benina",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "32.1",
        "country": "Libya",
        "country_id": "LBY",
        "lon": "20.27"
      },
      {
        "city": "Misurata",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "32.42",
        "country": "Libya",
        "country_id": "LBY",
        "lon": "15.05"
      },
      {
        "city": "Sirte",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.2",
        "country": "Libya",
        "country_id": "LBY",
        "lon": "16.58"
      },
      {
        "city": "Tripoli Internation",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "32.7",
        "country": "Libya",
        "country_id": "LBY",
        "lon": "13.08"
      },
      {
        "city": "Zuara",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "32.88",
        "country": "Libya",
        "country_id": "LBY",
        "lon": "12.08"
      }
    ]
  },
  {
    "country": "Liechtenstein",
    "cities": [
      {
        "city": "Vaduz (Liechtenstein)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.13",
        "country": "Liechtenstein",
        "country_id": "LIE",
        "lon": "9.52"
      }
    ]
  },
  {
    "country": "Lithuania",
    "cities": [
      {
        "city": "Birzai",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.2",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "24.77"
      },
      {
        "city": "Kaunas",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.88",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "23.83"
      },
      {
        "city": "Klaipeda",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.73",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "21.07"
      },
      {
        "city": "Laukuva",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.62",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "22.23"
      },
      {
        "city": "Siauliai",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.93",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "23.32"
      },
      {
        "city": "Utena",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.53",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "25.6"
      },
      {
        "city": "Vilnius",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.63",
        "country": "Lithuania",
        "country_id": "LTU",
        "lon": "25.28"
      }
    ]
  },
  {
    "country": "Luxembourg",
    "cities": [
      {
        "city": "Luxembourg/Luxembou",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.62",
        "country": "Luxembourg",
        "country_id": "LUX",
        "lon": "6.22"
      }
    ]
  },
  {
    "country": "Macao",
    "cities": [
      {
        "city": "Taipa Grande",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.15",
        "country": "Macao",
        "country_id": "MAC",
        "lon": "113.6"
      }
    ]
  },
  {
    "country": "Macedonia",
    "cities": [
      {
        "city": "Bitola",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.05",
        "country": "Macedonia",
        "country_id": "MKD",
        "lon": "21.37"
      },
      {
        "city": "Kriva Palanka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.2",
        "country": "Macedonia",
        "country_id": "MKD",
        "lon": "22.33"
      },
      {
        "city": "Ohrid",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.12",
        "country": "Macedonia",
        "country_id": "MKD",
        "lon": "20.8"
      },
      {
        "city": "Skopje- Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.97",
        "country": "Macedonia",
        "country_id": "MKD",
        "lon": "21.65"
      }
    ]
  },
  {
    "country": "Madagascar",
    "cities": [
      {
        "city": "Antananarivo/Ivato",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-18.8",
        "country": "Madagascar",
        "country_id": "MDG",
        "lon": "47.48"
      },
      {
        "city": "Mahajanga",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.67",
        "country": "Madagascar",
        "country_id": "MDG",
        "lon": "46.35"
      },
      {
        "city": "Toamasina",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-18.12",
        "country": "Madagascar",
        "country_id": "MDG",
        "lon": "49.4"
      }
    ]
  },
  {
    "country": "Malaysia",
    "cities": [
      {
        "city": "Bintulu",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "3.2",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "113.03"
      },
      {
        "city": "Kota Bharu",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.17",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "102.28"
      },
      {
        "city": "Kota Kinabalu",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "5.93",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "116.05"
      },
      {
        "city": "Kuala Lumpur Subang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "3.12",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "101.55"
      },
      {
        "city": "Kuantan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "3.78",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "103.22"
      },
      {
        "city": "Kuching",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "1.48",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "110.33"
      },
      {
        "city": "Labuan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "5.3",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "115.25"
      },
      {
        "city": "Malacca",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "2.27",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "102.25"
      },
      {
        "city": "Miri",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "4.33",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "113.98"
      },
      {
        "city": "Penang/Bayan Lepas",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "5.3",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "100.27"
      },
      {
        "city": "Sandakan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "5.9",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "118.07"
      },
      {
        "city": "Sibu",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "2.33",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "111.83"
      },
      {
        "city": "Sitiawan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "4.22",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "100.7"
      },
      {
        "city": "Tawau",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "4.27",
        "country": "Malaysia",
        "country_id": "MYS",
        "lon": "117.88"
      }
    ]
  },
  {
    "country": "Maldives",
    "cities": [
      {
        "city": "Male",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "4.2",
        "country": "Maldives",
        "country_id": "MDV",
        "lon": "73.53"
      }
    ]
  },
  {
    "country": "Mali",
    "cities": [
      {
        "city": "Bamako/Senou",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.53",
        "country": "Mali",
        "country_id": "MLI",
        "lon": "-7.95"
      }
    ]
  },
  {
    "country": "Malta",
    "cities": [
      {
        "city": "Luqa",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.85",
        "country": "Malta",
        "country_id": "MLT",
        "lon": "14.48"
      }
    ]
  },
  {
    "country": "Marshall Islands",
    "cities": [
      {
        "city": "Kwajalein Missle Ran",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.72",
        "country": "Marshall Islands",
        "country_id": "MHL",
        "lon": "167.73"
      },
      {
        "city": "Majuro Wso Ap",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.08",
        "country": "Marshall Islands",
        "country_id": "MHL",
        "lon": "171.38"
      }
    ]
  },
  {
    "country": "Martinique",
    "cities": [
      {
        "city": "Le Lamentin",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.6",
        "country": "Martinique",
        "country_id": "MTQ",
        "lon": "-61"
      }
    ]
  },
  {
    "country": "Mauritania",
    "cities": [
      {
        "city": "Nouadhibou",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "20.93",
        "country": "Mauritania",
        "country_id": "MRT",
        "lon": "-17.03"
      },
      {
        "city": "Nouakchott",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "18.1",
        "country": "Mauritania",
        "country_id": "MRT",
        "lon": "-15.95"
      }
    ]
  },
  {
    "country": "Mauritius",
    "cities": [
      {
        "city": "Agalega",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-10.43",
        "country": "Mauritius",
        "country_id": "MUS",
        "lon": "56.75"
      },
      {
        "city": "Plaisance (Mauritius)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.43",
        "country": "Mauritius",
        "country_id": "MUS",
        "lon": "57.68"
      },
      {
        "city": "Rodrigues",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.68",
        "country": "Mauritius",
        "country_id": "MUS",
        "lon": "63.42"
      },
      {
        "city": "Vacoas (Mauritius)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-20.3",
        "country": "Mauritius",
        "country_id": "MUS",
        "lon": "57.5"
      }
    ]
  },
  {
    "country": "Mayotte",
    "cities": [
      {
        "city": "Dzaoudzi/Pamanzi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-12.8",
        "country": "Mayotte",
        "country_id": "MYT",
        "lon": "45.28"
      }
    ]
  },
  {
    "country": "Mexico",
    "cities": [
      {
        "city": "Aerop. Internaciona",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "19.43",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-99.13"
      },
      {
        "city": "Aerop.Internacional",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.98",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-89.65"
      },
      {
        "city": "Cancun Intl",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.03",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-86.87"
      },
      {
        "city": "De Guanajuato Intl",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "20.98",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-101.48"
      },
      {
        "city": "Don Miguel Y Hidalg",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "20.52",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-103.3"
      },
      {
        "city": "General Abelardo L",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "32.53",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-116.97"
      },
      {
        "city": "General Francisco J",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.28",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-97.87"
      },
      {
        "city": "General Heriberto J",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.13",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-96.18"
      },
      {
        "city": "General Juan N Alva",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.75",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-99.75"
      },
      {
        "city": "General Leobardo C",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "22.88",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-102.68"
      },
      {
        "city": "General Mariano Esc",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "25.77",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-100.1"
      },
      {
        "city": "General Rafael Buel",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.15",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-106.27"
      },
      {
        "city": "Guanajuato",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "21",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-101.28"
      },
      {
        "city": "Licenciado Adolfo L",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "19.33",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-99.57"
      },
      {
        "city": "Licenciado Benito J",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "19.43",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-99.07"
      },
      {
        "city": "Licenciado Gustavo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.67",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-105.25"
      },
      {
        "city": "Mazatlan/G.Buelna I",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23.15",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-106.25"
      },
      {
        "city": "Monterrey City",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "25.73",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-100.3"
      },
      {
        "city": "Puerto Vallarta",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.68",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-105.25"
      },
      {
        "city": "San Luis Potosi",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "22.18",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-100.98"
      },
      {
        "city": "Tampico/Gen Fj Mina",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.28",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-97.85"
      },
      {
        "city": "Veracruz/Gen Jara",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.15",
        "country": "Mexico",
        "country_id": "MEX",
        "lon": "-96.18"
      }
    ]
  },
  {
    "country": "Micronesia, Federated States of",
    "cities": [
      {
        "city": "Chuuk Wso Ap",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.45",
        "country": "Micronesia, Federated States of",
        "country_id": "FSM",
        "lon": "151.83"
      },
      {
        "city": "Pohnpei Wso",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.97",
        "country": "Micronesia, Federated States of",
        "country_id": "FSM",
        "lon": "158.22"
      },
      {
        "city": "Yap Island Wso Ap",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.48",
        "country": "Micronesia, Federated States of",
        "country_id": "FSM",
        "lon": "138.08"
      }
    ]
  },
  {
    "country": "Moldova",
    "cities": [
      {
        "city": "Kisinev",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.02",
        "country": "Moldova",
        "country_id": "MDA",
        "lon": "28.98"
      }
    ]
  },
  {
    "country": "Mongolia",
    "cities": [
      {
        "city": "Altai",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "46.4",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "96.25"
      },
      {
        "city": "Arvaiheer",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.27",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "102.78"
      },
      {
        "city": "Baitag",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.12",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "91.47"
      },
      {
        "city": "Baruun-Urt",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.68",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "113.28"
      },
      {
        "city": "Baruunharaa",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.92",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "106.07"
      },
      {
        "city": "Baruunturuun",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "49.65",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "94.4"
      },
      {
        "city": "Bayan-Ovoo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.78",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "112.12"
      },
      {
        "city": "Bayanbulag",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "46.83",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "98.08"
      },
      {
        "city": "Bayandelger",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.73",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "112.37"
      },
      {
        "city": "Bayanhongor",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.13",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "100.68"
      },
      {
        "city": "Bulgan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.8",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "103.55"
      },
      {
        "city": "Choibalsan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.08",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "114.55"
      },
      {
        "city": "Choir",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.45",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "108.22"
      },
      {
        "city": "Dalanzadgad",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.58",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "104.42"
      },
      {
        "city": "Dashbalbar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.55",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "114.4"
      },
      {
        "city": "Erdenemandal",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.53",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "101.38"
      },
      {
        "city": "Galuut",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "46.7",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "100.13"
      },
      {
        "city": "Hatgal",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.43",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "100.15"
      },
      {
        "city": "Hovd",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.02",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "91.57"
      },
      {
        "city": "Hujirt",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.9",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "102.77"
      },
      {
        "city": "Hutag",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.38",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "102.7"
      },
      {
        "city": "Khalkh-Gol",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.62",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "118.62"
      },
      {
        "city": "Maanti",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "47.3",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "107.48"
      },
      {
        "city": "Mandalgobi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.77",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "106.28"
      },
      {
        "city": "Matad",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.17",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "115.63"
      },
      {
        "city": "Muren",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.63",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "100.17"
      },
      {
        "city": "Omno-Gobi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.02",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "91.72"
      },
      {
        "city": "Rinchinlhumbe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.12",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "99.67"
      },
      {
        "city": "Saikhan-Ovoo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.45",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "103.9"
      },
      {
        "city": "Sainshand",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.9",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "110.12"
      },
      {
        "city": "Tarialan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.57",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "102"
      },
      {
        "city": "Tosontsengel",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "48.73",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "98.2"
      },
      {
        "city": "Tsetserleg",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.45",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "101.47"
      },
      {
        "city": "Tsogt-Ovoo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.42",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "105.32"
      },
      {
        "city": "Ulaanbaatar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.92",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "106.87"
      },
      {
        "city": "Ulaangom",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "49.8",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "92.08"
      },
      {
        "city": "Ulgi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.93",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "89.93"
      },
      {
        "city": "Uliastai",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "47.75",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "96.85"
      },
      {
        "city": "Underkhaan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.32",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "110.63"
      },
      {
        "city": "Zamyn-Uud",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.73",
        "country": "Mongolia",
        "country_id": "MNG",
        "lon": "111.9"
      }
    ]
  },
  {
    "country": "Morocco",
    "cities": [
      {
        "city": "Agadir",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "30.38",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-9.57"
      },
      {
        "city": "Agadir Al Massira",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.32",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-9.4"
      },
      {
        "city": "Al Hoceima",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.18",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-3.85"
      },
      {
        "city": "Beni-Mellal",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.37",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-6.4"
      },
      {
        "city": "Casablanca",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.57",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-7.67"
      },
      {
        "city": "Errachidia",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.93",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-4.4"
      },
      {
        "city": "Essaouira",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "31.52",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-9.78"
      },
      {
        "city": "Fes-Sais",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.93",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-4.98"
      },
      {
        "city": "Marrakech",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.62",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-8.03"
      },
      {
        "city": "Meknes",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.88",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-5.53"
      },
      {
        "city": "Midelt",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "32.68",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-4.73"
      },
      {
        "city": "Nador-Aroui",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "34.98",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-3.02"
      },
      {
        "city": "Nouasseur",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "33.37",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-7.58"
      },
      {
        "city": "Ouarzazate",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.93",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-6.9"
      },
      {
        "city": "Oujda",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "34.78",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-1.93"
      },
      {
        "city": "Rabat-Sale",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.05",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-6.77"
      },
      {
        "city": "Safi",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.28",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-9.23"
      },
      {
        "city": "Tanger (Aerodrome)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.73",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-5.9"
      },
      {
        "city": "Taza",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.22",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-4"
      },
      {
        "city": "Tetuan/Sania Ramel",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.58",
        "country": "Morocco",
        "country_id": "MAR",
        "lon": "-5.33"
      }
    ]
  },
  {
    "country": "Mozambique",
    "cities": [
      {
        "city": "Maputo/Mavalane",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.92",
        "country": "Mozambique",
        "country_id": "MOZ",
        "lon": "32.57"
      }
    ]
  },
  {
    "country": "Namibia",
    "cities": [
      {
        "city": "Walvis Bay (Pelican Bay)",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-22.88",
        "country": "Namibia",
        "country_id": "NAM",
        "lon": "14.43"
      },
      {
        "city": "Windhoek",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-22.57",
        "country": "Namibia",
        "country_id": "NAM",
        "lon": "17.1"
      }
    ]
  },
  {
    "country": "Netherlands",
    "cities": [
      {
        "city": "Amsterdam Ap Schiph",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.3",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.77"
      },
      {
        "city": "De Bilt",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.1",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.18"
      },
      {
        "city": "De Kooy",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.92",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.78"
      },
      {
        "city": "Deelen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.07",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.88"
      },
      {
        "city": "Eindhoven",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.45",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.42"
      },
      {
        "city": "F3-Fb-1",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.85",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.7"
      },
      {
        "city": "Gilze Rijen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.57",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.93"
      },
      {
        "city": "Groningen Ap Eelde",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.13",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "6.58"
      },
      {
        "city": "Herwijnen Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.87",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.15"
      },
      {
        "city": "Hoek Van Holland",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.98",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.1"
      },
      {
        "city": "Hoogeveen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.75",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "6.52"
      },
      {
        "city": "Houtrib",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.53",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.43"
      },
      {
        "city": "Hupsel Aws",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.07",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "6.65"
      },
      {
        "city": "Ijmuiden",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.47",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.57"
      },
      {
        "city": "K13-A",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.22",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "3.22"
      },
      {
        "city": "Leeuwarden",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.22",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.77"
      },
      {
        "city": "Lelystad Aws",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.45",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.53"
      },
      {
        "city": "Maastricht Ap Zuid",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.92",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.78"
      },
      {
        "city": "Marknesse Aws",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.7",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.88"
      },
      {
        "city": "Nieuw Beerta Aws",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.2",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "7.15"
      },
      {
        "city": "Rotterdam Ap Zestie",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.95",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.45"
      },
      {
        "city": "Soesterberg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.13",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.28"
      },
      {
        "city": "Terschelling(Lgt-H)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.37",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.22"
      },
      {
        "city": "Twenthe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.27",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "6.9"
      },
      {
        "city": "Valkenburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.18",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.42"
      },
      {
        "city": "Vlieland",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.25",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.92"
      },
      {
        "city": "Vlissingen",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.45",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "3.6"
      },
      {
        "city": "Volkel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.65",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "5.7"
      },
      {
        "city": "Wilhelminadorp Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.53",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "3.9"
      },
      {
        "city": "Woensdrecht",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.45",
        "country": "Netherlands",
        "country_id": "NLD",
        "lon": "4.33"
      }
    ]
  },
  {
    "country": "New Caledonia",
    "cities": [
      {
        "city": "Ile Surprise",
        "state": "",
        "cz": "",
        "state_id": "",
        "lat": "-18.48",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "163.08"
      },
      {
        "city": "Koumac (Nlle-Caledonie)",
        "state": "",
        "cz": "",
        "state_id": "",
        "lat": "-20.57",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "164.28"
      },
      {
        "city": "La Roche (Ile Mare)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-21.48",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "168.03"
      },
      {
        "city": "La Tontouta (Nlle-Caledonie)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-22.02",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "166.22"
      },
      {
        "city": "Matthew (Ilot)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-22.33",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "171.33"
      },
      {
        "city": "Moue (Ile Des Pins)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-22.6",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "167.45"
      },
      {
        "city": "Noumea (Nlle-Caledonie)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-22.27",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "166.45"
      },
      {
        "city": "Ouanaham (Ile Lifou)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-20.77",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "167.23"
      },
      {
        "city": "Ouloup (Ile Ouvea)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.65",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "166.58"
      },
      {
        "city": "Poindimie (Nlle-Cal)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.93",
        "country": "New Caledonia",
        "country_id": "NCL",
        "lon": "165.32"
      }
    ]
  },
  {
    "country": "New Zealand",
    "cities": [
      {
        "city": "Auckland Aero Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.8"
      },
      {
        "city": "Auckland Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.02",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.8"
      },
      {
        "city": "Campbell Island Aws",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-52.55",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "169.15"
      },
      {
        "city": "Cape Campbell Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-41.73",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.2"
      },
      {
        "city": "Cape Reinga Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.43",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "172.68"
      },
      {
        "city": "Castlepoint",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-40.9",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "176.23"
      },
      {
        "city": "Chatham Islands Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.95",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "-176.57"
      },
      {
        "city": "Christchurch",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.48",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "172.55"
      },
      {
        "city": "Christchurch Aero A",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.48",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "172.52"
      },
      {
        "city": "Enderby Island Aws",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-50.48",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "166.3"
      },
      {
        "city": "Farewell Spit Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-40.55",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "173"
      },
      {
        "city": "Gisborne Aerodrome",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-38.65",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "177.98"
      },
      {
        "city": "Haast Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-43.87",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "169"
      },
      {
        "city": "Hicks Bay Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.55",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "178.3"
      },
      {
        "city": "Hokitika Aerodrome",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.72",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "170.98"
      },
      {
        "city": "Invercargill Aerodr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-46.42",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "168.32"
      },
      {
        "city": "Invercargill Airpor",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-46.42",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "168.33"
      },
      {
        "city": "Kaikoura",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-42.42",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "173.7"
      },
      {
        "city": "Kaitaia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-35.1",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "173.27"
      },
      {
        "city": "Mokohinau Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-35.9",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "175.1"
      },
      {
        "city": "Napier Aerodrome Aw",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-39.45",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "176.85"
      },
      {
        "city": "Nelson Aerodrome Aw",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-41.28",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "173.23"
      },
      {
        "city": "New Plymouth Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-39.02",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.18"
      },
      {
        "city": "Palmerston North Aw",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-40.32",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "175.6"
      },
      {
        "city": "Paraparaumu Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-40.9",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.98"
      },
      {
        "city": "Purerua",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-35.12",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.02"
      },
      {
        "city": "Puysegur Point Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-46.17",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "166.63"
      },
      {
        "city": "Queenstown Aerodrom",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-45.02",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "168.73"
      },
      {
        "city": "Raoul Island (Aut)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-29.25",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "-177.92"
      },
      {
        "city": "Secretary Island Aw",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-45.22",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "166.88"
      },
      {
        "city": "South West Cape Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-47.27",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "167.45"
      },
      {
        "city": "Taiaroa Head",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-45.77",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "170.73"
      },
      {
        "city": "Taupo Aws",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-38.73",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "176.07"
      },
      {
        "city": "Tauranga Aero Aws",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-37.67",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "176.2"
      },
      {
        "city": "Timaru Aerodrome Aw",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-44.3",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "171.23"
      },
      {
        "city": "Waiouru Aws",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "-39.47",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "175.68"
      },
      {
        "city": "Wellington Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-41.33",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "174.8"
      },
      {
        "city": "Westport Aerodrome",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-41.73",
        "country": "New Zealand",
        "country_id": "NZL",
        "lon": "171.57"
      }
    ]
  },
  {
    "country": "Nicaragua",
    "cities": [
      {
        "city": "Managua A.C.Sandino",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.15",
        "country": "Nicaragua",
        "country_id": "NIC",
        "lon": "-86.17"
      }
    ]
  },
  {
    "country": "Niger",
    "cities": [
      {
        "city": "Agadez",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "16.97",
        "country": "Niger",
        "country_id": "NER",
        "lon": "7.97"
      },
      {
        "city": "Birni-Nkonni",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.8",
        "country": "Niger",
        "country_id": "NER",
        "lon": "5.25"
      },
      {
        "city": "Gaya",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "11.88",
        "country": "Niger",
        "country_id": "NER",
        "lon": "3.45"
      },
      {
        "city": "Goure",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.98",
        "country": "Niger",
        "country_id": "NER",
        "lon": "10.3"
      },
      {
        "city": "Magaria",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "12.98",
        "country": "Niger",
        "country_id": "NER",
        "lon": "8.93"
      },
      {
        "city": "Maine-Soroa",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.23",
        "country": "Niger",
        "country_id": "NER",
        "lon": "11.98"
      },
      {
        "city": "Maradi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.47",
        "country": "Niger",
        "country_id": "NER",
        "lon": "7.08"
      },
      {
        "city": "Nguigmi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.25",
        "country": "Niger",
        "country_id": "NER",
        "lon": "13.12"
      },
      {
        "city": "Niamey-Aero",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.48",
        "country": "Niger",
        "country_id": "NER",
        "lon": "2.17"
      },
      {
        "city": "Tahoua",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.9",
        "country": "Niger",
        "country_id": "NER",
        "lon": "5.25"
      },
      {
        "city": "Tillabery",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.2",
        "country": "Niger",
        "country_id": "NER",
        "lon": "1.45"
      },
      {
        "city": "Zinder",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.78",
        "country": "Niger",
        "country_id": "NER",
        "lon": "8.98"
      }
    ]
  },
  {
    "country": "Niue",
    "cities": [
      {
        "city": "Alofi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.07",
        "country": "Niue",
        "country_id": "NIU",
        "lon": "-169.92"
      }
    ]
  },
  {
    "country": "Norfolk Island",
    "cities": [
      {
        "city": "Norfolk Island Aero",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-29.03",
        "country": "Norfolk Island",
        "country_id": "NFK",
        "lon": "167.93"
      }
    ]
  },
  {
    "country": "North Mariana Islands",
    "cities": [
      {
        "city": "Saipan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.12",
        "country": "North Mariana Islands",
        "country_id": "MNP",
        "lon": "145.7"
      }
    ]
  },
  {
    "country": "Norway",
    "cities": [
      {
        "city": "Alta Lufthavn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "69.98",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "23.37"
      },
      {
        "city": "Andoya",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "69.3",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "16.13"
      },
      {
        "city": "Banak",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "70.07",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "24.98"
      },
      {
        "city": "Bardufoss",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "69.07",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "18.53"
      },
      {
        "city": "Bergen/Flesland",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.28",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "5.23"
      },
      {
        "city": "Bergen/Florida",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "60.38",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "5.33"
      },
      {
        "city": "Bodo Vi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "67.27",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "14.37"
      },
      {
        "city": "Ekofisk Oil Pltfrm",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "56.55",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "3.25"
      },
      {
        "city": "Fagernes",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.98",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "9.23"
      },
      {
        "city": "Ferder Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.03",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "10.53"
      },
      {
        "city": "Fokstua Ii",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.12",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "9.28"
      },
      {
        "city": "Fruholmen Fyr",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "71.1",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "24"
      },
      {
        "city": "Geilo-Geilostolen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.52",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "8.22"
      },
      {
        "city": "Gullfax (Platform)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "61.03",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "2.03"
      },
      {
        "city": "Hammerfest",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "70.67",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "23.67"
      },
      {
        "city": "Jan Mayen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "70.93",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "-8.67"
      },
      {
        "city": "Kautokeino",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "23.03"
      },
      {
        "city": "Kirkenes",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "69.73",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "29.9"
      },
      {
        "city": "Kongsberg Iv",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.67",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "9.65"
      },
      {
        "city": "Kristiansand/Kjevik",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.2",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "8.08"
      },
      {
        "city": "Lista Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.12",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "6.57"
      },
      {
        "city": "Lyngor Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.63",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "9.15"
      },
      {
        "city": "Myken",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "66.77",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "12.48"
      },
      {
        "city": "Oksoy Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.07",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "8.05"
      },
      {
        "city": "Orland Iii",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.7",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "9.6"
      },
      {
        "city": "Oslo-Blindern",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.95",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "10.72"
      },
      {
        "city": "Oslo/Fornebu",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.9",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "10.62"
      },
      {
        "city": "Oslo/Gardermoen",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.2",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "11.08"
      },
      {
        "city": "Rena-Haugedalen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.17",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "11.45"
      },
      {
        "city": "Rygge",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.38",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "10.78"
      },
      {
        "city": "Sklinna Fyr",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "65.2",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "11"
      },
      {
        "city": "Skrova Fyr",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "68.15",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "14.65"
      },
      {
        "city": "Slatteroy Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.92",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "5.07"
      },
      {
        "city": "Slettnes Fyr",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "71.1",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "28.22"
      },
      {
        "city": "Sortland",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "68.7",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "15.42"
      },
      {
        "city": "Stavanger/Sola",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.88",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "5.63"
      },
      {
        "city": "Sula",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.85",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "8.47"
      },
      {
        "city": "Svinoy Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "62.33",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "5.27"
      },
      {
        "city": "Tafjord",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "62.23",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "7.42"
      },
      {
        "city": "Torsvag Fyr",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "70.25",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "19.5"
      },
      {
        "city": "Tromso/Langnes",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "69.68",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "18.92"
      },
      {
        "city": "Trondheim/Vernes",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.47",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "10.93"
      },
      {
        "city": "Tveitsund",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.03",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "8.52"
      },
      {
        "city": "Utsira Fyr",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.3",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "4.88"
      },
      {
        "city": "Vardo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "70.37",
        "country": "Norway",
        "country_id": "NOR",
        "lon": "31.1"
      }
    ]
  },
  {
    "country": "Oman",
    "cities": [
      {
        "city": "Buraimi",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.23",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "55.78"
      },
      {
        "city": "Fahud",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "22.33",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "56.48"
      },
      {
        "city": "Masirah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "20.67",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "58.9"
      },
      {
        "city": "Salalah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "17.03",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "54.08"
      },
      {
        "city": "Seeb Intl Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "23.58",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "58.28"
      },
      {
        "city": "Sohar Majis",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.47",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "56.63"
      },
      {
        "city": "Sur",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "22.53",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "59.47"
      },
      {
        "city": "Thumrait",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "17.67",
        "country": "Oman",
        "country_id": "OMN",
        "lon": "54.02"
      }
    ]
  },
  {
    "country": "Pakistan",
    "cities": [
      {
        "city": "Islamabad Airport",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "33.62",
        "country": "Pakistan",
        "country_id": "PAK",
        "lon": "73.1"
      },
      {
        "city": "Karachi Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.9",
        "country": "Pakistan",
        "country_id": "PAK",
        "lon": "67.13"
      },
      {
        "city": "Lahore Airport",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "31.52",
        "country": "Pakistan",
        "country_id": "PAK",
        "lon": "74.4"
      }
    ]
  },
  {
    "country": "Palau",
    "cities": [
      {
        "city": "Koror Wso",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.33",
        "country": "Palau",
        "country_id": "PLW",
        "lon": "134.48"
      }
    ]
  },
  {
    "country": "Palestinian Territory, Occupied",
    "cities": [
      {
        "city": "Jerusalem Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "31.87",
        "country": "Palestinian Territory, Occupied",
        "country_id": "PSE",
        "lon": "35.22"
      }
    ]
  },
  {
    "country": "Paraguay",
    "cities": [
      {
        "city": "Asuncion/Aeropuerto  ",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.25",
        "country": "Paraguay",
        "country_id": "PRY",
        "lon": "57.52"
      },
      {
        "city": "Marcos A Gelabert I",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.97",
        "country": "Paraguay",
        "country_id": "PRY",
        "lon": "-79.55"
      },
      {
        "city": "Tocumen",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.05",
        "country": "Paraguay",
        "country_id": "PRY",
        "lon": "-79.37"
      },
      {
        "city": "Villarrica",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-25.75",
        "country": "Paraguay",
        "country_id": "PRY",
        "lon": "-56.43"
      }
    ]
  },
  {
    "country": "Peru",
    "cities": [
      {
        "city": "Arequipa",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-16.33",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-71.57"
      },
      {
        "city": "Chiclayo",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-6.78",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-79.82"
      },
      {
        "city": "Cuzco",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-13.53",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-71.93"
      },
      {
        "city": "Iquitos",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-3.78",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-73.3"
      },
      {
        "city": "Lima-Callao/Aerop.",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-12",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-77.12"
      },
      {
        "city": "Pisco",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-13.73",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-76.22"
      },
      {
        "city": "Piura",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-5.2",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-80.6"
      },
      {
        "city": "Pucallpa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-8.37",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-74.57"
      },
      {
        "city": "San Juan",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-15.38",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-75.17"
      },
      {
        "city": "Tacna",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-18.05",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-70.27"
      },
      {
        "city": "Talara",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-4.57",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-81.23"
      },
      {
        "city": "Trujillo",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-8.08",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-79.1"
      },
      {
        "city": "Tumbes",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-3.55",
        "country": "Peru",
        "country_id": "PER",
        "lon": "-80.4"
      }
    ]
  },
  {
    "country": "Philippines",
    "cities": [
      {
        "city": "Baguio",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "16.42",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.6"
      },
      {
        "city": "Cagayan De Oro",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.48",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "124.63"
      },
      {
        "city": "Calapan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.42",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "121.18"
      },
      {
        "city": "Catanduanes Radar",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.98",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "124.32"
      },
      {
        "city": "Catbalogan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.78",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "124.88"
      },
      {
        "city": "Clark Ab",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.17",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.57"
      },
      {
        "city": "Cubi Point Nf",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.8",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.27"
      },
      {
        "city": "Daet",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.13",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "122.98"
      },
      {
        "city": "Dagupan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.05",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.33"
      },
      {
        "city": "Davao Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.12",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "125.65"
      },
      {
        "city": "Dumaguete",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.3",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "123.3"
      },
      {
        "city": "Gen. Santos",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.12",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "125.18"
      },
      {
        "city": "Iba",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.33",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "119.97"
      },
      {
        "city": "Iloilo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.7",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "122.57"
      },
      {
        "city": "Infanta",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.75",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "121.65"
      },
      {
        "city": "Laoag",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.18",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.53"
      },
      {
        "city": "Legaspi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.13",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "123.73"
      },
      {
        "city": "Lumbia Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.43",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "124.28"
      },
      {
        "city": "Mactan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.3",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "123.97"
      },
      {
        "city": "Malaybalay",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.15",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "125.08"
      },
      {
        "city": "Manila",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.58",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.98"
      },
      {
        "city": "Masbate",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.37",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "123.62"
      },
      {
        "city": "Munoz",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.72",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.9"
      },
      {
        "city": "Ninoy Aquino Intern",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.52",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "121"
      },
      {
        "city": "Puerto Princesa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.75",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "118.73"
      },
      {
        "city": "Roxas",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.58",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "122.75"
      },
      {
        "city": "San Jose",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.35",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "121.03"
      },
      {
        "city": "Sangley Point",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.5",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.92"
      },
      {
        "city": "Science Garden",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.63",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "121.02"
      },
      {
        "city": "Sinait",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.88",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "120.45"
      },
      {
        "city": "Tacloban",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.25",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "125"
      },
      {
        "city": "Tagbilaran",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.6",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "123.85"
      },
      {
        "city": "Tayabas",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.03",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "121.58"
      },
      {
        "city": "Zamboanga",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.9",
        "country": "Philippines",
        "country_id": "PHL",
        "lon": "122.07"
      }
    ]
  },
  {
    "country": "Poland",
    "cities": [
      {
        "city": "Bialystok",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.1",
        "country": "Poland",
        "country_id": "POL",
        "lon": "23.17"
      },
      {
        "city": "Bielsko-Biala",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.8",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19"
      },
      {
        "city": "Chojnice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.72",
        "country": "Poland",
        "country_id": "POL",
        "lon": "17.55"
      },
      {
        "city": "Czestochowa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.82",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.1"
      },
      {
        "city": "Elblag",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.17",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.43"
      },
      {
        "city": "Gdansk-Rebiechowo",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.38",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.47"
      },
      {
        "city": "Gdansk-Swibno",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.33",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.93"
      },
      {
        "city": "Gorzow Wlkp",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.75",
        "country": "Poland",
        "country_id": "POL",
        "lon": "15.28"
      },
      {
        "city": "Hel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.6",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.82"
      },
      {
        "city": "Jelenia Gora",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.9",
        "country": "Poland",
        "country_id": "POL",
        "lon": "15.8"
      },
      {
        "city": "Kalisz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.78",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.08"
      },
      {
        "city": "Kasprowy Wierch",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.23",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.98"
      },
      {
        "city": "Katowice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.23",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.03"
      },
      {
        "city": "Ketrzyn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.07",
        "country": "Poland",
        "country_id": "POL",
        "lon": "21.37"
      },
      {
        "city": "Kielce",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.82",
        "country": "Poland",
        "country_id": "POL",
        "lon": "20.7"
      },
      {
        "city": "Klodzko",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.43",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.62"
      },
      {
        "city": "Kolo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.2",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.67"
      },
      {
        "city": "Kolobrzeg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.18",
        "country": "Poland",
        "country_id": "POL",
        "lon": "15.58"
      },
      {
        "city": "Koszalin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.2",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.15"
      },
      {
        "city": "Kozienice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.57",
        "country": "Poland",
        "country_id": "POL",
        "lon": "21.55"
      },
      {
        "city": "Krakow",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.08",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.8"
      },
      {
        "city": "Leba",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.75",
        "country": "Poland",
        "country_id": "POL",
        "lon": "17.53"
      },
      {
        "city": "Lebork",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.55",
        "country": "Poland",
        "country_id": "POL",
        "lon": "17.75"
      },
      {
        "city": "Legnica",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.2",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.2"
      },
      {
        "city": "Lesko",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.47",
        "country": "Poland",
        "country_id": "POL",
        "lon": "22.35"
      },
      {
        "city": "Leszno",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.83",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.53"
      },
      {
        "city": "Lodz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.73",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.4"
      },
      {
        "city": "Lublin Radawiec",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.22",
        "country": "Poland",
        "country_id": "POL",
        "lon": "22.4"
      },
      {
        "city": "Mikolajki",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.78",
        "country": "Poland",
        "country_id": "POL",
        "lon": "21.58"
      },
      {
        "city": "Mlawa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.1",
        "country": "Poland",
        "country_id": "POL",
        "lon": "20.35"
      },
      {
        "city": "Nowy Sacz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.62",
        "country": "Poland",
        "country_id": "POL",
        "lon": "20.7"
      },
      {
        "city": "Olsztyn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.77",
        "country": "Poland",
        "country_id": "POL",
        "lon": "20.42"
      },
      {
        "city": "Opole",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.8",
        "country": "Poland",
        "country_id": "POL",
        "lon": "17.97"
      },
      {
        "city": "Ostroleka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.08",
        "country": "Poland",
        "country_id": "POL",
        "lon": "21.57"
      },
      {
        "city": "Pila",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.13",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.75"
      },
      {
        "city": "Plock",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.58",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.73"
      },
      {
        "city": "Poznan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.42",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.85"
      },
      {
        "city": "Przemysl",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.8",
        "country": "Poland",
        "country_id": "POL",
        "lon": "22.77"
      },
      {
        "city": "Raciborz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.05",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.2"
      },
      {
        "city": "Resko",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.77",
        "country": "Poland",
        "country_id": "POL",
        "lon": "15.42"
      },
      {
        "city": "Rzeszow-Jasionka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.1",
        "country": "Poland",
        "country_id": "POL",
        "lon": "22.05"
      },
      {
        "city": "Sandomierz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.7",
        "country": "Poland",
        "country_id": "POL",
        "lon": "21.72"
      },
      {
        "city": "Siedlce",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.25",
        "country": "Poland",
        "country_id": "POL",
        "lon": "22.25"
      },
      {
        "city": "Sniezka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.73",
        "country": "Poland",
        "country_id": "POL",
        "lon": "15.73"
      },
      {
        "city": "Sulejow",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.35",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.87"
      },
      {
        "city": "Suwalki",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.13",
        "country": "Poland",
        "country_id": "POL",
        "lon": "22.95"
      },
      {
        "city": "Swinoujscie",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.92",
        "country": "Poland",
        "country_id": "POL",
        "lon": "14.23"
      },
      {
        "city": "Szczecin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.4",
        "country": "Poland",
        "country_id": "POL",
        "lon": "14.62"
      },
      {
        "city": "Szczecinek",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.72",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.68"
      },
      {
        "city": "Tarnow",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.03",
        "country": "Poland",
        "country_id": "POL",
        "lon": "20.98"
      },
      {
        "city": "Terespol",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.07",
        "country": "Poland",
        "country_id": "POL",
        "lon": "23.62"
      },
      {
        "city": "Torun",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.05",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.58"
      },
      {
        "city": "Ustka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.58",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.87"
      },
      {
        "city": "Warszawa-Okecie",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.17",
        "country": "Poland",
        "country_id": "POL",
        "lon": "20.97"
      },
      {
        "city": "Wielun",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.22",
        "country": "Poland",
        "country_id": "POL",
        "lon": "18.57"
      },
      {
        "city": "Wlodawa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.55",
        "country": "Poland",
        "country_id": "POL",
        "lon": "23.53"
      },
      {
        "city": "Wroclaw Ii",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.1",
        "country": "Poland",
        "country_id": "POL",
        "lon": "16.88"
      },
      {
        "city": "Zakopane",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.3",
        "country": "Poland",
        "country_id": "POL",
        "lon": "19.97"
      },
      {
        "city": "Zamosc",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.7",
        "country": "Poland",
        "country_id": "POL",
        "lon": "23.25"
      },
      {
        "city": "Zielona Gora",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.93",
        "country": "Poland",
        "country_id": "POL",
        "lon": "15.53"
      }
    ]
  },
  {
    "country": "Portugal",
    "cities": [
      {
        "city": "Beja",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.02",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.87"
      },
      {
        "city": "Braganca",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "41.8",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-6.73"
      },
      {
        "city": "Castelo Branco",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.83",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.48"
      },
      {
        "city": "Coimbra",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.2",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-8.42"
      },
      {
        "city": "Evora",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.57",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.9"
      },
      {
        "city": "Evora/C. Coord",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.53",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.9"
      },
      {
        "city": "Faro/Aeroporto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.02",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.97"
      },
      {
        "city": "Flores (Acores)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.45",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-31.13"
      },
      {
        "city": "Funchal",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "32.63",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-16.9"
      },
      {
        "city": "Funchal/S.Catarina",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "32.68",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-16.77"
      },
      {
        "city": "Horta/Castelo Branc",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.52",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-28.72"
      },
      {
        "city": "Lajes (Acores)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.77",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-27.1"
      },
      {
        "city": "Lisboa/Portela",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.77",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-9.13"
      },
      {
        "city": "Ponta Delgada/Norde",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "37.73",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-25.7"
      },
      {
        "city": "Portalegre",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.28",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.42"
      },
      {
        "city": "Porto Santo",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "33.07",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-16.35"
      },
      {
        "city": "Porto/Pedras Rubras",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "41.23",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-8.68"
      },
      {
        "city": "Sagres",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "37",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-8.95"
      },
      {
        "city": "Santa Maria (Acores)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.97",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-25.17"
      },
      {
        "city": "Sines/Montes Chaos",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "37.95",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-8.87"
      },
      {
        "city": "Viana Do Castelo",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "41.7",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-8.8"
      },
      {
        "city": "Vila Real",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "41.32",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.73"
      },
      {
        "city": "Vila Real",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "41.27",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.72"
      },
      {
        "city": "Viseu",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "40.72",
        "country": "Portugal",
        "country_id": "PRT",
        "lon": "-7.88"
      }
    ]
  },
  {
    "country": "Qatar",
    "cities": [
      {
        "city": "Doha International",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.25",
        "country": "Qatar",
        "country_id": "QAT",
        "lon": "51.57"
      }
    ]
  },
  {
    "country": "Reunion",
    "cities": [
      {
        "city": "Saint-Denis/Gillot",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.88",
        "country": "Reunion",
        "country_id": "REU",
        "lon": "55.52"
      }
    ]
  },
  {
    "country": "Romania",
    "cities": [
      {
        "city": "Arad",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.13",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "21.35"
      },
      {
        "city": "Bacau",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.53",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.92"
      },
      {
        "city": "Baia Mare",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.67",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.5"
      },
      {
        "city": "Barlad",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.23",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "27.65"
      },
      {
        "city": "Bistrita",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.15",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.5"
      },
      {
        "city": "Blaj",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.18",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.93"
      },
      {
        "city": "Botosani",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.73",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.65"
      },
      {
        "city": "Bucuresti Afumati",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.48",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.18"
      },
      {
        "city": "Bucuresti Inmh-Bane",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.48",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.12"
      },
      {
        "city": "Buzau",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.13",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.85"
      },
      {
        "city": "Calafat",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.98",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "22.95"
      },
      {
        "city": "Calarasi",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.2",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "27.33"
      },
      {
        "city": "Caransebes",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.42",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "22.25"
      },
      {
        "city": "Ceahlau Toaca",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.98",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.95"
      },
      {
        "city": "Cluj-Napoca",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.78",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.57"
      },
      {
        "city": "Constanta",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.22",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "28.65"
      },
      {
        "city": "Craiova",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.32",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.87"
      },
      {
        "city": "Deva",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.87",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "22.9"
      },
      {
        "city": "Drobeta Turnu Sever",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.63",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "22.63"
      },
      {
        "city": "Fagaras",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.83",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.93"
      },
      {
        "city": "Fetesti",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.37",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "27.85"
      },
      {
        "city": "Galati",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.48",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "28.03"
      },
      {
        "city": "Giurgiu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.88",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.95"
      },
      {
        "city": "Grivita",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.75",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "27.3"
      },
      {
        "city": "Iasi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.17",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "27.63"
      },
      {
        "city": "Intorsura Buzaului",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "45.68",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.02"
      },
      {
        "city": "Jurilovca",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.77",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "28.88"
      },
      {
        "city": "Kogalniceanu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.33",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "28.43"
      },
      {
        "city": "Mangalia",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.82",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "28.58"
      },
      {
        "city": "Miercurea Ciuc",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.37",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.73"
      },
      {
        "city": "Oradea",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.03",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "21.9"
      },
      {
        "city": "Oravita",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.03",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "21.68"
      },
      {
        "city": "Petrosani",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.42",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.38"
      },
      {
        "city": "Ploiesti",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.95",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26"
      },
      {
        "city": "Predeal",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "45.5",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.58"
      },
      {
        "city": "Rarau (Monastery)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.45",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.57"
      },
      {
        "city": "Rimnicu Valcea",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.1",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.37"
      },
      {
        "city": "Roman",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.97",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.92"
      },
      {
        "city": "Rosiori De Vede",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.1",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.98"
      },
      {
        "city": "Satu Mare",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.72",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "22.88"
      },
      {
        "city": "Sibiu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.8",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.15"
      },
      {
        "city": "Sighetul Marmatiei",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.93",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.92"
      },
      {
        "city": "Suceava",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.63",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "26.25"
      },
      {
        "city": "Sulina",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "45.17",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "29.73"
      },
      {
        "city": "Targoviste",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.93",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.43"
      },
      {
        "city": "Tg. Jiu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.03",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.27"
      },
      {
        "city": "Tg. Mures",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.53",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.53"
      },
      {
        "city": "Timisoara",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.77",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "21.25"
      },
      {
        "city": "Tr. Magurele",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.75",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "24.88"
      },
      {
        "city": "Tulcea",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.18",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "28.82"
      },
      {
        "city": "Varfu Omu",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "45.45",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "25.45"
      },
      {
        "city": "Zalau",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.18",
        "country": "Romania",
        "country_id": "ROU",
        "lon": "23.08"
      }
    ]
  },
  {
    "country": "Russian Federation",
    "cities": [
      {
        "city": "Abakan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "91.4"
      },
      {
        "city": "Acinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.5"
      },
      {
        "city": "Adler",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.9"
      },
      {
        "city": "Agata",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "93.47"
      },
      {
        "city": "Aginskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "94.88"
      },
      {
        "city": "Aginskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "114.52"
      },
      {
        "city": "Agzu",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "138.4"
      },
      {
        "city": "Ajan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "138.15"
      },
      {
        "city": "Akjar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "58.18"
      },
      {
        "city": "Aksa",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "113.27"
      },
      {
        "city": "Alatyr`",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.58"
      },
      {
        "city": "Aldan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "125.37"
      },
      {
        "city": "Alejskaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.52",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.77"
      },
      {
        "city": "Aleksandrov-Gaj",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "48.55"
      },
      {
        "city": "Aleksandrovsk-Sahal",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.17"
      },
      {
        "city": "Aleksandrovskij Zav",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "117.93"
      },
      {
        "city": "Aleksandrovskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "77.87"
      },
      {
        "city": "Amderma",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "61.7"
      },
      {
        "city": "Amga",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.98"
      },
      {
        "city": "Anadyr",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "177.57"
      },
      {
        "city": "Anapa",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.28"
      },
      {
        "city": "Andreya Island",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "110.83"
      },
      {
        "city": "Antipajeta",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "76.85"
      },
      {
        "city": "Anucino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.07"
      },
      {
        "city": "Apuka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "169.67"
      },
      {
        "city": "Arhangelsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "40.58"
      },
      {
        "city": "Arhara",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "130.08"
      },
      {
        "city": "Arka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.33"
      },
      {
        "city": "Armavir",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.12"
      },
      {
        "city": "Astrahan",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "46.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "48.05"
      },
      {
        "city": "Astrahanka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "132.07"
      },
      {
        "city": "Atka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "151.77"
      },
      {
        "city": "B. Pronchishchevoy",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "75.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "113.52"
      },
      {
        "city": "Babaevo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "35.93"
      },
      {
        "city": "Babuskin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "105.85"
      },
      {
        "city": "Baevo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.77"
      },
      {
        "city": "Bagdarin",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "113.58"
      },
      {
        "city": "Bajandaj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "105.53"
      },
      {
        "city": "Bakaly",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "53.8"
      },
      {
        "city": "Bakchar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "81.92"
      },
      {
        "city": "Balagansk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "103.07"
      },
      {
        "city": "Balasov",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.15"
      },
      {
        "city": "Barabinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.33",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.37"
      },
      {
        "city": "Barencburg",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "78.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "14.25"
      },
      {
        "city": "Barnaul",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "83.52"
      },
      {
        "city": "Batamaj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.52",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.48"
      },
      {
        "city": "Beja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.92"
      },
      {
        "city": "Belogorsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "128.47"
      },
      {
        "city": "Belyj",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "32.95"
      },
      {
        "city": "Berezovo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "65.05"
      },
      {
        "city": "Beringovskaja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "179.32"
      },
      {
        "city": "Bezeck",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36.7"
      },
      {
        "city": "Bijsk Zonalnaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "84.95"
      },
      {
        "city": "Bikin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "134.27"
      },
      {
        "city": "Biriliussy",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.13",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.7"
      },
      {
        "city": "Birobidzhan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "132.95"
      },
      {
        "city": "Birsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "55.53"
      },
      {
        "city": "Blagovescensk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "127.57"
      },
      {
        "city": "Bodajbo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "57.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "114.23"
      },
      {
        "city": "Bogorodskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.38",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "140.47"
      },
      {
        "city": "Bogotol",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.58"
      },
      {
        "city": "Bogucany",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.38",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "97.45"
      },
      {
        "city": "Bogucar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "40.57"
      },
      {
        "city": "Bol`Sie-Uki",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "72.67"
      },
      {
        "city": "Bologoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.05"
      },
      {
        "city": "Bolshaja Murta",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "93.13"
      },
      {
        "city": "Bolshereche",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "74.63"
      },
      {
        "city": "Bolsoj Santar",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "137.53"
      },
      {
        "city": "Bomnak",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "128.93"
      },
      {
        "city": "Bor",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.02"
      },
      {
        "city": "Borzja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "116.52"
      },
      {
        "city": "Bratoljubovka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.33"
      },
      {
        "city": "Bratsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "101.75"
      },
      {
        "city": "Brjansk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.32"
      },
      {
        "city": "Budennovsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.13"
      },
      {
        "city": "Bugulma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.8"
      },
      {
        "city": "Buhta Providenja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "-173.23"
      },
      {
        "city": "Buj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.48",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.53"
      },
      {
        "city": "Burukan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.03"
      },
      {
        "city": "Buzuluk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.22"
      },
      {
        "city": "Cajvo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "143.18"
      },
      {
        "city": "Cape Bolvanskij",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "59.07"
      },
      {
        "city": "Cape Kamennyj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "73.6"
      },
      {
        "city": "Cape Menshikova",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "57.62"
      },
      {
        "city": "Cape Sterlegova",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "75.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "88.9"
      },
      {
        "city": "Cape Vasileva",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "155.4"
      },
      {
        "city": "Cekunda",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "132.25"
      },
      {
        "city": "Celina",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.05"
      },
      {
        "city": "Cemal",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86"
      },
      {
        "city": "Centralnyj Rudnik",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "87.65"
      },
      {
        "city": "Cerepovec",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.97"
      },
      {
        "city": "Cerlak",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "74.8"
      },
      {
        "city": "Cernuska",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "56.13"
      },
      {
        "city": "Chany",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "76.6"
      },
      {
        "city": "Chara",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "118.27"
      },
      {
        "city": "Cheljabinsk-Balandi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.3",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "61.53"
      },
      {
        "city": "Cherdyn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "56.52"
      },
      {
        "city": "Chernishevskij",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.5"
      },
      {
        "city": "Chernjaevo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "126"
      },
      {
        "city": "Cherskij",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "161.28"
      },
      {
        "city": "Chita",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "113.48"
      },
      {
        "city": "Chokurdah",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "147.88"
      },
      {
        "city": "Cjulbju",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "57.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "130.9"
      },
      {
        "city": "Cuguevka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.87"
      },
      {
        "city": "Culman",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "124.87"
      },
      {
        "city": "Culym",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.97"
      },
      {
        "city": "Curapca",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "132.6"
      },
      {
        "city": "Dalnerechensk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.73"
      },
      {
        "city": "Demjanskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "69.28"
      },
      {
        "city": "Divnoe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.35"
      },
      {
        "city": "Dudinka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86.17"
      },
      {
        "city": "Duki",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.93"
      },
      {
        "city": "Duvan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "57.9"
      },
      {
        "city": "Dzalinda",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "123.9"
      },
      {
        "city": "Dzardzan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "124"
      },
      {
        "city": "Dzerzhinskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "95.22"
      },
      {
        "city": "Egvekinot",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "-179.12"
      },
      {
        "city": "Ekaterinburg",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.63"
      },
      {
        "city": "Ekaterino-Nikolskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "130.97"
      },
      {
        "city": "Ekimchan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "132.98"
      },
      {
        "city": "Elabuga",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.07"
      },
      {
        "city": "Elat`Ma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.77"
      },
      {
        "city": "Elec",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "38.52"
      },
      {
        "city": "Enisejsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "92.15"
      },
      {
        "city": "Erbogacen",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "108.02"
      },
      {
        "city": "Ermakovskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.3",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "92.42"
      },
      {
        "city": "Erofej Pavlovic",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "121.93"
      },
      {
        "city": "Ersov",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "48.3"
      },
      {
        "city": "Gajny",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "54.35"
      },
      {
        "city": "Gar",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.07"
      },
      {
        "city": "Gari",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "62.33"
      },
      {
        "city": "Glazov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.13",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.58"
      },
      {
        "city": "Gmo Im.E.K. Fedorov",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "77.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "104.3"
      },
      {
        "city": "Gor'Kij",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.82"
      },
      {
        "city": "Gorin",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.8"
      },
      {
        "city": "Gorjacinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "108.28"
      },
      {
        "city": "Gridino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.77"
      },
      {
        "city": "Grossevichi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "139.53"
      },
      {
        "city": "Groznyj",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.68"
      },
      {
        "city": "Guga",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "137.53"
      },
      {
        "city": "Gvasjugi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.67",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.18"
      },
      {
        "city": "Habarovsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.52",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.17"
      },
      {
        "city": "Hadama",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "98.82"
      },
      {
        "city": "Hakasskaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "91.32"
      },
      {
        "city": "Hanty-Mansijsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "69.03"
      },
      {
        "city": "Hatanga",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "71.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "102.47"
      },
      {
        "city": "Hilok",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "110.47"
      },
      {
        "city": "Holm",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "31.18"
      },
      {
        "city": "Holmsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.05"
      },
      {
        "city": "Horinsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "109.78"
      },
      {
        "city": "Hoseda-Hard",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "59.38"
      },
      {
        "city": "Hularin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.08"
      },
      {
        "city": "Ica",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "155.58"
      },
      {
        "city": "Idrinskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "92.13"
      },
      {
        "city": "Igarka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86.57"
      },
      {
        "city": "Ignasino",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "122.4"
      },
      {
        "city": "Ikej",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "100.08"
      },
      {
        "city": "Ilyinskiy",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.2"
      },
      {
        "city": "Im Poliny Osipenko",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.5"
      },
      {
        "city": "Im. M.V. Popova",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "73.33",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "70.05"
      },
      {
        "city": "Irbejskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "95.47"
      },
      {
        "city": "Irkutsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "104.32"
      },
      {
        "city": "Isim",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "69.43"
      },
      {
        "city": "Isit",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "125.32"
      },
      {
        "city": "Ivdel",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.45"
      },
      {
        "city": "Izhevsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "53.45"
      },
      {
        "city": "Jakutsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.72"
      },
      {
        "city": "Jalturovosk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "66.35"
      },
      {
        "city": "Janaul",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "54.9"
      },
      {
        "city": "Jarcevo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.23"
      },
      {
        "city": "Jarensk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "49.12"
      },
      {
        "city": "Jaskul",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "46.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.35"
      },
      {
        "city": "Jubilejnaja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.22"
      },
      {
        "city": "Jur`Evec",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.33",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.12"
      },
      {
        "city": "Juzhno-Kurilsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "145.87"
      },
      {
        "city": "Juzhno-Sahalinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.72"
      },
      {
        "city": "Kacug",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "105.9"
      },
      {
        "city": "Kajlastuj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "118.38"
      },
      {
        "city": "Kalac",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.05"
      },
      {
        "city": "Kalacinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "74.58"
      },
      {
        "city": "Kalakan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "116.77"
      },
      {
        "city": "Kalevala",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "31.17"
      },
      {
        "city": "Kaliningrad",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "20.55"
      },
      {
        "city": "Kaluga",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36.4"
      },
      {
        "city": "Kamen-Na-Obi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "81.27"
      },
      {
        "city": "Kamenskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "166.08"
      },
      {
        "city": "Kamysin",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.37"
      },
      {
        "city": "Kamyslov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "62.72"
      },
      {
        "city": "Kandalaksa",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "67.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "32.35"
      },
      {
        "city": "Kanin Nos",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "68.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.3"
      },
      {
        "city": "Kansk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "95.63"
      },
      {
        "city": "Karasuk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.07"
      },
      {
        "city": "Kargasok",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "59.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.95"
      },
      {
        "city": "Kargopol",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "38.93"
      },
      {
        "city": "Kazachinsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "107.62"
      },
      {
        "city": "Kazan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "49.28"
      },
      {
        "city": "Kedon",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "158.92"
      },
      {
        "city": "Kem",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.65"
      },
      {
        "city": "Kemchug",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "91.67"
      },
      {
        "city": "Kemerovo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86.12"
      },
      {
        "city": "Kestenga",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "31.83"
      },
      {
        "city": "Kingisepp",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "28.6"
      },
      {
        "city": "Kirensk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "57.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "108.07"
      },
      {
        "city": "Kirov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "49.62"
      },
      {
        "city": "Kirov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "49.63"
      },
      {
        "city": "Kirovskij",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.53"
      },
      {
        "city": "Kirs",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.22"
      },
      {
        "city": "Kjahta",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "106.45"
      },
      {
        "city": "Kjusjur",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "127.4"
      },
      {
        "city": "Kljuchi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "160.83"
      },
      {
        "city": "Kljuci",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "79.13"
      },
      {
        "city": "Kochki",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.3",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.5"
      },
      {
        "city": "Kocubej",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "44.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.55"
      },
      {
        "city": "Kojnas",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "47.65"
      },
      {
        "city": "Kolba",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "93.37"
      },
      {
        "city": "Kolpasevo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.95"
      },
      {
        "city": "Kolyvan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.3",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.75"
      },
      {
        "city": "Kommunar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.33",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.28"
      },
      {
        "city": "Korf",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "166"
      },
      {
        "city": "Kotel`Nikovo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.15"
      },
      {
        "city": "Kotlas",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.72"
      },
      {
        "city": "Koz`Modem`Jansk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.33",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.58"
      },
      {
        "city": "Krasnodar",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.15"
      },
      {
        "city": "Krasnojarsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "92.88"
      },
      {
        "city": "Krasnojarsk Opytnoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "92.75"
      },
      {
        "city": "Krasnoozersk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "79.23"
      },
      {
        "city": "Krasnoscele",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.05"
      },
      {
        "city": "Krasnoufimsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "57.78"
      },
      {
        "city": "Krasnye Baki",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.13",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.17"
      },
      {
        "city": "Krasnyj Chikoj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "108.75"
      },
      {
        "city": "Krasnyj Jar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.32"
      },
      {
        "city": "Kreschenka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.03"
      },
      {
        "city": "Kudymkar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "54.65"
      },
      {
        "city": "Kupino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "77.28"
      },
      {
        "city": "Kur",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "134.63"
      },
      {
        "city": "Kurgan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "65.4"
      },
      {
        "city": "Kursk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36.17"
      },
      {
        "city": "Kyra",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "111.97"
      },
      {
        "city": "Kystovka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "76.57"
      },
      {
        "city": "Kyzyl",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "94.5"
      },
      {
        "city": "Lake Tajmyr",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "74.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "102.5"
      },
      {
        "city": "Lensk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "114.88"
      },
      {
        "city": "Leusi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "65.72"
      },
      {
        "city": "Ljuban",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "31.23"
      },
      {
        "city": "Loksak",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "130.45"
      },
      {
        "city": "Losinoborskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.37"
      },
      {
        "city": "Lovozero",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "35.03"
      },
      {
        "city": "Lukojanov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.5"
      },
      {
        "city": "Magadan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "59.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "150.78"
      },
      {
        "city": "Magdagaci",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "125.82"
      },
      {
        "city": "Magnitogorsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "59.08"
      },
      {
        "city": "Mahackala",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "43.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "47.48"
      },
      {
        "city": "Majsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "77.28"
      },
      {
        "city": "Makusino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "67.3"
      },
      {
        "city": "Malye Karmakuly",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "72.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.7"
      },
      {
        "city": "Mama",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.87"
      },
      {
        "city": "Maresale",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "69.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "66.8"
      },
      {
        "city": "Mariinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "87.75"
      },
      {
        "city": "Markovo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "170.42"
      },
      {
        "city": "Masljanino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.33",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "84.22"
      },
      {
        "city": "Matua",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "153.22"
      },
      {
        "city": "Medvezegorsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.43"
      },
      {
        "city": "Meleuz",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "55.97"
      },
      {
        "city": "Mezen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.22"
      },
      {
        "city": "Mineralnye Vody",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "44.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.07"
      },
      {
        "city": "Mogoca",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "119.73"
      },
      {
        "city": "Mondy",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "100.98"
      },
      {
        "city": "Morozovsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.87"
      },
      {
        "city": "Moskva",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.62"
      },
      {
        "city": "Mozdok",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.67"
      },
      {
        "city": "Murmansk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "68.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "33.05"
      },
      {
        "city": "Muzi",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.38",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "64.72"
      },
      {
        "city": "Mys Shalaurova",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "73.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "143.23"
      },
      {
        "city": "Mys Shmidta",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "-179.37"
      },
      {
        "city": "Mys Uelen",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "-169.83"
      },
      {
        "city": "Mys Zelanija",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "68.55"
      },
      {
        "city": "Nagornyj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "124.88"
      },
      {
        "city": "Najahan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "158.97"
      },
      {
        "city": "Napas",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "59.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "81.95"
      },
      {
        "city": "Narjan-Mar",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "53.03"
      },
      {
        "city": "Nazyvoevsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "71.37"
      },
      {
        "city": "Nelkan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "57.67",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.15"
      },
      {
        "city": "Nerchinskij Zavod",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "119.62"
      },
      {
        "city": "Nikol`Sk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.47"
      },
      {
        "city": "Nikolaevsk-Na-Amure",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "140.7"
      },
      {
        "city": "Nikolaevskoe",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "29.8"
      },
      {
        "city": "Nikolo-Poloma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.38"
      },
      {
        "city": "Nikolskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "165.98"
      },
      {
        "city": "Nizhneangarsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "109.55"
      },
      {
        "city": "Nizhneudinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "99.03"
      },
      {
        "city": "Nizhnyj Tagil",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.07"
      },
      {
        "city": "Niznij Novgorod",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44"
      },
      {
        "city": "Njaksimvol",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.87"
      },
      {
        "city": "Njandoma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.67",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "40.18"
      },
      {
        "city": "Njurba",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "118.33"
      },
      {
        "city": "Nogliki",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "143.13"
      },
      {
        "city": "Nolinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "49.95"
      },
      {
        "city": "Norsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.92"
      },
      {
        "city": "Novokuznetsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86.88"
      },
      {
        "city": "Novoselenginsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "106.65"
      },
      {
        "city": "Novosibirsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.9"
      },
      {
        "city": "Nozovka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "54.75"
      },
      {
        "city": "Objacevo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "49.65"
      },
      {
        "city": "Obluce",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.08"
      },
      {
        "city": "Odesskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "72.97"
      },
      {
        "city": "Ohansk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "55.38"
      },
      {
        "city": "Ohotsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "59.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "143.2"
      },
      {
        "city": "Oktjabrskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "66.05"
      },
      {
        "city": "Olekminsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "120.42"
      },
      {
        "city": "Olenek",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.43"
      },
      {
        "city": "Olovjannaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "115.58"
      },
      {
        "city": "Omsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "73.38"
      },
      {
        "city": "Onega",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "38.12"
      },
      {
        "city": "Ongudaj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86.15"
      },
      {
        "city": "Oparino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "48.28"
      },
      {
        "city": "Ordynskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "81.95"
      },
      {
        "city": "Orel",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36"
      },
      {
        "city": "Orenburg",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "55.1"
      },
      {
        "city": "Ostaskov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.13",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "33.12"
      },
      {
        "city": "Ostrov Chetyrehstol",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "162.48"
      },
      {
        "city": "Ostrov Dikson",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "73.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.4"
      },
      {
        "city": "Ostrov Golomjannyj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "79.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.62"
      },
      {
        "city": "Ostrov Kotelnyj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "137.87"
      },
      {
        "city": "Ostrov Preobrazenij",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "74.67",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.93"
      },
      {
        "city": "Ostrov Uedinenija",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "77.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.2"
      },
      {
        "city": "Ostrov Vize",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "79.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "76.98"
      },
      {
        "city": "Ostrov Vrangelja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "-178.48"
      },
      {
        "city": "Ozernaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.48",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "156.48"
      },
      {
        "city": "Padun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "68.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "31.85"
      },
      {
        "city": "Partizansk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.02"
      },
      {
        "city": "Pavelec",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.25"
      },
      {
        "city": "Pechora",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "57.1"
      },
      {
        "city": "Penza",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.02"
      },
      {
        "city": "Perm",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "56.2"
      },
      {
        "city": "Pervomajskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "86.22"
      },
      {
        "city": "Petropavlovsk-Kamch",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "158.58"
      },
      {
        "city": "Petropavlovsk-Kamch",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "158.65"
      },
      {
        "city": "Petrovskij Zavod",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "108.87"
      },
      {
        "city": "Petrozavodsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.27"
      },
      {
        "city": "Pihtovka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.7"
      },
      {
        "city": "Pilvo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.17"
      },
      {
        "city": "Pinega",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.38"
      },
      {
        "city": "Pirovskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "57.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "92.27"
      },
      {
        "city": "Pjalica",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.53"
      },
      {
        "city": "Pogibi",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "141.63"
      },
      {
        "city": "Pogranichnoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "143.77"
      },
      {
        "city": "Pogranichnyj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.38"
      },
      {
        "city": "Pojarkovo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "128.65"
      },
      {
        "city": "Pokrovskaja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.48",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.15"
      },
      {
        "city": "Polargmo Im. E.T. K",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "80.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "58.05"
      },
      {
        "city": "Poltavka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.32"
      },
      {
        "city": "Poronajsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "143.1"
      },
      {
        "city": "Poset",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "130.8"
      },
      {
        "city": "Preobrazhenie",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "42.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.9"
      },
      {
        "city": "Priargunsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "119.07"
      },
      {
        "city": "Primorsko-Ahtarsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "38.15"
      },
      {
        "city": "Pskov",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "28.42"
      },
      {
        "city": "Pudino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "79.37"
      },
      {
        "city": "Pudoz",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36.52"
      },
      {
        "city": "Ra-Iz",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "65.67"
      },
      {
        "city": "Reboly",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "30.82"
      },
      {
        "city": "Rebriha",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "82.3"
      },
      {
        "city": "Remontnoe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "43.67"
      },
      {
        "city": "Rjazan'",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.72"
      },
      {
        "city": "Rjazan`",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.7"
      },
      {
        "city": "Romanovka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.78"
      },
      {
        "city": "Roslavl",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "32.83"
      },
      {
        "city": "Rostov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.42"
      },
      {
        "city": "Rostov-Na-Donu",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.82"
      },
      {
        "city": "Rubcovsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "81.22"
      },
      {
        "city": "Rudnaja Pristan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.85"
      },
      {
        "city": "Russkaya Gavan`",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "62.57"
      },
      {
        "city": "Rybinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "38.68"
      },
      {
        "city": "Rzev",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.32"
      },
      {
        "city": "Sadrinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "63.65"
      },
      {
        "city": "Saim",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "64.22"
      },
      {
        "city": "Sakun`Ja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.67",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.63"
      },
      {
        "city": "Salehard",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "66.67"
      },
      {
        "city": "Samara",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "50.45"
      },
      {
        "city": "Samary",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "58.22"
      },
      {
        "city": "Sangary",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "127.47"
      },
      {
        "city": "Sar`Ja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.37",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.53"
      },
      {
        "city": "Saran-Paul",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.88"
      },
      {
        "city": "Saratov",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.03"
      },
      {
        "city": "Sargatskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "73.48"
      },
      {
        "city": "Segeza",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.28"
      },
      {
        "city": "Sejaha",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "72.52"
      },
      {
        "city": "Sejmchan",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "152.42"
      },
      {
        "city": "Sektagli",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.02"
      },
      {
        "city": "Selagoncy",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "114.28"
      },
      {
        "city": "Selemdza",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.13",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.97"
      },
      {
        "city": "Semjachik",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "159.98"
      },
      {
        "city": "Senkursk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.9"
      },
      {
        "city": "Serafimovic",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.75"
      },
      {
        "city": "Serbakul",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "72.43"
      },
      {
        "city": "Serov",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.53"
      },
      {
        "city": "Severnoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.35"
      },
      {
        "city": "Severo-Kurilsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "156.13"
      },
      {
        "city": "Sheremetyevo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.42"
      },
      {
        "city": "Shilka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "116.03"
      },
      {
        "city": "Simanovsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "127.65"
      },
      {
        "city": "Simusir",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "151.87"
      },
      {
        "city": "Sira",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.93"
      },
      {
        "city": "Skovorodino",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "123.97"
      },
      {
        "city": "Slavgorod",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.65"
      },
      {
        "city": "Smidovich",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.62",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.83"
      },
      {
        "city": "Smolensk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "32.07"
      },
      {
        "city": "Sofijskij Priisk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.98"
      },
      {
        "city": "Sojna",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.13"
      },
      {
        "city": "Solnethnaya",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "108.27"
      },
      {
        "city": "Solovevsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "115.75"
      },
      {
        "city": "Sortavala",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "30.72"
      },
      {
        "city": "Sosnovo-Ozerskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "111.55"
      },
      {
        "city": "Sosunovo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "138.33"
      },
      {
        "city": "Sosva",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "62.1"
      },
      {
        "city": "Sovetskaya Gavan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "140.3"
      },
      {
        "city": "Sredny Vasjugan",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.23"
      },
      {
        "city": "Sretensk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "117.7"
      },
      {
        "city": "St.Petersburg",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "30.3"
      },
      {
        "city": "Staraja Russa",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "31.32"
      },
      {
        "city": "Stavropol",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.08"
      },
      {
        "city": "Sterlitamak",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "56"
      },
      {
        "city": "Strelka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "93"
      },
      {
        "city": "Suhaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "107.13"
      },
      {
        "city": "Suhinici",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "35.58"
      },
      {
        "city": "Suhobuzimskoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "93.28"
      },
      {
        "city": "Sumiha",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "63.32"
      },
      {
        "city": "Suntar",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "117.65"
      },
      {
        "city": "Sura",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.63"
      },
      {
        "city": "Surgut",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.25",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "73.5"
      },
      {
        "city": "Sutur",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "50.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "132.13"
      },
      {
        "city": "Svetlograd",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.85"
      },
      {
        "city": "Svobodnyj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "128.12"
      },
      {
        "city": "Syktyvkar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "50.78"
      },
      {
        "city": "Sym",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "88.37"
      },
      {
        "city": "Syzran`",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "48.4"
      },
      {
        "city": "Tadibe-Yakha",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "70.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "74.13"
      },
      {
        "city": "Tajshet",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.95",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "98"
      },
      {
        "city": "Tambey",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "71.48",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "71.82"
      },
      {
        "city": "Tambov",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.33"
      },
      {
        "city": "Tanguj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.38",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "101.03"
      },
      {
        "city": "Tanhoj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.57",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "105.12"
      },
      {
        "city": "Tara",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "74.38"
      },
      {
        "city": "Tarko-Sale",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.92",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "77.82"
      },
      {
        "city": "Tastyp",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.92"
      },
      {
        "city": "Tatarsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "75.97"
      },
      {
        "city": "Tazovskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.73"
      },
      {
        "city": "Teriberka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "69.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "35.12"
      },
      {
        "city": "Ternej",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.6"
      },
      {
        "city": "Tevriz",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.52",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "72.4"
      },
      {
        "city": "Tihoreck",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.85",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "40.08"
      },
      {
        "city": "Tihvin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "33.55"
      },
      {
        "city": "Tiksi",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "71.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "128.92"
      },
      {
        "city": "Tisul",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "88.32"
      },
      {
        "city": "Tivjaku",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "48.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "137.05"
      },
      {
        "city": "Tjuhtet",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.32"
      },
      {
        "city": "Tjukalinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "72.2"
      },
      {
        "city": "Tjumen",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "65.43"
      },
      {
        "city": "Tobolsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "68.25"
      },
      {
        "city": "Toguchin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "84.4"
      },
      {
        "city": "Toko",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.13"
      },
      {
        "city": "Tompa",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "109.75"
      },
      {
        "city": "Tomsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "84.92"
      },
      {
        "city": "Tot`Ma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.88",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.75"
      },
      {
        "city": "Troicko-Pecherskoe",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "62.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "56.2"
      },
      {
        "city": "Troickoe",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "136.57"
      },
      {
        "city": "Troizk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "61.62"
      },
      {
        "city": "Trubcevsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "52.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "33.77"
      },
      {
        "city": "Tuapse",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.1",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.07"
      },
      {
        "city": "Tula",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "54.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.62"
      },
      {
        "city": "Tulun",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.6",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "100.63"
      },
      {
        "city": "Tumnin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.67",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "140.12"
      },
      {
        "city": "Tungokocen",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.53",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "115.62"
      },
      {
        "city": "Tunka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "102.53"
      },
      {
        "city": "Tura",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "100.23"
      },
      {
        "city": "Turinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "63.68"
      },
      {
        "city": "Turocak",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.27",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "87.17"
      },
      {
        "city": "Turuhansk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "87.93"
      },
      {
        "city": "Tver",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.9",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "35.88"
      },
      {
        "city": "Tynda",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "124.67"
      },
      {
        "city": "Uakit",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "113.62"
      },
      {
        "city": "Uega",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "142.78"
      },
      {
        "city": "Ufa",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "55.83"
      },
      {
        "city": "Uhta",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "53.82"
      },
      {
        "city": "Ujar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "94.33"
      },
      {
        "city": "Ulan-Ude",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "107.6"
      },
      {
        "city": "Ulety",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "51.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.47"
      },
      {
        "city": "Ulyanovsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "48.33"
      },
      {
        "city": "Umba",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.35"
      },
      {
        "city": "Unaha",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "55.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "126.8"
      },
      {
        "city": "Urjupinsk",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42"
      },
      {
        "city": "Urmi",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.4",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.23"
      },
      {
        "city": "Urup",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "150.5"
      },
      {
        "city": "Ust-Barguzin",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.42",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "109.02"
      },
      {
        "city": "Ust-Cilma",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.43",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "52.27"
      },
      {
        "city": "Ust-Ilimsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "102.75"
      },
      {
        "city": "Ust-Isim",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "57.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "-71.18"
      },
      {
        "city": "Ust-Judoma",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "59.18",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.15"
      },
      {
        "city": "Ust-Kamchatsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "162.47"
      },
      {
        "city": "Ust-Kulom",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.68",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "53.68"
      },
      {
        "city": "Ust-Kut",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "105.7"
      },
      {
        "city": "Ust-Maja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.38",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "134.45"
      },
      {
        "city": "Ust-Njukzha",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "56.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "121.48"
      },
      {
        "city": "Ust-Oloj",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.55",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "159.42"
      },
      {
        "city": "Ust-Uda",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "103.02"
      },
      {
        "city": "Ust-Umalta",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.63",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "133.32"
      },
      {
        "city": "Ust-Usa",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.97",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "56.92"
      },
      {
        "city": "Ust-Vojampolka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "58.5",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "159.17"
      },
      {
        "city": "Ustordynskij",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "104.77"
      },
      {
        "city": "Usugli",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "115.17"
      },
      {
        "city": "Uybat",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "90.37"
      },
      {
        "city": "Uzur",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.3",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.82"
      },
      {
        "city": "Vanzil-Kynak",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "60.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "84.08"
      },
      {
        "city": "Velikie Luki",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "30.62"
      },
      {
        "city": "Velsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.07"
      },
      {
        "city": "Verescagino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "54.68"
      },
      {
        "city": "Verhnee Penzino",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "64.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "164.23"
      },
      {
        "city": "Verhneimbatsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "87.95"
      },
      {
        "city": "Verhnij Baskuncak",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "48.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "46.73"
      },
      {
        "city": "Verhnij Ufalej",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.08",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.3"
      },
      {
        "city": "Verhnjaja Tojma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.23",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "45.02"
      },
      {
        "city": "Verhnjaja Tom",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "51.35",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "130.43"
      },
      {
        "city": "Verhotur`E",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "58.87",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "60.78"
      },
      {
        "city": "Veselaja Gorka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.28",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "135.8"
      },
      {
        "city": "Vesljana",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.98",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "50.9"
      },
      {
        "city": "Vikulovo",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.82",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "70.62"
      },
      {
        "city": "Viljujsk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "63.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "121.62"
      },
      {
        "city": "Vitim",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "59.45",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "112.58"
      },
      {
        "city": "Vjazma",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "55.17",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "34.4"
      },
      {
        "city": "Vladimir",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "40.35"
      },
      {
        "city": "Vladivostok",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "131.93"
      },
      {
        "city": "Vnukovo",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.58",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "37.25"
      },
      {
        "city": "Volciha",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "80.37"
      },
      {
        "city": "Volgograd",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.78",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.37"
      },
      {
        "city": "Vologda",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "59.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.92"
      },
      {
        "city": "Vorkuta",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.48",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "64.02"
      },
      {
        "city": "Vorogovo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "61.03",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "89.63"
      },
      {
        "city": "Voronez",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.65",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.25"
      },
      {
        "city": "Voronez",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "39.22"
      },
      {
        "city": "Vozega",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.47",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "40.2"
      },
      {
        "city": "Vyborg",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.72",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "28.73"
      },
      {
        "city": "Vytegra",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.02",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36.45"
      },
      {
        "city": "Wladikavkaz",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.05",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "44.65"
      },
      {
        "city": "Zametcino",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "53.48",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "42.63"
      },
      {
        "city": "Zamokta",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "52.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "109.97"
      },
      {
        "city": "Zavitaja",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "50.12",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "129.47"
      },
      {
        "city": "Zdvinsk",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "78.67"
      },
      {
        "city": "Zeja",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.7",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "127.3"
      },
      {
        "city": "Zerdevka",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.83",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "41.48"
      },
      {
        "city": "Zhigalovo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "54.8",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "105.22"
      },
      {
        "city": "Zhigansk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.77",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "123.4"
      },
      {
        "city": "Zilair",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "52.22",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "57.4"
      },
      {
        "city": "Zilovo",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "53.07",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "117.48"
      },
      {
        "city": "Zima",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "53.93",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "102.05"
      },
      {
        "city": "Zizgin",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.2",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "36.82"
      },
      {
        "city": "Zohova Island",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.15",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "152.83"
      },
      {
        "city": "Zolotoj",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.32",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "138.98"
      },
      {
        "city": "Zuravlevka",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "44.75",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "134.47"
      },
      {
        "city": "Zyrjanka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "65.73",
        "country": "Russian Federation",
        "country_id": "RUS",
        "lon": "150.9"
      }
    ]
  },
  {
    "country": "Saint Helena, Ascension, and Tristan de Cunha",
    "cities": [
      {
        "city": "St. Helena Is.",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-15.93",
        "country": "Saint Helena, Ascension, and Tristan de Cunha",
        "country_id": "SHN",
        "lon": "-5.67"
      },
      {
        "city": "Wide Awake Field (Asi)",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "-7.97",
        "country": "Saint Helena, Ascension, and Tristan de Cunha",
        "country_id": "SHN",
        "lon": "-14.4"
      }
    ]
  },
  {
    "country": "Saint Lucia",
    "cities": [
      {
        "city": "Hewanorra Intl Airp",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.75",
        "country": "Saint Lucia",
        "country_id": "LCA",
        "lon": "-60.95"
      }
    ]
  },
  {
    "country": "Samoa",
    "cities": [
      {
        "city": "Apia",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-13.8",
        "country": "Samoa",
        "country_id": "WSM",
        "lon": "-171.78"
      }
    ]
  },
  {
    "country": "Saudi Arabia",
    "cities": [
      {
        "city": "Abha",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "18.23",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "42.65"
      },
      {
        "city": "Al-Ahsa",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.3",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "49.48"
      },
      {
        "city": "Al-Baha",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "20.3",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "41.65"
      },
      {
        "city": "Al-Jouf",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "29.78",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "40.1"
      },
      {
        "city": "Al-Madinah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.55",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "39.7"
      },
      {
        "city": "Al-Qaisumah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "28.32",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "46.13"
      },
      {
        "city": "Al-Taif",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "21.48",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "40.55"
      },
      {
        "city": "Al-Wejh",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.2",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "36.48"
      },
      {
        "city": "Arar",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "30.9",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "41.13"
      },
      {
        "city": "Bisha",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "19.98",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "42.63"
      },
      {
        "city": "Dhahran",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.27",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "50.17"
      },
      {
        "city": "Gassim",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "26.3",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "43.77"
      },
      {
        "city": "Gizan",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "16.88",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "42.58"
      },
      {
        "city": "Guriat",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "31.4",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "37.28"
      },
      {
        "city": "Hail",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "27.43",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "41.68"
      },
      {
        "city": "Jeddah (King Abdul Aziz Intl)",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "21.7",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "39.18"
      },
      {
        "city": "Khamis Mushait",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "18.3",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "42.8"
      },
      {
        "city": "King Khaled Int. Ai",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.93",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "46.72"
      },
      {
        "city": "Makkah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "21.43",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "39.77"
      },
      {
        "city": "Najran",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "17.62",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "44.42"
      },
      {
        "city": "Rafha",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "29.62",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "43.48"
      },
      {
        "city": "Riyadh Obs. (O.A.P.)",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.7",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "46.73"
      },
      {
        "city": "Sharorah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "17.47",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "47.1"
      },
      {
        "city": "Tabuk",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "28.38",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "36.6"
      },
      {
        "city": "Turaif",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "31.68",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "38.73"
      },
      {
        "city": "Yenbo",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.13",
        "country": "Saudi Arabia",
        "country_id": "SAU",
        "lon": "38.07"
      }
    ]
  },
  {
    "country": "Senegal",
    "cities": [
      {
        "city": "Dakar/Yoff",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.73",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-17.5"
      },
      {
        "city": "Kaolack",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "14.13",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-16.07"
      },
      {
        "city": "Linguere",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "15.38",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-15.12"
      },
      {
        "city": "Matam",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "15.65",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-13.25"
      },
      {
        "city": "Saint-Louis",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "16.05",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-16.45"
      },
      {
        "city": "Tambacounda",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "13.77",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-13.68"
      },
      {
        "city": "Ziguinchor",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.55",
        "country": "Senegal",
        "country_id": "SEN",
        "lon": "-16.27"
      }
    ]
  },
  {
    "country": "Serbia",
    "cities": [
      {
        "city": "Banatski Karlovac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.05",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.03"
      },
      {
        "city": "Beograd",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.8",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.47"
      },
      {
        "city": "Beograd/Surcin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.82",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.28"
      },
      {
        "city": "Crni Vrh",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "44.12",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.95"
      },
      {
        "city": "Cuprija",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.93",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.38"
      },
      {
        "city": "Dimitrovgrad",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.02",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "22.75"
      },
      {
        "city": "Kikinda",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.85",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.47"
      },
      {
        "city": "Kopaonik",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "43.28",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.8"
      },
      {
        "city": "Kraljevo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.7",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.7"
      },
      {
        "city": "Krusevac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.57",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.35"
      },
      {
        "city": "Leskovac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.98",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.95"
      },
      {
        "city": "Loznica",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.55",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.23"
      },
      {
        "city": "Negotin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.23",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "22.55"
      },
      {
        "city": "Nis",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.33",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.9"
      },
      {
        "city": "Novi Sad Rimski San",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.33",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.85"
      },
      {
        "city": "Palic",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.1",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.77"
      },
      {
        "city": "Pec",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.67",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.3"
      },
      {
        "city": "Plevlja",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.35",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.35"
      },
      {
        "city": "Podgorica",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.35",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.25"
      },
      {
        "city": "Podgorica/Golubovci",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.37",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.25"
      },
      {
        "city": "Pristina",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "42.65",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.15"
      },
      {
        "city": "Prizren",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.22",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.73"
      },
      {
        "city": "Sjenica",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "43.28",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20"
      },
      {
        "city": "Smederevska Palanka",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.37",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.95"
      },
      {
        "city": "Sombor",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.77",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.15"
      },
      {
        "city": "Sremska Mitrovica",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.1",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.55"
      },
      {
        "city": "Tivat",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.4",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "18.73"
      },
      {
        "city": "Valjevo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.32",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.92"
      },
      {
        "city": "Veliko Gradiste",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.75",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.52"
      },
      {
        "city": "Vranje",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.55",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.92"
      },
      {
        "city": "Vrsac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.15",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "21.32"
      },
      {
        "city": "Zlatibor",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "43.73",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "19.72"
      },
      {
        "city": "Zrenjanin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.37",
        "country": "Serbia",
        "country_id": "SRB",
        "lon": "20.42"
      }
    ]
  },
  {
    "country": "Seychelles",
    "cities": [
      {
        "city": "Seychelles Internat",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-4.67",
        "country": "Seychelles",
        "country_id": "SYC",
        "lon": "55.52"
      }
    ]
  },
  {
    "country": "Singapore",
    "cities": [
      {
        "city": "Singapore/Changi Ai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "1.37",
        "country": "Singapore",
        "country_id": "SGP",
        "lon": "103.98"
      }
    ]
  },
  {
    "country": "Sint Maarten, Dutch part",
    "cities": [
      {
        "city": "Prinses Juliana",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.03",
        "country": "Sint Maarten, Dutch part",
        "country_id": "SXM",
        "lon": "-63.12"
      }
    ]
  },
  {
    "country": "Slovakia",
    "cities": [
      {
        "city": "Bratislava-Letisko",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.2",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "17.2"
      },
      {
        "city": "Chopok",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "48.98",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "19.6"
      },
      {
        "city": "Dudince",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.17",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "18.87"
      },
      {
        "city": "Hurbanovo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.87",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "18.2"
      },
      {
        "city": "Kamenica Nad Ciroch",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.93",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "22"
      },
      {
        "city": "Kosice",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.67",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "21.22"
      },
      {
        "city": "Liesek",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.37",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "19.68"
      },
      {
        "city": "Lomnicky Stit",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "49.2",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "20.22"
      },
      {
        "city": "Lucenec",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.33",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "19.73"
      },
      {
        "city": "Milhostov",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.67",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "21.73"
      },
      {
        "city": "Nitra",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.28",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "18.13"
      },
      {
        "city": "Piestany",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.62",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "17.83"
      },
      {
        "city": "Poprad/Tatry",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.07",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "20.25"
      },
      {
        "city": "Prievidza",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.77",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "18.6"
      },
      {
        "city": "Sliac",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.65",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "19.15"
      },
      {
        "city": "Strbske Pleso",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "49.12",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "20.08"
      },
      {
        "city": "Stropkov/Tisinec",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.22",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "21.65"
      },
      {
        "city": "Telgart",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "48.85",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "20.18"
      },
      {
        "city": "Zilina/Hricov",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.23",
        "country": "Slovakia",
        "country_id": "SVK",
        "lon": "18.62"
      }
    ]
  },
  {
    "country": "Slovenia",
    "cities": [
      {
        "city": "Kredarica",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.38",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "13.85"
      },
      {
        "city": "Lisca",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.07",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "15.28"
      },
      {
        "city": "Ljubljana/Bezigrad",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.07",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "14.52"
      },
      {
        "city": "Ljubljana/Brnik",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.22",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "14.48"
      },
      {
        "city": "Maribor",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.48",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "15.68"
      },
      {
        "city": "Murska Sobota",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.65",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "16.18"
      },
      {
        "city": "Nova Gorica",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.9",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "13.63"
      },
      {
        "city": "Novo Mesto",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.8",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "15.18"
      },
      {
        "city": "Portoroz",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.52",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "13.57"
      },
      {
        "city": "Slavonski Brod",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.17",
        "country": "Slovenia",
        "country_id": "SVN",
        "lon": "18"
      }
    ]
  },
  {
    "country": "Solomon Islands",
    "cities": [
      {
        "city": "Honiara/Henderson",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-9.42",
        "country": "Solomon Islands",
        "country_id": "SLB",
        "lon": "160.05"
      }
    ]
  },
  {
    "country": "South Africa",
    "cities": [
      {
        "city": "Bethlehem",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-28.25",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.33"
      },
      {
        "city": "Bethlehem",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-28.25",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.33"
      },
      {
        "city": "Bloemfontein Airpor",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-29.1",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "26.3"
      },
      {
        "city": "Bloemfontein Airpor",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-29.1",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "26.3"
      },
      {
        "city": "Calvinia",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.47",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "19.77"
      },
      {
        "city": "Calvinia",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-31.47",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "19.77"
      },
      {
        "city": "Cape Columbine",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-32.83",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "17.85"
      },
      {
        "city": "Cape Columbine",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-32.83",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "17.85"
      },
      {
        "city": "Cape Town Intnl. Ai",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.97",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "18.6"
      },
      {
        "city": "Cape Town Intnl. Ai",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "-33.97",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "18.6"
      },
      {
        "city": "De Aar",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-30.65",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "24"
      },
      {
        "city": "De Aar",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-30.65",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "24"
      },
      {
        "city": "Durban Intnl. Airpo",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-29.97",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "30.95"
      },
      {
        "city": "Durban Intnl. Airpo",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "-29.97",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "30.95"
      },
      {
        "city": "East London",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.03",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "27.83"
      },
      {
        "city": "East London",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.03",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "27.83"
      },
      {
        "city": "George Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.02",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "22.38"
      },
      {
        "city": "George Airport",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.02",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "22.38"
      },
      {
        "city": "Gough Island",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-40.35",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "-9.88"
      },
      {
        "city": "Gough Island",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "-40.35",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "-9.88"
      },
      {
        "city": "Johannesburg Intnl.",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-26.15",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.23"
      },
      {
        "city": "Johannesburg Intnl.",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-26.15",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.23"
      },
      {
        "city": "Kimberley",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-28.8",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "24.77"
      },
      {
        "city": "Kimberley",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-28.8",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "24.77"
      },
      {
        "city": "Langebaanweg",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-32.97",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "18.17"
      },
      {
        "city": "Langebaanweg",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-32.97",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "18.17"
      },
      {
        "city": "Mafikeng Wo",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-25.82",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "25.55"
      },
      {
        "city": "Mafikeng Wo",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-25.82",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "25.55"
      },
      {
        "city": "Marion Island",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-46.88",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "37.87"
      },
      {
        "city": "Marion Island",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "-46.88",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "37.87"
      },
      {
        "city": "Mossel Bay (Cape St Blaize)",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.18",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "22.15"
      },
      {
        "city": "Mossel Bay (Cape St Blaize)",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-34.18",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "22.15"
      },
      {
        "city": "Pietersburg",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-23.87",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "29.45"
      },
      {
        "city": "Pietersburg",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-23.87",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "29.45"
      },
      {
        "city": "Port Elizabeth",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.98",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "25.62"
      },
      {
        "city": "Port Elizabeth",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.98",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "25.62"
      },
      {
        "city": "Pretoria (Irene)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-25.92",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.22"
      },
      {
        "city": "Pretoria (Irene)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-25.92",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.22"
      },
      {
        "city": "Pretoria-Eendracht",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-25.73",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.18"
      },
      {
        "city": "Pretoria-Eendracht",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-25.73",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "28.18"
      },
      {
        "city": "Springbok",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-29.67",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "17.9"
      },
      {
        "city": "Springbok",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "-29.67",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "17.9"
      },
      {
        "city": "Upington",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.4",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "21.27"
      },
      {
        "city": "Upington",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-28.4",
        "country": "South Africa",
        "country_id": "ZAF",
        "lon": "21.27"
      }
    ]
  },
  {
    "country": "Spain",
    "cities": [
      {
        "city": "Albacete/Los Llanos",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.95",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-1.85"
      },
      {
        "city": "Alicante/El Altet",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.28",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-0.55"
      },
      {
        "city": "Almeria/Aeropuerto",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.85",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-2.38"
      },
      {
        "city": "Barcelona/Aeropuert",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.28",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "2.07"
      },
      {
        "city": "Bilbao/Sondica",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.3",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-2.9"
      },
      {
        "city": "Caceres",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.47",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-6.33"
      },
      {
        "city": "Ciudad Real",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.98",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-3.92"
      },
      {
        "city": "Coimbra/Cernache",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "40.15",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-8.47"
      },
      {
        "city": "Gerona/Costa Brava",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.9",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "2.77"
      },
      {
        "city": "Granada/Aeropuerto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.18",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-3.78"
      },
      {
        "city": "Ibiza/Es Codola",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.88",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "1.38"
      },
      {
        "city": "La Coruna",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "43.37",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-8.42"
      },
      {
        "city": "Las Palmas De Gran",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "27.93",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-15.38"
      },
      {
        "city": "Logrono/Agoncillo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.45",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-2.33"
      },
      {
        "city": "Madrid/Barajas Rs",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.45",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-3.55"
      },
      {
        "city": "Madrid/Torrejon",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.48",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-3.45"
      },
      {
        "city": "Malaga/Aeropuerto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.67",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-4.48"
      },
      {
        "city": "Menorca/Mahon",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.87",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "4.23"
      },
      {
        "city": "Moron De La Fronter",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.15",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-5.62"
      },
      {
        "city": "Murcia",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-1.17"
      },
      {
        "city": "Oviedo",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "43.35",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-5.87"
      },
      {
        "city": "Palma De Mallorca/S",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.55",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "2.73"
      },
      {
        "city": "Rota Nas",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.65",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-6.35"
      },
      {
        "city": "Salamanca/Matacan",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "40.95",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-5.5"
      },
      {
        "city": "San Sebastian/Iguel",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "43.3",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-2.03"
      },
      {
        "city": "Santander",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "43.48",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-3.8"
      },
      {
        "city": "Santiago/Labacolla",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "42.9",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-8.43"
      },
      {
        "city": "Sevilla/San Pablo",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.42",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-5.9"
      },
      {
        "city": "Sta. Cruz De Teneri",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "28.45",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-16.25"
      },
      {
        "city": "Tenerife Sur",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "28.05",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-16.57"
      },
      {
        "city": "Valencia/Aeropuerto",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "39.5",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-0.47"
      },
      {
        "city": "Valladolid",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.65",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-4.77"
      },
      {
        "city": "Vigo/Peinador",
        "state": "",
        "cz": "3C",
        "state_id": "",
        "lat": "42.23",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-8.63"
      },
      {
        "city": "Vitoria",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "42.88",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-2.72"
      },
      {
        "city": "Zaragoza (Usafb)",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "41.67",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-1.05"
      },
      {
        "city": "Zaragoza/Aeropuerto",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "41.67",
        "country": "Spain",
        "country_id": "ESP",
        "lon": "-1"
      }
    ]
  },
  {
    "country": "Sri Lanka",
    "cities": [
      {
        "city": "Katunayake",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.17",
        "country": "Sri Lanka",
        "country_id": "LKA",
        "lon": "79.88"
      }
    ]
  },
  {
    "country": "Suriname",
    "cities": [
      {
        "city": "Zanderij",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "5.45",
        "country": "Suriname",
        "country_id": "SUR",
        "lon": "-55.2"
      }
    ]
  },
  {
    "country": "Svalbard and Jan Mayen",
    "cities": [
      {
        "city": "Bjornoya",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "74.52",
        "country": "Svalbard and Jan Mayen",
        "country_id": "SJM",
        "lon": "19.02"
      },
      {
        "city": "Hopen",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "76.5",
        "country": "Svalbard and Jan Mayen",
        "country_id": "SJM",
        "lon": "25.07"
      },
      {
        "city": "Svalbard Lufthavn",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "78.25",
        "country": "Svalbard and Jan Mayen",
        "country_id": "SJM",
        "lon": "15.47"
      }
    ]
  },
  {
    "country": "Sweden",
    "cities": [
      {
        "city": "Angelholm",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.3",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.85"
      },
      {
        "city": "Arjeplog",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.05",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.87"
      },
      {
        "city": "Arvika",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.67",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.58"
      },
      {
        "city": "Bjuroklubb (Lgt-H)",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.48",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "21.58"
      },
      {
        "city": "Blomskog",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.22",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.08"
      },
      {
        "city": "Borlange",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.43",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.52"
      },
      {
        "city": "Edsbyn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.37",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.72"
      },
      {
        "city": "Falsterbo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.38",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.82"
      },
      {
        "city": "Faro Island",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.9",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "19.17"
      },
      {
        "city": "Floda",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.05",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.4"
      },
      {
        "city": "Folkarna",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.17",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.32"
      },
      {
        "city": "Fransta",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.5",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.18"
      },
      {
        "city": "Gaddede",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.5",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.17"
      },
      {
        "city": "Gallivare",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "67.15",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "20.65"
      },
      {
        "city": "Gavle",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.72",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.17"
      },
      {
        "city": "Gladhammar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.78",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.6"
      },
      {
        "city": "Goteborg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.72",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12"
      },
      {
        "city": "Goteborg/Landvetter",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.67",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.3"
      },
      {
        "city": "Goteborg/Save",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.78",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "11.88"
      },
      {
        "city": "Gotska Sandon",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.4",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "19.2"
      },
      {
        "city": "Gunnarn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.02",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.68"
      },
      {
        "city": "Hagshult",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.3",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.13"
      },
      {
        "city": "Hallands Vadero",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.45",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.55"
      },
      {
        "city": "Hano",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.02",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.85"
      },
      {
        "city": "Haparanda",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.83",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "24.15"
      },
      {
        "city": "Harstena",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.25",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.02"
      },
      {
        "city": "Helsingborg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.03",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.77"
      },
      {
        "city": "Hoburg",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.92",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "18.15"
      },
      {
        "city": "Holmogadd",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.6",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "20.75"
      },
      {
        "city": "Idvattnet",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "64.45",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.08"
      },
      {
        "city": "Jokkmokk (Swe-Afb)",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "66.63",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "19.65"
      },
      {
        "city": "Jonkoping/Axamo",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.75",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.08"
      },
      {
        "city": "Junsele",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.68",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.95"
      },
      {
        "city": "Kalmar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.68",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.3"
      },
      {
        "city": "Kalmar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.73",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.3"
      },
      {
        "city": "Karesuando",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.45",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "22.45"
      },
      {
        "city": "Karlsborg(Safb)",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.52",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.53"
      },
      {
        "city": "Karlstad Flygplats",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.45",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.47"
      },
      {
        "city": "Katterjakk",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.42",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "18.17"
      },
      {
        "city": "Kiruna",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.82",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "20.33"
      },
      {
        "city": "Klimpfjall",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.07",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.8"
      },
      {
        "city": "Krangede",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.15",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.17"
      },
      {
        "city": "Kuggoren",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "61.7",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.53"
      },
      {
        "city": "Kullen (Lgt-H)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.3",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.45"
      },
      {
        "city": "Kvikkjokk-Arrenjark",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.88",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.75"
      },
      {
        "city": "Lainio",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.77",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "22.35"
      },
      {
        "city": "Landsort",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.75",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.87"
      },
      {
        "city": "Linkoping/Malmslatt",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.4",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.53"
      },
      {
        "city": "Ljungby",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.85",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.88"
      },
      {
        "city": "Ljungbyhed",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.08",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.2"
      },
      {
        "city": "Lulea-Kallax",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.55",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "22.13"
      },
      {
        "city": "Malilla",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.4",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.82"
      },
      {
        "city": "Malmo/Sturup",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.55",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.37"
      },
      {
        "city": "Malung",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.68",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.72"
      },
      {
        "city": "Maseskar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.1",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "11.33"
      },
      {
        "city": "Naimakka",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "68.68",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "21.53"
      },
      {
        "city": "Nidingen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.3",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "11.9"
      },
      {
        "city": "Nikkaluokta",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "67.85",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "19.03"
      },
      {
        "city": "Norrkoping/Bravalla",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.62",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.12"
      },
      {
        "city": "Norrkoping/Kungsang",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.58",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.15"
      },
      {
        "city": "Olands Norra Udde",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.37",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.1"
      },
      {
        "city": "Olands Sodra Udde",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.2",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.4"
      },
      {
        "city": "Orebro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.23",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.05"
      },
      {
        "city": "Orskar",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.53",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "18.38"
      },
      {
        "city": "Osby",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.37",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.95"
      },
      {
        "city": "Ostersund Froson",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.2",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.5"
      },
      {
        "city": "Ostmark",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "60.35",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.65"
      },
      {
        "city": "Overkalix Svartbyn",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.27",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "22.85"
      },
      {
        "city": "Pajala",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "67.22",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "23.4"
      },
      {
        "city": "Palkem",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "66.38",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "21.63"
      },
      {
        "city": "Pite-Ronnskar",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "65.03",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "21.57"
      },
      {
        "city": "Rangedala",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.78",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.17"
      },
      {
        "city": "Ritsem",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "67.73",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.47"
      },
      {
        "city": "Ronneby/Kallinge",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.27",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.27"
      },
      {
        "city": "Rorbacksnas",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.13",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.82"
      },
      {
        "city": "Sarna",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "61.7",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "13.18"
      },
      {
        "city": "Satenas",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.43",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.72"
      },
      {
        "city": "Singo Island",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.17",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "18.75"
      },
      {
        "city": "Skagsudde",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "63.18",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "19.02"
      },
      {
        "city": "Skavsta",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "58.78",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "16.9"
      },
      {
        "city": "Skillinge",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.48",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.32"
      },
      {
        "city": "Soderhamn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "61.25",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.1"
      },
      {
        "city": "Stockholm/Arlanda",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.65",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.95"
      },
      {
        "city": "Stockholm/Bromma",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.37",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.9"
      },
      {
        "city": "Storlien",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.3",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.12"
      },
      {
        "city": "Sveg",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.02",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "14.37"
      },
      {
        "city": "Svenska Hogarna",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "59.45",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "19.5"
      },
      {
        "city": "Tannas",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.45",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "12.67"
      },
      {
        "city": "Timra/Midlanda",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "62.52",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "17.45"
      },
      {
        "city": "Umea",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "63.8",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "20.28"
      },
      {
        "city": "Ungskar",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.03",
        "country": "Sweden",
        "country_id": "SWE",
        "lon": "15.8"
      }
    ]
  },
  {
    "country": "Switzerland",
    "cities": [
      {
        "city": "Aigle",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.33",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.92"
      },
      {
        "city": "Altdorf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.87",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.63"
      },
      {
        "city": "Chur-Ems",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.87",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "9.53"
      },
      {
        "city": "Cimetta",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.2",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.8"
      },
      {
        "city": "Comprovasco",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.47",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.93"
      },
      {
        "city": "Corvatsch",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "46.42",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "9.82"
      },
      {
        "city": "Disentis",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.7",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.85"
      },
      {
        "city": "Fahy",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.43",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.95"
      },
      {
        "city": "Geneve-Cointrin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.25",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.13"
      },
      {
        "city": "Guetsch",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.65",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.62"
      },
      {
        "city": "Hoernki",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.37",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.95"
      },
      {
        "city": "Interlaken",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.67",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.87"
      },
      {
        "city": "Jungfraujoch",
        "state": "",
        "cz": "8",
        "state_id": "",
        "lat": "46.55",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.98"
      },
      {
        "city": "La Chaux-De-Fonds",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47.08",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.8"
      },
      {
        "city": "La Dole",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.43",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.1"
      },
      {
        "city": "Laegern",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.48",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.4"
      },
      {
        "city": "Locarno-Magadino",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.17",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.88"
      },
      {
        "city": "Locarno-Monti",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46.17",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.78"
      },
      {
        "city": "Lugano",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "46",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.97"
      },
      {
        "city": "Montana",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.32",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.48"
      },
      {
        "city": "Napf",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "47",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.93"
      },
      {
        "city": "Neuchatel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.95"
      },
      {
        "city": "Payerne",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.82",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "6.95"
      },
      {
        "city": "Piotta",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.52",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.68"
      },
      {
        "city": "Plaffeien-Oberschro",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.75",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.27"
      },
      {
        "city": "Robbia",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "46.35",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "10.07"
      },
      {
        "city": "Saentis",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "47.25",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "9.35"
      },
      {
        "city": "Samedam-Flugplatz",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.53",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "9.88"
      },
      {
        "city": "San Bernardino",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "46.47",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "9.18"
      },
      {
        "city": "Sion",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.22",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.33"
      },
      {
        "city": "St. Gallen",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.43",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "9.4"
      },
      {
        "city": "Stabio",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.85",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.93"
      },
      {
        "city": "Wynau",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.25",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "7.78"
      },
      {
        "city": "Zuerich Meteoschwei",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.38",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.57"
      },
      {
        "city": "Zurich-Kloten",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.48",
        "country": "Switzerland",
        "country_id": "CHE",
        "lon": "8.53"
      }
    ]
  },
  {
    "country": "Syria",
    "cities": [
      {
        "city": "Aleppo Int. Aeropor",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.18",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "37.2"
      },
      {
        "city": "Damascus Int. Airpo",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "33.42",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "36.52"
      },
      {
        "city": "Daraa",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "32.6",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "36.1"
      },
      {
        "city": "Deir Ezzor",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "35.32",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "40.15"
      },
      {
        "city": "Hama",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.12",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "36.75"
      },
      {
        "city": "Lattakia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "35.53",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "35.77"
      },
      {
        "city": "Nabk",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "34.03",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "36.72"
      },
      {
        "city": "Palmyra",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "34.55",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "38.3"
      },
      {
        "city": "Safita",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "34.82",
        "country": "Syria",
        "country_id": "SYR",
        "lon": "36.13"
      }
    ]
  },
  {
    "country": "Taiwan, Province of China",
    "cities": [
      {
        "city": "Chiang Kai Shek",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.08",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.22"
      },
      {
        "city": "Chiayi",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.5",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.45"
      },
      {
        "city": "Chiayi (Tw-Afb)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.47",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.38"
      },
      {
        "city": "Chihhang (Tw-Afb)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.8",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.18"
      },
      {
        "city": "Chilung",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.15",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.8"
      },
      {
        "city": "Chinmem/Shatou(Afb)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.43",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "118.37"
      },
      {
        "city": "Dongsha Dao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.67",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "116.72"
      },
      {
        "city": "Hengchun",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.93",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.83"
      },
      {
        "city": "Hengchun",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.75"
      },
      {
        "city": "Hsinchu (Tw-Afb)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.82",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.93"
      },
      {
        "city": "Hsinchu City",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.83",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.93"
      },
      {
        "city": "Hua-Lien City",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.98",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.6"
      },
      {
        "city": "Hulien Ab (=593620)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.03",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.62"
      },
      {
        "city": "Ilan City",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.75",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.78"
      },
      {
        "city": "Kangshan (Tw-Afb)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.78",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.27"
      },
      {
        "city": "Kaohsiung",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.63",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.28"
      },
      {
        "city": "Kaohsiung Intl Arpt",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.58",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.35"
      },
      {
        "city": "Lan Yu",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.03",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.55"
      },
      {
        "city": "Makung Ab (=593450)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.58",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "119.62"
      },
      {
        "city": "Mazu",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "26.17",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "119.93"
      },
      {
        "city": "Nansha Dao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.38",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "114.37"
      },
      {
        "city": "Penghu Islands",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "23.5",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "119.5"
      },
      {
        "city": "Pengjia Yu",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.63",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "122.07"
      },
      {
        "city": "Pingtung North(Afb)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.7",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.48"
      },
      {
        "city": "Pingtung South(Afb)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.68",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.47"
      },
      {
        "city": "Suao Met Station",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.6",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.85"
      },
      {
        "city": "Sungshan/Taipei",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.07",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.55"
      },
      {
        "city": "Taibei",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.03",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.52"
      },
      {
        "city": "Taichung (Tw-Afb)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.18",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.65"
      },
      {
        "city": "Taidong",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.75",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.15"
      },
      {
        "city": "Tainan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "23",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.22"
      },
      {
        "city": "Tainan (Tw-Afb)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.95",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.2"
      },
      {
        "city": "Taizhong",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "24.15",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.68"
      },
      {
        "city": "Taoyuan Ab (=589650)",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "25.07",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "121.23"
      },
      {
        "city": "Wu-Chi Observatory",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.25",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.52"
      },
      {
        "city": "Wuchia Observatory",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "24.27",
        "country": "Taiwan, Province of China",
        "country_id": "TWN",
        "lon": "120.62"
      }
    ]
  },
  {
    "country": "Tajikistan",
    "cities": [
      {
        "city": "Dushanbe",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.55",
        "country": "Tajikistan",
        "country_id": "TJK",
        "lon": "68.78"
      },
      {
        "city": "Khorog",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "37.5",
        "country": "Tajikistan",
        "country_id": "TJK",
        "lon": "71.5"
      },
      {
        "city": "Khudjand",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.22",
        "country": "Tajikistan",
        "country_id": "TJK",
        "lon": "69.73"
      }
    ]
  },
  {
    "country": "Tanzania, United Republic of",
    "cities": [
      {
        "city": "Dar Es Salaam Airpo",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-6.87",
        "country": "Tanzania, United Republic of",
        "country_id": "TZA",
        "lon": "39.2"
      }
    ]
  },
  {
    "country": "Thailand",
    "cities": [
      {
        "city": "Aranyaprathet",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.7",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.58"
      },
      {
        "city": "Bangkok Metropolis",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.73",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.57"
      },
      {
        "city": "Bhumibol Dam",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.25",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.02"
      },
      {
        "city": "Bua Chum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.27",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.18"
      },
      {
        "city": "Chaiyaphum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.8",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.03"
      },
      {
        "city": "Chanthaburi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.6",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.12"
      },
      {
        "city": "Chiang Mai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.78",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "98.98"
      },
      {
        "city": "Chiang Rai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.97",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.88"
      },
      {
        "city": "Chon Buri",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.37",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.98"
      },
      {
        "city": "Chumphon",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.48",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.18"
      },
      {
        "city": "Don Muang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.92",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.6"
      },
      {
        "city": "Hat Yai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.92",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.43"
      },
      {
        "city": "Hua Hin",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.58",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.95"
      },
      {
        "city": "Kam Paeng Phet",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.48",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.53"
      },
      {
        "city": "Kanchanaburi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.02",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.53"
      },
      {
        "city": "Khlong Yai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.77",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.88"
      },
      {
        "city": "Khon Kaen",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.43",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.83"
      },
      {
        "city": "Ko Lanta",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.53",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.05"
      },
      {
        "city": "Ko Samui",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.47",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.05"
      },
      {
        "city": "Ko Sichang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.17",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.8"
      },
      {
        "city": "Lampang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.28",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.52"
      },
      {
        "city": "Lamphun",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.57",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.03"
      },
      {
        "city": "Loei",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.45",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.73"
      },
      {
        "city": "Lop Buri",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.8",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.62"
      },
      {
        "city": "Mae Hong Son",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.3",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "97.83"
      },
      {
        "city": "Mae Sariang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.17",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "97.93"
      },
      {
        "city": "Mae Sot",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.67",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "98.55"
      },
      {
        "city": "Mukdahan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.53",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "104.72"
      },
      {
        "city": "Nakhon Phanom",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.42",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "104.78"
      },
      {
        "city": "Nakhon Ratchasima",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.97",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.08"
      },
      {
        "city": "Nakhon Sawan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.8",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.17"
      },
      {
        "city": "Nakhon Si Thammarat",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.53",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.95"
      },
      {
        "city": "Nan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.77",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.77"
      },
      {
        "city": "Narathiwat",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.42",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.82"
      },
      {
        "city": "Nong Khai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.87",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.72"
      },
      {
        "city": "Pattani",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.78",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.15"
      },
      {
        "city": "Phatthaya",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.92",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.87"
      },
      {
        "city": "Phayao",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.13",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.9"
      },
      {
        "city": "Phetchabun",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.43",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.15"
      },
      {
        "city": "Phetchaburi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.15",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.07"
      },
      {
        "city": "Phitsanulok",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.78",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.27"
      },
      {
        "city": "Phrae",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.17",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.17"
      },
      {
        "city": "Phuket",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.88",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "98.4"
      },
      {
        "city": "Phuket Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.13",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "98.32"
      },
      {
        "city": "Prachin Buri",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.05",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.37"
      },
      {
        "city": "Prachuap Khirikhan",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.82",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.82"
      },
      {
        "city": "Ranong",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.98",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "98.62"
      },
      {
        "city": "Rayong",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.63",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "101.35"
      },
      {
        "city": "Roi Et",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.05",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "103.68"
      },
      {
        "city": "Sakon Nakhon",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.15",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "104.13"
      },
      {
        "city": "Sattahip",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.68",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.98"
      },
      {
        "city": "Songkhla",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.2",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.62"
      },
      {
        "city": "Suphan Buri",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.47",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.13"
      },
      {
        "city": "Surat Thani",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.12",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.15"
      },
      {
        "city": "Surin",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.88",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "103.5"
      },
      {
        "city": "Tak",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.88",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.15"
      },
      {
        "city": "Tha Tum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.32",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "103.68"
      },
      {
        "city": "Thong Pha Phum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "14.75",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "98.63"
      },
      {
        "city": "Trang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.52",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "99.62"
      },
      {
        "city": "Ubon Ratchathani",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "15.25",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "104.87"
      },
      {
        "city": "Udon Thani",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.38",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "102.8"
      },
      {
        "city": "Uttaradit",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.62",
        "country": "Thailand",
        "country_id": "THA",
        "lon": "100.1"
      }
    ]
  },
  {
    "country": "Togo",
    "cities": [
      {
        "city": "Lome",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "6.17",
        "country": "Togo",
        "country_id": "TGO",
        "lon": "1.25"
      }
    ]
  },
  {
    "country": "Tonga",
    "cities": [
      {
        "city": "Fuaamotu",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-21.23",
        "country": "Tonga",
        "country_id": "TON",
        "lon": "-175.15"
      },
      {
        "city": "Haapai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-19.8",
        "country": "Tonga",
        "country_id": "TON",
        "lon": "-174.35"
      }
    ]
  },
  {
    "country": "Trinidad and Tobago",
    "cities": [
      {
        "city": "Crown Point Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "11.15",
        "country": "Trinidad and Tobago",
        "country_id": "TTO",
        "lon": "-60.83"
      },
      {
        "city": "Piarco Int. Airport",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.62",
        "country": "Trinidad and Tobago",
        "country_id": "TTO",
        "lon": "-61.35"
      }
    ]
  },
  {
    "country": "Tunisia",
    "cities": [
      {
        "city": "Bizerte",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.25",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "9.8"
      },
      {
        "city": "Djerba Mellita",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "33.87",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.77"
      },
      {
        "city": "Gabes",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "33.88",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.1"
      },
      {
        "city": "Gafsa",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "34.42",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "8.82"
      },
      {
        "city": "Habib Bourguiba Int",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "35.75",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.75"
      },
      {
        "city": "Jendouba",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.48",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "8.8"
      },
      {
        "city": "Kairouan",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "35.67",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.1"
      },
      {
        "city": "Kelibia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.85",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "11.08"
      },
      {
        "city": "Monastir-Skanes",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "35.67",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.75"
      },
      {
        "city": "Sfax El-Maou",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "34.72",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.68"
      },
      {
        "city": "Tabarka",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.95",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "8.75"
      },
      {
        "city": "Thala",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.55",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "8.68"
      },
      {
        "city": "Tozeur",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "33.92",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "8.17"
      },
      {
        "city": "Tunis-Carthage",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.83",
        "country": "Tunisia",
        "country_id": "TUN",
        "lon": "10.23"
      }
    ]
  },
  {
    "country": "Turkey",
    "cities": [
      {
        "city": "Adana",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "36.98",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "35.3"
      },
      {
        "city": "Adana/Incirlik Ab",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "35.43"
      },
      {
        "city": "Adana/Incirlik Afb",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "35.42"
      },
      {
        "city": "Afyon",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "38.75",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "30.53"
      },
      {
        "city": "Akhisar",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.92",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.85"
      },
      {
        "city": "Antalya",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.87",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "30.73"
      },
      {
        "city": "Aydin",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.85",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.85"
      },
      {
        "city": "Balikesir",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.62",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.92"
      },
      {
        "city": "Bandirma",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.32",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.97"
      },
      {
        "city": "Bodrum",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "37.03",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.43"
      },
      {
        "city": "Bolu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.73",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "31.6"
      },
      {
        "city": "Bursa",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.18",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "29.07"
      },
      {
        "city": "Canakkale",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.13",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "26.4"
      },
      {
        "city": "Corum",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "40.55",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "34.95"
      },
      {
        "city": "Dalaman",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "36.7",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "28.78"
      },
      {
        "city": "Dikili",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "39.07",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "26.88"
      },
      {
        "city": "Diyarbakir",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.88",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "40.18"
      },
      {
        "city": "Edirne",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.67",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "26.57"
      },
      {
        "city": "Elazig",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.6",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "39.28"
      },
      {
        "city": "Erzincan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.7",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "39.52"
      },
      {
        "city": "Erzurum",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "39.95",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "41.17"
      },
      {
        "city": "Esenboga",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "40.12",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "33"
      },
      {
        "city": "Eskisehir",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.78",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "30.57"
      },
      {
        "city": "Etimesgut",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "39.95",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "32.68"
      },
      {
        "city": "Gaziantep",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.08",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "37.37"
      },
      {
        "city": "Gokceada",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.18",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "25.9"
      },
      {
        "city": "Golcuk/Dumlupinar",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.67",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "29.83"
      },
      {
        "city": "Inebolu",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.98",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "33.78"
      },
      {
        "city": "Iskenderun",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "36.58",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "36.17"
      },
      {
        "city": "Isparta",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "37.75",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "30.55"
      },
      {
        "city": "Istanbul/Ataturk",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "40.97",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "28.82"
      },
      {
        "city": "Izmir/A. Menderes",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.27",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.15"
      },
      {
        "city": "Izmir/Cigli",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "38.52",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.02"
      },
      {
        "city": "Kayseri/Erkilet",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "38.82",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "35.43"
      },
      {
        "city": "Konya",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "37.97",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "32.55"
      },
      {
        "city": "Malatya/Erhac",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.43",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "38.08"
      },
      {
        "city": "Merzifon",
        "state": "",
        "cz": "4C",
        "state_id": "",
        "lat": "40.85",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "35.58"
      },
      {
        "city": "Mugla",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "37.22",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "28.37"
      },
      {
        "city": "Samsun",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41.28",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "36.3"
      },
      {
        "city": "Silifke",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "36.38",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "33.93"
      },
      {
        "city": "Sinop",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "42.03",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "35.17"
      },
      {
        "city": "Sivas",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "39.75",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "37.02"
      },
      {
        "city": "Tekirdag",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.98",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "27.55"
      },
      {
        "city": "Trabzon",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "41",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "39.72"
      },
      {
        "city": "Usak",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "38.68",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "29.4"
      },
      {
        "city": "Van",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "38.45",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "43.32"
      },
      {
        "city": "Zonguldak",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.45",
        "country": "Turkey",
        "country_id": "TUR",
        "lon": "31.8"
      }
    ]
  },
  {
    "country": "Turkmenistan",
    "cities": [
      {
        "city": "Ashgabat Keshi",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.92",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "58.33"
      },
      {
        "city": "Bajramaly",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.6",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "62.18"
      },
      {
        "city": "Bakherden",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.43",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "57.42"
      },
      {
        "city": "Byrdalyk",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.47",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "64.37"
      },
      {
        "city": "Carsanga",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "37.52",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "66.02"
      },
      {
        "city": "Chardzhev",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "39.08",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "63.6"
      },
      {
        "city": "Dashkhovuz",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "41.75",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "59.82"
      },
      {
        "city": "Ekezhe",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "41.03",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "57.77"
      },
      {
        "city": "Erbent",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "39.32",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "58.6"
      },
      {
        "city": "Esengyly",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.47",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "53.97"
      },
      {
        "city": "Gazandzhyk",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "39.25",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "55.52"
      },
      {
        "city": "Gyshgy",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "35.28",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "62.35"
      },
      {
        "city": "Gyzylarbat",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "38.98",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "56.28"
      },
      {
        "city": "Kerki",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.83",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "65.2"
      },
      {
        "city": "Saragt",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "36.53",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "61.22"
      },
      {
        "city": "Tedzhen",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.38",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "60.52"
      },
      {
        "city": "Turkmenbashi",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.05",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "53"
      },
      {
        "city": "Uchadzhy",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.08",
        "country": "Turkmenistan",
        "country_id": "TKM",
        "lon": "62.8"
      }
    ]
  },
  {
    "country": "Tuvalu",
    "cities": [
      {
        "city": "Funafuti Nf",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-8.53",
        "country": "Tuvalu",
        "country_id": "TUV",
        "lon": "179.22"
      }
    ]
  },
  {
    "country": "Ukraine",
    "cities": [
      {
        "city": "Boryspil",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.33",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "30.97"
      },
      {
        "city": "Chernihiv",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.47",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "31.25"
      },
      {
        "city": "Chernivtsi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.37",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "25.9"
      },
      {
        "city": "Chornomorske",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "45.52",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "32.7"
      },
      {
        "city": "Dnipropetrovsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.37",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "35.08"
      },
      {
        "city": "Donetsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.07",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "37.77"
      },
      {
        "city": "Henichesk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.17",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "34.82"
      },
      {
        "city": "Ivano-Frankivsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.97",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "24.7"
      },
      {
        "city": "Izium",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.18",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "37.3"
      },
      {
        "city": "Izmail",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.37",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "28.85"
      },
      {
        "city": "Kerch",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.4",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "36.42"
      },
      {
        "city": "Kharkiv",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.97",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "36.13"
      },
      {
        "city": "Kherson",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.63",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "32.57"
      },
      {
        "city": "Khmelnytskyi",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.43",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "26.98"
      },
      {
        "city": "Kirovohrad",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.52",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "32.2"
      },
      {
        "city": "Konotop",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.23",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "33.2"
      },
      {
        "city": "Kryvyi Rih",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.03",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "33.22"
      },
      {
        "city": "Kyiv",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.4",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "30.57"
      },
      {
        "city": "Liubashivka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.85",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "30.27"
      },
      {
        "city": "Lubny",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "33.02"
      },
      {
        "city": "Luhansk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.57",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "39.25"
      },
      {
        "city": "Lviv",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.82",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "23.95"
      },
      {
        "city": "Mariupol",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.03",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "37.5"
      },
      {
        "city": "Mohyliv-Podilskyi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.45",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "27.78"
      },
      {
        "city": "Myronivka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.67",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "31"
      },
      {
        "city": "Nizhyn",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "51.05",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "31.9"
      },
      {
        "city": "Nova Kakhovka",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.78",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "33.37"
      },
      {
        "city": "Odesa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "46.43",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "30.77"
      },
      {
        "city": "Poltava",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.6",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "34.55"
      },
      {
        "city": "Rivne",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.58",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "26.13"
      },
      {
        "city": "Sarny",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.28",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "26.62"
      },
      {
        "city": "Shepetivka",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.17",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "27.03"
      },
      {
        "city": "Simferopol",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "45.02",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "33.98"
      },
      {
        "city": "Sumy",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.85",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "34.67"
      },
      {
        "city": "Svitlovodsk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "49.05",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "33.25"
      },
      {
        "city": "Ternopil",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.53",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "25.67"
      },
      {
        "city": "Uman",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.77",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "30.23"
      },
      {
        "city": "Uzhhorod",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "48.63",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "22.27"
      },
      {
        "city": "Vinnytsia",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "49.23",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "28.6"
      },
      {
        "city": "Volodymyr-Volynskyi",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.83",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "24.32"
      },
      {
        "city": "Voznesensk",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.57",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "31.33"
      },
      {
        "city": "Yalta",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "44.48",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "34.17"
      },
      {
        "city": "Zaporizhzhia",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "47.8",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "35.02"
      },
      {
        "city": "Zhytomyr",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "50.23",
        "country": "Ukraine",
        "country_id": "UKR",
        "lon": "28.73"
      }
    ]
  },
  {
    "country": "United Arab Emirates",
    "cities": [
      {
        "city": "Abu Dhabi Bateen Ai",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.43",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "54.47"
      },
      {
        "city": "Abu Dhabi Inter. Ai",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.43",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "54.65"
      },
      {
        "city": "Al Ain Internationa",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "24.27",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "55.6"
      },
      {
        "city": "Dubai International",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.25",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "55.33"
      },
      {
        "city": "Fujairah",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.1",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "56.33"
      },
      {
        "city": "Ras Al Khaimah Inte",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.62",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "55.93"
      },
      {
        "city": "Sharjah Inter. Airp",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "25.33",
        "country": "United Arab Emirates",
        "country_id": "ARE",
        "lon": "55.52"
      }
    ]
  },
  {
    "country": "United Kingdom",
    "cities": [
      {
        "city": "Aberdaron",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.78",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.73"
      },
      {
        "city": "Aberdeen/Dyce Airpo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.2",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.22"
      },
      {
        "city": "Aberporth",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.13",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.57"
      },
      {
        "city": "Aboyne",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.83"
      },
      {
        "city": "Alconbury Raf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.37",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.22"
      },
      {
        "city": "Altnaharra No2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.28",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.43"
      },
      {
        "city": "Andrewsfield",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.88",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.45"
      },
      {
        "city": "Aonach Mor",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "56.82",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.97"
      },
      {
        "city": "Aughton",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.55",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.92"
      },
      {
        "city": "Aultbea No2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.63"
      },
      {
        "city": "Aviemore",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.2",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.83"
      },
      {
        "city": "Ballykelly",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.07",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-7.02"
      },
      {
        "city": "Ballypatrick Forest",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.18",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.17"
      },
      {
        "city": "Baltasound No.2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "60.75",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.85"
      },
      {
        "city": "Bangor Harbour",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "54.67",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.67"
      },
      {
        "city": "Barra",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.03",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-7.45"
      },
      {
        "city": "Bedford",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.22",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.48"
      },
      {
        "city": "Belfast/Aldergrove",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.65",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.22"
      },
      {
        "city": "Benbecula Island",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.47",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-7.37"
      },
      {
        "city": "Benson",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.62",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.08"
      },
      {
        "city": "Bingley No.2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.82",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.87"
      },
      {
        "city": "Birmingham Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.45",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.73"
      },
      {
        "city": "Blackpool Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.77",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.03"
      },
      {
        "city": "Boscombe Down",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.17",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.75"
      },
      {
        "city": "Boulmer",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.42",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.6"
      },
      {
        "city": "Bournemouth/Hurn",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.78",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.83"
      },
      {
        "city": "Bracknell/Beaufort",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.38",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.78"
      },
      {
        "city": "Brawdy(Raf)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.88",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.12"
      },
      {
        "city": "Bridlington Mrsc",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.1",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.17"
      },
      {
        "city": "Bristol",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.38",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.72"
      },
      {
        "city": "Bristol Wea Center",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.47",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.6"
      },
      {
        "city": "Brize Norton",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.75",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.58"
      },
      {
        "city": "Butt Of Lewis (Lh)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.52",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.27"
      },
      {
        "city": "Camborne",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.22",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.32"
      },
      {
        "city": "Cape Wrath (Lgt-H)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.63",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5"
      },
      {
        "city": "Capel Curig",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.1",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.93"
      },
      {
        "city": "Cardiff Weather Cen",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.48",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.18"
      },
      {
        "city": "Cardiff-Wales Arpt",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.4",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.35"
      },
      {
        "city": "Cardinham",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.67"
      },
      {
        "city": "Carlisle",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.93",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.97"
      },
      {
        "city": "Cellarhead",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.03",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.08"
      },
      {
        "city": "Charterhall",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.7",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.38"
      },
      {
        "city": "Chivenor",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.15"
      },
      {
        "city": "Church Lawford",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.37",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.33"
      },
      {
        "city": "Cilfynydd",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.63",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.3"
      },
      {
        "city": "Collafirth Hill",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.53",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.38"
      },
      {
        "city": "Coltishall",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.77",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.35"
      },
      {
        "city": "Coningsby",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.17"
      },
      {
        "city": "Corsewall Pt. (Lh)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.15"
      },
      {
        "city": "Cottesmore",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.73",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.65"
      },
      {
        "city": "Cranwell",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.03",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.5"
      },
      {
        "city": "Crosby",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.07"
      },
      {
        "city": "Culdrose",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.25"
      },
      {
        "city": "Disforth Airfield",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.13",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.42"
      },
      {
        "city": "Donna Nook No.2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.48",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.08"
      },
      {
        "city": "Drumalbin",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.62",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.73"
      },
      {
        "city": "Dumfries/Drungans",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.05",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.65"
      },
      {
        "city": "Dundrennan",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.8",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4"
      },
      {
        "city": "Dunkeswell Aerodrom",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.23"
      },
      {
        "city": "East Midlands",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.83",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.32"
      },
      {
        "city": "Edinburgh Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.95",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.35"
      },
      {
        "city": "Emley Moor",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.62",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.67"
      },
      {
        "city": "Eskdalemuir",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "55.32",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.2"
      },
      {
        "city": "Exeter Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.73",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.42"
      },
      {
        "city": "Fair Isle",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.53",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.63"
      },
      {
        "city": "Fairford Raf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.68",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.78"
      },
      {
        "city": "Farnborough",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.28",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.77"
      },
      {
        "city": "Fife Ness",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.3",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.58"
      },
      {
        "city": "Finningley(Raf)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.48",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1"
      },
      {
        "city": "Foula",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "60.12",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.07"
      },
      {
        "city": "Foyers",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.27",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.48"
      },
      {
        "city": "Fylingdales",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.37",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.67"
      },
      {
        "city": "Glasgow Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.43"
      },
      {
        "city": "Glen Ogle",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "56.42",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.32"
      },
      {
        "city": "Glenanne",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.23",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.5"
      },
      {
        "city": "Gravesend-Broadness",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.47",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.3"
      },
      {
        "city": "Great Dun Fell",
        "state": "",
        "cz": "7",
        "state_id": "",
        "lat": "54.68",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.45"
      },
      {
        "city": "Greenock Mrcc",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.97",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.8"
      },
      {
        "city": "Gwennap Head",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.03",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.67"
      },
      {
        "city": "Hawarden",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.17",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.98"
      },
      {
        "city": "Hemsby",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.68",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.68"
      },
      {
        "city": "Herstmonceux",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.9",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.32"
      },
      {
        "city": "Herstmonceux",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "50.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.33"
      },
      {
        "city": "High Wycombe Hqstc",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.68",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.8"
      },
      {
        "city": "Hillsborough",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.48",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.1"
      },
      {
        "city": "Holbeach",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.15"
      },
      {
        "city": "Honington",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.33",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.77"
      },
      {
        "city": "Hyskeir (Lgt-H)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.97",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.68"
      },
      {
        "city": "Inverbervie No.2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.85",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.27"
      },
      {
        "city": "Invergordon Harbour",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.68",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.17"
      },
      {
        "city": "Isle Of Portland",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.52",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.45"
      },
      {
        "city": "Kenley Airfield",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.3",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.08"
      },
      {
        "city": "Keswick",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.62",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.17"
      },
      {
        "city": "Kinloss",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.65",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.57"
      },
      {
        "city": "Kirkwall Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.95",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.9"
      },
      {
        "city": "Lakenheath Raf",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.42",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.57"
      },
      {
        "city": "Lakenheath Raf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.4",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.57"
      },
      {
        "city": "Langdon Bay",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.13",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.35"
      },
      {
        "city": "Larkhill",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.2",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.8"
      },
      {
        "city": "Larne",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.85",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.8"
      },
      {
        "city": "Leconfield",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.43"
      },
      {
        "city": "Leeds Bradford",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.65"
      },
      {
        "city": "Leeds Weather Ctr",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.8",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.55"
      },
      {
        "city": "Leeming",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.3",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.53"
      },
      {
        "city": "Lerwick",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "60.13",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.18"
      },
      {
        "city": "Leuchars",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.4",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.87"
      },
      {
        "city": "Linton-On-Ouse",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.05",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.25"
      },
      {
        "city": "Liscombe",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.6"
      },
      {
        "city": "Little Rissington",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.68"
      },
      {
        "city": "Liverpool",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.33",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.85"
      },
      {
        "city": "Loch Glascarnoch",
        "state": "",
        "cz": "6A",
        "state_id": "",
        "lat": "57.72",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.88"
      },
      {
        "city": "Loftus Samos",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.57",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.87"
      },
      {
        "city": "London Wea Center",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.52",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.12"
      },
      {
        "city": "London Weather Cent",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.52",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.1"
      },
      {
        "city": "London/Gatwick Arpt",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.15",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.18"
      },
      {
        "city": "London/Heathrow Air",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.48",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.45"
      },
      {
        "city": "Lossiemouth",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.72",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.32"
      },
      {
        "city": "Lough Fea",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.72",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.82"
      },
      {
        "city": "Lundy Isl (Lgt-H)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.17",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.65"
      },
      {
        "city": "Luton",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.37"
      },
      {
        "city": "Lyneham",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.98"
      },
      {
        "city": "Machrihanish",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.43",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.7"
      },
      {
        "city": "Madley",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.03",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.85"
      },
      {
        "city": "Manchester Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.35",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.28"
      },
      {
        "city": "Manston",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.35",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.37"
      },
      {
        "city": "Marham",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.65",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.57"
      },
      {
        "city": "Middle Wallop",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.15",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.57"
      },
      {
        "city": "Mildenhall Raf",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.37",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.48"
      },
      {
        "city": "Milford Haven",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.7",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.05"
      },
      {
        "city": "Muckle Flugga",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "60.85",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.88"
      },
      {
        "city": "Muckle Holm",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "60.58",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.27"
      },
      {
        "city": "Mumbles",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.57",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.98"
      },
      {
        "city": "Newcastle",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.03",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.68"
      },
      {
        "city": "Newcastle Weather C",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.98",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.6"
      },
      {
        "city": "Newhaven (Lgt-H)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.78",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.05"
      },
      {
        "city": "North Rona",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.12",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.82"
      },
      {
        "city": "Northolt",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.55",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.42"
      },
      {
        "city": "Norwich Wea Cntre",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.63",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.32"
      },
      {
        "city": "Nottingham/Watnall",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.25"
      },
      {
        "city": "Oban",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.42",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.47"
      },
      {
        "city": "Odiham",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.23",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.95"
      },
      {
        "city": "Orsay (Lgt-H)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.67",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.5"
      },
      {
        "city": "Pembrey Sands",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.72",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.37"
      },
      {
        "city": "Pendennis Point",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.15",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.07"
      },
      {
        "city": "Pendine (Aut)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.75",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.52"
      },
      {
        "city": "Pershore",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.15",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.03"
      },
      {
        "city": "Peterhead Harbour",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.77"
      },
      {
        "city": "Plymouth Mount Batt",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.35",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.12"
      },
      {
        "city": "Portglenone",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.45"
      },
      {
        "city": "Portland Heliport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.57",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.45"
      },
      {
        "city": "Prestwick Rnas",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.52",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.58"
      },
      {
        "city": "Prestwick(Civ/Navy)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.58"
      },
      {
        "city": "Redesdale Camp",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.28",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.28"
      },
      {
        "city": "Rhyl",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.25",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.5"
      },
      {
        "city": "Scilly: St Marys Ai",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "49.92",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.3"
      },
      {
        "city": "Sella Ness",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "60.45",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.27"
      },
      {
        "city": "Sennybridge",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.07",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.62"
      },
      {
        "city": "Shap",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.68"
      },
      {
        "city": "Shawbury",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.8",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.67"
      },
      {
        "city": "Sheerness",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.45",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.75"
      },
      {
        "city": "Shobdon",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.25",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.88"
      },
      {
        "city": "Shoeburyness",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.55",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.83"
      },
      {
        "city": "Shoreham Airport",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.83",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.28"
      },
      {
        "city": "Skye/Lusa",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.25",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5.8"
      },
      {
        "city": "Solent M.R.S.C.",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.8",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.22"
      },
      {
        "city": "Southampton Wx Cntr",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.9",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.4"
      },
      {
        "city": "Southend",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.57",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.68"
      },
      {
        "city": "Spadeadam",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.05",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.55"
      },
      {
        "city": "St Angelo",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.4",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-7.65"
      },
      {
        "city": "St Bees Head No.2",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.52",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.6"
      },
      {
        "city": "St. Catherines Poin",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.58",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.3"
      },
      {
        "city": "St. Mawgan",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.43",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-5"
      },
      {
        "city": "Stansted Airport",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.88",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.23"
      },
      {
        "city": "Stornoway",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.22",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.32"
      },
      {
        "city": "Strathallen Airfiel",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.32",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.73"
      },
      {
        "city": "Sule Skerry",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.4"
      },
      {
        "city": "Sumburgh (Cape)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "59.88",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.3"
      },
      {
        "city": "Tain Range",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "57.82",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.97"
      },
      {
        "city": "Thames Tower (Aut)",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.67",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.1"
      },
      {
        "city": "Thorney Island",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "50.82",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.92"
      },
      {
        "city": "Tiree",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.5",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-6.88"
      },
      {
        "city": "Trawsgoed",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.35",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.95"
      },
      {
        "city": "Tulloch Bridge",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "56.87",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.7"
      },
      {
        "city": "Tynemouth",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "55.02",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.42"
      },
      {
        "city": "Upper Heyford Raf",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "51.93",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-1.25"
      },
      {
        "city": "Valley",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "53.25",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.53"
      },
      {
        "city": "Waddington",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.17",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.52"
      },
      {
        "city": "Wainfleet",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "53.08",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.27"
      },
      {
        "city": "Walney Island",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.12",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.25"
      },
      {
        "city": "Walton-On-Naze",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51.85",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.28"
      },
      {
        "city": "Warcop Range",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.57",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.42"
      },
      {
        "city": "Wattisham",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.12",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "0.97"
      },
      {
        "city": "West Freugh",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "54.85",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-4.95"
      },
      {
        "city": "Weybourne",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "52.95",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "1.13"
      },
      {
        "city": "Wick",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "58.45",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-3.08"
      },
      {
        "city": "Wittering",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.62",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.47"
      },
      {
        "city": "Wyton(Raf)",
        "state": "",
        "cz": "5A",
        "state_id": "",
        "lat": "52.35",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-0.12"
      },
      {
        "city": "Yeovilton",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "51",
        "country": "United Kingdom",
        "country_id": "GBR",
        "lon": "-2.63"
      }
    ]
  },
  {
    "country": "Uruguay",
    "cities": [
      {
        "city": "Artigas",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-30.38",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-56.5"
      },
      {
        "city": "Carrasco",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.83",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-56"
      },
      {
        "city": "Colonia",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.45",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-57.83"
      },
      {
        "city": "Paso De Los Toros",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-32.8",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-56.52"
      },
      {
        "city": "Prado",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.85",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-56.2"
      },
      {
        "city": "Rivera",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-30.88",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-55.53"
      },
      {
        "city": "Rocha",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-34.48",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-54.3"
      },
      {
        "city": "Salto",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-31.38",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-57.95"
      },
      {
        "city": "Treinta Y Tres",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-33.22",
        "country": "Uruguay",
        "country_id": "URY",
        "lon": "-54.38"
      }
    ]
  },
  {
    "country": "Uzbekistan",
    "cities": [
      {
        "city": "Ak-Bajtal",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "43.15",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "64.33"
      },
      {
        "city": "Buhara",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "39.72",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "64.62"
      },
      {
        "city": "Buzaubaj",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "41.75",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "62.47"
      },
      {
        "city": "Chimbaj",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "42.95",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "59.82"
      },
      {
        "city": "Darganata",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.47",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "62.28"
      },
      {
        "city": "Dzizak",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.12",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "67.83"
      },
      {
        "city": "Fergana",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.37",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "71.75"
      },
      {
        "city": "Karshi",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "38.8",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "65.72"
      },
      {
        "city": "Kungrad",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "43.08",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "58.93"
      },
      {
        "city": "Namangan",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.98",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "71.58"
      },
      {
        "city": "Nukus",
        "state": "",
        "cz": "5B",
        "state_id": "",
        "lat": "42.45",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "59.62"
      },
      {
        "city": "Nurata",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "40.55",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "65.68"
      },
      {
        "city": "Pskem",
        "state": "",
        "cz": "5C",
        "state_id": "",
        "lat": "41.9",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "70.37"
      },
      {
        "city": "Samarkand",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "39.57",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "66.95"
      },
      {
        "city": "Syr-Darja",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "40.82",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "68.68"
      },
      {
        "city": "Tamdy",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "41.73",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "64.62"
      },
      {
        "city": "Tashkent",
        "state": "",
        "cz": "4A",
        "state_id": "",
        "lat": "41.27",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "69.27"
      },
      {
        "city": "Termez",
        "state": "",
        "cz": "3B",
        "state_id": "",
        "lat": "37.23",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "67.27"
      },
      {
        "city": "Urgench",
        "state": "",
        "cz": "4B",
        "state_id": "",
        "lat": "41.57",
        "country": "Uzbekistan",
        "country_id": "UZB",
        "lon": "60.57"
      }
    ]
  },
  {
    "country": "Vanuatu",
    "cities": [
      {
        "city": "Aneityum",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-20.23",
        "country": "Vanuatu",
        "country_id": "VUT",
        "lon": "169.77"
      },
      {
        "city": "Bauerfield (Efate)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-17.7",
        "country": "Vanuatu",
        "country_id": "VUT",
        "lon": "168.3"
      },
      {
        "city": "Pekoa Airport (Santo)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-15.52",
        "country": "Vanuatu",
        "country_id": "VUT",
        "lon": "167.22"
      }
    ]
  },
  {
    "country": "Venezuela",
    "cities": [
      {
        "city": "Caracas/Maiquetia A",
        "state": "",
        "cz": "1B",
        "state_id": "",
        "lat": "10.6",
        "country": "Venezuela",
        "country_id": "VEN",
        "lon": "-66.98"
      },
      {
        "city": "Guanare",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.02",
        "country": "Venezuela",
        "country_id": "VEN",
        "lon": "-69.73"
      },
      {
        "city": "San Antonio Del Tac",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "7.85",
        "country": "Venezuela",
        "country_id": "VEN",
        "lon": "-72.45"
      }
    ]
  },
  {
    "country": "Viet Nam",
    "cities": [
      {
        "city": "Bach Long Vi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.13",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "107.72"
      },
      {
        "city": "Ca Mau",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "9.18",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "105.15"
      },
      {
        "city": "Cao Bang",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "22.67",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.25"
      },
      {
        "city": "Con Son",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "8.68",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.6"
      },
      {
        "city": "Da Nang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.07",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "108.35"
      },
      {
        "city": "Dong Hoi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "17.48",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.6"
      },
      {
        "city": "Ha Noi",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "21.03",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "105.8"
      },
      {
        "city": "Hue",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "16.43",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "107.58"
      },
      {
        "city": "Lang Son",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.83",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.77"
      },
      {
        "city": "Lao Cai",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "22.5",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "103.97"
      },
      {
        "city": "Nam Dinh",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.43",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.15"
      },
      {
        "city": "Nha Trang",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "12.22",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "109.22"
      },
      {
        "city": "Phan Thiet",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.93",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "108.1"
      },
      {
        "city": "Phu Lien",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "20.8",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.63"
      },
      {
        "city": "Phu Quoc",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.22",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "103.97"
      },
      {
        "city": "Quy Nhon",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "13.77",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "109.22"
      },
      {
        "city": "Son La",
        "state": "",
        "cz": "2A",
        "state_id": "",
        "lat": "21.33",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "103.9"
      },
      {
        "city": "Tan Son Hoa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "10.82",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "106.67"
      },
      {
        "city": "Thanh Hoa",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "19.75",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "105.78"
      },
      {
        "city": "Vinh",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "18.67",
        "country": "Viet Nam",
        "country_id": "VNM",
        "lon": "105.68"
      }
    ]
  },
  {
    "country": "Wallis and Futuna",
    "cities": [
      {
        "city": "Hihifo (Ile Wallis)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-13.23",
        "country": "Wallis and Futuna",
        "country_id": "WLF",
        "lon": "-176.17"
      },
      {
        "city": "Maopoopo (Ile Futuna)",
        "state": "",
        "cz": "1A",
        "state_id": "",
        "lat": "-14.32",
        "country": "Wallis and Futuna",
        "country_id": "WLF",
        "lon": "-178.12"
      }
    ]
  },
  {
    "country": "Zimbabwe",
    "cities": [
      {
        "city": "Harare (Kutsaga)",
        "state": "",
        "cz": "3A",
        "state_id": "",
        "lat": "-17.92",
        "country": "Zimbabwe",
        "country_id": "ZWE",
        "lon": "31.13"
      },
      {
        "city": "Masvingo",
        "state": "",
        "cz": "2B",
        "state_id": "",
        "lat": "-20.07",
        "country": "Zimbabwe",
        "country_id": "ZWE",
        "lon": "30.87"
      }
    ]
  }
]
;});

